import { Button, Fade, makeStyles, Typography } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import React, { useEffect, useRef, useState } from "react";
import Moment from "react-moment";
import { centers as centersAPI } from "../../../api";
import { DATE_FORMAT, FADE_TRANSITION } from "../../../lib/constants";
import CustomTable from "../../core/CustomTable";
import { LoadingIcon } from "../../core/LoadingIcon";
import ModalAddFormPayment from "./ModalAddFormPayment";

const NO_VALUE = "No valor";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
  },
  button: {
    marginRight: theme.spacing(1),
  },
}));

const columns = [
  {
    title: "Fecha",
    field: "date",
    render: (data) => <Moment format={DATE_FORMAT}>{data.date}</Moment>,
    width: 100,
    emptyValue: NO_VALUE,
    cardFormat: "DATE",
  },
  {
    title: "Concepto",
    field: "description",
    render: (data) => (
      <Typography>{data.description && data.description}</Typography>
    ),
    emptyValue: NO_VALUE,
    cardFormat: "STRING",
  },
  {
    title: "Importe",
    field: "quantity",
    type: "currency",
    currencySetting: { currencyCode: "EUR" },
    emptyValue: NO_VALUE,
    cardFormat: "CURRENCY",
  },
];

const ArchingsClosures = ({ centerID }) => {
  const classes = useStyles();
  const tableRef = useRef(null);

  // const columns = useSearchParamsInColumns(tableColumns);
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const defaultPageSize = 10;
  const [totalResults, setTotalResults] = useState();
  const [openAdd, setOpenAdd] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    setIsLoading(true);
    let params = {
      centerID: centerID !== "1" ? centerID : null,
      perPage: defaultPageSize,
      page: page,
    };
    centersAPI.getClosures(params).then((result) => {
      setData(result.results);
      setPage(result.page);
      setTotalResults(result.totalCount);
      setIsLoading(false);
    });
  }, [page, openAdd, centerID]);

  const handlePageChange = (newPage) => {
    window.scrollTo(0, 0);
    setPage(newPage);
  };

  const handleClickAddOpen = () => {
    setOpenAdd(true);
  };

  if (columns.length === 0) return "LOADING";

  return (
    <Fade in={true} timeout={FADE_TRANSITION}>
      <div className={classes.root}>
        <ModalAddFormPayment
          open={openAdd}
          setOpen={setOpenAdd}
          tableRef={tableRef}
          centerID={centerID}
        />
        {centerID === 1 ? null : (
          <div style={{ margin: "40px 40px 5px 40px" }}>
            <Grid container xs={12} direction="row" justify="flex-end">
              <Button className={classes.button} onClick={handleClickAddOpen}>
                RETIRAR DINERO
              </Button>
            </Grid>
          </div>
        )}

        <div style={{ padding: "20px 40px 0 40px" }}>
          {isLoading ? (
            <LoadingIcon></LoadingIcon>
          ) : (
            <CustomTable
              tableRef={tableRef}
              data={data}
              columns={columns}
              handlePageChange={handlePageChange}
              page={page}
              totalResults={totalResults}
              defaultPageSize={defaultPageSize}
              // handleClickEditOpen={handleStudentSelected}
            ></CustomTable>
          )}
        </div>
      </div>
    </Fade>
  );
};

export default ArchingsClosures;
