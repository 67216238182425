import {
  Button,
  Fade,
  Grid,
  MenuItem,
  TextField,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  classesPractical as classesPracticalAPI,
  students as studentsAPI,
  tariffs as tariffsAPI,
  payments as paymentsAPI,
} from "../../../api";
import {
  studentExitPointsApi,
  studentTeachersApi,
  
} from "../../../api/student";
import { FADE_TRANSITION } from "../../../lib/constants";
import CalendarCustom from "../../core/Calendar";
import ModalBuyClass from "./ModalBuyClass";
import ModalClassPaymentSuccess from "./ModalClassPaymentSuccess";
import ModalEditClass from "./ModalEditClass";
import ModalFormBuyPacks from "./ModalFormBuyPacks";
import ModalPackPaymentSuccess from "./ModalPackPaymentSuccess";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    minHeight: "100vh",
    "& .MuiTextField-root": {
      minWidth: "20ch",
    },
  },
  gridContainer: {
    backgroundColor: "rgb(255,255,255)",
    // margin: "20px",
    borderTop: "5px solid rgb(159,46,37)",
    borderRadius: "0 0 10px 10px",
    // padding: 20,
  },
  header: {
    backgroundColor: "rgb(248,250,252)",
    borderBottom: "2px solid rgb(227,232,239)",
    minHeight: 70,
    padding: 20,
  },
  headerText: {
    fontWeight: "bold",
    color: theme.palette.primary.main,
  },
  textArea: {
    minHeight: 150,
    minWidth: "99%",
    maxWidth: "99%",
    width: "99",
    borderRadius: 3,
    padding: 8,
  },
}));

const CalendarStudent = ({ user }) => {
  const classes = useStyles();
  const [values, setValues] = useState({
    teacherID: "Todos",
    disponibility: "Libres",
    exitPointID: "Todos",
  });
  const [teachers, setTeachers] = useState([]);
  const [exitPointOptions, setExitPointOptions] = useState([]);
  const [students] = useState([]);
  const [studentClasses, setStudentClasses] = useState(null);
  const [classesTypes] = useState([]);
  const [packs, setPacks] = useState([]);
  const [timeTable, setTimeTable] = useState([]);
  const [openEdit, setOpenEdit] = React.useState(false);
  const [openConfirmPack, setOpenConfirmPack] = React.useState(false);
  const [openFree, setOpenFree] = React.useState(false);
  const [openPack, setOpenPack] = React.useState(false);
  const [selectedClassID, setSelectedClassID] = useState("");
  const [classesAdded, setClassesAdded] = useState(null);
  const [openConfirmClass, setOpenConfirmClass] = useState(false);
  const [loading, setLoading] = useState(false);

  const history = useHistory();
  let search = window.location.search;

  let paramsObject = new URLSearchParams(search);

  let params = {
    Ds_SignatureVersion: paramsObject.get("Ds_SignatureVersion"),
    Ds_MerchantParameters: paramsObject.get("Ds_MerchantParameters"),
    Ds_Signature: paramsObject.get("Ds_Signature"),
    state: paramsObject.get("state"),
    packId: paramsObject.get("packId"),
    centerId: paramsObject.get("centerId"),
    studentId: paramsObject.get("studentId"),
    classId: paramsObject.get("classId"),
    price: paramsObject.get("price"),
  };

  const tableRef = useRef(null);

  useEffect(() => {
    if (params.packId) {
      if (params.state === "OK") {
        paymentsAPI
          .processPaymentCallback(params)
          .then((response) => {
            setClassesAdded(response.classesAdded);
            setOpenConfirmPack(true);
            history.replace({
              search: "",
            });
          })
          .catch((x) => {
            console.log("Error adding permiso");
          });
      } else {
        console.log("NO ES CORRECTA LA COMPRA");
      }
    } else if (params.classId) {
      if (params.state === "OK") {
        paymentsAPI
          .processPaymentCallbackClass(params)
          .then((response) => {
            setOpenConfirmClass(true);
            history.replace({
              search: "",
            });
          })
          .catch((x) => {
            console.log("Error adding permiso");
          });
      } else {
        console.log("NO ES CORRECTA LA COMPRA");
      }
    }
    let paramsForGets = {
      centerID: user.centerID,
      perPage: 100,
      page: 1,
    };
    studentTeachersApi.getAll(paramsForGets).then((results) => {
      if (results?.status === 403) {
        console.log("no auth");
      } else {
        setTeachers(results.data.teachersList);
      }
    });
    studentExitPointsApi
      .getAll(paramsForGets)
      .then((result) => {
        if (result.status === 403) {
          console.log("no auth");
        } else {
          setExitPointOptions(result.data.exitPointsList);
        }
      })
      .catch((err) => console.log(err));

    tariffsAPI
      .getStudentPackActives(user.id)
      .then((response) => {
        setPacks(response.result);
      })
      .catch((x) => {
        // setError("Error trayendo centros");
      });

    fetchStudentClasses();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchStudentClasses = () => {
    // student get one
    studentsAPI
      .getOwnProfile()
      .then((response) => {
        setStudentClasses(response.classes);
      })
      .catch((x) => {
        // setError("Error trayendo centros");
      });
  };

  useEffect(() => {
    getClasses();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values]);

  const getClasses = () => {
    let teacherID = null;
    let exitPointID = null;
    if (values.teacherID) {
      teacherID = values.teacherID;
    }
    if (values.exitPointID) {
      exitPointID = values.exitPointID;
    }
    let disponibility = values.disponibility;
    setLoading(true);
    classesPracticalAPI
      .getClassesForStudent({
        disponibility,
        teacherID,
        exitPointID,
        centerID: user.centerID,
      })
      .then((results) => {
        const resultsWithDates = results.results.map((elem) => {
          elem.start = new Date(elem.start);
          elem.end = new Date(elem.end);
          return elem;
        });
        setTimeTable(resultsWithDates);
        setLoading(false);
      });
  };

  const handleChange = (key, value) => {
    setValues({
      ...values,
      [key]: value,
    });
  };

  const handleClickOpenPacks = () => {
    setOpenPack(true);
  };

  const handleClickEditOpen = (ID, type) => {
    // Falta logica de clases pasadas comparandola con la fecha actual para no poder abrirlas
    if (type === "editar") {
      setSelectedClassID(ID);
      setOpenEdit(true);
    } else if (type === "reservar") {
      setSelectedClassID(ID);
      setOpenFree(true);
    }
  };

  const teacherOption = teachers.filter((elem) => {
    if (values.exitPointID === "Todos") {
      return true;
    } else {
      return elem.idExitPoint === values.exitPointID;
    }
  });

  return (
    <Fade in={true} timeout={FADE_TRANSITION}>
      <div className={classes.root}>
        <ModalFormBuyPacks
          open={openPack}
          packs={packs}
          setOpen={setOpenPack}
          studentID={user.id}
          centerID={user.centerID}
        />
        <ModalBuyClass
          studentClasses={studentClasses}
          open={openFree}
          classesTypes={classesTypes}
          teachers={teachers}
          setOpen={setOpenFree}
          tableRef={tableRef}
          classID={selectedClassID}
          students={students}
          studentID={user.id}
          getClasses={getClasses}
          fetchStudentClasses={fetchStudentClasses}
          centerID={user.centerID}
        />
        <ModalPackPaymentSuccess
          open={openConfirmPack}
          setOpen={setOpenConfirmPack}
          classesAdded={classesAdded}
          fetchStudentClasses={fetchStudentClasses}
        />
        <ModalClassPaymentSuccess
          open={openConfirmClass}
          setOpen={setOpenConfirmClass}
          fetchStudentClasses={fetchStudentClasses}
        />
        <ModalEditClass
          open={openEdit}
          classesTypes={classesTypes}
          teachers={teachers}
          setOpen={setOpenEdit}
          tableRef={tableRef}
          classID={selectedClassID}
          students={students}
          studentID={user.id}
          getClasses={getClasses}
          fetchStudentClasses={fetchStudentClasses}
        />
        <Grid
          container
          className={classes.header}
          direction="row"
          justify="space-between"
          alignItems="center"
        >
          <Grid item xs={12} sm={6}>
            <Typography className={classes.headerText}>CALENDARIO</Typography>
          </Grid>
          <Grid
            item
            container
            xs={12}
            sm={6}
            justify="flex-end"
            alignItems="flex-end"
          >
            <Grid xs={12} sm="auto">
              <Typography>
                <span style={{ color: "#ad201c", fontWeight: "bold" }}>
                  {studentClasses ? studentClasses : 0} Clases restantes
                </span>{" "}
              </Typography>
            </Grid>
            <Grid xs={12} sm="auto">
              <Button onClick={handleClickOpenPacks}>COMPRAR PACKS</Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid container style={{ margin: "20px 0" }}>
          <Grid
            item
            container
            direction="row"
            xs={12}
            md={12}
            lg={12}
            className={classes.gridContainer}
          >
            <Grid
              item
              xs={12}
              sm="auto"
              md="auto"
              lg="auto"
              style={{ margin: 10 }}
            >
              <Typography>P. Salida</Typography>
              <TextField
                fullWidth
                variant="outlined"
                size="small"
                id="teacher"
                placeholder="disponibility"
                select
                value={values.exitPointID || ""}
                onChange={(e) => handleChange("exitPointID", e.target.value)}
              >
                <MenuItem value={"Todos"}>Todos</MenuItem>
                {!!exitPointOptions.length &&
                  exitPointOptions.map((option) => (
                    <MenuItem key={option.id} value={option.id}>
                      {option.name}
                    </MenuItem>
                  ))}
              </TextField>
            </Grid>
            <Grid
              item
              xs={12}
              sm="auto"
              md="auto"
              lg="auto"
              style={{ margin: 10 }}
            >
              <Typography>Profesor</Typography>
              <TextField
                fullWidth
                variant="outlined"
                size="small"
                id="teacher"
                placeholder="teacher"
                select
                value={values.teacherID || ""}
                onChange={(e) => handleChange("teacherID", e.target.value)}
              >
                <MenuItem value={"Todos"}>Todos</MenuItem>
                {teacherOption &&
                  teacherOption.map((option) => (
                    <MenuItem key={option.id} value={option.id}>
                      {`${option.firstName} ${option.lastName1}`}
                    </MenuItem>
                  ))}
              </TextField>
            </Grid>
            <Grid
              item
              xs={12}
              sm="auto"
              md="auto"
              lg="auto"
              style={{ margin: 10 }}
            >
              <Typography>Disponibilidad</Typography>
              <TextField
                fullWidth
                variant="outlined"
                size="small"
                id="teacher"
                placeholder="disponibility"
                select
                value={values.disponibility || ""}
                onChange={(e) => handleChange("disponibility", e.target.value)}
              >
                {["Todas", "Libres"].map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          </Grid>
          <Grid item xs={12} md={12} lg={12}>
            <div className={classes.gridContainer}>
              <Grid
                container
                style={{ margin: "20px 10px" }}
                // spacing={2}
                // justify="space-between"
              >
                <Grid item>
                  <Typography>
                    <span
                      style={{
                        backgroundColor: "green",
                        padding: "5px 30px",
                        marginRight: "5px",
                      }}
                    ></span>
                    Libres
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography>
                    <span
                      style={{
                        backgroundColor: "#ad201c",
                        padding: "5px 30px",
                        marginRight: "5px",
                      }}
                    ></span>
                    Ocupadas
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography>
                    <span
                      style={{
                        backgroundColor: "rgb(207, 179, 55)",
                        padding: "5px 30px",
                        marginRight: "5px",
                      }}
                    ></span>
                    Mis clases
                  </Typography>
                </Grid>
              </Grid>
              <Grid
                container
                style={{ margin: "20px 0" }}
                spacing={2}
                xs={12}

                // justify="space-between"
              >
                <CalendarCustom
                  view="student"
                  studentID={user.id}
                  handleClickEditOpen={handleClickEditOpen}
                  events={loading ? [] : timeTable}
                ></CalendarCustom>
              </Grid>
            </div>
          </Grid>
        </Grid>
      </div>
    </Fade>
  );
};

export default CalendarStudent;
