import { Grid, makeStyles, Typography } from "@material-ui/core";
import React from "react";
import { changeFormat } from "../../lib/helpers";

const useStyles = makeStyles((theme) => ({
  card: {
    border: "1px solid rgba(226, 232, 240)",
    borderLeft: "8px solid rgb(229, 62, 62)",
    backgroundColor: "rgb(245,249,251)",
    cursor: "pointer",
  },
  card2: {
    border: "1px solid rgba(226, 232, 240)",
    borderLeft: "8px solid #ad201c",
    backgroundColor: "#fff",
    cursor: "pointer",
  },
  cardElement: {
    padding: "0.5rem",
  },
}));

const CustomCardItem = ({
  elem,
  index,
  columns,
  fetchAll = false,
  handleClickEditOpen = () => {},
}) => {
  const classes = useStyles();

  return (
    <Grid
      className={index % 2 ? classes.card : classes.card2}
      container
      direction="row"
      key={index}
      onClick={
        fetchAll
          ? () => handleClickEditOpen(elem)
          : () => handleClickEditOpen(elem.id)
      }
    >
      {columns &&
        columns.map((column, columnIndex) => {
          return (
            <Grid
              className={classes.cardElement}
              item
              xs={6}
              // direction="row"
              key={columnIndex}
            >
              <Typography style={{ color: "#718096" }}>
                {column.title.toUpperCase()}
              </Typography>
              <Typography>
                {changeFormat(elem[column.field], column["cardFormat"]) || ""}
              </Typography>
            </Grid>
          );
        })}
    </Grid>
  );
};

export default CustomCardItem;
