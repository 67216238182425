import { Button, Fade, makeStyles, Typography } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { students as studentsApi } from "../../../api";
import { FADE_TRANSITION } from "../../../lib/constants";
import StudentsForTheory from "./StudentsForTheory";
import CoursesList from "./CoursesList";
import CodesList from "./CodesList";

const useStyles = makeStyles((theme) => ({
  rootGrid: {
    maxWidth: "100%",
    margin: "auto",
  },
  root: {
    flexGrow: 1,
    width: "100%",
    minHeight: "100vh",
    "& .MuiTextField-root": {
      width: "20ch",
    },
  },
  button: {
    marginRight: theme.spacing(1),
    backgroundColor: "rgb(160, 174, 192)",
  },
  activeButton: {
    marginRight: theme.spacing(1),
    border: "1px solid #ad201c",
    backgroundColor: "#ad201c",
    opacity: 1,
    color: "#FFFFFF",
    "&:hover": {
      marginRight: theme.spacing(1),
      border: "1px solid #ad201c",
      backgroundColor: "#ad201c",
      opacity: 1,
      color: "#FFFFFF",
    },
  },
  header: {
    backgroundColor: "rgb(248,250,252)",
    borderBottom: "2px solid rgb(227,232,239)",
    height: 70,
    alignItems: "center",
  },
  headerText: {
    fontWeight: "bold",
    marginLeft: 40,
    color: theme.palette.primary.main,
  },
  headerButtons: {
    marginRight: 40,
  },
  searchContainer: {
    margin: 20,
    border: "1px solid",
    borderColor: theme.palette.primary.dark,
    borderRadius: 5,
    padding: 12,
  },
  tabPanel: {
    backgroundColor: theme.palette.primary.main,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.primary.dark,
  },
  formControl: {
    width: "100%",
  },
  buttonsGroup: {
    textAlign: "right",
    margin: "0 40px",
  },
  alignLeft: {
    textAlign: "left",
  },
  container: {
    width: "100%",
    marginLeft: 12,
    marginRight: 12,
  },
  savedSearchLink: {
    marginLeft: "auto",
    marginRight: 0,
  },
}));

const CourseViews = ({ centerID, isAdmin }) => {
  const { courseID } = useParams();
  const classes = useStyles();
  const [view, setView] = useState("Cursos");
  const [student, setStudent] = useState("");

  useEffect(() => {
    if (courseID) {
      studentsApi
        .getOne(courseID)
        .then((response) => {
          setStudent(response);
        })
        .catch((x) => {
          console.log("Error trayendo estudiante");
        });
    }
  }, [courseID, centerID]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const changeView = (newView) => {
    setView(newView);
  };

  return (
    <Fade in={true} timeout={FADE_TRANSITION}>
      <div className={classes.root}>
        <Grid
          container
          direction="row"
          justify="space-between"
          className={classes.header}
        >
          <Grid item>
            <Typography className={classes.headerText}>
              <span>{view.toUpperCase()}</span>
            </Typography>
            <Typography className={classes.headerText}>
              <span>
                {student.firstName} {student.lastName1} {student.registerNumber}
              </span>
            </Typography>
          </Grid>
          <Grid item className={classes.buttonsGroup}>
            <Button
              onClick={() => changeView("Cursos")}
              variant="outlined"
              className={
                view === "Cursos" ? classes.activeButton : classes.button
              }
            >
              <span>CURSOS</span>
            </Button>
            <Button
              onClick={() => changeView("Códigos")}
              variant="outlined"
              className={
                view === "Códigos" ? classes.activeButton : classes.button
              }
            >
              <span>CÓDIGOS</span>
            </Button>
            <Button
              onClick={() => changeView("Seguimientos")}
              variant="outlined"
              className={
                view === "Seguimientos" ? classes.activeButton : classes.button
              }
            >
              <span>SEGUIMIENTOS</span>
            </Button>
          </Grid>
        </Grid>

        {view === "Cursos" && (
          <CoursesList
            centerID={centerID}
            courseID={courseID}
            isAdmin={isAdmin}
          />
        )}
        {view === "Códigos" && (
          <CodesList
            centerID={centerID}
            courseID={courseID}
            isAdmin={isAdmin}
          />
        )}
        {view === "Seguimientos" && (
          <StudentsForTheory
            centerID={centerID}
            courseID={courseID}
            isAdmin={isAdmin}
          />
        )}
      </div>
    </Fade>
  );
};

export default CourseViews;
