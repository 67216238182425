import { Grid, Hidden, makeStyles } from "@material-ui/core";
import { Pagination } from "@material-ui/lab";
import MaterialTable from "material-table";
import React from "react";
import { ROJO_AUTIUS } from "../../lib/constants";
import CustomCardList from "./CustomCardList";

const useStyles = makeStyles((theme) => ({
  rootGrid: {
    maxWidth: "100%",
    margin: "auto",
  },
}));

const CustomTable = ({
  tableRef,
  data,
  columns,
  handlePageChange,
  page,
  totalResults,
  defaultPageSize,
  hasPagination = true,
  hasActions = false,
  fetchAll = false,
  isEditable = false,
  handleProfile = () => {},
  handleClickEditOpen = () => {},
  handleDelete = () => {},
}) => {
  const classes = useStyles();
  return (
    <Grid container className={classes.rootGrid}>
      <Hidden mdDown>
        <Grid item xs={12}>
          <MaterialTable
            style={{
              border: 0,
              display: "grid",
              zIndex: 0,
            }}
            tableRef={tableRef}
            columns={columns}
            data={data}
            actions={
              hasActions
                ? [
                    {
                      icon: isEditable ? "edit" : "delete",
                      tooltip: isEditable ? "Editar" : "Borrar",
                      onClick: (event, rowData) => {
                        handleDelete(rowData.id);
                      },
                    },
                  ]
                : null
            }
            localization={{
              header: { actions: "Acciones" },
              body: {
                emptyDataSourceMessage: "NO SE HAN ENCONTRADO RESULTADOS",
              },
            }}
            options={{
              actionsColumnIndex: -1,
              headerStyle: {
                backgroundColor: ROJO_AUTIUS,
                color: "#FFF",
                fontWeight: "bold",
                paddingTop: 8,
                paddingBottom: 8,
              },
              rowStyle: (x) => {
                if (x.active === 0 && x.firstName) {
                  return {
                    backgroundColor: "rgba(254, 215, 215, 1)",
                    border: 0,
                  };
                }
                if (x.tableData.id % 2) {
                  return { backgroundColor: "rgb(245,249,251)", border: 0 };
                }

                return { border: 0 };
              },
              paging: false,
              // pageSize: defaultPageSize,
              pageSizeOptions: [],
              addRowPosition: "first",
              search: false,
              toolbar: false,
            }}
            onRowClick={
              fetchAll
                ? (event, rowData) => handleClickEditOpen(rowData)
                : (event, rowData) => handleClickEditOpen(rowData.id)
            }
          />
        </Grid>
      </Hidden>
      <Hidden lgUp>
        <CustomCardList
          data={data}
          columns={columns}
          handleClickEditOpen={handleClickEditOpen}
          fetchAll={fetchAll}
        ></CustomCardList>
      </Hidden>

      {hasPagination ? (
        <Grid container style={{ justifyContent: "flex-end", padding: "20px" }}>
          <Pagination
            shape="rounded"
            color="primary"
            // Hacer operacion para saber el numero de paginas a mostrar dependiendo de los resultados que tenga(igual puedo traer el dato ya hecho dle back)
            count={Math.ceil(totalResults / defaultPageSize)}
            page={page}
            onChange={
              (event, newPage) => handlePageChange(newPage)
              // handlePageChange
            }
            // defaultPageSize={defaultPageSize}
            // pageSize={defaultPageSize}
            // total={totalResults}
          />
        </Grid>
      ) : null}
    </Grid>
  );
};

export default CustomTable;
