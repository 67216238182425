import { Fade, makeStyles, Typography } from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import Moment from "react-moment";
import { useParams } from "react-router-dom";
import { courses as coursesApi } from "../../../api";
import { DATE_FORMAT_COMPLETE, FADE_TRANSITION } from "../../../lib/constants";
import CustomTable from "../../core/CustomTable";
import { LoadingIcon } from "../../core/LoadingIcon";
import { Link } from "react-router-dom";

const NO_VALUE = "No valor";

const useStyles = makeStyles((theme) => ({
  rootGrid: {
    maxWidth: "100%",
    margin: "auto",
  },
  root: {
    flexGrow: 1,
    width: "100%",
    height: "100vh",
    "& .MuiTextField-root": {
      minWidth: "20ch",
    },
  },
  header: {
    backgroundColor: "rgb(248,250,252)",
    borderBottom: "2px solid rgb(227,232,239)",
    height: 70,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  headerText: {
    fontWeight: "bold",
    marginLeft: 40,
    color: theme.palette.primary.main,
  },
  headerButtons: {
    marginRight: 40,
  },
  searchContainer: {
    margin: 20,
    border: "1px solid",
    borderColor: theme.palette.primary.dark,
    borderRadius: 5,
    padding: 12,
  },
  tabPanel: {
    backgroundColor: theme.palette.primary.main,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.primary.dark,
  },
  marginLeft: {
    marginLeft: "10px",
  },
  formControl: {
    width: "100%",
  },
  alignRight: {
    textAlign: "right",
  },
  alignLeft: {
    textAlign: "left",
  },
  container: {
    width: "100%",
    marginLeft: 12,
    marginRight: 12,
  },
  savedSearchLink: {
    marginLeft: "auto",
    marginRight: 0,
  },
}));

const columns = [
  {
    title: "NOMBRE",
    field: "firstName",
    render: (data) => (
      <Link
      to={`/edit-student/${data.id}`}
      target="_blank"
      rel="noopener noreferrer"
    >
      <Typography >
        {data.firstName} {data.lastName1}
      </Typography>
      </Link>
    ),
    emptyValue: NO_VALUE,
    cardFormat: "STRING",
    cellStyle: { textAlign: "center" },
    headerStyle: { textAlign: "center" },
  },
  {
    title: "ESTADO",
    field: "registerNumber",
    render: (data) => (
      <Typography>
        {data.registerNumber == null ? "" : "MATRICULADO"}
      </Typography>
    ),
    cardFormat: "STRING",
    cellStyle: { textAlign: "center" },
    headerStyle: { textAlign: "center" },
  },
  {
    title: "FECHA COMPRA",
    field: "created_at",
    render: (data) => (
      <Moment format={DATE_FORMAT_COMPLETE}>{data.created_at}</Moment>
    ),
    emptyValue: NO_VALUE,
    cardFormat: "DATE_COMPLETE",
    cellStyle: { textAlign: "center" },
    headerStyle: { textAlign: "center" },
  },
];

const CourseStudentsList = ({ centerID, isAdmin }) => {
  const { courseID } = useParams();

  const classes = useStyles();
  const tableRef = useRef(null);

  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);
  const [, setSelectedCourseID] = useState("");
  const [page, setPage] = useState(1);
  const [, setTotalResults] = useState();
  const [sorting, ] = useState({ orderBy: "name", orderDir: "asc" });

  const [criteria, ] = useState({ type: "Todos" });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    setIsLoading(true);
    let params = {
      centerID: centerID !== "1" ? centerID : null,
      courseID: courseID,
      ...criteria,
    };

    coursesApi.getStudentsCourse(params).then((result) => {
      setData(result.results);
      setPage(result.page);
      setTotalResults(result.totalCount);
      setIsLoading(false);
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, sorting, centerID]);


  const handlePageChange = (newPage) => {
    window.scrollTo(0, 0);
    setPage(newPage);
  };
  const handleStudentSelected = (id) => {
    setSelectedCourseID(id);
  };

  if (columns.length === 0) return "LOADING";

  return (
    <Fade in={true} timeout={FADE_TRANSITION}>
      <div className={classes.root}>
        <div style={{ padding: "20px 40px 0 40px" }}>
          {isLoading ? (
            <LoadingIcon></LoadingIcon>
          ) : (
            <CustomTable
              tableRef={tableRef}
              data={data}
              columns={columns}
              handlePageChange={handlePageChange}
              hasPagination={false}
              handleClickEditOpen={handleStudentSelected}
            ></CustomTable>
          )}
        </div>
      </div>
    </Fade>
  );
};

export default CourseStudentsList;
