import React from "react";

export const Sms = (props) => (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    width={props.width || "100px"}
    height={props.height || "100px"}
    viewBox="0 0 748.581 444.096"
    enable-background="new 0 0 748.581 444.096"
  >
    <g id="BG">
      <g id="Layer_2"></g>
    </g>
    <g id="Icon">
      <g>
        <g>
          <path
            fill="#B20903"
            d="M92.944,391.576c3.354-7.212,5.945-14.582,7.824-22.02C44.153,335.103,6.343,272.815,6.343,201.695
				C6.343,93.247,94.259,5.333,202.706,5.333h345.276c108.447,0,196.361,87.914,196.361,196.362
				c0,108.447-87.914,196.361-196.361,196.361H202.706c-14.912,0-29.434-1.671-43.395-4.82
				c-25.564,31.89-65.287,48.916-105.486,46.068C70.163,427.353,83.804,411.228,92.944,391.576z"
          />
        </g>
        <g>
          <g>
            <path
              fill="#E63B76"
              d="M165.866,335.494c-0.584-0.43-1.168-0.857-1.746-1.294C164.608,334.567,165.187,334.998,165.866,335.494
					z"
            />
          </g>
          <g>
            <path
              fill="#E63B76"
              d="M184.569,347.976c-6.318-3.795-12.439-7.878-18.354-12.228
					C169.917,338.445,176.149,342.791,184.569,347.976z"
            />
          </g>
          <g>
            <path
              fill="#E63B76"
              d="M164.12,334.2c-1.193-0.896-1.824-1.397-1.83-1.401C162.899,333.266,163.505,333.738,164.12,334.2z"
            />
          </g>
          <g>
            <path
              fill="#5B0000"
              d="M92.944,391.576c3.354-7.212,5.945-14.582,7.824-22.02C44.153,335.103,6.343,272.815,6.343,201.695
					c0-69.006,35.604-129.69,89.436-164.709C79.093,69.39,69.667,106.145,69.667,145.103c0,74.571,37.021,146.944,97.105,191.053
					c5.742,4.215,11.691,8.152,17.797,11.82c31.305,19.281,92.711,50.08,165.554,50.08H202.706c-14.912,0-29.434-1.671-43.395-4.82
					c-25.564,31.89-65.287,48.916-105.486,46.068C70.163,427.353,83.804,411.228,92.944,391.576z"
            />
          </g>
          <g>
            <path
              fill="#E63B76"
              d="M166.216,335.749c-0.119-0.087-0.236-0.171-0.35-0.255C165.981,335.58,166.099,335.664,166.216,335.749z
					"
            />
          </g>
        </g>
        <g>
          <path
            fill="#7C0505"
            d="M549.128,28.255c95.766,0,173.401,77.635,173.401,173.401c0,39.379-13.15,75.673-35.267,104.782
				c-0.231,0.305-0.448,0.619-0.681,0.922c0.449-0.78,0.874-1.576,1.312-2.363c10.458-23.896,16.273-50.287,16.273-78.041
				c0-58.63-25.899-111.207-66.876-146.927c-33.488-29.193-77.048-47.114-124.781-47.881
				C524.317,29.61,536.563,28.255,549.128,28.255z"
          />
        </g>
      </g>
      <g>
        <path
          fill="#FFFFFF"
          d="M180.357,263.62c-10.406-3.021-18.764-6.948-25.073-11.781l11.077-24.569
			c6.042,4.431,13.222,7.989,21.548,10.673c8.323,2.688,16.646,4.028,24.972,4.028c9.264,0,16.111-1.375,20.542-4.128
			c4.431-2.75,6.646-6.41,6.646-10.976c0-3.354-1.309-6.142-3.927-8.357s-5.976-3.993-10.069-5.337
			c-4.097-1.34-9.635-2.819-16.614-4.43c-10.743-2.549-19.535-5.101-26.382-7.653c-6.847-2.549-12.722-6.646-17.621-12.285
			c-4.903-5.639-7.351-13.156-7.351-22.555c0-8.188,2.215-15.607,6.646-22.253c4.431-6.646,11.108-11.913,20.038-15.809
			c8.927-3.893,19.836-5.84,32.725-5.84c8.993,0,17.788,1.076,26.382,3.222c8.59,2.149,16.111,5.236,22.555,9.264l-10.069,24.771
			c-13.024-7.382-26.048-11.076-39.069-11.076c-9.132,0-15.878,1.479-20.239,4.431c-4.365,2.955-6.545,6.847-6.545,11.68
			c0,4.833,2.518,8.427,7.552,10.774c5.035,2.351,12.719,4.667,23.059,6.948c10.74,2.552,19.535,5.104,26.382,7.653
			c6.847,2.552,12.719,6.58,17.621,12.083c4.899,5.507,7.351,12.958,7.351,22.354c0,8.055-2.25,15.406-6.746,22.052
			c-4.5,6.646-11.247,11.917-20.24,15.809c-8.996,3.892-19.937,5.84-32.826,5.84C201.535,268.151,190.76,266.641,180.357,263.62z"
        />
        <path
          fill="#FFFFFF"
          d="M423.126,265.734l-0.202-84.583l-41.485,69.68h-14.701l-41.285-67.867v82.77h-30.61V124.764h26.985
			l52.764,87.603l51.958-87.603h26.785l0.403,140.971H423.126z"
        />
        <path
          fill="#FFFFFF"
          d="M501.161,263.62c-10.406-3.021-18.764-6.948-25.073-11.781l11.077-24.569
			c6.042,4.431,13.222,7.989,21.548,10.673c8.323,2.688,16.646,4.028,24.972,4.028c9.264,0,16.111-1.375,20.542-4.128
			c4.431-2.75,6.646-6.41,6.646-10.976c0-3.354-1.309-6.142-3.927-8.357s-5.976-3.993-10.069-5.337
			c-4.097-1.34-9.635-2.819-16.614-4.43c-10.743-2.549-19.535-5.101-26.382-7.653c-6.847-2.549-12.722-6.646-17.621-12.285
			c-4.903-5.639-7.351-13.156-7.351-22.555c0-8.188,2.215-15.607,6.646-22.253c4.431-6.646,11.108-11.913,20.038-15.809
			c8.927-3.893,19.836-5.84,32.725-5.84c8.993,0,17.788,1.076,26.381,3.222c8.594,2.149,16.113,5.236,22.559,9.264l-10.07,24.771
			c-13.025-7.382-26.049-11.076-39.07-11.076c-9.132,0-15.878,1.479-20.239,4.431c-4.365,2.955-6.545,6.847-6.545,11.68
			c0,4.833,2.518,8.427,7.552,10.774c5.035,2.351,12.719,4.667,23.059,6.948c10.74,2.552,19.535,5.104,26.381,7.653
			c6.85,2.552,12.721,6.58,17.623,12.083c4.9,5.507,7.352,12.958,7.352,22.354c0,8.055-2.25,15.406-6.746,22.052
			c-4.5,6.646-11.246,11.917-20.242,15.809c-8.996,3.892-19.937,5.84-32.825,5.84C522.338,268.151,511.563,266.641,501.161,263.62z"
        />
      </g>
    </g>
  </svg>
);
