import {
  Button,
  Fade,
  makeStyles,
  MenuItem,
  TextField,
  Typography,
} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import CloseIcon from "@material-ui/icons/Close";
import DoneIcon from "@material-ui/icons/Done";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import React, { useEffect, useRef, useState } from "react";
import Moment from "react-moment";
import { courses as coursesAPI } from "../../../api";
import { DATE_FORMAT, FADE_TRANSITION } from "../../../lib/constants";
import { activeOptions } from "../../../lib/textOptions";
import CustomDatePicker from "../../core/CustomDatePicker";
import CustomTable from "../../core/CustomTable";
import ModalEditStudentTheory from "./ModalEditStudentTheory";
import moment from "moment";
import { LoadingIcon } from "../../core/LoadingIcon";
import { Link } from "react-router-dom";

const NO_VALUE = "No valor";

const useStyles = makeStyles((theme) => ({
  rootGrid: {
    maxWidth: "100%",
    margin: "auto",
  },
  root: {
    flexGrow: 1,
    width: "100%",
    "& .MuiTextField-root": {
      minWidth: "20ch",
    },
  },
  header: {
    backgroundColor: "rgb(248,250,252)",
    borderBottom: "2px solid rgb(227,232,239)",
    height: 70,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  headerText: {
    fontWeight: "bold",
    marginLeft: 40,
    color: theme.palette.primary.main,
  },
  headerButtons: {
    marginRight: 40,
  },
  searchContainer: {
    margin: 20,
    border: "1px solid",
    borderColor: theme.palette.primary.dark,
    borderRadius: 5,
    padding: 12,
  },
  tabPanel: {
    backgroundColor: theme.palette.primary.main,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.primary.dark,
  },
  marginLeft: {
    marginLeft: "10px",
  },
  formControl: {
    width: "100%",
  },
  alignRight: {
    textAlign: "right",
  },
  alignLeft: {
    textAlign: "left",
  },
  container: {
    width: "100%",
    marginLeft: 12,
    marginRight: 12,
  },
  savedSearchLink: {
    marginLeft: "auto",
    marginRight: 0,
  },
}));

const columns = [
  {
    title: "NOMBRE",
    field: "firstName",
    emptyValue: NO_VALUE,
    render: (data) => (
      <Link
        onClick={(e) => {
          e.stopPropagation();
        }}
        to={`/edit-student/${data.id}`}
        target="_blank"
        rel="noopener noreferrer"
      >
        <Button
          // className={classes.acceptButton}
          size="large"
          variant="outlined"
        >
          <Typography>
            <strong>
              {data.firstName} {data.lastName1}
            </strong>
          </Typography>
        </Button>
      </Link>
    ),
    cardFormat: "STRING",
    // cellStyle: { textAlign: "center" },
    // headerStyle: { textAlign: "center" },
    width: 100,
    sorting: false,
  },
  {
    title: "TEL.",
    field: "phone",
    emptyValue: NO_VALUE,
    cardFormat: "STRING",
    // cellStyle: { textAlign: "center" },
    // headerStyle: { textAlign: "center" },
    width: 100,
    sorting: false,
  },
  {
    title: "EMAIL",
    field: "email",
    emptyValue: NO_VALUE,
    cardFormat: "STRING",
    // cellStyle: { textAlign: "center" },
    // headerStyle: { textAlign: "center" },
    width: 100,
    sorting: false,
  },
  {
    title: "NIVEL",
    field: "level",
    emptyValue: NO_VALUE,
    cardFormat: "STRING",
    // cellStyle: { textAlign: "center" },
    // headerStyle: { textAlign: "center" },
    width: 100,
    sorting: false,
  },
  {
    title: "Nº TESTS REALIZADOS",
    field: "totalTestsDone",
    emptyValue: NO_VALUE,
    cardFormat: "STRING",
    // cellStyle: { textAlign: "center" },
    // headerStyle: { textAlign: "center" },
    width: 100,
    sorting: false,
  },
  {
    title: "Nº TESTS APROBADOS",
    field: "passedTests",
    emptyValue: NO_VALUE,
    cardFormat: "STRING",
    // cellStyle: { textAlign: "center" },
    // headerStyle: { textAlign: "center" },
    width: 100,
    sorting: false,
  },
  {
    title: "INICIO CURSO",
    field: "startDate",
    render: (data) => <Moment format={DATE_FORMAT}>{data.startDate}</Moment>,
    emptyValue: NO_VALUE,
    cardFormat: "DATE",
    width: 100,
    sorting: false,
  },
  {
    title: "CURSO",
    field: "courseDescription",
    emptyValue: "",
    cardFormat: "STRING",
    // cellStyle: { textAlign: "center" },
    // headerStyle: { textAlign: "center" },
    width: 100,
    sorting: false,
  },
  {
    title: "PSICOT",
    field: "medicalExamination",
    render: (data) => {
      if (data.medicalExamination == null || data.medicalExamination == "") {
        return <Typography>NO</Typography>;
      } else {
        return <Typography>SI</Typography>;
      }
    },
    // emptyValue: "NO",
    cardFormat: "STRING",
    // cellStyle: { textAlign: "center" },
    // headerStyle: { textAlign: "center" },
    width: 100,
    sorting: false,
  },
  {
    title: "VA A TEORIA",
    field: "signUpTheory",
    cardFormat: "STRING",
    render: (data) => (
      <>
        {data.signUpTheory === 1 ? (
          <Grid>
            <DoneIcon
              style={{
                padding: 3,
                fontSize: "20px",
                color: "rgb(39, 103, 73)",
                backgroundColor: "rgb(154, 230, 180)",
                borderRadius: "50%",
              }}
            ></DoneIcon>
          </Grid>
        ) : (
          <Grid>
            <CloseIcon
              style={{
                padding: 3,
                fontSize: "20px",
                paddding: 5,
                color: "#9b2c2c",
                backgroundColor: "rgba(254, 215, 215)",
                borderRadius: "50%",
              }}
            ></CloseIcon>
          </Grid>
        )}
      </>
    ),
    // cellStyle: { textAlign: "center" },
    // headerStyle: { textAlign: "center" },
    width: 80,
    sorting: false,
  },
];

const StudentsForTheory = ({ centerID, isAdmin }) => {
  const classes = useStyles();
  const tableRef = useRef(null);

  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);
  const [studentID, setStudentID] = useState("");
  const [courses, setCourses] = useState([]);
  const [openEdit, setOpenEdit] = useState(false);
  const [totalResults, setTotalResults] = useState();
  const [sorting, setSorting] = useState({
    orderBy: "INICIO CURSO",
    orderDir: "asc",
  });

  const [criteria, setCriteria] = useState({
    level: "Todos",
    doingTheory: "Todos",
    hasMedical: "Todos",
    courseID: "Todos",
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    getStudents();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [criteria, centerID, sorting]);

  useEffect(() => {
    coursesAPI
      .getAll({
        centerID: centerID !== "1" ? centerID : null,
        coursesForOptions: "Todos",
      })
      .then((result) => {
        setCourses(result.results);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getStudents = () => {
    setIsLoading(true);
    let params = {
      centerID: centerID !== "1" ? centerID : null,
      perPage: 1000,
      ...criteria,
      ...sorting
    };

    coursesAPI.getTheoryStudents(params).then((result) => {
      const studentsWithLevel = result.results
      setData(studentsWithLevel);
      setTotalResults(result.totalCount);
      setIsLoading(false);
    });
  };

  const handleCriteriaChange = (newCriteria) => {
    setCriteria({ ...criteria, ...newCriteria });
  };
  const handleCriteriaChangeSorting = (newCriteria) => {
    setSorting({ ...sorting, ...newCriteria });
  };

 
  const handleStudentSelected = (ID) => {
    if (ID) {
      setStudentID(ID);
    } else {
      setStudentID(null);
    }
    setOpenEdit(true);
  };

  if (columns.length === 0) return "LOADING";
   
  return (
    <Fade in={true} timeout={FADE_TRANSITION}>
      <div className={classes.root}>
        <ModalEditStudentTheory
          open={openEdit}
          setOpen={setOpenEdit}
          tableRef={tableRef}
          studentID={studentID}
          getStudents={getStudents}
        />
        <div style={{ margin: "40px 40px 5px 40px" }}>
          <Grid container spacing={2} direction="row" justify="flex-start">
            <Grid item xs={12} sm={6} md="auto" lg="auto">
              <Typography>CURSO</Typography>
              <TextField
                fullWidth
                style={{ backgroundColor: "white" }}
                variant="outlined"
                size="small"
                id="Type"
                placeholder="CURSO"
                select
                value={criteria.courseID}
                onChange={(e) =>
                  handleCriteriaChange({ courseID: e.target.value })
                }
              >
                <MenuItem value={"Todos"}>Todos</MenuItem>
                {courses.map((option) => (
                  <MenuItem key={option.id} value={option.id}>
                    {moment(option.startDate).format("YYYY/MM/DD")}|
                    {option.startTime.slice(0, option.startTime.length - 3)}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            {/* <Grid item xs={12} sm={6} md="auto" lg="auto">
              <Typography>NIVEL</Typography>
              <TextField
                fullWidth
                style={{ backgroundColor: "white" }}
                variant="outlined"
                size="small"
                id="Type"
                select
                value={criteria.level}
                onChange={(e) =>
                  handleCriteriaChange({ level: e.target.value })
                }
              >
                <MenuItem value="Todos">Todos</MenuItem>
                {[1, 2, 3, 4, 5].map((option) => (
                  <MenuItem key={option} value={option}>
                    Nivel {option}
                  </MenuItem>
                ))}
              </TextField>
            </Grid> */}
            <Grid item xs={12} sm={6} md="auto" lg="auto">
              <Typography>PSICOTECNICO</Typography>
              <TextField
                fullWidth
                style={{ backgroundColor: "white" }}
                variant="outlined"
                size="small"
                id="Type"
                placeholder="PSICOTECNICO"
                select
                value={criteria.hasMedical}
                onChange={(e) =>
                  handleCriteriaChange({ hasMedical: e.target.value })
                }
              >
                <MenuItem value={"Todos"}>Todos</MenuItem>
                {activeOptions.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} sm={6} md="auto" lg="auto">
              <Typography>VA A TEORIA</Typography>
              <TextField
                fullWidth
                style={{ backgroundColor: "white" }}
                variant="outlined"
                size="small"
                id="Type"
                placeholder="TEORIA"
                select
                value={criteria.doingTheory}
                onChange={(e) =>
                  handleCriteriaChange({ doingTheory: e.target.value })
                }
              >
                <MenuItem value={"Todos"}>Todos</MenuItem>
                {activeOptions.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} sm={12} md="auto" lg="auto">
              <Typography>FECHA INICIO CURSO</Typography>
              <CustomDatePicker
                startDate={criteria.startDate}
                endDate={criteria.endDate}
                handleChangeCriteria={handleCriteriaChange}
              ></CustomDatePicker>
            </Grid>
            <Grid item xs={12}>
              <Typography>ORDEN</Typography>
              <TextField
                fullWidth
                style={{ backgroundColor: "white" }}
                variant="outlined"
                size="small"
                id="order"
                placeholder="ORDEN"
                select
                value={sorting.orderBy}
                onChange={(e) =>
                  handleCriteriaChangeSorting({ orderBy: e.target.value })
                }
              >
                {[
                  "INICIO CURSO",
                  "NIVEL",
                  "TESTS REALIZADOS",
                  "TESTS APROBADOS",
                ].map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </TextField>
              {sorting.orderDir === "asc" ? (
                <Button
                  style={{ width: "20px" }}
                  onClick={(e) =>
                    handleCriteriaChangeSorting({ orderDir: "desc" })
                  }
                >
                  <ArrowUpwardIcon></ArrowUpwardIcon>
                </Button>
              ) : (
                <Button
                  style={{ width: "20px" }}
                  onClick={(e) =>
                    handleCriteriaChangeSorting({ orderDir: "asc" })
                  }
                >
                  <ArrowDownwardIcon></ArrowDownwardIcon>
                </Button>
              )}
            </Grid>
          </Grid>
        </div>
        {/* {JSON.stringify(data)} */}
        <div style={{ padding: "20px 40px 0 40px" }}>
          {isLoading ? (
            <LoadingIcon></LoadingIcon>
          ) : (
            <CustomTable
              tableRef={tableRef}
              data={data}
              columns={columns}
              // handlePageChange={handlePageChange}
              totalResults={totalResults}
              hasPagination={false}
              handleClickEditOpen={handleStudentSelected}
            ></CustomTable>
          )}
        </div>
      </div>
    </Fade>
  );
};

export default StudentsForTheory;
