import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import CreateIcon from "@material-ui/icons/Create";
import React from "react";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  marginRight: {
    marginRight: theme.spacing(1),
  },
  labelRoot: {
    color: "white",
    fontWeight: "bold",
    "&$labelFocused": {
      color: "white",
    },
  },
  acceptButton: {
    backgroundColor: "rgb(18,151,91)",
    "&:hover": {
      backgroundColor: "rgb(18,151,91)",
    },
  },
  actions: {
    backgroundColor: "rgb(248,250,252)",
  },
}));

const ModalStartTest = (props) => {
  const { open, setOpen, type = "normal", level = 1 } = props;
  const classes = useStyles();

  const handleCancelClose = () => {
    setOpen(false);
  };

  if (type === "normal") {
    return (
      <Dialog open={open} onClose={handleCancelClose}>
        <DialogTitle id="form-dialog-title">
          <span style={{ fontWeight: "bold" }}>EMPEZAR NUEVO TEST</span>
        </DialogTitle>
        <Divider style={{ margin: "0 20px" }}></Divider>
        <DialogContent style={{ overflowY: "hidden", margin: "20px 0" }}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography>
                Se va a generar un test con varias preguntas de examen.
              </Typography>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions className={classes.actions}>
          <Button size="large" variant="outlined" onClick={handleCancelClose}>
            <CloseIcon className={classes.marginRight} />
            Cancelar
          </Button>
          <Link to={`/nuevo_test/${level}`}>
            <Button
              className={classes.acceptButton}
              size="large"
              variant="outlined"
            >
              <CreateIcon className={classes.marginRight} />{" "}
              <span>¡Vamos!</span>
            </Button>
          </Link>
        </DialogActions>
      </Dialog>
    );
  }
  if (type === "error") {
    return (
      <Dialog open={open} onClose={handleCancelClose}>
        <DialogTitle id="form-dialog-title">
          <span style={{ fontWeight: "bold" }}>EMPEZAR TEST DE ERRORES</span>
        </DialogTitle>
        <Divider style={{ margin: "0 20px" }}></Divider>
        <DialogContent style={{ overflowY: "hidden", margin: "20px 0" }}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography>
                Se va a generar un test con las preguntas más falladas de
                examen.
              </Typography>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions className={classes.actions}>
          <Button size="large" variant="outlined" onClick={handleCancelClose}>
            <CloseIcon className={classes.marginRight} />
            Cancelar
          </Button>
          <Link to="/test-errores">
            <Button
              className={classes.acceptButton}
              size="large"
              variant="outlined"
            >
              <CreateIcon className={classes.marginRight} />{" "}
              <span>¡Vamos!</span>
            </Button>
          </Link>
        </DialogActions>
      </Dialog>
    );
  }
  if (type === "reto") {
    return (
      <Dialog open={open} onClose={handleCancelClose}>
        <DialogTitle id="form-dialog-title">
          <span style={{ fontWeight: "bold" }}>EMPEZAR TEST RETAME</span>
        </DialogTitle>
        <Divider style={{ margin: "0 20px" }}></Divider>
        <DialogContent style={{ overflowY: "hidden", margin: "20px 0" }}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography>
                Se va a generar un test con preguntas de examen más difíciles.
              </Typography>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions className={classes.actions}>
          <Button size="large" variant="outlined" onClick={handleCancelClose}>
            <CloseIcon className={classes.marginRight} />
            Cancelar
          </Button>
          <Link to="/test-challenge">
            <Button
              className={classes.acceptButton}
              size="large"
              variant="outlined"
            >
              <CreateIcon className={classes.marginRight} />{" "}
              <span>¡Vamos!</span>
            </Button>
          </Link>
        </DialogActions>
      </Dialog>
    );
  }
  return <></>;
};

export default ModalStartTest;
