import axios from "axios";
import {API_NAME} from "./base";

const PatternInstance = axios.create({
  baseURL: `${API_NAME}api/patterns`,
  withCredentials: true,
});

PatternInstance.interceptors.request.use(
  (config) => {
    config.headers.Authorization = `Bearer ${localStorage.getItem("user")}`;
    return config;
  },
  (error) => Promise.reject(error)
);

const PatternApi = {
  getAll: ({ teacherID = null } = {}) => {
    return PatternInstance.get(``, {
      params: { teacherID },
    })
      .then((response) => response.data)
      .catch((err) => console.error(err));
  },

  create: (data) => {
    return PatternInstance.post("", data)
      .then((response) => response.data)
      .catch((err) => console.error(err));
  },

  update: (data, ID) => {
    return PatternInstance.post(`/${ID}`, data)
      .then((response) => response.data)
      .catch((err) => console.error(err));
  },
};

const patterns = PatternApi;
export default patterns;
