import axios from "axios";
import {API_NAME} from "./base";

const CenterInstance = axios.create({
  baseURL: `${API_NAME}api/centers`,
  withCredentials: true,
});

CenterInstance.interceptors.request.use(
  (config) => {
    config.headers.Authorization = `Bearer ${localStorage.getItem("user")}`;
    return config;
  },
  (error) => Promise.reject(error)
);

const CenterApi = {
  getAll: () => {
    return CenterInstance.get()
      .then((response) => response.data)
      .catch((err) => console.error(err));
  },

  getOne: (centerID) => {
    return CenterInstance.get(`/${centerID}`)
      .then((response) => response.data)
      .catch((err) => console.error(err));
  },

  create: (data) => {
    return CenterInstance.post("", data)
      .then((response) => response.data)
      .catch((err) => console.error(err));
  },
  update: (data, ID) => {
    return CenterInstance.post(`/${ID}`, data)
      .then((response) => response.data)
      .catch((err) => console.error(err));
  },

  getPayments: ({
    view = "listing",
    centerID = null,
    startDate = null,
    endDate = null,
    orderBy = "name",
    orderDir = "asc",
    page = 1,
    perPage = 10,
  } = {}) => {
    return CenterInstance.get(`/get-payments`, {
      params: {
        view,
        centerID,
        startDate,
        endDate,
        orderBy,
        orderDir,
        page,
        perPage,
      },
    })
      .then((response) => response.data)
      .catch((err) => console.error(err));
  },

  getSumarizeStudentsPayments: ({
    view = "listing",
    centerID = null,
    startDate = null,
    endDate = null,
    orderBy = "name",
    orderDir = "asc",
    page = 1,
    perPage = 10,
  } = {}) => {
    return CenterInstance.get(`/get-students-total-payments`, {
      params: {
        view,
        centerID,
        startDate,
        endDate,
        orderBy,
        orderDir,
        page,
        perPage,
      },
    })
      .then((response) => response.data)
      .catch((err) => console.error(err));
  },

  getClosures: ({
    centerID = null,
    orderBy = "name",
    orderDir = "asc",
    page = 1,
    perPage = 10,
  } = {}) => {
    return CenterInstance.get(`/get-closures`, {
      params: { centerID, orderBy, orderDir, page, perPage },
    })
      .then((response) => response.data)
      .catch((err) => console.error(err));
  },

  getPacksForCourses: ({ centerID = null } = {}) => {
    return CenterInstance.get(`/get-packs-courses`, {
      params: { centerID },
    })
      .then((response) => response.data)
      .catch((err) => console.error(err));
  },

  createClosure: (data) => {
    return CenterInstance.post("/closure", data)
      .then((response) => response.data)
      .catch((err) => console.error(err));
  },

  updateBanner: (data, ID) => {
    return CenterInstance.post(`/update-banner/${ID}`, data)
      .then((response) => response.data)
      .catch((err) => console.error(err));
  },
};

const centers = CenterApi;
export default centers;
