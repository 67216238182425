export const calculateLevelPracticalClasses = (level) => {
    if (level >= 20) {
      return 4;
    } else if (level >= 15) {
      return 3;
    } else if (level >= 10) {
      return 2;
    } else if (level >= 1) {
      return 1;
    } else {
      return 0;
    }
  };

