import axios from "axios";
import {API_NAME} from "../base";


class SupervisorQuestionsApi {

    constructor() {
        this.service = axios.create({
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + localStorage.getItem("user") || ""
            },
            baseURL: `${API_NAME}api/supervisor/questions`,
            withCredentials: true,
        });
    }

    getAll = ({
        searchText = "",
        page = 1,
        perPage = 10,
        category = "Todos",
        active = "Todas",
    } = {}) => {
        return this.service
            .get(`/`, {
                params: {
                    searchText,
                    active,
                    category,
                    page,
                    perPage,
                },
            })
            .then((response) => response.data)
            .catch((err) => console.error(err));
    };


    update = (data, ID) => {
        return this.service
            .post(`/${ID}`, data)
            .then((response) => response.data)
            .catch((err) => console.error(err));
    };
}

const supervisorQuestions = new SupervisorQuestionsApi();
export default supervisorQuestions;
