import axios from "axios";
import {API_NAME} from "./base";

const ExitPointInstance = axios.create({
  baseURL: `${API_NAME}api/exit-points`,
  withCredentials: true,
});

ExitPointInstance.interceptors.request.use(
  (config) => {
    config.headers.Authorization = `Bearer ${localStorage.getItem("user")}`;
    return config;
  },
  (error) => Promise.reject(error)
);

const ExitPointsApi = {
  getAll: ({
    centerID = null,
    orderBy = "name",
    orderDir = "asc",
    page = 1,
    perPage = 10,
  } = {}) => {
    return ExitPointInstance.get(``, {
      params: { centerID, orderBy, orderDir, page, perPage },
    })
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        return err;
      });
  },

  getOne: (vehicleID) => {
    return ExitPointInstance.get(`/${vehicleID}`)
      .then((response) => response.data)
      .catch((err) => console.error(err));
  },

  create: (data) => {
    return ExitPointInstance.post("", data)
      .then((response) => response.data)
      .catch((err) => console.error(err));
  },

  update: (data, ID) => {
    return ExitPointInstance.post(`/${ID}`, data)
      .then((response) => response.data)
      .catch((err) => console.error(err));
  },
  delete: (ID) => {
    return ExitPointInstance.delete(`/${ID}`)
      .then((response) => response.data)
      .catch((err) => console.error(err));
  },
};

const exitPoints = ExitPointsApi;
export default exitPoints;
