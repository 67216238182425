import { Button, Fade, Grid, MenuItem, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { Redirect, useParams } from "react-router-dom";
import { codes as codesApi } from "../../../api";
import { FADE_TRANSITION } from "../../../lib/constants";
import { activeOptions, codeOptions } from "../../../lib/textOptions";
import { CustomField } from "../../core/CustomField";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
    "& .MuiTextField-root": {
      width: "20ch",
    },
  },
  gridContainer: {
    backgroundColor: "rgb(255,255,255)",
    margin: 40,
    borderTop: "5px solid rgb(159,46,37)",
    borderRadius: "0 0 10px 10px",
    padding: 20,
  },
  header: {
    backgroundColor: "rgb(248,250,252)",
    borderBottom: "2px solid rgb(227,232,239)",
    height: 70,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  headerText: {
    fontWeight: "bold",
    marginLeft: 40,
    color: theme.palette.primary.main,
  },
  textArea: {
    minHeight: 150,
    minWidth: "99%",
    maxWidth: "99%",
    width: "99",
    borderRadius: 3,
    padding: 8,
  },
}));

const validate = (values, codeID) => {
  const errors = {};
  if (!values.name) {
    errors.name = "Requerido";
  }

  if (!values.code) {
    errors.code = "Requerido";
  }
  if (!values.description) {
    errors.description = "Requerido";
  }

  if (!values.discount) {
    errors.discount = "Requerido";
  }
  if (!values.type) {
    errors.type = "Requerido";
  }
  if (!values.isActive) {
    errors.isActive = "Requerido";
  }

  return errors;
};
const FormCode = ({ centerID, isAdmin }) => {
  const classes = useStyles();

  const { codeID } = useParams();

  const [values, setValues] = useState({ idCenter: centerID });
  const [error, setError] = useState(null);
  const [redirect, setRedirect] = useState(false);

  useEffect(() => {
    if (codeID) {
      codesApi
        .getOne(codeID)
        .then((response) => {
          setValues(response);
        })
        .catch((x) => {
          setError("Error trayendo centros");
        });
    }
  }, [codeID]);

  const formik = useFormik({
    initialValues: {
      idCenter: centerID,
      code: (values && values.code) || "",
      name: (values && values.name) || "",
      description: (values && values.description) || "",
      discount: (values && values.discount) || "",
      type: (values && values.type) || "",
      isActive: (values && values.isActive) || 0,
    },
    enableReinitialize: true,
    isInitialValid: false,
    validate: (values) => validate(values, codeID),
    onSubmit: (values) => {
      handleSubmitCourse(values);
    },
  });


  const handleSubmitCourse = (values) => {
    setError("");
    //AQUI USAMOS EL formik.isValid que es booleano para que deje o no hacer la acción

    codeID
      ? codesApi
          .update(values, codeID)
          .then((response) => {
            setRedirect(true);
          })
          .catch((x) => {
            setError("Error adding permiso");
          })
      : codesApi
          .create(values)
          .then((response) => {
            setRedirect(true);
          })
          .catch((x) => {
            setError("Error adding permiso");
          });
  };

  if (redirect) {
    return <Redirect to={"/cursos"}></Redirect>;
  }

  return (
    <Fade in={true} timeout={FADE_TRANSITION}>
      <div className={classes.root}>
        {codeID ? null : (
          <div className={classes.header}>
            <Grid container xs={12}>
              <Typography className={classes.headerText}>
                <span>NUEVO CÓDIGO</span>
              </Typography>
            </Grid>
          </div>
        )}

        <div className={classes.gridContainer}>
          <Grid container spacing={3} direction="row" justify="flex-start">
            <Grid item xs={12}>
              <Grid container direction="row" justify="space-between">
                <Grid>
                  {codeID ? (
                    <Typography>CÓDIGO - {values.code}</Typography>
                  ) : (
                    <Typography>CÓDIGO</Typography>
                  )}
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={6} container direction="row" spacing={3}>
              <Grid item xs="auto" sm="auto" md="auto" lg="auto">
                <CustomField
                  title="Código"
                  required
                  type="text"
                  formik={formik}
                  fieldKey="code"
                  placeHolder="Código"
                  validate
                />
              </Grid>
              <Grid item xs="auto" sm="auto" md="auto" lg="auto">
                <CustomField
                  title="Nombre"
                  required
                  type="text"
                  formik={formik}
                  fieldKey="name"
                  placeHolder="Nombre"
                  validate
                />
              </Grid>
              <Grid item xs="auto" sm="auto" md="auto" lg="auto">
                <CustomField
                  title="Descripción"
                  required
                  type="text"
                  formik={formik}
                  fieldKey="description"
                  placeHolder="Descripción"
                  validate
                />
              </Grid>
              <Grid item xs="auto" sm="auto" md="auto" lg="auto">
                <CustomField
                  title="Tipo"
                  required
                  type="select"
                  formik={formik}
                  fieldKey="type"
                  placeHolder="Tipo"
                  validate
                >
                  {codeOptions.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </CustomField>
              </Grid>
              <Grid item xs="auto" sm="auto" md="auto" lg="auto">
                <CustomField
                  title="Cantidad"
                  required
                  type="number"
                  formik={formik}
                  fieldKey="discount"
                  placeHolder="Cantidad"
                  validate
                />
                <Typography>Si es un 2x1, pon 1€</Typography>
              </Grid>
              <Grid item xs="auto" sm="auto" md="auto" lg="auto">
                <CustomField
                  title="Activo"
                  required
                  type="select"
                  formik={formik}
                  fieldKey="isActive"
                  placeHolder="Activo"
                  validate
                >
                  {activeOptions.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </CustomField>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Button
                disabled={!formik.isValid}
                fullWidth
                onClick={formik.handleSubmit}
              >
                {codeID ? <span>MODIFICAR</span> : <span>CREAR</span>}
              </Button>
              {error}
            </Grid>
          </Grid>
        </div>
        <div style={{ height: "20px" }}></div>
      </div>
    </Fade>
  );
};

export default FormCode;
