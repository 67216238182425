import {
  Grid,
  Hidden, ListItem,
  ListItemText,
  Tooltip,
  withStyles
} from "@material-ui/core";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import EventIcon from "@material-ui/icons/Event";
import React, { useState } from "react";
import { NavLink, useLocation } from "react-router-dom";

const StyledListItem = withStyles({
  root: {
    "&.Mui-selected": {
      backgroundColor: "darkRed",
    },
  },
})(ListItem);

const OPTIONS = [
  {
    title: "Calendario",
    index: 0,
    query: "",
    icon: <EventIcon fill="white"></EventIcon>,
  },
  {
    title: "Perfil",
    index: 1,
    query: "mi_perfil",
    icon: <AccountCircleIcon fill="white"></AccountCircleIcon>,
  },
];


const TeacherRoutes = ({ user, isMobil = false, setExpanded = () => {} }) => {

  // PINTAR EL APARTADO DONDE ESTAS ACTUALMENTE AL ACCEDER A LA PAGINA
  let location = useLocation();

  let initialValue;
  OPTIONS.forEach((option) => {
    if (location.pathname.includes(option.query)) {
      initialValue = option.index;
    }
  });

  const [selectedIndex, setSelectedIndex] = useState(initialValue);

  const handleListItemClick = (event, index) => {
    setSelectedIndex(index);
    setExpanded(false);
  };

  return (
    <>
      <Grid item xs={12}>
        {OPTIONS.map((row, index) => {
          return (
            <Tooltip title={row.title} placement="top">
              <StyledListItem
                button
                onClick={
                  row.download
                    ? () => {}
                    : (event) => handleListItemClick(event, index)
                }
                component={NavLink}
                selected={selectedIndex === index}
                style={{
                  color: "white",
                  display: "flex",
                  justifyContent: "flex-start",
                  borderRadius: "6px",
                }}
                to={`/${row.query}`}
              >
                {isMobil ? (
                  <ListItemText primary={row.title} />
                ) : (
                  <>
                    {row.icon}
                    <span style={{ margin: "0 5px" }}></span>
                    <Hidden mdDown>
                      <ListItemText primary={row.title} />
                    </Hidden>
                  </>
                )}
              </StyledListItem>
            </Tooltip>
          );
        })}
      </Grid>
    </>
  );
};

export default TeacherRoutes;
