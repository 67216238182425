import { TextField } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { classes as classesAPI } from "../../../api";
import React, { useState } from "react";

const useStyles = makeStyles((theme) => ({
  marginRight: {
    marginRight: theme.spacing(1),
  },
  labelRoot: {
    color: "white",
    fontWeight: "bold",
    "&$labelFocused": {
      color: "white",
    },
  },
  acceptButton: {
    backgroundColor: "rgb(18,151,91)",
    "&:hover": {
      backgroundColor: "rgb(18,151,91)",
    },
  },
  actions: {
    padding: "20px 20px",
    backgroundColor: "rgb(248,250,252)",
  },
}));

const AddCalificationModal = (props) => {
  const { open, setRedirect = () => {}, classID } = props;
  const classes = useStyles();

  const [evaluation, setEvaluation] = useState(0);
  const [error, setError] = useState("");

  const handleChange = (value) => {
    setEvaluation(value);
  };

  const handleSubmitClose = () => {
    if (evaluation <= 0 || evaluation > 10) {
      setError("La evaluación debe ser entre 1 y 10");
    } else {
      const newValues = {
        evaluation: evaluation,
      };
      classesAPI
        .updatePracticalClass(newValues, classID)
        .then((response) => {
          setRedirect(true);
        })
        .catch((x) => {
          console.log("Error adding permiso");
        });
    }
  };

  return (
    <Dialog
      open={open}
      // onClose={handleCancelClose}
    >
      <DialogTitle id="form-dialog-title">
        <span style={{ fontWeight: "bold" }}>EVALUACIÓN</span>
      </DialogTitle>
      <Divider style={{ margin: "0 20px" }}></Divider>
      <DialogContent style={{ overflowY: "hidden", margin: "20px 20px" }}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography>Evaluación</Typography>
            <TextField
              fullWidth
              variant="outlined"
              size="small"
              placeholder="0"
              type="number"
              value={evaluation}
              onChange={(e) => handleChange(e.target.value)}
            ></TextField>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions className={classes.actions}>
        <Typography>{error}</Typography>
        <Button
          className={classes.acceptButton}
          size="large"
          variant="outlined"
          onClick={handleSubmitClose}
        >
          <span>Enviar</span>
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddCalificationModal;
