import React, { useState } from "react";
import {
  Grid,
  Hidden,
  ListItem,
  ListItemText,
  Tooltip,
  withStyles,
} from "@material-ui/core";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import { NavLink, useLocation } from "react-router-dom";
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import LiveHelpIcon from '@material-ui/icons/LiveHelp';
import AnnouncementIcon from '@material-ui/icons/Announcement';


const StyledListItem = withStyles({
  root: {
    "&.Mui-selected": {
      backgroundColor: "darkRed",
    },
  },
})(ListItem);

const OPTIONS = [
  {
    title: "Central",
    index: 0,
    query: "",
    icon: <TrendingUpIcon fill="white"></TrendingUpIcon>,
  },
  {
    title: "Preguntas test",
    index: 1,
    query: "preguntas",
    icon: <LiveHelpIcon fill="white"></LiveHelpIcon>,
  },
  {
    title: "Cursos",
    index: 2,
    query: "cursos",
    icon: <AnnouncementIcon fill="white"></AnnouncementIcon>,
  },
  {
    title: "Perfil",
    index: 3,
    query: "mi_perfil",
    icon: <AccountCircleIcon fill="white"></AccountCircleIcon>,
  }
];

const SupervisorRoutes = ({
  user,
  isMobil = false,
  setExpanded = () => { },
}) => {
  // PINTAR EL APARTADO DONDE ESTAS ACTUALMENTE AL ACCEDER A LA PAGINA
  let location = useLocation();

  let initialValue;
  OPTIONS.forEach((option) => {
    if (location.pathname.includes(option.query)) {
      initialValue = option.index;
    }
  });

  const [selectedIndex, setSelectedIndex] = useState(initialValue);

  const handleListItemClick = (event, index) => {
    setSelectedIndex(index);
    setExpanded(false);
  };

  return (
    <>
      <Grid item xs={12}>
        {OPTIONS.map((row, index) => {
          return (
            <Tooltip key={index} title={row.title} placement="top">
              <StyledListItem
                button
                onClick={
                  row.download
                    ? () => { }
                    : (event) => handleListItemClick(event, index)
                }
                component={NavLink}
                selected={selectedIndex === index}
                style={{
                  color: "white",
                  display: "flex",
                  justifyContent: "flex-start",
                  borderRadius: "6px",
                }}
                to={`/${row.query}`}
              >
                {isMobil ? (
                  <ListItemText primary={row.title} />
                ) : (
                  <>
                    {row.icon}
                    <span style={{ margin: "0 5px" }}></span>
                    <Hidden mdDown>
                      <ListItemText primary={row.title} />
                    </Hidden>
                  </>
                )}
              </StyledListItem>
            </Tooltip>
          );
        })}
      </Grid>
    </>
  );
};

export default SupervisorRoutes;
