import axios from "axios";
import {API_NAME} from "./base";

const PermissionInstance = axios.create({
  baseURL: `${API_NAME}api/permissions`,
  withCredentials: true,
});

PermissionInstance.interceptors.request.use(
  (config) => {
    config.headers.Authorization = `Bearer ${localStorage.getItem("user")}`;
    return config;
  },
  (error) => Promise.reject(error)
);

const PermissionApi = {
  getAll: () => {
    return PermissionInstance.get()
      .then((response) => response.data)
      .catch((err) => console.error(err));
  },

  create: (name) => {
    return PermissionInstance.post("", { name: name })
      .then((response) => response.data)
      .catch((err) => console.error(err));
  },

  delete: (ID) => {
    return PermissionInstance.delete(`/${ID}`)
      .then((response) => response.data)
      .catch((err) => console.error(err));
  },
};

const permissions = PermissionApi;
export default permissions;
