import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Divider from "@material-ui/core/Divider";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import SaveIcon from "@material-ui/icons/Save";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { teachers as teachersAPI, classes as classesAPI } from "../../../api";

const useStyles = makeStyles((theme) => ({
  marginRight: {
    marginRight: theme.spacing(1),
  },
  labelRoot: {
    color: "white",
    fontWeight: "bold",
    "&$labelFocused": {
      color: "white",
    },
  },
  acceptButton: {
    backgroundColor: "rgb(18,151,91)",
    "&:hover": {
      backgroundColor: "rgb(18,151,91)",
    },
  },
  actions: {
    backgroundColor: "rgb(248,250,252)",
  },
}));

const ModalEditClass = (props) => {
  const {
    teachers,
    classID,
    open,
    setOpen,
    studentID,
    getClasses,
    fetchStudentClasses,
  } = props;
  const classes = useStyles();

  const [values, setValues] = useState({});
  const [loading, setLoading] = useState(true);

  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    if (classID) {
      teachersAPI.getClassById({ classID }).then((results) => {
        setValues(results.results);
        setLoading(false);
      });
    }
  }, [classID]);

  const handleSubmit = () => {
    setLoading(true);
    classesAPI
      .cancel({ studentID }, classID)
      .then(() => {
        setOpen(false);
        setLoading(true);
        getClasses();
        fetchStudentClasses();
      })
      .catch((error) => {
        console.log("hay error");
        switch (error.code) {
          case "Invalid value":
            setErrorMessage("Error: Invalid value(s), please try again");
            break;
          default:
            setErrorMessage("");
        }
      });
  };

  const handleCancelClose = () => {
    setOpen(false);
  };

  const teacher = teachers.filter((teacher) => teacher.id === values.idTeacher);

  if (values === null) {
    return <Typography>Loading</Typography>;
  }
  
  return (
    <Dialog open={open} className={classes.root} onClose={handleCancelClose}>
      <DialogTitle id="form-dialog-title">
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <span style={{ fontWeight: "bold" }}>Anular Clase</span>
        </div>
      </DialogTitle>
      <Divider style={{ margin: "0 20px" }}></Divider>
      <DialogContent style={{ overflowY: "hidden", margin: "20px" }}>
        <Typography>
          Va a anular la clase del{" "}
          <span style={{ fontWeight: "bold" }}>
            {moment(values.date).format("DD/MM/YYYY")}
          </span>{" "}
          a las{" "}
          <span style={{ fontWeight: "bold" }}>
            {values.startHour &&
              values.startHour.slice(0, values.startHour.length - 3)}
          </span>{" "}
          impartida por:{" "}
          <span style={{ fontWeight: "bold" }}>
            {teacher.length > 0 &&
              `${teacher[0].firstName} ${teacher[0].lastName1}`}
          </span>
        </Typography>
      </DialogContent>
      <DialogActions className={classes.actions}>
        <p>{errorMessage}</p>
        <Button size="large" variant="outlined" onClick={handleCancelClose}>
          <CloseIcon className={classes.marginRight} />
          Cancelar
        </Button>
        <Button
          className={classes.acceptButton}
          size="large"
          variant="outlined"
          onClick={handleSubmit}
          disabled={loading}
        >
          <SaveIcon className={classes.marginRight} /> Anular
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ModalEditClass;
