import { Fade, makeStyles, Typography } from "@material-ui/core";

import Grid from "@material-ui/core/Grid";
import CloseIcon from "@material-ui/icons/Close";
import DoneIcon from "@material-ui/icons/Done";
import React, { useEffect, useRef, useState } from "react";

import Moment from "react-moment";
import { Redirect } from "react-router-dom";
import { tests as testsAPI } from "../../../api";
import {
  calculateLevelByPermit,
  testToNextLevel,
} from "../../../lib/calculateLevelByPermit";
import { DATE_FORMAT, FADE_TRANSITION, NO_VALUE } from "../../../lib/constants";
import CustomTable from "../../core/CustomTable";
import { LoadingIcon } from "../../core/LoadingIcon";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
    minHeight: "100vh",
    "& .MuiTextField-root": {
      minWidth: "20ch",
    },
  },
  input: {
    marginRight: theme.spacing(1),
  },
  button: {
    marginRight: theme.spacing(1),
    backgroundColor: "rgb(160, 174, 192)",
    "&:hover": {
      backgroundColor: "rgb(160, 174, 192)",
      opacity: 1,
      color: "#FFFFFF",
    },
  },
  activeButton: {
    // marginRight: theme.spacing(1),
    border: "1px solid #ad201c",
    backgroundColor: "#ad201c",
    opacity: 1,
    color: "#FFFFFF",
    "&:hover": {
      border: "1px solid #ad201c",
      backgroundColor: "#ad201c",
      opacity: 1,
      color: "#FFFFFF",
    },
  },
  header: {
    backgroundColor: "rgb(248,250,252)",
    borderBottom: "2px solid rgb(227,232,239)",
    minHeight: 70,
    alignItems: "center",
  },
  headerText: {
    fontWeight: "bold",
    color: theme.palette.primary.main,
  },
  activeTestButton: {
    // marginRight: theme.spacing(1),
    border: "1px solid #ad201c",
    backgroundColor: "grey",
    opacity: 1,
    color: "#FFFFFF",
    "&:hover": {
      border: "1px solid #ad201c",
      backgroundColor: "#ad201c",
      opacity: 1,
      color: "#FFFFFF",
    },
  },
  testButton: {
    width: "200px",
    opacity: 1,
    color: "#FFFFFF",
  },
  initial: {
    // marginRight: theme.spacing(1),
    border: "1px solid darkGrey",
    backgroundColor: "darkGrey",
    opacity: 1,
    color: "#FFFFFF",
    "&:hover": {
      border: "1px solid #ad201c",
      backgroundColor: "darkGrey",
      opacity: 1,
      color: "#FFFFFF",
    },
  },
  passed: {
    // marginRight: theme.spacing(1),
    border: "1px solid green",
    backgroundColor: "green",
    opacity: 1,
    color: "#FFFFFF",
    "&:hover": {
      border: "1px solid #ad201c",
      backgroundColor: "green",
      opacity: 1,
      color: "#FFFFFF",
    },
  },
  failed: {
    // marginRight: theme.spacing(1),
    border: "1px solid #ad201c",
    backgroundColor: "#ad201c",
    opacity: 1,
    color: "#FFFFFF",
    "&:hover": {
      border: "1px solid #ad201c",
      backgroundColor: "#ad201c",
      opacity: 1,
      color: "#FFFFFF",
    },
  },
}));

const columns = [
  {
    title: "#",
    field: "num_row",
    emptyValue: NO_VALUE,
    width: 5,
    cardFormat: "STRING",
  },
  {
    title: "Fecha ",
    field: "date",
    render: (data) => <Moment format={DATE_FORMAT}>{data.date}</Moment>,
    emptyValue: "",
    cardFormat: "DATE",
  },
  {
    title: "Numero",
    field: "testNumber",
    render: (data) => (
      <Typography>
        {data.testNumber ? data.testNumber : data.category}
      </Typography>
    ),
    cardFormat: "STRING",
  },
  {
    title: "Preguntas acertadas",
    field: "result",
    render: (data) => <Typography>{data.result}</Typography>,
    emptyValue: NO_VALUE,
    cardFormat: "STRING",
  },
  {
    title: "Resultado",
    field: "testResult",
    render: (data) => (
      <>
        {data.testResult === 0 ? (
          <Grid>
            <CloseIcon
              style={{
                padding: 3,
                fontSize: "20px",
                paddding: 5,
                color: "#9b2c2c",
                backgroundColor: "rgba(254, 215, 215)",
                borderRadius: "50%",
              }}
            ></CloseIcon>
          </Grid>
        ) : (
          <Grid>
            <DoneIcon
              style={{
                padding: 3,
                fontSize: "20px",
                color: "rgb(39, 103, 73)",
                backgroundColor: "rgb(154, 230, 180)",
                borderRadius: "50%",
              }}
            ></DoneIcon>
          </Grid>
        )}
      </>
    ),
    emptyValue: NO_VALUE,
    cardFormat: "RESULT",
  },
];

const Historial = ({ user: student }) => {
  const classes = useStyles();
  const tableRef = useRef(null);
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [selectedTestID, setSelectedTestID] = useState(false);
  const defaultPageSize = 10;
  const [totalResults, setTotalResults] = useState(0);
  const [passedCount, setPassedCount] = useState(0);
  const [level, setLevel] = useState(0);
  const [sorting] = useState({ orderBy: "name", orderDir: "asc" });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    setIsLoading(true);
    let params = {
      studentID: student.id,
      perPage: defaultPageSize,
      page: page,
    };

    testsAPI.getStudentTests(params).then((result) => {
      setData(result.results);
      setPage(result.page);
      setTotalResults(result.totalCount);
      setPassedCount(result.passed);
      setLevel(calculateLevelByPermit(result.passed, student.permission));
      setIsLoading(false);
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, sorting]);

  const handlePageChange = (newPage) => {
    window.scrollTo(0, 0);
    setPage(newPage);
  };

  const handleTestSelected = (id) => {
    setSelectedTestID(id);
  };

  if (selectedTestID) {
    return (
      <Redirect
        to={{
          pathname: `/test/${student.id}/${selectedTestID}`,
          nextPath: "/tests-historial",
        }}
      ></Redirect>
    );
  }

  return (
    <Fade in={true} timeout={FADE_TRANSITION}>
      <div className={classes.root}>
        <Grid
          container
          // spacing={2}
          direction="row"
          justify="space-between"
          className={classes.header}
          style={{ padding: 20 }}
        >
          <Grid item xs={12} sm={2}>
            <Typography
              className={classes.headerText}
              style={{ cursor: "pointer" }}
            >
              <span>
                TEST - NIVEL {level} DE 4. Te faltan{" "}
                {level
                  ? testToNextLevel(student.permission, level, passedCount)
                  : 0}{" "}
                tests aprobados para subir de nivel
              </span>
              {/* </Tooltip> */}
            </Typography>
          </Grid>
        </Grid>
        {isLoading ? (
          <LoadingIcon></LoadingIcon>
        ) : (
          <>
            <Grid
              container
              style={{
                display: "flex",
                alignContent: "center",
                justifyContent: "center",
                alignItems: "center",
                padding: "20px 40px 0 40px",
              }}
            >
              <CustomTable
                tableRef={tableRef}
                data={data}
                columns={columns}
                handlePageChange={handlePageChange}
                page={page}
                totalResults={totalResults}
                defaultPageSize={defaultPageSize}
                handleClickEditOpen={handleTestSelected}
              ></CustomTable>
            </Grid>
          </>
        )}
      </div>
    </Fade>
  );
};

export default Historial;
