import axios from "axios";
import {API_NAME} from "./base";

const CodesInstance = axios.create({
  baseURL: `${API_NAME}api/codes`,
  withCredentials: true,
});

CodesInstance.interceptors.request.use(
  (config) => {
    config.headers.Authorization = `Bearer ${localStorage.getItem("user")}`;
    return config;
  },
  (error) => Promise.reject(error)
);

const CodesApi = {
  getAll: ({
    centerID = null,
    orderBy = "name",
    orderDir = "asc",
    page = 1,
    perPage = 10,
  } = {}) => {
    return CodesInstance.get(`/`, {
      params: { centerID, orderBy, orderDir, page, perPage },
    })
      .then((response) => response.data)
      .catch((err) => console.error(err));
  },

  getOne: (codeID) => {
    return CodesInstance.get(`/${codeID}`)
      .then((response) => response.data)
      .catch((err) => console.error(err));
  },

  create: (data) => {
    return CodesInstance.post("", data)
      .then((response) => response.data)
      .catch((err) => console.error(err));
  },

  update: (data, ID) => {
    return CodesInstance.post(`/${ID}`, data)
      .then((response) => response.data)
      .catch((err) => console.error(err));
  },
  delete: (ID) => {
    return CodesInstance.delete(`/${ID}`)
      .then((response) => response.data)
      .catch((err) => console.error(err));
  },
};

class CourseApi {
  constructor() {
    CodesInstance = axios.create({
      baseURL: `${API_NAME}api/codes`,
      withCredentials: true,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("user") || ""}`,
      },
    });
  }
}

const codes = CodesApi;
export default codes;
