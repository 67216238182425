import React, { useEffect, useState } from "react";
import {
  Button,
  Fade,
  FormControlLabel,
  Grid,
  MenuItem,
  Switch,
  TextareaAutosize,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { useFormik } from "formik";
import { Redirect, useParams } from "react-router-dom";
import {
  hows as howApi,
  pendingStudents as pendingStudentsAPI,
  permissions as permissionApi,
  students as studentApi,
  tariffs as tariffApi,
  courses as coursesApi,
} from "../../../api";
import { FADE_TRANSITION } from "../../../lib/constants";
import { firstContactOptions, genderOptions } from "../../../lib/textOptions";
import { CustomField } from "../../core/CustomField";
import { SmsNo } from "./../../../assets/img/no-sms";
import { Sms } from "./../../../assets/img/sms";
import moment from "moment";
import ModalAddFormHow from "./ModalAddFormHow";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      minWidth: "20ch",
    },
  },
  gridContainer: {
    backgroundColor: "rgba(255,255,255, 1)",
    margin: 40,
    borderTop: "5px solid rgb(159,46,37)",
    borderRadius: "0 0 10px 10px",
    padding: 20,
  },
  header: {
    backgroundColor: "rgb(248,250,252)",
    borderBottom: "2px solid rgb(227,232,239)",
    height: 70,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  headerText: {
    fontWeight: "bold",
    marginLeft: 40,
    color: theme.palette.primary.main,
  },
  textArea: {
    minHeight: 150,
    minWidth: "99%",
    maxWidth: "99%",
    width: "99",
    borderRadius: 3,
    padding: 8,
  },
}));

const validate = (values, studentID) => {
  const errors = {};
  if (!values.firstName) {
    errors.firstName = "Requerido";
  }

  if (!values.lastName1) {
    errors.lastName1 = "Requerido";
  }

  if (!values.email) {
    errors.email = "Requerido";
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = "Dirección de email incorrecta";
  }
  if (!values.phone) {
    errors.phone = "Requerido";
  }
  if (!values.dni) {
    errors.dni = "Requerido";
  }
  if (!values.gender) {
    errors.gender = "Requerido";
  }
  if (!values.dni) {
    errors.dni = "Requerido";
  }
  if (!values.nationality) {
    errors.nationality = "Requerido";
  }
  if (!values.countryBirth) {
    errors.countryBirth = "Requerido";
  }
  if (!values.wayType) {
    errors.wayType = "Requerido";
  }
  if (!values.wayName) {
    errors.wayName = "Requerido";
  }
  if (!values.wayNumber) {
    errors.wayNumber = "Requerido";
  }
  if (!values.postalCode) {
    errors.postalCode = "Requerido";
  }
  if (!values.dniExpiration) {
    errors.dniExpiration = "Requerido";
  }
  if (!values.city) {
    errors.city = "Requerido";
  }
  if (!values.province) {
    errors.province = "Requerido";
  }
  if (!values.tariff) {
    errors.tariff = "Requerido";
  }
  if (!values.permission) {
    errors.permission = "Requerido";
  }
  if (!values.firstContact) {
    errors.firstContact = "Requerido";
  }
  if (!values.how) {
    errors.how = "Requerido";
  }
  // if (!values.centerCourseId) {
  //   errors.centerCourseId = "Requerido";
  // }
  if (!values.courseType) {
    errors.courseType = "Requerido";
  }
  if (studentID) {
    if (!values.user) {
      errors.user = "Requerido";
    }
   
  }

  return errors;
};
const FormStudent = ({
  studentID = null,
  centerID,
  isAdmin,
  fetchPendingStudents,
}) => {
  const classes = useStyles();
  const { pendingStudentID } = useParams();
  // const { studentID, centerID } = useParams();
  const [user, setUser] = useState(null);
  const [values, setValues] = useState({
    idCenter: centerID,
    recieveNotifications: 1,
  });
  const [permissions, setPermissions] = useState([]);
  const [hows, setHows] = useState([]);
  const [tariffs, setTariffs] = useState([]);
  const [courses, setCourses] = useState([]);
  const [redirect, setRedirect] = useState(false);
  const [openAdd, setOpenAdd] = useState(false);

  useEffect(() => {
    if (pendingStudentID) {
      pendingStudentsAPI
        .getOne(pendingStudentID)
        .then((response) => {
          setValues(response);
          setUser(response);
        })
        .catch((x) => {
          console.log("Error trayendo centros");
        });
    } else if (studentID) {
      studentApi
        .getOne(studentID)
        .then((response) => {
          setValues(response);
          setUser(response);
        })
        .catch((x) => {
          console.log("Error trayendo centros");
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [studentID]);

  useEffect(() => {
    coursesApi
      .getAll({
        centerID: centerID !== "1" ? centerID : null,
        coursesForOptions: "Todos",
      })
      .then((result) => {
        setCourses(result.results);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    permissionApi
      .getAll()
      .then((response) => {
        const result = response.results.map((elem) => {
          return { value: elem.name, label: elem.name };
        });
        setPermissions(result);
      })
      .catch((x) => {
        console.log("Error trayendo centros");
      });
  }, []);

  useEffect(() => {
    getHows();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getHows = () => {
    howApi
      .getAll({ centerID: centerID !== "1" ? centerID : null })
      .then((response) => {
        const result = response.results.map((elem) => {
          return { value: elem.name, label: elem.name };
        });
        setHows(result);
      })
      .catch((x) => {
        console.log("Error trayendo centros");
      });
  };

  useEffect(() => {
    tariffApi
      .getAll({ centerID: centerID !== "1" ? centerID : null })
      .then((response) => {
        setTariffs(response.results);
      })
      .catch((x) => {
        console.log("Error trayendo centros");
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const formik = useFormik({
    initialValues: {
      idCenter: centerID,
      active: (user && user.active) || 0,
      firstName: (user && user.firstName) || "",
      lastName1: (user && user.lastName1) || "",
      lastName2: (user && user.lastName2) || "",
      dni: (user && user.dni) || "",
      gender: (user && user.gender) || "",
      nationality: (user && user.nationality) || "",
      dniExpiration: (user && user.dniExpiration) || null,
      countryBirth: (user && user.countryBirth) || "",
      birthday: (user && user.birthday) || null,
      medicalExamination: (user && user.medicalExamination) || "",
      email: (user && user.email) || "",
      phone: (user && user.phone) || "",
      wayType: (user && user.wayType) || "",
      wayName: (user && user.wayName) || "",
      wayNumber: (user && user.wayNumber) || "",
      block: (user && user.block) || "",
      floor: (user && user.floor) || "",
      door: (user && user.door) || "",
      postalCode: (user && user.postalCode) || "",
      signUp: (user && user.signUp) || moment(new Date()).format("YYYY-MM-DD"),
      city: (user && user.city) || "",
      province: (user && user.province) || "",
      startDate: (user && user.startDate) || null,
      endDate: (user && user.endDate) || null,
      tariff: (user && user.tariff) || "",
      permission: (user && user.permission) || "",
      reactivation1: (user && user.reactivation1) || null,
      reactivation2: (user && user.reactivation2) || null,
      firstContact: (user && user.firstContact) || "",
      how: (user && user.how) || "",
      user: (user && user.user) || "",
      classes: (user && user.classes) || "",
      observations: (user && user.observations) || "",
      recieveNotifications: (user && user.recieveNotifications) || 1,
      centerCourseId: (user && user.centerCourseId) || null,
      courseType: (user && user.courseType) || 1,
    },
    enableReinitialize: true,
    isInitialValid: false,
    validate: (values) => validate(values, studentID),
    onSubmit: (values) => {
      handleSubmitCenter(values);
    },
  });

  const handleSubmitCenter = (values) => {
    //AQUI USAMOS EL formik.isValid que es booleano para que deje o no hacer la acción

    if (studentID) {
      studentApi
        .update(values, studentID)
        .then((response) => {
          setRedirect(true);
        })
        .catch((x) => {
          console.log("Error adding permiso");
        });
    } else if (pendingStudentID) {
      studentApi
        .updateToStudent(values, pendingStudentID)
        .then((response) => {
          setRedirect(true);
          fetchPendingStudents(centerID);
        })
        .catch((x) => {
          console.log("Error adding permiso");
        });
    } else {
      studentApi
        .create(values)
        .then((response) => {
           setRedirect(true);
        })
        .catch((x) => {
          console.log("Error adding permiso");
        });
    }
  };

  const handleClickAddOpen = () => {
    setOpenAdd(true);
  };

  if (redirect) {
    return <Redirect to={""}></Redirect>;
  }

  return (
    <Fade in={true} timeout={FADE_TRANSITION}>
      <div className={classes.root}>
        <ModalAddFormHow
          open={openAdd}
          setOpen={setOpenAdd}
          centerID={centerID}
          getHows={getHows}
        />
        {studentID ? null : (
          <div className={classes.header}>
            <Grid container xs={12}>
              <Typography className={classes.headerText}>
                NUEVO ESTUDIANTE {values.isOther ? "DE RECICLAJE" : null}
              </Typography>
            </Grid>
          </div>
        )}

        <div className={classes.gridContainer}>
          <Grid container spacing={3} direction="row" justify="flex-start">
            <Grid item xs={12}>
              <Grid container direction="row" justify="space-between">
                <Grid>
                  {studentID ? (
                    <Typography>
                      ALUMNO - {values.firstName} {values.lastName1}{" "}
                      {values.lastName2}
                    </Typography>
                  ) : (
                    <Typography>FICHA</Typography>
                  )}
                </Grid>
                <Grid>

                  {studentID ? (
                    <FormControlLabel
                      control={
                        <Switch
                          color="primary"
                          id="active"
                          name="active"
                          checked={formik.values.active}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />
                      }
                      label="Alumno activo"
                    />
                  ) : null}
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Typography>
                <b>Datos personales</b>
              </Typography>
            </Grid>
            <Grid item xs="auto" sm="auto" md="auto" lg="auto">
              <CustomField
                title="Nombre"
                required
                type="text"
                formik={formik}
                fieldKey="firstName"
                placeHolder="NOMBRE"
                validate
              />
            </Grid>
            <Grid item xs="auto" sm="auto" md="auto" lg="auto">
              <CustomField
                title="Primer apellido"
                required
                type="text"
                formik={formik}
                fieldKey="lastName1"
                placeHolder="PRIMER APELLIDO"
                validate
              />
            </Grid>
            <Grid item xs="auto" sm="auto" md="auto" lg="auto">
              <CustomField
                title="Segundo apellido"
                type="text"
                formik={formik}
                fieldKey="lastName2"
                placeHolder="SEGUNDO APELLIDO"
              />
            </Grid>
            <Grid item xs="auto" sm="auto" md="auto" lg="auto">
              <CustomField
                title="DNI"
                required
                type="text"
                formik={formik}
                fieldKey="dni"
                placeHolder="DNI"
                validate
              />
            </Grid>
            <Grid item xs="auto" sm="auto" md="auto" lg="auto">
              <CustomField
                title="Expiración del DNI"
                required
                type="date"
                formik={formik}
                fieldKey="dniExpiration"
                placeHolder="EXPIRACIÓN"
                validate
              />
            </Grid>
            <Grid item xs="auto" sm="auto" md="auto" lg="auto">
              <CustomField
                title="Género"
                required
                type="select"
                formik={formik}
                fieldKey="gender"
                placeHolder="Género"
                validate
              >
                {genderOptions.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </CustomField>
            </Grid>
            <Grid item xs="auto" sm="auto" md="auto" lg="auto">
              <CustomField
                title="Nacionalidad"
                required
                type="text"
                formik={formik}
                fieldKey="nationality"
                placeHolder="ESPAÑA"
                validate
              />
            </Grid>
            <Grid item xs="auto" sm="auto" md="auto" lg="auto">
              <CustomField
                title="País de Nacimiento"
                required
                type="text"
                formik={formik}
                fieldKey="countryBirth"
                placeHolder="ESPAÑA"
                validate
              />
            </Grid>
            <Grid item xs="auto" sm="auto" md="auto" lg="auto">
              <CustomField
                title="Fecha de Nacimiento"
                type="date"
                formik={formik}
                fieldKey="birthday"
                placeHolder="FECHA"
              />
            </Grid>
            {studentID ? (
              <>
                <Grid item xs="auto" sm="auto" md="auto" lg="auto">
                  <CustomField
                    title="Número de reconocimiento médico"
                    type="text"
                    formik={formik}
                    fieldKey="medicalExamination"
                    placeHolder="0"
                  />
                </Grid>
              </>
            ) : null}

            <Grid item xs={12}>
              <Typography>
                <b>Datos de contacto</b>
              </Typography>
            </Grid>
            <Grid item xs="auto" sm="auto" md="auto" lg="auto">
              <CustomField
                title="Email"
                required
                type="email"
                formik={formik}
                fieldKey="email"
                placeHolder="Email"
                validate
              />
            </Grid>
            <Grid item xs="auto" sm="auto" md="auto" lg="auto">
              <CustomField
                title="Teléfono"
                required
                type="number"
                formik={formik}
                fieldKey="phone"
                placeHolder="Teléfono"
                validate
              />
            </Grid>
            <Grid>
              {" "}
              <FormControlLabel
                control={
                  <Switch
                    color="primary"
                    id="recieveNotifications"
                    name="recieveNotifications"
                    checked={formik.values.recieveNotifications}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                }
                label={
                  formik.values.recieveNotifications ? (
                    <Sms fill="white"></Sms>
                  ) : (
                    <SmsNo fill="white"></SmsNo>
                  )
                }
              />
              {/* <img src={smsIcon} class="w-40 max-w-20" /> */}
            </Grid>
            <Grid item xs={12}>
              <Typography>
                <b>Dirección</b>
              </Typography>
            </Grid>
            <Grid item xs="auto" sm="auto" md="auto" lg="auto">
              <CustomField
                title="Tipo de vía"
                required
                type="text"
                formik={formik}
                fieldKey="wayType"
                placeHolder="TIPO VÍA"
                validate
              />
            </Grid>
            <Grid item xxs="auto" sm="auto" md="auto" lg="auto">
              <CustomField
                title="Nombre de la vía"
                required
                type="text"
                formik={formik}
                fieldKey="wayName"
                placeHolder="NOMBRE VÍA"
                validate
              />
            </Grid>
            <Grid item xs="auto" sm="auto" md="auto" lg="auto">
              <CustomField
                title="Bloque"
                type="text"
                formik={formik}
                fieldKey="block"
                placeHolder="BLOQUE"
              />
            </Grid>
            <Grid item xs="auto" sm="auto" md="auto" lg="auto">
              <CustomField
                title="Número"
                required
                type="number"
                formik={formik}
                fieldKey="wayNumber"
                placeHolder="NÚMERO"
                validate
              />
            </Grid>
            <Grid item xs="auto" sm="auto" md="auto" lg="auto">
              <CustomField
                title="Planta"
                type="number"
                formik={formik}
                fieldKey="floor"
                placeHolder="PLANTA"
              />
            </Grid>
            <Grid item xs="auto" sm="auto" md="auto" lg="auto">
              <CustomField
                title="Puerta"
                type="text"
                formik={formik}
                fieldKey="door"
                placeHolder="PUERTA"
              />
            </Grid>
            <Grid item xs={12} style={{ margin: "-15px" }}></Grid>
            <Grid item xs="auto" sm="auto" md="auto" lg="auto">
              <CustomField
                title="Código postal"
                required
                type="number"
                formik={formik}
                fieldKey="postalCode"
                placeHolder="CÓDIGO POSTAL"
                validate
              />
            </Grid>
            <Grid item xs="auto" sm="auto" md="auto" lg="auto">
              <CustomField
                title="Ciudad"
                required
                type="text"
                formik={formik}
                fieldKey="city"
                placeHolder="CIUDAD"
                validate
              />
            </Grid>
            <Grid item xs="auto" sm="auto" md="auto" lg="auto">
              <CustomField
                title="Provincia"
                required
                type="text"
                formik={formik}
                fieldKey="province"
                placeHolder="PROVINCIA"
                validate
              />
            </Grid>
            <Grid item xs={12}>
              <Typography>
                <b>Datos del curso</b>
              </Typography>
              <Grid item xs="auto" sm="auto" md="auto" lg="auto">
              <CustomField
                disabled={studentID || pendingStudentID ? true : false}
                title="Tipo de curso"
                required
                type="select"
                formik={formik}
                fieldKey="courseType"
                placeHolder="TIPO CURSO"
                validate
              >
                {[{value: 1, label:'Obtención permiso'}, {value: 2, label: 'Solo práctica'}, 
                // {value: 3, label:'Puntos'}, 
                {value: 4, label: 'Reciclaje'}].map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
              </CustomField>
            </Grid>
            </Grid>
            {formik.values.courseType === 1 && ( <Grid item xs="auto" sm="auto" md="auto" lg="auto">
              <CustomField
                title="Curso"
                required
                type="select"
                formik={formik}
                fieldKey="centerCourseId"
                placeHolder="Curso"
                validate
              >
                {courses.map((option) => (
                  <MenuItem key={option.id} value={option.id}>
                    {moment(option.startDate).format("YYYY/MM/DD")}|
                    {option.startTime.slice(0, option.startTime.length - 3)}{" "}
                    {option.type}
                  </MenuItem>
                ))}
              </CustomField>
            </Grid>)}
            {formik.values.courseType === 3 && ( <Grid item xs="auto" sm="auto" md="auto" lg="auto">
              <CustomField
                title="Curso Puntos"
                required
                type="select"
                formik={formik}
                fieldKey="pointCourseId"
                placeHolder="Curso puntos"
                validate
              >
                {courses.map((option) => (
                  <MenuItem key={option.id} value={option.id}>
                    {moment(option.startDate).format("YYYY/MM/DD")}|
                    {option.startTime.slice(0, option.startTime.length - 3)}{" "}
                    {option.type}
                  </MenuItem>
                ))}
              </CustomField>
            </Grid>)}
           
            <Grid item xs="auto" sm="auto" md="auto" lg="auto">
              <CustomField
                title="Fecha de alta"
                type="date"
                formik={formik}
                fieldKey="signUp"
                placeHolder="ALTA"
              />
            </Grid>
            {studentID  ? (
              <>
              { formik.values.courseType === 1 && (<>
                <Grid item xs="auto" sm="auto" md="auto" lg="auto">
                  <CustomField
                    disabled={true}
                    title="Inicio de curso"
                    type="date"
                    formik={formik}
                    fieldKey="startDate"
                    placeHolder="INICIO"
                  />
                </Grid>
                <Grid item xs="auto" sm="auto" md="auto" lg="auto">
                  <CustomField
                    disabled={true}
                    title="Fecha de finalización"
                    type="date"
                    formik={formik}
                    fieldKey="endDate"
                    placeHolder="FINAL"
                  />
                </Grid>
                <Grid item xs="auto" sm="auto" md="auto" lg="auto">
                  <Typography>Teórica aprobada</Typography>
                  <KeyboardDatePicker
                    disabled
                    autoOk
                    variant="inline"
                    inputVariant="outlined"
                    size="small"
                    format="DD/MM/yyyy"
                    value={values.theory || null}
                    InputAdornmentProps={{ position: "end" }}
                    // onChange={(date) => handleDateChange(date)}
                    onBlur={formik.handleBlur}
                  />
                </Grid></>) }
               

               {formik.values.courseType === 1 || formik.values.courseType === 2 && (
                <Grid item xs="auto" sm="auto" md="auto" lg="auto">
                  <Typography>Práctica aprobada</Typography>
                  <KeyboardDatePicker
                    disabled
                    autoOk
                    variant="inline"
                    inputVariant="outlined"
                    size="small"
                    format="DD/MM/yyyy"
                    value={values.practice || null}
                    InputAdornmentProps={{ position: "end" }}
                    // onChange={(date) => handleDateChange(date)}
                    onBlur={formik.handleBlur}
                  />
                </Grid>
               )}
                
                <Grid item xs={12} style={{ margin: "-15px" }}></Grid>
              </>
            ) : null}
            <Grid item xs={12} style={{ margin: "-15px" }}></Grid>
            <Grid item xs="auto" sm="auto" md="auto" lg="auto">
              <CustomField
                title="Tarifa"
                required
                type="select"
                formik={formik}
                fieldKey="tariff"
                placeHolder="Tarifa"
                validate
              >
                {tariffs.map((option) => (
                  <MenuItem key={option.id} value={option.id}>
                    {option.name}
                  </MenuItem>
                ))}
              </CustomField>
            </Grid>
            <Grid item xs="auto" sm="auto" md="auto" lg="auto">
              <CustomField
                title="Tipo de permiso"
                required
                type="select"
                formik={formik}
                fieldKey="permission"
                placeHolder="PERMISO"
                validate
              >
                {permissions &&
                  permissions.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
              </CustomField>
            </Grid>

            {studentID ? (
              <>
                <Grid item xs="auto" sm="auto" md="auto" lg="auto">
                  <CustomField
                    title="Fecha de reactivación 1"
                    type="date"
                    formik={formik}
                    fieldKey="reactivation1"
                    placeHolder="REACTIVACION 1"
                  />
                </Grid>
                {values.reactivation1 ? (
                  <Grid item xs="auto" sm="auto" md="auto" lg="auto">
                    <CustomField
                      title="Fecha de reactivación 2"
                      type="date"
                      formik={formik}
                      fieldKey="reactivation2"
                      placeHolder="REACTIVACION 2"
                    />
                  </Grid>
                ) : null}
              </>
            ) : null}

            <Grid item xs={12} style={{ margin: "-15px" }}></Grid>
            <Grid item xs="auto" sm="auto" md="auto" lg="auto">
              <CustomField
                title="Primer contacto"
                required
                type="select"
                formik={formik}
                fieldKey="firstContact"
                placeHolder="PRIMER CONTACTO"
                validate
              >
                {firstContactOptions.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </CustomField>
            </Grid>
            <Grid item xs="auto" sm="auto" md="auto" lg="auto">
              <CustomField
                title="Cómo nos conociste"
                required
                type="select"
                formik={formik}
                fieldKey="how"
                placeHolder="CÓMO NOS CONOCISTE"
                validate
              >
                {hows.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </CustomField>
              <Button onClick={handleClickAddOpen}>+</Button>
            </Grid>
            {studentID ? (
              <>
                <Grid item xs={12} style={{ margin: "-15px" }}></Grid>
                <Grid item xs="auto" sm="auto" md="auto" lg="auto">
                  <CustomField
                    title="Usuario"
                    required
                    type="text"
                    formik={formik}
                    fieldKey="user"
                    placeHolder="USUARIO"
                    validate
                  />
                </Grid>
              
                <Grid item xs="auto" sm="auto" md="auto" lg="auto">
                  <CustomField
                    disabled={isAdmin ? false : true}
                    title="Bolsa de clases"
                    required
                    type="number"
                    formik={formik}
                    fieldKey="classes"
                    placeHolder="0"
                    validate
                  />
                </Grid>
              </>
            ) : null}

            <Grid item xs={12}>
              <Typography>Observaciones</Typography>
              <TextareaAutosize
                id="observations"
                name="observations"
                className={classes.textArea}
                rowsMax={4}
                placeholder="Escriba el mensaje"
                value={formik.values.observations}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                disabled={!formik.isValid}
                fullWidth
                onClick={formik.handleSubmit}
              >
                {studentID ? <span>MODIFICAR</span> : <span>CREAR</span>}
              </Button>
            </Grid>
          </Grid>
        </div>
        <div style={{ height: "20px" }}></div>
      </div>
    </Fade>
  );
};

export default FormStudent;
