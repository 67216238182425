import { MenuItem } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import SaveIcon from "@material-ui/icons/Save";
import { KeyboardDatePicker } from "@material-ui/pickers";
import moment from "moment";
import React, { useState } from "react";
import { exams as examsAPI } from "../../../api";

const useStyles = makeStyles((theme) => ({
  marginRight: {
    marginRight: theme.spacing(1),
  },
  labelRoot: {
    color: "white",
    fontWeight: "bold",
    "&$labelFocused": {
      color: "white",
    },
  },
  acceptButton: {
    backgroundColor: "rgb(18,151,91)",
    "&:hover": {
      backgroundColor: "rgb(18,151,91)",
    },
  },
  actions: {
    backgroundColor: "rgb(248,250,252)",
  },
}));

const ModalAddFormExam = (props) => {
  const { centerID, open, setOpen } = props;
  const classes = useStyles();

  const [values, setValues] = useState({ centerID });
  const [errorMessage, setErrorMessage] = useState("");

  const handleChange = (key, value) => {
    setValues({
      ...values,
      [key]: value,
    });
  };

  const handleSubmit = () => {
    examsAPI
      .create(values)
      .then((response) => {
        setValues({ centerID });
        setOpen(false);
      })
      .catch((x) => {
        setErrorMessage("Error adding permiso");
      });
  };

  const handleCancelClose = () => {
    setValues({ centerID });
    setOpen(false);
  };

  return (
    <Dialog open={open} onClose={handleCancelClose}>
      <DialogTitle id="form-dialog-title">
        <span>CREAR EXAMEN</span>
      </DialogTitle>
      <Divider style={{ margin: "0 20px" }}></Divider>
      <DialogContent style={{ overflowY: "hidden", margin: "20px 0" }}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography>
              Fecha de examen
              <span style={{ color: "rgb(159,46,37)" }}>*</span>
            </Typography>
            <KeyboardDatePicker
              fullWidth
              autoOk
              placeholder="Fecha"
              variant="inline"
              inputVariant="outlined"
              size="small"
              format="DD/MM/yyyy"
              value={values.date || null}
              InputAdornmentProps={{ position: "end" }}
              onChange={(date) => handleChange("date", moment(date).format())}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography>
              Tipo de examen (Teorico o practico)
              <span style={{ color: "rgb(159,46,37)" }}>*</span>
            </Typography>
            <TextField
              fullWidth
              variant="outlined"
              size="small"
              id="genre"
              placeholder="PROFESOR"
              select
              value={values.type}
              onChange={(e) => handleChange("type", e.target.value)}
            >
              {["Teórico", "Práctico"].map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions className={classes.actions}>
        <p>{errorMessage}</p>
        <Button size="large" variant="outlined" onClick={handleCancelClose}>
          <CloseIcon className={classes.marginRight} />
          Cancelar
        </Button>
        <Button
          className={classes.acceptButton}
          size="large"
          variant="outlined"
          onClick={handleSubmit}
        >
          <SaveIcon className={classes.marginRight} /> <span>Añadir</span>
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ModalAddFormExam;
