import {
  Button,
  Fade,
  makeStyles,
  MenuItem,
  TextField,
  Typography,
} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import React, { useEffect, useRef, useState } from "react";
import Moment from "react-moment";
import { Link, Redirect } from "react-router-dom";
import { students as studentsAPI } from "../../../api";
import { DATE_FORMAT, FADE_TRANSITION } from "../../../lib/constants";
import { exportData } from "../../../lib/exportData";
import { studentTypeOptions } from "../../../lib/textOptions";
import CustomDatePicker from "../../core/CustomDatePicker";
import CustomTable from "../../core/CustomTable";
import DelayedChangeTextField from "../../core/DelayedChangeTextField";
import { LoadingIcon } from "../../core/LoadingIcon";
const NO_VALUE = "No valor";

const useStyles = makeStyles((theme) => ({
  rootGrid: {
    maxWidth: "100%",
    margin: "auto",
  },
  root: {
    flexGrow: 1,
    width: "100%",
    minHeight: "100vh",
    "& .MuiTextField-root": {
      minWidth: "20ch",
    },
  },
  header: {
    backgroundColor: "rgb(248,250,252)",
    borderBottom: "2px solid rgb(227,232,239)",
    height: 70,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  headerText: {
    fontWeight: "bold",
    marginLeft: 40,
    color: theme.palette.primary.main,
  },
  headerButtons: {
    marginRight: 40,
  },
  searchContainer: {
    margin: 20,
    border: "1px solid",
    borderColor: theme.palette.primary.dark,
    borderRadius: 5,
    padding: 12,
  },
  tabPanel: {
    backgroundColor: theme.palette.primary.main,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.primary.dark,
  },
  marginLeft: {
    marginLeft: "10px",
  },
  formControl: {
    width: "100%",
  },
  alignRight: {
    textAlign: "right",
  },
  alignLeft: {
    textAlign: "left",
  },
  container: {
    width: "100%",
    marginLeft: 12,
    marginRight: 12,
  },
  savedSearchLink: {
    marginLeft: "auto",
    marginRight: 0,
  },
  downLoadButton: {
    marginRight: theme.spacing(1),
    backgroundColor: "transparent",
    color: "rgb(56, 161, 105)",
    border: "1px solid rgb(56, 161, 105)",
    "&:hover": {
      marginRight: theme.spacing(1),
      border: "1px solid rgb(56, 161, 105)",
      backgroundColor: "rgb(56, 161, 105)",
      opacity: 1,
      color: "#FFFFFF",
    },
  },
}));

const columns = [
  {
    title: "ID",
    field: "registerNumber",
    render: (data) => <Typography>{data.registerNumber}</Typography>,
    emptyValue: NO_VALUE,
    width: 5,
    cardFormat: "STRING",
  },
  { title: "DNI", field: "dni", emptyValue: NO_VALUE, cardFormat: "STRING" },
  {
    title: "Nombre",
    field: "firstName",
    emptyValue: NO_VALUE,
    cardFormat: "STRING",
  },
  {
    title: "Apellidos",
    field: "lastName1",
    render: (data) => (
      <Typography>
        {data.lastName1 && data.lastName1} {data.lastName2 && data.lastName2}
      </Typography>
    ),
    emptyValue: NO_VALUE,
    cardFormat: "STRING",
  },
  {
    title: "Télefono",
    field: "phone",
    emptyValue: NO_VALUE,
    cardFormat: "STRING",
  },
  {
    title: "Fecha matrícula",
    field: "signUp",
    render: (data) => <Moment format={DATE_FORMAT}>{data.signUp}</Moment>,
    emptyValue: "",
    cardFormat: "DATE",
  },
  {
    title: "Fecha inicio",
    field: "startDate",
    render: (data) => <Moment format={DATE_FORMAT}>{data.startDate}</Moment>,
    emptyValue: "",
    cardFormat: "DATE",
  },
  {
    title: "Fecha apto práctica",
    field: "practice",
    render: (data) => <Moment format={DATE_FORMAT}>{data.practice}</Moment>,
    emptyValue: "",
    cardFormat: "DATE",
  },
  { title: "Permiso", field: "permission", emptyValue: NO_VALUE },
  {
    title: "Fecha nacimiento",
    field: "birthday",
    render: (data) => <Moment format={DATE_FORMAT}>{data.birthday}</Moment>,
    emptyValue: NO_VALUE,
    cardFormat: "DATE",
  },
];

const Students = ({ centerID, isAdmin }) => {
  const classes = useStyles();
  const tableRef = useRef(null);

  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);
  const [selectedStudentID, setSelectedStudentID] = useState("");
  const [page, setPage] = useState(1);
  const defaultPageSize = 10;
  const [totalResults, setTotalResults] = useState();
  const [criteria, setCriteria] = useState({ type: "Todos" });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    setIsLoading(true);
    let params = {
      centerID: centerID !== "1" ? centerID : null,
      perPage: defaultPageSize,
      page: page,
      ...criteria,
    };

    studentsAPI.getAll(params).then((result) => {
      setData(result.results);
      setPage(result.page);
      setTotalResults(result.totalCount);
      setIsLoading(false);
    });
  }, [criteria, page, centerID]);

  const handleCriteriaChange = (newCriteria) => {
    setPage(1);
    setCriteria({ ...criteria, ...newCriteria });
  };

  const handlePageChange = (newPage) => {
    window.scrollTo(0, 0);
    setPage(newPage);
  };
  const handleStudentSelected = (id) => {
    setSelectedStudentID(id);
  };

  const downloadExcel = () => {
    let params = {
      view: "export",
      centerID: centerID !== "1" ? centerID : null,
      perPage: 25,
      page: page,
      ...criteria,
    };
    studentsAPI.getAll(params).then((result) => {
      exportData(result.results, "Estudiantes", "Estudiantes");
    });
  };

  if (selectedStudentID) {
    return <Redirect to={`/edit-student/${selectedStudentID}`}></Redirect>;
  }

  if (columns.length === 0) return "LOADING";

  return (
    <Fade in={true} timeout={FADE_TRANSITION}>
      <div className={classes.root}>
        <div className={classes.header}>
          <Grid container>
            <Typography className={classes.headerText}>ALUMNOS</Typography>
          </Grid>
          {centerID === 1 ? null : (
            <Grid
              container
              spacing={3}
              className={classes.headerButtons}
              direction="row"
              justify="flex-end"
            >
              <Link to={`/add-student`}>
                <Button>CREAR ALUMNO</Button>
              </Link>
            </Grid>
          )}
        </div>

        <div style={{ margin: "40px 40px 5px 40px" }}>
          <Grid container spacing={2} direction="row" justify="flex-start">
            <Grid item xs={12} sm={6} md="auto" lg="auto">
              <DelayedChangeTextField
                value={criteria.q}
                onChange={(val) => {
                  handleCriteriaChange({
                    q: val.toUpperCase() || "",
                  });
                }}
              ></DelayedChangeTextField>
            </Grid>
            <Grid item xs={12} sm={6} md="auto" lg="auto">
              <TextField
                fullWidth
                style={{ backgroundColor: "white" }}
                variant="outlined"
                size="small"
                id="Type"
                select
                value={criteria.type}
                onChange={(e) => handleCriteriaChange({ type: e.target.value })}
              >
                {studentTypeOptions.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} sm={12} md="auto" lg="auto">
              <CustomDatePicker
                startDate={criteria.startDate}
                endDate={criteria.endDate}
                handleChangeCriteria={handleCriteriaChange}
              ></CustomDatePicker>
            </Grid>
            {isAdmin ? (
              <Grid item xs={12} sm={6} md="auto" lg="auto">
                <Button
                  onClick={() => downloadExcel()}
                  className={classes.downLoadButton}
                  variant="outlined"
                >
                  <span>Exportar a Excel</span>
                </Button>
              </Grid>
            ) : null}
          </Grid>
        </div>
        <div style={{ padding: "20px 40px 0 40px" }}>
          {isLoading ? (
            <LoadingIcon></LoadingIcon>
          ) : (
            <CustomTable
              tableRef={tableRef}
              data={data}
              columns={columns}
              handlePageChange={handlePageChange}
              page={page}
              totalResults={totalResults}
              defaultPageSize={defaultPageSize}
              handleClickEditOpen={handleStudentSelected}
            ></CustomTable>
          )}
        </div>
      </div>
    </Fade>
  );
};

export default Students;
