import {
  Button,
  Fade,
  Grid,
  TextareaAutosize,
  TextField,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React, { useEffect, useRef, useState } from "react";
import CanvasDraw from "react-canvas-draw";
import { Redirect, useParams } from "react-router-dom";
import { FADE_TRANSITION } from "../../../lib/constants";
import { classes as classesAPI } from "../../../api";
import moment from "moment";
import AddCalificationModal from "./AddCalificationModal";
import { calculateLevelPracticalClasses } from "../../../lib/calculateLevelPracticalClasses";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      minWidth: "20ch",
    },
  },
  gridContainer: {
    backgroundColor: "rgb(255,255,255)",
    margin: "20px 40px",
    borderTop: "5px solid rgb(159,46,37)",
    borderRadius: "0 0 10px 10px",
    padding: 20,
    height: "100vh",
  },
  header: {
    backgroundColor: "rgb(248,250,252)",
    borderBottom: "2px solid rgb(227,232,239)",
    height: 70,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  headerText: {
    fontWeight: "bold",
    marginLeft: 40,
    color: theme.palette.primary.main,
  },
  headerSubText: {
    fontWeight: "bold",
    color: theme.palette.primary.main,
  },
  textArea: {
    minHeight: 70,
    minWidth: "90%",
    // maxWidth: "99%",
    // width: "99",
    borderRadius: 3,
    // padding: 8,
  },
}));

const FormTeacherClass = () => {
  const classes = useStyles();
  let canvasRef = useRef(null);
  const { classID } = useParams();
  const [values, setValues] = useState({});
  const [isAwaiting, setIsAwaiting] = useState(false);
  const [isStarted, setIsStarted] = useState(false);
  const [isFinished, setIsFinished] = useState(false);
  const [redirect, setRedirect] = useState(false);
  const [error, setError] = useState(false);
  const [openCalificationModal, setOpenCalificationModal] = useState(false);

  const handleChange = (key, value) => {
    setValues({
      ...values,
      [key]: value,
    });
  };

  useEffect(() => {
    getClass();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getClass = () => {
    if (classID) {
      classesAPI.getOnePracticalClass(classID).then(async (results) => {
        let levelStudent;

        await classesAPI
          .getPracticalClassesLevel({ studentID: results.result.studentID })
          .then((result) => {
            levelStudent = result.level.filter(({ evaluation }) => {
              return evaluation >= 5;
            }).length;

            results.result.level = calculateLevelPracticalClasses(levelStudent);
          });

        setValues(results.result);

        if (results.result.kmStart === null) {
          // Clase no ha empezado
          setIsAwaiting(true);
        } else if (results.result.kmStart && results.result.kmEnd === null) {
          // Clase esta en marcha
          setIsStarted(true);
        } else if (results.result.kmStart && results.result.kmEnd) {
          // Clase ha terminado
          setIsFinished(true);
        }
      });
    }
  };

  const handleSubmitCenter = () => {
    if (isAwaiting) {
      const newValues = {
        startTime: moment(new Date()).format("hh:mm:ss"),
        kmStart: values.kmStart,
      };
      classesAPI
        .updatePracticalClass(newValues, classID)
        .then((response) => {
          setIsAwaiting(false);
          getClass();
        })
        .catch((x) => {
          console.log("Error adding permiso");
        });
    } else if (isStarted) {
      const newValues = {
        endTime: moment(new Date()).format("hh:mm:ss"),
        kmEnd: values.kmEnd,
        studentSignature: values.studentSignature,
      };
      classesAPI
        .updatePracticalClass(newValues, classID)
        .then((response) => {
          setIsStarted(false);
          setOpenCalificationModal(true);
        })
        .catch((x) => {
          console.log("Error adding permiso");
        });
    } else if (isFinished) {
      if (values.evaluation > 10) {
        setError("La evaluación no puede ser mayor que 10");
      } else {
        const newValues = {
          evaluation: values.evaluation,
          observation: values.observation,
        };
        classesAPI
          .updatePracticalClass(newValues, classID)
          .then((response) => {
            setIsFinished(false);
            setRedirect(true);
          })
          .catch((x) => {
            console.log("Error adding permiso");
          });
      }
    }
  };

  if (redirect) {
    return <Redirect to="/"></Redirect>;
  }

  return (
    <Fade in={true} timeout={FADE_TRANSITION}>
      <div className={classes.root}>
        <AddCalificationModal
          open={openCalificationModal}
          setOpen={setOpenCalificationModal}
          setRedirect={setRedirect}
          classID={classID}
        />
        <div className={classes.header}>
          <Grid
            container
            // style={{ margin: "0 20px" }}
            xs={12}
            justify="space-between"
          >
            <Grid item>
              <Typography className={classes.headerText}>
                <span>CLASE</span>
              </Typography>
            </Grid>
            <Grid item style={{ marginRight: 40 }}>
              <a
                href={`https://develop2020.autius.com/api/generatePracticalFile/${values.studentID}`}
              >
                <Button>Descargar Actividad</Button>
              </a>
            </Grid>
          </Grid>
        </div>

        <div className={classes.gridContainer}>
          <Grid
            style={{ margin: "10px 0" }}
            container
            spacing={3}
            direction="row"
            justify="flex-start"
          >
            <Grid item xs={12}>
              <Grid container direction="row" justify="space-between">
                <Grid>
                  <Typography className={classes.headerSubText}>
                    CLASE DE 45m - {values?.permission} {values?.firstName}{" "}
                    {values?.lastName1} {values?.phone} | NIVEL PRACTICAS:{" "}
                    {values?.level}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm="auto" md="auto" lg="auto">
              <Typography>
                KM iniciales<span style={{ color: "rgb(159,46,37)" }}>*</span>
              </Typography>
              <TextField
                disabled={isAwaiting ? false : true}
                fullWidth
                variant="outlined"
                size="small"
                placeholder="0"
                value={values.kmStart}
                onChange={(e) => handleChange("kmStart", e.target.value)}
              ></TextField>
            </Grid>
            <Grid item xs={12} sm="auto" md="auto" lg="auto">
              <Typography>
                KM finales
                <span style={{ color: "rgb(159,46,37)" }}>*</span>
              </Typography>
              <TextField
                disabled={isStarted ? false : true}
                fullWidth
                variant="outlined"
                size="small"
                placeholder="0"
                value={values.kmEnd}
                onChange={(e) => handleChange("kmEnd", e.target.value)}
              ></TextField>
            </Grid>
            <Grid item xs={12} style={{ margin: "-15px 0" }}></Grid>
            <Grid item xs={12} sm="auto" md="auto" lg="auto">
              <Typography>Hora de inicio</Typography>
              <TextField
                fullWidth
                disabled
                variant="outlined"
                size="small"
                placeholder="Hora inicio"
                value={values.startTime}
              ></TextField>
            </Grid>
            <Grid item xs={12} sm="auto" md="auto" lg="auto">
              <Typography>Hora de finalización</Typography>
              <TextField
                fullWidth
                disabled
                variant="outlined"
                size="small"
                placeholder="Hora finalización"
                value={values.endTime}
              ></TextField>
            </Grid>
            {isFinished && (
              <Grid item xs={12} sm="auto" md="auto" lg="auto">
                <Typography>Evaluación</Typography>
                <TextField
                  fullWidth
                  variant="outlined"
                  size="small"
                  placeholder="0"
                  type="number"
                  value={values.evaluation}
                  onChange={(e) =>
                    handleChange("evaluation", e.target.value.toUpperCase())
                  }
                ></TextField>
              </Grid>
            )}
            <Grid item xs={12} style={{ margin: "-15px 0" }}></Grid>
            {isFinished && (
              <Grid item xs={12}>
                <div style={{ maxWidth: "700px" }}>
                  <Typography>Observaciones</Typography>
                  <TextareaAutosize
                    fullWidth
                    id="observations"
                    name="observations"
                    className={classes.textArea}
                    rowsMax={3}
                    placeholder="Escriba el mensaje"
                    value={values.observation}
                    onChange={(e) =>
                      handleChange("observation", e.target.value)
                    }
                    // onBlur={handleBlur}
                  />
                </div>
              </Grid>
            )}
          </Grid>
          <Grid container style={{ marginTop: "10px" }}></Grid>
          <Grid
            style={{ margin: "10px 0" }}
            spacing={2}
            container
            direction="row"
            justify="flex-start"
          >
            {isAwaiting ? null : (
              <Grid item xs={12}>
                <Typography>
                  Firma del alumno
                  <span style={{ color: "rgb(159,46,37)" }}>*</span>
                </Typography>
                {isFinished && <img src={values.studentSignature} alt=""></img>}
                {isStarted && (
                  <Grid container spacing={3} style={{ maxWidth: "700px" }}>
                    <Grid item xs={12} md={9}>
                      <CanvasDraw
                        ref={canvasRef}
                        style={{
                          backgroundColor: "#e2e8f0",
                          borderRadius: "20px",
                        }}
                        imgSrc={values.studentSignature}
                        lazyRadius={0}
                        hideGrid={true}
                        brushColor={"black"}
                        brushRadius={2}
                        canvasWidth={500}
                        canvasHeight={200}
                        onChange={() =>
                          handleChange(
                            "studentSignature",
                            canvasRef.current.canvasContainer.children[1].toDataURL()
                          )
                        }
                      />
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <Button
                        onClick={() => handleChange("studentSignature", "")}
                      >
                        Borrar
                      </Button>
                    </Grid>
                  </Grid>
                )}
              </Grid>
            )}
            <Grid item xs={12}>
              <Button fullWidth onClick={handleSubmitCenter}>
                {isAwaiting && <span>EMPEZAR CLASE</span>}
                {isStarted && <span>TERMINAR</span>}
                {isFinished && <span>GUARDAR</span>}
              </Button>
              <Typography style={{ color: "red" }}>{error && error}</Typography>
            </Grid>
          </Grid>
        </div>
      </div>
    </Fade>
  );
};

export default FormTeacherClass;
