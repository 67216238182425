import { Button, Fade, Grid, TextField, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { KeyboardDatePicker } from "@material-ui/pickers";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Redirect } from "react-router-dom";
import { supervisors as supervisorsApi } from "../../api";
import { FADE_TRANSITION } from "../../lib/constants";
import { useParams } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      minWidth: "20ch",
    },
  },
  gridContainer: {
    backgroundColor: "rgb(255,255,255)",
    margin: 40,
    borderTop: "5px solid rgb(159,46,37)",
    borderRadius: "0 0 10px 10px",
    padding: 20,
  },
  header: {
    backgroundColor: "rgb(248,250,252)",
    borderBottom: "2px solid rgb(227,232,239)",
    height: 70,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  headerText: {
    fontWeight: "bold",
    marginLeft: 40,
    color: theme.palette.primary.main,
  },
  textArea: {
    minHeight: 150,
    minWidth: "99%",
    maxWidth: "99%",
    width: "99",
    borderRadius: 3,
    padding: 8,
  },
}));

const FormSupervisor = ({ centerID }) => {
  const { supervisorID } = useParams();
  const classes = useStyles();
  const [values, setValues] = useState({ idCenter: centerID });
  const [loading, setLoading] = useState(true);
  const [redirect, setRedirect] = useState(false);

  useEffect(() => {
    if (supervisorID) {
      supervisorsApi
        .getOne(supervisorID)
        .then((response) => {
          setValues(response.result);
        })
        .catch((x) => {
          console.log("Error trayendo centros");
        });
    }
    setLoading(false);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = (key, value) => {
    setValues({
      ...values,
      [key]: value,
    });
  };

  const handleSubmitSupervisor = () => {
    supervisorID
      ? supervisorsApi
          .update(values, supervisorID)
          .then((response) => {
            setRedirect(true);
          })
          .catch((x) => {
            console.log("Error adding permiso");
          })
      : supervisorsApi
          .create({ ...values, idIdentity: values.idCenter })
          .then((response) => {
            setRedirect(true);
          })
          .catch((x) => {
            console.log("Error adding permiso");
          });
  };

  if (loading) {
    return <>Loading</>;
  }

  if (redirect) {
    return <Redirect to={`/central`}></Redirect>;
  }

  return (
    <Fade in={true} timeout={FADE_TRANSITION}>
      <div className={classes.root}>
        {supervisorID ? null : (
          <div className={classes.header}>
            <Grid container xs={12}>
              <Typography className={classes.headerText}>
                <span>NUEVO SUPERVISOR</span>
              </Typography>
            </Grid>
          </div>
        )}

        <div className={classes.gridContainer}>
          <Grid container spacing={3} direction="row" justify="flex-start">
            <Grid item xs={12}>
              <Grid container direction="row" justify="space-between">
                <Grid>
                  {supervisorID ? (
                    <Typography>
                      PROFESOR - {values.firstName} {values.lastName1}{" "}
                      {values.lastName2}
                    </Typography>
                  ) : (
                    <Typography>FICHA</Typography>
                  )}
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Typography>
                <b>Datos personales</b>
              </Typography>
            </Grid>
            <Grid item xs="auto" sm="auto" md="auto" lg="auto">
              <Typography>
                Nombre<span style={{ color: "rgb(159,46,37)" }}>*</span>
              </Typography>
              <TextField
                variant="outlined"
                size="small"
                placeholder="NOMBRE"
                value={values.firstName}
                onChange={(e) =>
                  handleChange("firstName", e.target.value.toUpperCase())
                }
              ></TextField>
            </Grid>
            <Grid item xs="auto" sm="auto" md="auto" lg="auto">
              <Typography>
                Primer apellido
                <span style={{ color: "rgb(159,46,37)" }}>*</span>
              </Typography>
              <TextField
                variant="outlined"
                size="small"
                placeholder="PRIMER APELLIDO"
                value={values.lastName1}
                onChange={(e) =>
                  handleChange("lastName1", e.target.value.toUpperCase())
                }
              ></TextField>
            </Grid>
            <Grid item xs="auto" sm="auto" md="auto" lg="auto">
              <Typography>Segundo Apellido</Typography>
              <TextField
                variant="outlined"
                size="small"
                placeholder="SEGUNDO APELLIDO"
                value={values.lastName2}
                onChange={(e) =>
                  handleChange("lastName2", e.target.value.toUpperCase())
                }
              ></TextField>
            </Grid>
            <Grid item xs="auto" sm="auto" md="auto" lg="auto">
              <Typography>
                DNI<span style={{ color: "rgb(159,46,37)" }}>*</span>
              </Typography>
              <TextField
                variant="outlined"
                size="small"
                placeholder="DNI"
                value={values.dni}
                onChange={(e) =>
                  handleChange("dni", e.target.value.toUpperCase())
                }
              ></TextField>
            </Grid>
            <Grid item xs="auto" sm="auto" md="auto" lg="auto">
              <Typography>Fecha de Nacimiento</Typography>
              <KeyboardDatePicker
                autoOk
                fullWidth
                placeholder="FECHA"
                variant="inline"
                inputVariant="outlined"
                size="small"
                format="DD/MM/yyyy"
                value={values.birthday || null}
                InputAdornmentProps={{ position: "end" }}
                onChange={(date) =>
                  handleChange("birthday", moment(date).format())
                }
              />
            </Grid>

            <Grid item xs={12}>
              <Typography>
                <b>Datos de contacto</b>
              </Typography>
            </Grid>
            <Grid item xs="auto" sm="auto" md="auto" lg="auto">
              <Typography>Email</Typography>
              <TextField
                variant="outlined"
                size="small"
                placeholder="Email"
                value={values.email}
                onChange={(e) => handleChange("email", e.target.value)}
              ></TextField>
            </Grid>
            <Grid item xs="auto" sm="auto" md="auto" lg="auto">
              <Typography>Teléfono</Typography>
              <TextField
                variant="outlined"
                size="small"
                placeholder="Teléfono"
                value={values.phone}
                onChange={(e) => handleChange("phone", e.target.value)}
              ></TextField>
            </Grid>

            {supervisorID ? (
              <>
                <Grid item xs={12}>
                  <Typography>
                    <b>Datos de contacto</b>
                  </Typography>
                </Grid>
                <Grid item xs="auto" sm="auto" md="auto" lg="auto">
                  <Typography>Usuario</Typography>
                  <TextField
                    variant="outlined"
                    size="small"
                    placeholder="USUARIO"
                    value={values.user}
                    onChange={(e) => handleChange("user", e.target.value)}
                  ></TextField>
                </Grid>
                <Grid item xs="auto" sm="auto" md="auto" lg="auto">
                  <Typography>Contraseña</Typography>
                  <TextField
                    variant="outlined"
                    size="small"
                    placeholder="CONTRASEÑA"
                    value={values.password}
                    onChange={(e) => handleChange("password", e.target.value)}
                  ></TextField>
                </Grid>
              </>
            ) : null}

            <Grid item xs={12}>
              <Button fullWidth onClick={handleSubmitSupervisor}>
                {supervisorID ? <span>MODIFICAR</span> : <span>CREAR</span>}
              </Button>
            </Grid>
          </Grid>
        </div>
        {/* <div style={{ height: "20px" }}></div> */}
      </div>
    </Fade>
  );
};

export default FormSupervisor;
