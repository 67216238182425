import axios from "axios";
import { API_NAME } from "./base";

const ExamsInstance = axios.create({
  baseURL: `${API_NAME}api/exams`,
  withCredentials: true,
});

ExamsInstance.interceptors.request.use(
  (config) => {
    config.headers.Authorization = `Bearer ${localStorage.getItem("user")}`;
    return config;
  },
  (error) => Promise.reject(error)
);

const ExamsApi = {
  getAll: ({
    centerID = null,
    orderBy = "name",
    orderDir = "asc",
    page = 1,
    perPage = 10,
  } = {}) => {
    return ExamsInstance.get(``, {
      params: { centerID, orderBy, orderDir, page, perPage },
    })
      .then((response) => response.data)
      .catch((err) => console.error(err));
  },

  getOne: (examID) => {
    return ExamsInstance.get(`/${examID}`)
      .then((response) => response.data)
      .catch((err) => console.error(err));
  },

  getTheoryStudents: (examID) => {
    return ExamsInstance.get(`/theory-students/${examID}`)
      .then((response) => response.data)
      .catch((err) => console.error(err));
  },

  getExamTypes: async() => {
    return ExamsInstance.get(`/exam-types`)
      .then((response) => response.data)
      .catch((err) => console.error(err));
  },

  getPracticalStudents: (examID) => {
    return ExamsInstance.get(`/practial-students/${examID}`)
      .then((response) => response.data)
      .catch((err) => console.error(err));
  },

  updateStudentResult: (data, examID) => {
    return ExamsInstance.post(`/edit-student-result/${examID}`, data)
      .then((response) => response.data)
      .catch((err) => console.error(err));
  },

  create: (data) => {
    return ExamsInstance.post("", data)
      .then((response) => response.data)
      .catch((err) => console.error(err));
  },

  update: (data, ID) => {
    return ExamsInstance.post(`/edit-exam/${ID}`, data)
      .then((response) => response.data)
      .catch((err) => console.error(err));
  },

  storeTheoryStudents: (data) => {
    return ExamsInstance.post("/store-theory-students", data)
      .then((response) => response.data)
      .catch((err) => console.error(err));
  },

  storePracticalStudents: (data) => {
    return ExamsInstance.post("/store-practical-students", data)
      .then((response) => response.data)
      .catch((err) => console.error(err));
  },

  delete: (data) => {
    return ExamsInstance.delete(`/${data.ID}/${data.examID}`)
      .then((response) => response.data)
      .catch((err) => console.error(err));
  },
};

const exams = ExamsApi;
export default exams;
