import { Button, Fade, Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React, { useEffect, useRef, useState } from "react";
import { exitPoints as exitPointsAPI } from "../../../api";
import { FADE_TRANSITION, NO_VALUE } from "../../../lib/constants";
import CustomTable from "../../core/CustomTable";
import { LoadingIcon } from "../../core/LoadingIcon";
import ModalAddFormPoints from "./ModalAddFormPoints";
import ModalEditFormPoints from "./ModalEditFormPoints";

const useStyles = makeStyles((theme) => ({
  rootGrid: {
    maxWidth: "100%",
    margin: "auto",
  },
  root: {
    flexGrow: 1,
    width: "100%",
    minHeight: "100vh",
  },
  header: {
    backgroundColor: "rgb(248,250,252)",
    borderBottom: "2px solid rgb(227,232,239)",
    height: 70,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  headerText: {
    fontWeight: "bold",
    marginLeft: 40,
    color: theme.palette.primary.main,
  },
  headerButtons: {
    marginRight: 40,
  },
  searchContainer: {
    margin: 20,
    border: "1px solid",
    borderColor: theme.palette.primary.dark,
    borderRadius: 5,
    padding: 12,
  },
  tabPanel: {
    backgroundColor: theme.palette.primary.main,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.primary.dark,
  },
  formControl: {
    width: "100%",
  },
  alignRight: {
    textAlign: "right",
  },
  alignLeft: {
    textAlign: "left",
  },
  container: {
    width: "100%",
    marginLeft: 12,
    marginRight: 12,
  },
  savedSearchLink: {
    marginLeft: "auto",
    marginRight: 0,
  },
}));

const columns = [
  {
    title: "Nombre",
    field: "name",
    emptyValue: NO_VALUE,
    cardFormat: "STRING",
  },
  {
    title: "Estado",
    field: "active",
    emptyValue: NO_VALUE,
    cardFormat: "STRING",
    render: (data) => (
      <p>{data.active ? 'Activo':'Inactivo'}</p>
    ),
  },
  
];

const ExitPointsList = ({ centerID }) => {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);
  const [selectedPointID, setSelectedPointID] = useState("");
  const [page, setPage] = useState(1);
  const defaultPageSize = 10;
  const [totalResults, setTotalResults] = useState();
  const [openEdit, setOpenEdit] = useState(false);
  const [openAdd, setOpenAdd] = useState(false);

  const tableRef = useRef(null);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const getPoints = () => {
    setIsLoading(true);
    let params = {
      centerID: centerID !== "1" ? centerID : null,
      perPage: 25,
      page: page,
    };
    exitPointsAPI.getAll(params).then((result) => {
      setData(result.results);
      setPage(result.page);
      setTotalResults(result.totalCount);
      setIsLoading(false);
    });
  };

  useEffect(() => {
    getPoints();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, centerID]);

  const handleClickAddOpen = () => {
    setOpenAdd(true);
  };

  const handlePageChange = (newPage) => {
    window.scrollTo(0, 0);
    setPage(newPage);
  };


  const handleClickEditOpen = (ID) => {
    if (ID) {
      setSelectedPointID(ID);
    } else {
      setSelectedPointID(null);
    }
    setOpenEdit(true);
  };

  if (columns.length === 0) return "LOADING";

  return (
    <Fade in={true} timeout={FADE_TRANSITION}>
      <div className={classes.root}>
        <ModalAddFormPoints
          open={openAdd}
          setOpen={setOpenAdd}
          tableRef={tableRef}
          centerID={centerID}
          getPoints={getPoints}
        />
        <ModalEditFormPoints
          open={openEdit}
          setOpen={setOpenEdit}
          tableRef={tableRef}
          exitPointID={selectedPointID}
          getPoints={getPoints}
        />
        <div className={classes.header}>
          <Grid container xs={12}>
            <Typography className={classes.headerText}>
              <span>PUNTOS DE SALIDA</span>
            </Typography>
          </Grid>
          <Grid
            container
            spacing={3}
            xs={12}
            className={classes.headerButtons}
            direction="row"
            justify="flex-end"
          >
            <Button onClick={handleClickAddOpen}>CREAR PUNTO DE SALIDA</Button>
          </Grid>
        </div>
        <div
          style={{
            maxWidth: "600px",
            padding: "20px 40px 0 40px",
            margin: "0 auto",
            position: "relative",
          }}
        >
          {isLoading ? (
            <LoadingIcon></LoadingIcon>
          ) : (
            <CustomTable
              tableRef={tableRef}
              data={data}
              columns={columns}
              hasPagination={false}
              handlePageChange={handlePageChange}
              page={page}
              totalResults={totalResults}
              defaultPageSize={defaultPageSize}
              handleClickEditOpen={handleClickEditOpen}
              hasActions={false}
              // handleDelete={handleDelete}
            ></CustomTable>
          )}
        </div>
      </div>
    </Fade>
  );
};

export default ExitPointsList;
