import { Grid, makeStyles, Typography } from "@material-ui/core";
import React from "react";
import CustomCardItem from "./CustomCardItem";

const useStyles = makeStyles((theme) => ({
  card: {
    border: "1px solid rgba(226, 232, 240)",
    borderLeft: "8px solid rgb(229, 62, 62)",
    backgroundColor: "rgb(245,249,251)",
    cursor: "pointer",
  },
  card2: {
    border: "1px solid rgba(226, 232, 240)",
    borderLeft: "8px solid #ad201c",
    backgroundColor: "#fff",
    cursor: "pointer",
  },
  cardElement: {
    padding: "0.5rem",
  },
}));

const CustomCardList = ({
  data = [],
  columns = [],
  handleClickEditOpen = () => {},
  fetchAll = false,
}) => {
  const classes = useStyles();

  if (data.length == 0) {
    return (
      <Grid className={classes.card} justify="center" container>
        <Typography>NO SE HAN ENCONTRADO RESULTADOS</Typography>
      </Grid>
    );
  }
  return (
    <Grid container direction="column">
      {data &&
        data.map((elem, index) => {
          return (
            <CustomCardItem
              key={index}
              elem={elem}
              index={index}
              columns={columns}
              fetchAll={fetchAll}
              handleClickEditOpen={handleClickEditOpen}
            />
          );
        })}
    </Grid>
  );
};

export default CustomCardList;
