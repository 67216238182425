import React from "react";
import {
  FormControlLabel,
  Switch,
  TextField,
  Typography,
} from "@material-ui/core";
import moment from "moment";
import { KeyboardDatePicker } from "@material-ui/pickers";

export const CustomField = ({
  disabled = false,
  title,
  fieldKey,
  children,
  placeHolder,
  type,
  required = false,
  validate = false,
  formik: { values, handleBlur, setFieldValue, handleChange, errors, touched },
}) => {
  if (type === "text") {
    return (
      <>
        <Typography>
          {title}
          {required && <span style={{ color: "rgb(159,46,37)" }}>*</span>}
        </Typography>
        <TextField
          disabled={disabled}
          id={fieldKey}
          name={fieldKey}
          variant="outlined"
          size="small"
          placeholder={placeHolder}
          value={values && values[fieldKey] && values[fieldKey].toUpperCase()}
          onChange={handleChange}
          onBlur={handleBlur}
        >
          {children}
        </TextField>
        {validate && errors[fieldKey] && touched[fieldKey] ? (
          <div style={{ color: "red" }}>{errors[fieldKey]}</div>
        ) : null}
      </>
    );
  }
  if (type === "email") {
    return (
      <>
        <Typography>
          {title}
          {required && <span style={{ color: "rgb(159,46,37)" }}>*</span>}
        </Typography>
        <TextField
          disabled={disabled}
          id={fieldKey}
          name={fieldKey}
          variant="outlined"
          size="small"
          placeholder={placeHolder}
          value={values && values[fieldKey] && values[fieldKey]}
          onChange={handleChange}
          onBlur={handleBlur}
        >
          {children}
        </TextField>
        {validate && errors[fieldKey] && touched[fieldKey] ? (
          <div style={{ color: "red" }}>{errors[fieldKey]}</div>
        ) : null}
      </>
    );
  }

  if (type === "select") {
    return (
      <>
        <Typography>
          {title}
          {required && <span style={{ color: "rgb(159,46,37)" }}>*</span>}
        </Typography>
        <TextField
          disabled={disabled}
          id={fieldKey}
          name={fieldKey}
          variant="outlined"
          size="small"
          placeholder={placeHolder}
          value={values && values[fieldKey] && values[fieldKey]}
          select
          onChange={handleChange}
          onBlur={handleBlur}
        >
          {children}
        </TextField>
        {validate && errors[fieldKey] && touched[fieldKey] ? (
          <div style={{ color: "red" }}>{errors[fieldKey]}</div>
        ) : null}
      </>
    );
  }

  if (type === "number") {
    return (
      <>
        <Typography>
          {title}
          {required && <span style={{ color: "rgb(159,46,37)" }}>*</span>}
        </Typography>
        <TextField
          disabled={disabled}
          id={fieldKey}
          type="number"
          name={fieldKey}
          variant="outlined"
          size="small"
          placeholder={placeHolder}
          value={values && values[fieldKey]}
          onChange={handleChange}
          onBlur={handleBlur}
        >
          {children}
        </TextField>
        {validate && errors[fieldKey] && touched[fieldKey] ? (
          <div style={{ color: "red" }}>{errors[fieldKey]}</div>
        ) : null}
      </>
    );
  }
  if (type === "time") {
    return (
      <>
        <Typography>
          {title}
          {required && <span style={{ color: "rgb(159,46,37)" }}>*</span>}
        </Typography>
        <TextField
          disabled={disabled}
          id={fieldKey}
          type="time"
          name={fieldKey}
          variant="outlined"
          size="small"
          placeholder={placeHolder}
          value={values && values[fieldKey]}
          onChange={handleChange}
          onBlur={handleBlur}
        >
          {children}
        </TextField>
        {validate && errors[fieldKey] && touched[fieldKey] ? (
          <div style={{ color: "red" }}>{errors[fieldKey]}</div>
        ) : null}
      </>
    );
  }
  if (type === "date") {
    return (
      <>
        <Typography>
          {title}
          {required && <span style={{ color: "rgb(159,46,37)" }}>*</span>}
        </Typography>
        <KeyboardDatePicker
          disabled={disabled}
          id={fieldKey}
          name={fieldKey}
          autoOk
          placeholder={placeHolder}
          variant="inline"
          inputVariant="outlined"
          size="small"
          format="DD/MM/yyyy"
          value={(values && values[fieldKey]) || null}
          InputAdornmentProps={{ position: "end" }}
          onChange={(date) => setFieldValue(fieldKey, moment(date).format())}
          onBlur={handleBlur}
        />
      </>
    );
  }
  if (type === "boolean") {
    return (
      <>
        <FormControlLabel
          control={
            <Switch
              color="primary"
              checked={(values && values[fieldKey]) || null}
              onChange={handleChange}
              // onChange={(e) =>
              //   handleChange("completeCourse", !values.completeCourse)
              // }
              name="checkedB"
            />
          }
          label="Curso completo"
        />
      </>
    );
  }
};
