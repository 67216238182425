import {
  Button,
  Fade,
  makeStyles,
  MenuItem,
  TextField,
  Typography,
} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import React, { useEffect, useRef, useState } from "react";
import { Redirect } from "react-router";
import { centers as centersAPI } from "../../../api";
import CustomTable from "../../../components/core/CustomTable";
import { FADE_TRANSITION } from "../../../lib/constants";
import { exportData } from "../../../lib/exportData";
import { LoadingIcon } from "../../core/LoadingIcon";

const NO_VALUE = "No valor";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
  },
  button: {
    marginRight: theme.spacing(1),
  },
  clearButton: {
    marginLeft: theme.spacing(2),
  },
  downLoadButton: {
    marginRight: theme.spacing(1),
    backgroundColor: "transparent",
    color: "rgb(56, 161, 105)",
    border: "1px solid rgb(56, 161, 105)",
    "&:hover": {
      marginRight: theme.spacing(1),
      border: "1px solid rgb(56, 161, 105)",
      backgroundColor: "rgb(56, 161, 105)",
      opacity: 1,
      color: "#FFFFFF",
    },
  },
}));

const columns = [
  {
    title: "Activo",
    field: "active",
    emptyValue: "",
    cardFormat: "STRING",
    width: 100,
  },
  {
    title: "Alumno",
    field: "firstName",
    render: (data) => (
      <Typography>
        {data.firstName && data.firstName} {data.lastName1 && data.lastName1}{" "}
        {data.lastName2 && data.lastName2}
      </Typography>
    ),
    emptyValue: NO_VALUE,
    cardFormat: "STRING",
  },
  {
    title: "Bolsa clases",
    field: "quantity",
    emptyValue: NO_VALUE,
    cardFormat: "NUMBER",
  },
  {
    title: "Total",
    field: "totalPayment",
    type: "currency",
    currencySetting: { currencyCode: "EUR" },
    emptyValue: NO_VALUE,
    cardFormat: "CURRENCY",
  },
];

const ArchingsStudents = ({ centerID }) => {
  const classes = useStyles();
  const tableRef = useRef(null);

  // const columns = useSearchParamsInColumns(tableColumns);
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const defaultPageSize = 10;
  // const [totalResults, setTotalResults] = useState();

  const [criteria, setCriteria] = useState({ status: "Activo" });
  const [selectedStudentId, setSelectedStudentId] = useState(null);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    setIsLoading(true);
    let params = {
      centerID: centerID !== "1" ? centerID : null,
      ...criteria,
    };
    centersAPI.getSumarizeStudentsPayments(params).then((result) => {
      const finalResults = result.map((student) => {
        if (student.active === 1) {
          student.active = "Activo";
        } else {
          student.active = "Inactivo";
        }
        return student;
      });

      setData(finalResults);
      setIsLoading(false);
      //   setPage(result.page);
      //   setTotalResults(result.totalCount);
    });
  }, [centerID, criteria]);

  const handlePageChange = (newPage) => {
    window.scrollTo(0, 0);
    setPage(newPage);
  };

  const handleCriteriaChange = (newCriteria) => {
    setCriteria({ ...criteria, ...newCriteria });
  };

  const downloadExcel = (newData) => {
    const exportDataResult = newData.map((student) => {
      return {
        Nombre: `${student.firstName} ${student.lastName1}`,
        Clases: student.quantity,
        "Total(€)": student.totalPayment,
        Estado: student.active,
      };
    });
    exportData(exportDataResult, "Cargos");
  };

  const handleStudentSelected = (id) => {
    setSelectedStudentId(id);
  };

  if (selectedStudentId) {
    return <Redirect to={`edit-student/${selectedStudentId}`}></Redirect>;
  }

  if (columns.length === 0) return "LOADING";

  const finalData = data.filter((elem) => {
    if (criteria.status === "Activo") {
      return elem.active === "Activo" ? true : false;
    } else if (criteria.status === "Inactivo") {
      return elem.active === "Inactivo" ? true : false;
    }
    return true;
  });

  return (
    <Fade in={true} timeout={FADE_TRANSITION}>
      <div className={classes.root}>
        <div style={{ margin: "40px 40px 5px 40px" }}>
          <Grid
            container
            xs={12}
            // spacing={2}
            direction="row"
            justify="space-between"
          >
            <Grid item xs={12} sm={6} md="auto" lg="auto">
              <Typography>ESTADO</Typography>
              <TextField
                fullWidth
                style={{ backgroundColor: "white" }}
                variant="outlined"
                size="small"
                id="Type"
                placeholder="CURSO"
                select
                value={criteria.status}
                onChange={(e) =>
                  handleCriteriaChange({ status: e.target.value })
                }
              >
                <MenuItem value={"Todos"}>Todos</MenuItem>
                {[
                  { status: "Activos", value: "Activo" },
                  { status: "Inactivos", value: "Inactivo" },
                ].map((option, index) => (
                  <MenuItem key={index} value={option.value}>
                    {option.status}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid
              item
              xs={12}
              sm="auto"
              md="auto"
              lg="auto"
              direction="row"
              justify="flex-end"
            >
              <Button
                fullWidth
                onClick={() => downloadExcel(finalData)}
                className={classes.downLoadButton}
                variant="outlined"
              >
                <span>Exportar Saldos</span>
              </Button>
            </Grid>
          </Grid>
        </div>

        <div style={{ padding: "20px 40px 0 40px" }}>
          {isLoading ? (
            <LoadingIcon></LoadingIcon>
          ) : (
            <CustomTable
              tableRef={tableRef}
              data={finalData}
              columns={columns}
              handlePageChange={handlePageChange}
              page={page}
              // totalResults={totalResults}
              defaultPageSize={defaultPageSize}
              hasPagination={false}
              handleClickEditOpen={handleStudentSelected}
            ></CustomTable>
          )}
        </div>
      </div>
    </Fade>
  );
};

export default ArchingsStudents;
