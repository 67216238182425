import {
  Button,
  Fade,
  Grid,
  MenuItem,
  TextField,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { KeyboardDatePicker } from "@material-ui/pickers";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import CanvasDraw from "react-canvas-draw";
import { Redirect } from "react-router-dom";
import {
  exitPoints as exitPointsAPI,
  teachers as teacherApi,
} from "../../../api";
import { FADE_TRANSITION } from "../../../lib/constants";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      minWidth: "20ch",
    },
  },
  gridContainer: {
    backgroundColor: "rgb(255,255,255)",
    margin: 40,
    borderTop: "5px solid rgb(159,46,37)",
    borderRadius: "0 0 10px 10px",
    padding: 20,
  },
  header: {
    backgroundColor: "rgb(248,250,252)",
    borderBottom: "2px solid rgb(227,232,239)",
    height: 70,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  headerText: {
    fontWeight: "bold",
    marginLeft: 40,
    color: theme.palette.primary.main,
  },
  textArea: {
    minHeight: 150,
    minWidth: "99%",
    maxWidth: "99%",
    width: "99",
    borderRadius: 3,
    padding: 8,
  },
}));

const FormTeacher = ({ teacherID, centerID }) => {
  const classes = useStyles();
  let canvasRef = useRef(null);
  const [values, setValues] = useState({ idCenter: centerID });
  const [exitPointOptions, setExitPointOptions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [redirect, setRedirect] = useState(false);
  const [isSigning, setIsSigning] = useState(true);
  
  useEffect(() => {
    if (teacherID) {
      teacherApi
        .getOne(teacherID)
        .then((response) => {
          setValues(response.result);
          setIsSigning(false);
        })
        .catch((x) => {
          console.log("Error trayendo centros");
        });
    }
    getPoints();
    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = (key, value) => {
    setValues({
      ...values,
      [key]: value,
    });
  };

  const getPoints = () => {
    let params = {
      centerID: centerID !== "1" ? centerID : null,
    };
    exitPointsAPI.getAll(params).then((result) => {
      setExitPointOptions(result.results);
    });
  };

  const handleSubmitCenter = () => {
    teacherID
      ? teacherApi
          .update(values, teacherID)
          .then((response) => {
            setRedirect(true);
          })
          .catch((x) => {
            console.log("Error adding permiso");
          })
      : teacherApi
          .create({ ...values, idIdentity: values.idCenter })
          .then((response) => {
            setRedirect(true);
          })
          .catch((x) => {
            console.log("Error adding permiso");
          });
  };

  if (loading) {
    return <>Loading</>;
  }

  if (redirect) {
    return <Redirect to={`/teachers`}></Redirect>;
  }

  return (
    <Fade in={true} timeout={FADE_TRANSITION}>
      <div className={classes.root}>
        {teacherID ? null : (
          <div className={classes.header}>
            <Grid container xs={12}>
              <Typography className={classes.headerText}>
                <span>NUEVO PROFESOR</span>
              </Typography>
            </Grid>
          </div>
        )}

        <div className={classes.gridContainer}>
          <Grid container spacing={3} direction="row" justify="flex-start">
            <Grid item xs={12}>
              <Grid container direction="row" justify="space-between">
                <Grid>
                  {teacherID ? (
                    <Typography>
                      PROFESOR - {values.firstName} {values.lastName1}{" "}
                      {values.lastName2}
                    </Typography>
                  ) : (
                    <Typography>FICHA</Typography>
                  )}
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Typography>
                <b>Datos personales</b>
              </Typography>
            </Grid>
            <Grid item xs="auto" sm="auto" md="auto" lg="auto">
              <Typography>
                Nombre<span style={{ color: "rgb(159,46,37)" }}>*</span>
              </Typography>
              <TextField
                variant="outlined"
                size="small"
                placeholder="NOMBRE"
                value={values.firstName}
                onChange={(e) =>
                  handleChange("firstName", e.target.value.toUpperCase())
                }
              ></TextField>
            </Grid>
            <Grid item xs="auto" sm="auto" md="auto" lg="auto">
              <Typography>
                Primer apellido
                <span style={{ color: "rgb(159,46,37)" }}>*</span>
              </Typography>
              <TextField
                variant="outlined"
                size="small"
                placeholder="PRIMER APELLIDO"
                value={values.lastName1}
                onChange={(e) =>
                  handleChange("lastName1", e.target.value.toUpperCase())
                }
              ></TextField>
            </Grid>
            <Grid item xs="auto" sm="auto" md="auto" lg="auto">
              <Typography>Segundo Apellido</Typography>
              <TextField
                variant="outlined"
                size="small"
                placeholder="SEGUNDO APELLIDO"
                value={values.lastName2}
                onChange={(e) =>
                  handleChange("lastName2", e.target.value.toUpperCase())
                }
              ></TextField>
            </Grid>
            <Grid item xs="auto" sm="auto" md="auto" lg="auto">
              <Typography>
                DNI<span style={{ color: "rgb(159,46,37)" }}>*</span>
              </Typography>
              <TextField
                variant="outlined"
                size="small"
                placeholder="DNI"
                value={values.dni}
                onChange={(e) =>
                  handleChange("dni", e.target.value.toUpperCase())
                }
              ></TextField>
            </Grid>
            <Grid item xs="auto" sm="auto" md="auto" lg="auto">
              <Typography>Fecha de Nacimiento</Typography>
              <KeyboardDatePicker
                autoOk
                fullWidth
                placeholder="FECHA"
                variant="inline"
                inputVariant="outlined"
                size="small"
                format="DD/MM/yyyy"
                value={values.birthday || null}
                InputAdornmentProps={{ position: "end" }}
                onChange={(date) =>
                  handleChange("birthday", moment(date).format())
                }
              />
            </Grid>
            <Grid item xs="auto" sm="auto" md="auto" lg="auto">
              <Typography>Punto de Salida</Typography>
              <TextField
                fullWidth
                variant="outlined"
                size="small"
                id="type"
                placeholder="Punto de salida"
                select
                value={values.idExitPoint || null}
                onChange={(e) => handleChange("idExitPoint", e.target.value)}
              >
                <MenuItem value={null}>Ninguno</MenuItem>
                {!!exitPointOptions.length &&
                  exitPointOptions.map((option) => (
                    <MenuItem key={option.id} value={option.id}>
                      {option.name}
                    </MenuItem>
                  ))}
              </TextField>
            </Grid>

            <Grid item xs={12}>
              <Typography>
                <b>Datos de contacto</b>
              </Typography>
            </Grid>
            <Grid item xs="auto" sm="auto" md="auto" lg="auto">
              <Typography>Email</Typography>
              <TextField
                variant="outlined"
                size="small"
                placeholder="Email"
                value={values.email}
                onChange={(e) => handleChange("email", e.target.value)}
              ></TextField>
            </Grid>
            <Grid item xs="auto" sm="auto" md="auto" lg="auto">
              <Typography>Teléfono</Typography>
              <TextField
                variant="outlined"
                size="small"
                placeholder="Teléfono"
                value={values.phone}
                onChange={(e) => handleChange("phone", e.target.value)}
              ></TextField>
            </Grid>

            {teacherID ? (
              <>
                <Grid item xs={12}>
                  <Typography>
                    <b>Datos de contacto</b>
                  </Typography>
                </Grid>
                <Grid item xs="auto" sm="auto" md="auto" lg="auto">
                  <Typography>Usuario</Typography>
                  <TextField
                    variant="outlined"
                    size="small"
                    placeholder="USUARIO"
                    value={values.user}
                    onChange={(e) => handleChange("user", e.target.value)}
                  ></TextField>
                </Grid>
                <Grid item xs="auto" sm="auto" md="auto" lg="auto">
                  <Typography>Contraseña</Typography>
                  <TextField
                    variant="outlined"
                    size="small"
                    placeholder="CONTRASEÑA"
                    value={values.password}
                    onChange={(e) => handleChange("password", e.target.value)}
                  ></TextField>
                </Grid>
              </>
            ) : null}

            {teacherID ? (
              <>
                <Grid item xs={12}>
                  <Typography>
                    <b>Estado</b>
                  </Typography>
                </Grid>
                <Grid item xs="auto" sm="auto" md="auto" lg="auto">
                  <TextField
                    fullWidth
                    variant="outlined"
                    size="small"
                    id="type"
                    placeholder="Activo"
                    select
                    value={values.isActive || 0}
                    onChange={(e) => handleChange("isActive", e.target.value)}
                  >
                    {[
                      { id: 1, value: 1, name: "Activo" },
                      { id: 2, value: 0, name: "Inactivo" },
                    ].map((option) => (
                      <MenuItem key={option.id} value={option.value}>
                        {option.name}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
              </>
            ) : null}

            <Grid item container xs={12}>
              <Grid
                item
                xs={12}
                container
                spacing={2}
                style={{ margin: "10px 0" }}
              >
                <Grid item>
                  <Typography>Firma del profesor</Typography>
                </Grid>
                <Grid item>
                  {isSigning ? null : (
                    <Button
                      onClick={() => {
                        setIsSigning(!isSigning);
                      }}
                    >
                      Nueva Firma
                    </Button>
                  )}
                </Grid>
              </Grid>
              {teacherID === null || isSigning ? (
                <CanvasDraw
                  ref={canvasRef}
                  style={{
                    backgroundColor: "#e2e8f0",
                    borderRadius: "20px",
                  }}
                  // imgSrc={values.teacherSignature}
                  lazyRadius={0}
                  hideGrid={true}
                  brushColor={"black"}
                  brushRadius={2}
                  canvasWidth={500}
                  canvasHeight={300}
                  onChange={() =>
                    handleChange(
                      "teacherSignature",
                      canvasRef.current.canvasContainer.children[1].toDataURL()
                    )
                  }
                />
              ) : (
                <Grid continer>
                  <img src={values.teacherSignature} alt=""></img>
                </Grid>
              )}
            </Grid>
            <Grid item xs={12}>
              <Button fullWidth onClick={handleSubmitCenter}>
                {teacherID ? <span>MODIFICAR</span> : <span>CREAR</span>}
              </Button>
            </Grid>
          </Grid>
        </div>
        {/* <div style={{ height: "20px" }}></div> */}
      </div>
    </Fade>
  );
};

export default FormTeacher;
