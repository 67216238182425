import axios from "axios";
import {API_NAME} from "./base";

const TariffInstance = axios.create({
  baseURL: `${API_NAME}api/tariffs`,
  withCredentials: true,
});

TariffInstance.interceptors.request.use(
  (config) => {
    config.headers.Authorization = `Bearer ${localStorage.getItem("user")}`;
    return config;
  },
  (error) => Promise.reject(error)
);

const TariffApi = {
  getAll: ({ centerID = null } = {}) => {
    return TariffInstance.get(``, {
      params: { centerID },
    })
      .then((response) => response.data)
      .catch((err) => console.error(err));
  },

  getOne: (tariffID) => {
    return TariffInstance.get(`/${tariffID}`)
      .then((response) => response.data)
      .catch((err) => console.error(err));
  },

  getStudentPaymentTariff: (studentID) => {
    return TariffInstance.get(`/get-tariff-payment/${studentID}`)
      .then((response) => response.data)
      .catch((err) => console.error(err));
  },

  getStudentPackActives: (studentID) => {
    return TariffInstance.get(`/get-packs-actives/${studentID}`)
      .then((response) => response.data)
      .catch((err) => console.error(err));
  },

  storePayment: (data) => {
    return TariffInstance.post("/store-payment", data)
      .then((response) => response.data)
      .catch((err) => console.error(err));
  },
  create: (data) => {
    return TariffInstance.post("", data)
      .then((response) => response.data)
      .catch((err) => console.error(err));
  },

  update: (data, ID) => {
    return TariffInstance.post(`/${ID}`, data)
      .then((response) => response.data)
      .catch((err) => console.error(err));
  },

  getOnePack: (packID) => {
    return TariffInstance.get(`/pack/${packID}`)
      .then((response) => response.data)
      .catch((err) => console.error(err));
  },

  createPack: (data) => {
    return TariffInstance.post("/pack", data)
      .then((response) => response.data)
      .catch((err) => console.error(err));
  },

  updatePack: (data, ID) => {
    return TariffInstance.post(`/pack/${ID}`, data)
      .then((response) => response.data)
      .catch((err) => console.error(err));
  },
};

const tariffs = TariffApi;
export default tariffs;
