import React, { useEffect, useState } from "react";
import { Button, Fade, makeStyles, Typography } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { useParams } from "react-router-dom";
import { students as studentsApi } from "../../../api";
import { FADE_TRANSITION } from "../../../lib/constants";
import FormStudent from "./FormStudent";
import StudentsDownloads from "./StudentDownloads";
import StudentPayments from "./StudentPayments";
import StudentPracticalClasses from "./StudentPracticalClasses";
import StudentExams from "./StudentsExams";
import StudentTests from "./StudentTests";
import StudentRequests from "./StudentRequests";
import { useLocation } from "react-router-dom/cjs/react-router-dom";

const useStyles = makeStyles((theme) => ({
  rootGrid: {
    maxWidth: "100%",
    margin: "auto",
  },
  root: {
    flexGrow: 1,
    width: "100%",
    minHeight: "100vh",
    "& .MuiTextField-root": {
      width: "20ch",
    },
  },
  button: {
    marginRight: theme.spacing(1),
    backgroundColor: "rgb(160, 174, 192)",
  },
  activeButton: {
    marginRight: theme.spacing(1),
    border: "1px solid #ad201c",
    backgroundColor: "#ad201c",
    opacity: 1,
    color: "#FFFFFF",
    "&:hover": {
      marginRight: theme.spacing(1),
      border: "1px solid #ad201c",
      backgroundColor: "#ad201c",
      opacity: 1,
      color: "#FFFFFF",
    },
  },
  header: {
    backgroundColor: "rgb(248,250,252)",
    borderBottom: "2px solid rgb(227,232,239)",
    height: 70,
    alignItems: "center",
  },
  headerText: {
    fontWeight: "bold",
    marginLeft: 40,
    color: theme.palette.primary.main,
  },
  headerButtons: {
    marginRight: 40,
  },
  searchContainer: {
    margin: 20,
    border: "1px solid",
    borderColor: theme.palette.primary.dark,
    borderRadius: 5,
    padding: 12,
  },
  tabPanel: {
    backgroundColor: theme.palette.primary.main,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.primary.dark,
  },
  formControl: {
    width: "100%",
  },
  buttonsGroup: {
    textAlign: "right",
    margin: "0 40px",
  },
  alignLeft: {
    textAlign: "left",
  },
  container: {
    width: "100%",
    marginLeft: 12,
    marginRight: 12,
  },
  savedSearchLink: {
    marginLeft: "auto",
    marginRight: 0,
  },
}));

const StudentsViews = ({ centerID, isAdmin }, props) => {
  const { studentID } = useParams();
  const classes = useStyles();
  const [view, setView] = useState("Ficha");
  const [student, setStudent] = useState("");
  const params = useLocation()

 
  useEffect(() => {
    if (studentID) {
      studentsApi
        .getOne(studentID)
        .then((response) => {
          setStudent(response);
        })
        .catch((x) => {
          console.log("Error trayendo estudiante");
        });
    }
  }, [studentID, centerID]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);


  useEffect(() => {
    const query = new URLSearchParams(params.search)
    const paramView = query.get('view')
    if(paramView){

      setView(paramView)
    }else{
      setView('Ficha')
    }
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);



  const changeView = (newView) => {
    setView(newView);
  };

  return (
    <Fade in={true} timeout={FADE_TRANSITION}>
      <div className={classes.root}>
        <Grid
          container
          direction="row"
          justify="space-between"
          className={classes.header}
        >
          <Grid item>
            <Typography className={classes.headerText}>
              <span>{view.toUpperCase()}</span>
            </Typography>
            <Typography className={classes.headerText}>
              <span>
                {student.firstName} {student.lastName1} {student.registerNumber}
              </span>
            </Typography>
          </Grid>
          <Grid item className={classes.buttonsGroup}>
            <Button
              onClick={() => changeView("Ficha")}
              variant="outlined"
              className={
                view === "Ficha" ? classes.activeButton : classes.button
              }
            >
              <span>FICHA</span>
            </Button>
            <Button
              onClick={() => changeView("Examenes")}
              variant="outlined"
              className={
                view === "Examenes" ? classes.activeButton : classes.button
              }
            >
              <span>EXÁMENES</span>
            </Button>
            <Button
              onClick={() => changeView("Solicitudes")}
              variant="outlined"
              className={
                view === "Solicitudes" ? classes.activeButton : classes.button
              }
            >
              <span>SOLICITUDES</span>
            </Button>
            <Button
              onClick={() => changeView("Tests")}
              variant="outlined"
              className={
                view === "Tests" ? classes.activeButton : classes.button
              }
            >
              <span>TESTS</span>
            </Button>
            <Button
              onClick={() => changeView("Practicas")}
              variant="outlined"
              className={
                view === "Practicas" ? classes.activeButton : classes.button
              }
            >
              <span>PRÁCTICAS</span>
            </Button>
            <Button
              onClick={() => changeView("Movimientos")}
              variant="outlined"
              className={
                view === "Movimientos" ? classes.activeButton : classes.button
              }
            >
              <span>MOVIMIENTOS</span>
            </Button>
            <Button
              onClick={() => changeView("Descargas")}
              variant="outlined"
              className={
                view === "Descargas" ? classes.activeButton : classes.button
              }
            >
              <span>DESCARGAS</span>
            </Button>
          </Grid>
        </Grid>

        {view === "Ficha" && (
          <FormStudent
            centerID={centerID}
            studentID={studentID}
            isAdmin={isAdmin}
          />
        )}
        {view === "Examenes" && (
          <StudentExams
            centerID={centerID}
            studentID={studentID}
            isAdmin={isAdmin}
          />
        )}
        {view === "Solicitudes" && (
          <StudentRequests
            centerID={centerID}
            studentID={studentID}
            isAdmin={isAdmin}
          />
        )}
        {view === "Tests" && (
          <StudentTests
            centerID={centerID}
            studentID={studentID}
            student={student}
          />
        )}
        {view === "Practicas" && (
          <StudentPracticalClasses
            centerID={centerID}
            studentID={studentID}
            isAdmin={isAdmin}
          />
        )}
        {view === "Movimientos" && (
          <StudentPayments
            centerID={centerID}
            studentID={studentID}
            isAdmin={isAdmin}
          />
        )}
        {view === "Descargas" && (
          <StudentsDownloads
            centerID={centerID}
            studentID={studentID}
            isAdmin={isAdmin}
          />
        )}
      </div>
    </Fade>
  );
};

export default StudentsViews;
