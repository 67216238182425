import axios from "axios";
import { API_NAME_2 } from "../base";

const StudentPracticalClassesInstance = axios.create({
  baseURL: `${API_NAME_2}student/practical-classes`,
  withCredentials: true,
});

StudentPracticalClassesInstance.interceptors.request.use(
  (config) => {
    config.headers.Authorization = `Bearer ${localStorage.getItem("user")}`;
    return config;
  },
  (error) => Promise.reject(error)
);

const StudentPracticalClassesApi = {
  buyClassWithBag: async (data) => {
    return StudentPracticalClassesInstance.post(`/reserve-class`, data)
      .then((response) => {
        return response.data;
      })
      .catch((err) => console.error(err));
  },
};

const studentPracticalClasses = StudentPracticalClassesApi;
export default studentPracticalClasses;
