import axios from "axios";
import {API_NAME, API_NAME_2} from "./base";

const StudentInstance = axios.create({
  baseURL: `${API_NAME}api/students`,
  withCredentials: true,
});

StudentInstance.interceptors.request.use(
  (config) => {
    config.headers.Authorization = `Bearer ${localStorage.getItem("user")}`;
    return config;
  },
  (error) => Promise.reject(error)
);

const StudentApi = {
  getAll: ({
    q = null,
    startDate = null,
    endDate = null,
    type = null,
    centerID = null,
    orderBy = "name",
    orderDir = "asc",
    page = 1,
    perPage = 10,
    view = "listing",
  } = {}) => {
    return StudentInstance.get(``, {
      params: {
        q,
        startDate,
        endDate,
        type,
        centerID,
        orderBy,
        orderDir,
        page,
        perPage,
        view,
      },
    })
      .then((response) => response.data)
      .catch((err) => console.error(err));
  },

  getStudentForClasses: ({ centerID = null } = {}) => {
    return StudentInstance.get(`/class-selection`, {
      params: { centerID },
    })
      .then((response) => response.data)
      .catch((err) => console.error(err));
  },

  getStudentExams: ({
    studentID = null,
    orderBy = "name",
    orderDir = "asc",
    page = 1,
    perPage = 10,
  } = {}) => {
    return StudentInstance.get(`/get-exams`, {
      params: { studentID, orderBy, orderDir, page, perPage },
    })
      .then((response) => response.data)
      .catch((err) => console.error(err));
  },

  getPracticalClasses: ({
    studentID = null,
    orderBy = "name",
    orderDir = "asc",
    page = 1,
    perPage = 10,
  } = {}) => {
    return StudentInstance.get(`/get-practical`, {
      params: { studentID, orderBy, orderDir, page, perPage },
    })
      .then((response) => response.data)
      .catch((err) => console.error(err));
  },

  getPayments: ({
    view = "listing",
    studentID = null,
    startDate = null,
    endDate = null,
    orderBy = "name",
    orderDir = "asc",
    page = 1,
    perPage = 10,
  } = {}) => {
    return StudentInstance.get(`/get-payments`, {
      params: {
        view,
        studentID,
        startDate,
        endDate,
        orderBy,
        orderDir,
        page,
        perPage,
      },
    })
      .then((response) => response.data)
      .catch((err) => console.error(err));
  },

  getSumPayments: ({ studentID = null } = {}) => {
    return StudentInstance.get(`/get-sum-payments`, {
      params: { studentID },
    })
      .then((response) => response.data)
      .catch((err) => console.error(err));
  },

  getRequests: ({
    view = "listing",
    studentID = null,
    startDate = null,
    endDate = null,
    orderBy = "name",
    orderDir = "asc",
    page = 1,
    perPage = 10,
  } = {}) => {
    return StudentInstance.get(`/get-requests`, {
      params: {
        view,
        studentID,
        startDate,
        endDate,
        orderBy,
        orderDir,
        page,
        perPage,
      },
    })
      .then((response) => response.data)
      .catch((err) => console.error(err));
  },

  getOne: (studentID) => {
    return StudentInstance.get(`/${studentID}`)
      .then((response) => response.data)
      .catch((err) => console.error(err));
  },

  getOwnProfile: () => {
    return StudentInstance.get(`${API_NAME_2}student/profile`)
      .then((response) => response.data.data.student)
      .catch((err) => console.error(err));
  },

  getDocuments: (studentID) => {
    return StudentInstance.get(`${API_NAME_2}center/documents/personal-student-documents/${studentID}`)
      .then((response) => response.data.data)
      .catch((err) => console.error(err));
  },


  getContrato: async (studentID) => {
    try {
      const response = await StudentInstance.get(`${API_NAME_2}center/documents/contrato/${studentID}`);
      return response.data.data;
    } catch (err) {
      return console.error(err);
    }
  },
  getTrafficAuth: async (studentID) => {
    
    try {
      const response = await StudentInstance.get(`${API_NAME_2}center/documents/trafficAuth/${studentID}`, {responseType: 'arraybuffer'});
      return response.data;
    } catch (err) {
      return console.error(err);
    }
  },
  getSolicitud: async (studentID) => {
    
    try {
      const response = await StudentInstance.get(`${API_NAME_2}center/documents/solicitud/${studentID}`, {responseType: 'arraybuffer'});
      return response.data;
    } catch (err) {
      return console.error(err);
    }
  },

  create: (data) => {
    return StudentInstance.post("", data)
      .then((response) => response.data)
      .catch((err) => console.error(err));
  },

  update: (data, ID) => {
    return StudentInstance.post(`/${ID}`, data)
      .then((response) => response.data)
      .catch((err) => console.error(err));
  },

  updateToStudent: (data, ID) => {
    return StudentInstance.post(`/signup-pending/${ID}`, data)
      .then((response) => response.data)
      .catch((err) => console.error(err));
  },
};

const students = StudentApi;
export default students;
