import React from "react";
import { Route } from "react-router-dom";
import Central from "../Admin/Central";
import CoursesConfig from "../Supervisor/courses_config/CoursesConfig";
import SupervisorProfile from "../Supervisor/profile/SupervisorProfile";
import Questions from "../Supervisor/questions/Questions";

const RoutesSupervisor = ({ user, reloadUser }) => {
  return (
    <>
      <Route
        exact={true}
        path="/"
        render={(props) => (
          <Central {...props} user={user} reloadUser={reloadUser} />
        )}
      />
      <Route
        exact={true}
        path="/preguntas"
        render={(props) => (
          <Questions {...props} user={user} reloadUser={reloadUser} />
        )}
      />
      <Route
        exact={true}
        path="/mi_perfil"
        render={(props) => (
          <SupervisorProfile {...props} user={user} reloadUser={reloadUser} />
        )}
      />
      <Route
        exact={true}
        path="/cursos"
        render={(props) => (
          <CoursesConfig {...props} user={user} reloadUser={reloadUser} />
        )}
      />
    </>
  );
};

export default RoutesSupervisor;
