import { Button, Fade, Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React, { useEffect, useRef, useState } from "react";
import Moment from "react-moment";
import { vehicles as vehiclesAPI } from "../../../api";
import { DATE_FORMAT, FADE_TRANSITION, NO_VALUE } from "../../../lib/constants";
import CustomTable from "../../core/CustomTable";
import { LoadingIcon } from "../../core/LoadingIcon";
import ModalAddFormVehicle from "./ModalAddFormVehicle";
import ModalEditFormVehicle from "./ModalEditFormVehicle";

const useStyles = makeStyles((theme) => ({
  rootGrid: {
    maxWidth: "100%",
    margin: "auto",
  },
  root: {
    flexGrow: 1,
    width: "100%",
    minHeight: "100vh",
  },
  header: {
    backgroundColor: "rgb(248,250,252)",
    borderBottom: "2px solid rgb(227,232,239)",
    height: 70,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  headerText: {
    fontWeight: "bold",
    marginLeft: 40,
    color: theme.palette.primary.main,
  },
  headerButtons: {
    marginRight: 40,
  },
  searchContainer: {
    margin: 20,
    border: "1px solid",
    borderColor: theme.palette.primary.dark,
    borderRadius: 5,
    padding: 12,
  },
  tabPanel: {
    backgroundColor: theme.palette.primary.main,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.primary.dark,
  },
  formControl: {
    width: "100%",
  },
  alignRight: {
    textAlign: "right",
  },
  alignLeft: {
    textAlign: "left",
  },
  container: {
    width: "100%",
    marginLeft: 12,
    marginRight: 12,
  },
  savedSearchLink: {
    marginLeft: "auto",
    marginRight: 0,
  },
}));

const columns = [
  {
    title: "Descripción",
    field: "description",
    emptyValue: NO_VALUE,
    cardFormat: "STRING",
  },
  {
    title: "Matrícula",
    field: "enrollment",
    emptyValue: NO_VALUE,
    cardFormat: "STRING",
  },
  {
    title: "ITV",
    field: "itvDueDate",
    render: (data) => <Moment format={DATE_FORMAT}>{data.itvDueDate}</Moment>,
    emptyValue: NO_VALUE,
    cardFormat: "DATE",
  },
  {
    title: "Seguro",
    field: "insuranceDueDate",
    render: (data) => (
      <Moment format={DATE_FORMAT}>{data.insuranceDueDate}</Moment>
    ),
    emptyValue: NO_VALUE,
    cardFormat: "DATE",
  },
  {
    title: "Mantenimiento",
    field: "nextPreventiveMaintenance",
    render: (data) => (
      <Moment format={DATE_FORMAT}>{data.nextPreventiveMaintenance}</Moment>
    ),
    emptyValue: NO_VALUE,
    cardFormat: "DATE",
  },
  {
    title: "Estado",
    field: "isActive",
    emptyValue: NO_VALUE,
    cardFormat: "STRING",
    render: (data) => (
      <p>{data.isActive ? 'Activo':'Inactivo'}</p>
    ),
  },
  // {
  //   title: "Acciones",
  //   emptyValue: NO_VALUE,
  // },
];

const ListVehicles = ({ centerID }) => {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);
  const [selectedVehicleID, setSelectedVehicleID] = useState("");
  const [page, setPage] = useState(1);
  const defaultPageSize = 10;
  const [totalResults, setTotalResults] = useState();
  const [sorting, ] = useState({ orderBy: "name", orderDir: "asc" });
  const [criteria, ] = useState({});
  const [openEdit, setOpenEdit] = useState(false);
  const [openAdd, setOpenAdd] = useState(false);

  const tableRef = useRef(null);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const getVehicles = () => {
    setIsLoading(true);
    let params = {
      centerID: centerID !== "1" ? centerID : null,
      perPage: 25,
      page: page,
    };
    vehiclesAPI.getAll(params).then((result) => {
      setData(result.results);
      setPage(result.page);
      setTotalResults(result.totalCount);
      setIsLoading(false);
    });
  };

  useEffect(() => {
    getVehicles();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [criteria, page, sorting, centerID]);

  const handleClickAddOpen = () => {
    setOpenAdd(true);
  };

  const handleClickEditOpen = (ID) => {
    if (ID) {
      setSelectedVehicleID(ID);
    } else {
      setSelectedVehicleID(null);
    }
    setOpenEdit(true);
  };

  const handlePageChange = (newPage) => {
    window.scrollTo(0, 0);
    setPage(newPage);
  };

  const handleDelete = (ID) => {
    vehiclesAPI
      .delete(ID)
      .then((response) => {
        getVehicles();
      })
      .catch((x) => {
        // setError("Error adding permiso");
      });
  };

  if (columns.length === 0) return "LOADING";

  return (
    <Fade in={true} timeout={FADE_TRANSITION}>
      <div className={classes.root}>
        <ModalAddFormVehicle
          open={openAdd}
          setOpen={setOpenAdd}
          tableRef={tableRef}
          centerID={centerID}
          getVehicles={getVehicles}
        />
        <ModalEditFormVehicle
          open={openEdit}
          setOpen={setOpenEdit}
          tableRef={tableRef}
          vehicleID={selectedVehicleID}
          getVehicles={getVehicles}
        />
        <div className={classes.header}>
          <Grid container xs={12}>
            <Typography className={classes.headerText}>
              <span>VEHICULOS</span>
            </Typography>
          </Grid>
          {centerID === 1 ? null : (
            <Grid
              container
              spacing={3}
              xs={12}
              className={classes.headerButtons}
              direction="row"
              justify="flex-end"
            >
              <Button onClick={handleClickAddOpen}>CREAR VEHÍCULO</Button>
            </Grid>
          )}
        </div>
        <div style={{ padding: "20px 40px 0 40px" }}>
          {isLoading ? (
            <LoadingIcon></LoadingIcon>
          ) : (
            <CustomTable
              tableRef={tableRef}
              data={data}
              columns={columns}
              handlePageChange={handlePageChange}
              page={page}
              totalResults={totalResults}
              defaultPageSize={defaultPageSize}
              handleClickEditOpen={handleClickEditOpen}
              hasActions={false}
              handleDelete={handleDelete}
            ></CustomTable>
          )}
        </div>
      </div>
    </Fade>
  );
};

export default ListVehicles;
