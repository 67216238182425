import React from "react";
import {
  Document,
  Page,
  Text,
  View,
  Image,
  StyleSheet,
} from "@react-pdf/renderer";
import TableCell from "../components/TableCell/TableCell";
import firmaDirector from "../../../assets/img/firmaDirector.png";
import moment from "moment";

const StudentContract = ({
  data = { tarif: {}, student: {}, center: {}, studentSignature: "" },
  classPrice = "",
}) => {
  // Create styles
  const styles = StyleSheet.create({
    body: {
      fontSize: 9,
      marginTop: 30,
      marginBottom: 30,
    },
    title: {
      fontSize: 20,
      marginBottom: 20,
      textAlign: "center",
    },
    page: {
      flexDirection: "column",
      padding: 10,
      fontSize: 12,
    },
    section: {
      marginBottom: 10,
    },
    logo: {
      width: 70,
      height: 70,
      marginRight: 10,
    },
    signature: {
      width: 150,
      height: 150,
      margin: 60,
    },
    signatureDirector: {
      width: 90,
      height: 90,
      margin: 60,
    },
  });

  const { student, tariff, center, course, studentSignature } = data;

  return (
    <Document>
      <Page style={styles.body}>
        <Text style={styles.title}>
          Contrato de Enseñanza Nº {student.registerNumber}
        </Text>

        <View style={{ flexDirection: "row", margin: "0 30" }}>
          <View>
            <Image
              src="https://play-lh.googleusercontent.com/gGlJ52cjSgu5YeTeaqh4byZJ7vmdZOh90Vk-iISWJCDq5w7iNItlia3runfV751buw?not-from-cache-please"
              style={styles.logo}
            />
          </View>
          <View>
            <View
              style={{
                width: 450,
                flexDirection: "row",
                justifyContent: "space-around",
                alignContent: "flex-start",
              }}
            >
              <TableCell
                placeHolder="autoescuela"
                value={center.denomination}
                flex={2}
              />
              <TableCell
                placeHolder="Director"
                value={center.master}
                flex={2}
              />
              <TableCell
                placeHolder="Numero Provincial"
                value={center.provinceNumber}
                flex={2}
              />
            </View>
            <View
              style={{
                width: 450,
                flexDirection: "row",
                justifyContent: "space-around",
                alignContent: "flex-start",
              }}
            >
              <TableCell flex={1} placeHolder="CIF fiscal" value={center.cif} />
            </View>
            <View
              style={{
                width: 450,
                flexDirection: "row",
                justifyContent: "space-around",
                alignContent: "flex-start",
              }}
            >
              <TableCell
                flex={2}
                placeHolder="Domicilio"
                value={center.adress}
              />
              <TableCell flex={2} placeHolder="Localidad" value={center.city} />
              <TableCell
                flex={2}
                placeHolder="C.P."
                value={center.postalCode}
              />
            </View>
          </View>
        </View>

        <View style={{ flexDirection: "column", margin: "0 35" }}>
          <View
            style={{
              flexDirection: "row",
            }}
          >
            <TableCell
              flex={3}
              placeHolder="Alumno"
              value={`${student.firstName} ${student.lastName1} ${student.lastName2}`}
            />
            <TableCell
              flex={2}
              placeHolder="Fecha Nacimiento"
              value={moment(new Date(student.birthday))
                .format("DD/MM/yyyy")
                .toString()}
            />
            <TableCell flex={2} placeHolder="DNI" value={student.dni} />
          </View>
          <View
            style={{
              flexDirection: "row",
            }}
          >
            <TableCell
              flex={3}
              placeHolder="Domicilio"
              value={`${student.wayName} ${student.wayNumber} ${student.block} ${student.floor} ${student.door}`}
            />
            <TableCell flex={2} placeHolder="Localidad" value={student.city} />
            <TableCell flex={2} placeHolder="C.P." value={student.postalCode} />
          </View>
        </View>

        <View style={{ flexDirection: "column", margin: "20 35 5 35" }}>
          <Text style={{ fontSize: "10px" }}>
            Ambos con capacidad legal para contraer y obligarse, convienen el
            presente contrato de enseñanza para la formación y adiestramiento en
            la conducción de automóviles, para los que es necesario el permiso
            de clase:{" "}
            <Text style={{ fontWeight: "extrabold" }}>{course.permission}</Text>
          </Text>
        </View>

        <View style={{ flexDirection: "column", margin: "5 35" }}>
          <Text style={{ fontSize: "10px" }}>
            De acuerdo con el régimen de enseñanza establecido en el capítulo 5º
            del Reglamento de las Escuelas Particulares de Conductores, aprobado
            por el Real Decreto 1.295/2003, de fecha 17 de octubre de 2003
            (B.O.E. 258-28-10-03).
          </Text>

          <View
            style={{
              flexDirection: "row",
              marginTop: 20,
            }}
          >
            <View style={{ flex: 4, marginRight: 20 }}>
              <Text style={{ textAlign: "center", marginBottom: 10 }}>
                IVA Incluido
              </Text>
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <Text>Matrícula</Text>
                <Text>{tariff.pvpSignUp} €</Text>
              </View>
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <Text>Clases teóricas</Text>
                <Text>{classPrice} €</Text>
              </View>
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <Text>Clase Circulación</Text>
                <Text>{tariff.pvpPracticalClass} €</Text>
              </View>
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <Text>Tramitación expediente Jefatura</Text>
                <Text>{tariff.pvpProcedure} €</Text>
              </View>
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <Text>Clase examen circulación</Text>
                <Text>{tariff.pvpPracticalExam} €</Text>
              </View>
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <Text>Tasa de solicitud oficial</Text>
                <Text>{tariff.pvpRate} €</Text>
              </View>
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <Text>Examen teórico</Text>
                <Text>{tariff.pvpTheoricExam} €</Text>
              </View>
            </View>
            <View style={{ flex: 4 }}>
              <Text style={{ textAlign: "center", marginBottom: 10 }}>
                Plazos
              </Text>
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <Text style={{ flex: 4 }}>Matrícula</Text>
                <Text style={{ flex: 1 }}>6 meses</Text>
              </View>

              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <Text style={{ flex: 4 }}>Periodo de formación teórica</Text>
                <Text style={{ flex: 1 }}>3 meses</Text>
              </View>
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <Text style={{ flex: 4 }}>
                  Coste reactivación test por caducidad
                </Text>
                <Text style={{ flex: 1 }}>39,90 €</Text>
              </View>
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <Text style={{ flex: 4 }}>Reactivación de Test</Text>
                <Text style={{ flex: 1 }}>2 meses</Text>
              </View>
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <Text style={{ flex: 4 }}>(DGT) Apto del examen teórico</Text>
                <Text style={{ flex: 1 }}>2 años</Text>
              </View>
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <Text style={{ flex: 4, fontSize: "8px" }}>
                  Tras un no apto, tiempo hasta la caducidad de la tasa y
                  expediente, si hay convocatoria abierta
                </Text>
                <Text style={{ flex: 1 }}>6 meses</Text>
              </View>
            </View>
          </View>

          <View style={{ flexDirection: "column", margin: "5 0" }}>
            <Text>
              La Tramitación de Expediente se refiere a la gestión documental.
              Si el alumno autoriza que se realice por la Escuela, se firmará al
              Anexo adjunto de autorización de representación.
            </Text>
          </View>

          <View style={{ flexDirection: "column", margin: "5 0" }}>
            <Text style={{ textAlign: "center" }}>CONDICIONES GENERALES</Text>
          </View>

          <View style={{ flexDirection: "column", margin: "5 0" }}>
            <Text>
              1a. La Escuela impartirá las enseñanzas necesarias para la
              obtención del permiso de conducción reseñado, con la máxima
              dedicación y eficacia, y con profesorado que cumpla todos los
              requisitos exigidos en el Reglamento de Escuelas Particulares de
              Conductores.
            </Text>
          </View>

          <View style={{ flexDirection: "column", margin: "5 0" }}>
            <Text>
              2a. La duración de las clases será la siguiente: Teórica curso
              programado de 12 horas; Prácticas de maniobras, 45 minutos;
              Prácticas de circulación, 45 minutos.
            </Text>
          </View>

          <View style={{ flexDirection: "column", margin: "5 0" }}>
            <Text>
              3a. Para poder ser presentado a examen será necesaria una
              certificación conjunta del Director/a y el Profesor/a que haya
              impartido las enseñanzas de la conducción en la que se expresa
              que, a su juicio, el alumno ha recibido las enseñanzas necesarias
              para la obtención del permiso solicitado. Si el alumno/a
              insistiera en ser presentado a examen sin este requisito, el
              Director de la Escuela lo hará constar expresamente, resolviendo
              en cada caso la Jefatura Provincial de Tráfico, según proceda.
            </Text>
          </View>

          <View style={{ flexDirection: "column", margin: "5 0" }}>
            <Text>
              4a. Los gastos de inscripción en la Escuela se abonarán una sola
              vez, independientemente del número de convocatorias necesarias
              para la obtención del permiso de conducir. Si resuelto el contrato
              por el motivo indicado en la cláusula novena, el alumno/a
              decidiera suscribir un nuevo o nuevos contrato/s de enseñanza con
              la autoescuela, éste no abonará ningún gasto de inscripción por la
              suscripción de dicho/s contrato/s.
            </Text>
          </View>

          <View style={{ flexDirection: "column", margin: "5 0" }}>
            <Text>
              5a. Salvo casos debidamente justificados y autorizados por la
              Jefatura de Tráfico, el alumno será acompañado, durante la prueba
              de circulación por el Profesor/es que le haya impartido la
              enseñanza práctica, que se responsabilizará del doble mando.
            </Text>
          </View>

          <View style={{ flexDirection: "column", margin: "5 0" }}>
            <Text>
              6a. La Escuela velará por el cumplimiento de todo lo dispuesto en
              la legislación vigente, así como del estricto cumplimiento de las
              obligaciones del profesorado, atendiendo a cualquier reclamación o
              queja del alumno en relación con la enseñanza recibida, a cuyo
              efecto, en el caso de quedar disconforme el alumno, dispone de
              hojas de reclamaciones en el que podrá manifestar sus quejas.
            </Text>
          </View>

          <View style={{ flexDirection: "column", margin: "5 0" }}>
            <Text>
              7a. La gestión en los centros oficiales de cuantos documentos
              interesen para obtener el permiso de conducción será realizada por
              la autoescuela, previa información sobre su cuantía en el cuadro
              de condiciones económicas y autorización del alumno (a cuyo efecto
              prestará consentimiento expreso por escrito en documento adjunto
              según RD 1295/2003 de 17 de octubre, artículo 1.3). La gestión
              correspondiente a la tramitación de un segundo o sucesivos
              expedientes de la JPT, cuando se haya agotado el número de
              convocatorias a que tuviera derecho el alumno por el primer
              expediente, requerirá el consentimiento expreso del alumno en el
              momento de efectuarse esta solicitud, previa información del
              importe de este servicio y el de las tasas vigentes en tal
              momento.
            </Text>
          </View>

          <View style={{ flexDirection: "column", margin: "5 0" }} break>
            <Text>
              8a. Cualquier cantidad de dinero que haya de percibir la Escuela
              por parte del alumno deberá estar reflejada en el contrato, con
              indicación del concepto.
            </Text>
          </View>

          <View style={{ flexDirection: "column", margin: "5 0" }}>
            <Text style={{ textAlign: "center" }}>
              CONDICIONES PARTICULARES
            </Text>
          </View>

          <View style={{ flexDirection: "column", margin: "5 0" }}>
            <Text>
              9a. Tras el inicio del curso teórico, el alumno dispondrá de hasta
              3 meses para realizar su formación teórica y tener acceso a los
              test, pasado dicho periodo, para poder continuar con su formación
              deberá pagar la "reactivación" de test por periodos sucesivos de 2
              meses.
            </Text>
          </View>

          <View style={{ flexDirection: "column", margin: "5 0" }}>
            <Text>
              10a. El presente contrato tendrá una duración de 6 meses desde el
              día de su firma. Finalizado el mismo, debido a la caducidad del
              contrato, el alumno perderá los importes pagados y no consumidos.
              En relación a los pagos que realiza el alumno, no serán
              reembolsables, excepto causa de fuerza mayor
            </Text>
          </View>

          <View style={{ flexDirection: "column", margin: "5 0" }}>
            <Text>
              11a. Si el alumno/a renunciase a la obtención del permiso de
              conducción pretendido, no por ello dejará de abonar el importe de
              los servicios que, a la liquidación del presente contrato, le
              hubiesen sido prestados por la escuela, de acuerdo con los precios
              reseñados. Excepto causa de fuerza mayor o por negligencia de la
              autoescuela
            </Text>
          </View>

          <View style={{ flexDirection: "column", margin: "5 0" }}>
            <Text>
              12a. El alumno abonará a la Auto Escuela anticipadamente el
              importe de los servicios a recibir, así como el importe
              correspondiente a cada examen.
            </Text>
          </View>
          <View style={{ flexDirection: "column", margin: "5 0" }}>
            <Text>
              13a. La documentación necesaria para la solicitud del permiso de
              conducir aludido en este contrato será aportado por el alumno/a
              con una anticipación de diez días a la convocatoría
              correspondiente.
            </Text>
          </View>
          <View style={{ flexDirection: "column", margin: "5 0" }}>
            <Text>
              14a. El alumno deberá avisar, al menos con VEINTICUATRO horas de
              antelación su falta de asistencia a la clase práctica. En caso de
              no hacerlo la clase se contará como realizada por lo que tendrá
              que abonar el importe de la misma. salvo causa de fuerza mayor. Si
              el alumno/a tuviese alguna promoción de clases prácticas sin
              coste, serán realizadas una vez realizado el curso teórico y
              superado el examen teórico.
            </Text>
          </View>
          <View style={{ flexDirection: "column", margin: "5 0" }}>
            <Text>
              15a. Las partes, para la resolución de cualquier conflicto
              derivado del presente contrato que genere litigio judicial, se
              someten a la competencia de los Juzgados y Tribunales que
              correspondan al lugar del cumplimiento de la obligación que genere
              dicho conflicto.
            </Text>
          </View>
          <View style={{ flexDirection: "column", margin: "5 0" }}>
            <Text>
              16a. En virtud de lo establecido en la Ley 3/2018 de Protección de
              Datos y RGPD UE2016/679, La Escuela le informa que sus datos
              pasarán a formar parte de un fichero titularidad de la misma, con
              la única finalidad de gestionar la relación que en virtud del
              presente contrato se establece entre usted y la referida entidad.
              La Escuela le garantiza el ejercicio de sus derechos de acceso,
              rectificación, cancelación y oposición solicitando por escrito en
              info@autius.com. Asimismo se autoriza la comunicación por las
              sigueintes vías: WASAP (SI) - TELEFONO (SI) - SMS (SI) - CORREO
              ELECTRONICO (SI)
            </Text>
          </View>
          <View style={{ flexDirection: "column", margin: "5 0" }}>
            <Text>
              17a. Convocatorias: se estará a lo dispuesto en el Artículo 51 del
              Reglamento General de Conductores. (Real Decreto 818/2009 del 8 de
              Mayo): entre convocatorias de un mismo expediente no mediarán más
              de 6 meses. La no presentación a una convocatoria supondrá la
              pérdida de la misma, salvo causa justificada ante Jefatura de
              Tráfico.
            </Text>
          </View>
          <View style={{ flexDirection: "column", margin: "5 0" }}>
            <Text>
              18a. La Auto Escuela se reservará el derecho el derecho de cambio
              de profesor o vehículo por razones de fuerza mayor.
            </Text>
          </View>
          <View style={{ flexDirection: "column", margin: "5 0" }}>
            <Text>
              19a. El importe de las tasas oficiales está sujeto a
              modificaciones en función de la variación que por parte de la DGT
              pueda sufrir.
            </Text>
          </View>
          <View style={{ flexDirection: "column", margin: "5 0" }}>
            <Text>
              En prueba de conformidad, ambas partes firman por duplicado el
              presente contrato, entregandose un ejemplar al alumno/a. En 
              {center.city} a {moment(new Date(course.signUp)).format("DD/MM/yyyy").toString()}
            </Text>
          </View>

          <View
            style={{
              flexDirection: "row",
              marginTop: 20,
            }}
          >
            <View style={{ flex: 6 }}>
              <Text style={{ textAlign: "center" }}>
                El Alumno/a (o su representante)
              </Text>
              {studentSignature && (
                <View>
                  <Image src={studentSignature} style={styles.signature} />
                </View>
              )}
            </View>
            <View style={{ flex: 6 }}>
              <Text style={{ textAlign: "center" }}>Por la Escuela</Text>
              <View>
                <Image src={firmaDirector} style={styles.signatureDirector} />
              </View>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
};

export default StudentContract;
