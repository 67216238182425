import { Button, Fade, Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React, { useEffect, useRef, useState } from "react";
import Moment from "react-moment";
import { Redirect } from "react-router-dom";
import { exams as examsAPI } from "../../../api";
import { DATE_FORMAT, FADE_TRANSITION, NO_VALUE } from "../../../lib/constants";
import CustomTable from "../../core/CustomTable";
import { LoadingIcon } from "../../core/LoadingIcon";
import ModalAddFormExam from "../exams/ModalAddFormExam";

const useStyles = makeStyles((theme) => ({
  rootGrid: {
    maxWidth: "100%",
    margin: "auto",
  },
  root: {
    flexGrow: 1,
    width: "100%",
    minHeight: "100vh",
  },
  header: {
    backgroundColor: "rgb(248,250,252)",
    borderBottom: "2px solid rgb(227,232,239)",
    height: 70,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  headerText: {
    fontWeight: "bold",
    marginLeft: 40,
    color: theme.palette.primary.main,
  },
  headerButtons: {
    marginRight: 40,
  },
  searchContainer: {
    margin: 20,
    border: "1px solid",
    borderColor: theme.palette.primary.dark,
    borderRadius: 5,
    padding: 12,
  },
  tabPanel: {
    backgroundColor: theme.palette.primary.main,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.primary.dark,
  },
  formControl: {
    width: "100%",
  },
  alignRight: {
    textAlign: "right",
  },
  alignLeft: {
    textAlign: "left",
  },
  container: {
    width: "100%",
    marginLeft: 12,
    marginRight: 12,
  },
  savedSearchLink: {
    marginLeft: "auto",
    marginRight: 0,
  },
}));

const columns = [
  {
    title: "Nº de registro",
    field: "registerNumber",
    emptyValue: NO_VALUE,
    cardFormat: "STRING",
  },
  {
    title: "Fecha de examen",
    field: "date",
    render: (data) => <Moment format={DATE_FORMAT}>{data.date}</Moment>,
    emptyValue: NO_VALUE,
    cardFormat: "DATE",
  },
  {
    title: "Tipo de examen",
    field: "type",
    emptyValue: NO_VALUE,
    cardFormat: "STRING",
  },
];

const ListVehicles = ({ centerID, isAdmin }) => {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const defaultPageSize = 10;
  const [totalResults, setTotalResults] = useState();
  const [openAdd, setOpenAdd] = useState(false);
  const [selectedExamID, setSelectedExamID] = useState("");

  const tableRef = useRef(null);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    setIsLoading(true);
    let params = {
      centerID: centerID !== "1" ? centerID : null,
      perPage: defaultPageSize,
      page: page,
    };
    examsAPI.getAll(params).then((result) => {
      setData(result.results);
      setPage(result.page);
      setTotalResults(result.totalCount);
      setIsLoading(false);
    });
  }, [page, openAdd, centerID]);

  const handleClickAddOpen = () => {
    setOpenAdd(true);
  };

  const handlePageChange = (newPage) => {
    window.scrollTo(0, 0);
    setPage(newPage);
  };

  const handleExamSelected = (id) => {
    setSelectedExamID(id);
  };

  if (selectedExamID) {
    return <Redirect to={`/examen/${selectedExamID}`}></Redirect>;
  }

  if (columns.length === 0) return "LOADING";

  return (
    <Fade in={true} timeout={FADE_TRANSITION}>
      <div className={classes.root}>
        <ModalAddFormExam
          centerID={centerID}
          open={openAdd}
          setOpen={setOpenAdd}
          tableRef={tableRef}
        />
        <div className={classes.header}>
          <Grid container xs={12}>
            <Typography className={classes.headerText}>
              <span>EXÁMENES</span>
            </Typography>
          </Grid>
          {isAdmin ? null : (
            <Grid
              container
              spacing={3}
              xs={12}
              className={classes.headerButtons}
              direction="row"
              justify="flex-end"
            >
              <Button onClick={handleClickAddOpen}>CREAR EXAMEN</Button>
            </Grid>
          )}
        </div>
        <div style={{ padding: "20px 40px 0 40px" }}>
          {isLoading ? (
            <LoadingIcon></LoadingIcon>
          ) : (
            <CustomTable
              tableRef={tableRef}
              data={data}
              columns={columns}
              handlePageChange={handlePageChange}
              page={page}
              totalResults={totalResults}
              defaultPageSize={defaultPageSize}
              handleClickEditOpen={handleExamSelected}
            ></CustomTable>
          )}
        </div>
      </div>
    </Fade>
  );
};

export default ListVehicles;
