import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Divider from "@material-ui/core/Divider";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import SaveIcon from "@material-ui/icons/Save";
import moment from "moment";
import React, { useEffect, useState } from "react";
import {
  teachers as teachersAPI,
  classes as classesAPI,
  payments as paymentsAPI,
} from "../../../api";
import {
  practicalClassesApi,
} from "../../../api/student";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";

const useStyles = makeStyles((theme) => ({
  marginRight: {
    marginRight: theme.spacing(1),
  },
  labelRoot: {
    color: "white",
    fontWeight: "bold",
    "&$labelFocused": {
      color: "white",
    },
  },
  acceptButton: {
    backgroundColor: "rgb(18,151,91)",
    "&:hover": {
      backgroundColor: "rgb(18,151,91)",
    },
  },
  actions: {
    backgroundColor: "rgb(248,250,252)",
  },
}));

const ModalBuyClass = (props) => {
  const {
    classID,
    open,
    setOpen,
    studentID,
    teachers,
    studentClasses = 0,
    getClasses,
    fetchStudentClasses,
    centerID,
  } = props;
  const classes = useStyles();

  const [values, setValues] = useState({});

  const [errorMessage, setErrorMessage] = useState("");
  const [message, setMessage] = useState("");
  const [redsysParameters, setRedsysParameters] = useState({});
  const [isReserved, setIsReserved] = useState(false);

  useEffect(() => {
    if (classID) {
      teachersAPI.getClassById({ classID }).then((results) => {
        setValues(results.results);
        let paramsRedsys = {
          centerID,
          studentID,
          price: results.results.price,
          classID,
        };
        paymentsAPI.getPaymentClassDetails(paramsRedsys).then((result) => {
          setRedsysParameters(result.result);
        });
      });
    }
    setMessage("");
  }, [classID, open, centerID, studentID]);

  const handleSubmit = () => {
    let params = {
      studentID,
    };
    // Mirar si alguien la ha reservado antes
    teachersAPI.getClassById({ classID }).then((results) => {
      if (results.results.class == 0) {
        // Se puede reservar
        if (studentClasses >= 0) {

          //Aqui
          practicalClassesApi
            .buyClassWithBag({classId: classID})
            .then((response) => {
              setMessage(response.data.message);
            })
            .catch((x) => {
              setErrorMessage("Lo siento, ha habido un error ");
            });
        } else {
        }
      } else {
        setMessage("Lo siento, alguien ya la ha reservado");
      }
    });
  };

  const handleCancelClose = () => {
    setOpen(false);
    getClasses();
    fetchStudentClasses();
  };

  const handleReserveClass = () => {
    teachersAPI.getClassById({ classID }).then((results) => {
      if (results.results.class == 0) {
        // Se puede reservar, marcar como reservada
        classesAPI.reserveClass({}, classID).then((result) => {
          setIsReserved(true);
        });
      } else {
        // setMessage("Lo siento, alguien ya la ha reservado");
      }
    });
  };

  const teacher = teachers.filter((teacher) => teacher.id === values.idTeacher);

  if (values === null) {
    return <Typography>Loading</Typography>;
  }

  if (message !== "") {
    return (
      <Dialog open={open} className={classes.root} onClose={handleCancelClose}>
        <DialogTitle id="form-dialog-title">
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <span style={{ fontWeight: "bold" }}>Reservar Clase</span>
            {studentClasses <= 0 ? (
              <span style={{ fontWeight: "bold" }}>{values.price}€</span>
            ) : null}
          </div>
        </DialogTitle>
        <Divider style={{ margin: "0 20px" }}></Divider>
        <DialogContent style={{ overflowY: "hidden", margin: "20px" }}>
          <Typography>{message}</Typography>
        </DialogContent>
        <DialogActions className={classes.actions}>
          <p>{errorMessage}</p>
          <Button
            className={classes.acceptButton}
            size="large"
            variant="outlined"
            onClick={handleCancelClose}
          >
            <CloseIcon className={classes.marginRight} />
            Continuar
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
  if (isReserved) {
    return (
      <Dialog open={open} className={classes.root} onClose={handleCancelClose}>
        <DialogTitle id="form-dialog-title">
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <span style={{ fontWeight: "bold" }}>Reservar Clase</span>
            {studentClasses <= 0 ? (
              <span style={{ fontWeight: "bold" }}>{values.price}€</span>
            ) : null}
          </div>
        </DialogTitle>
        <Divider style={{ margin: "0 20px" }}></Divider>
        <DialogContent style={{ overflowY: "hidden", margin: "20px" }}>
          <Typography>
            <span style={{ fontWeight: "bold" }}>
              Se va a redireccionar a Redsys para proceder al pago
            </span>
          </Typography>
        </DialogContent>
        <DialogActions className={classes.actions}>
          <form
            name="from"
            //PRUEBAS
            // action="https://sis-t.redsys.es:25443/sis/realizarPago"
            // REAL
            action="https://sis.redsys.es/sis/realizarPago"
            method="POST"
          >
            <input
              type="hidden"
              name="Ds_SignatureVersion"
              value="HMAC_SHA256_V1"
            />
            <input
              type="hidden"
              name="Ds_MerchantParameters"
              value={redsysParameters.Ds_MerchantParameters || null}
            />
            <input
              type="hidden"
              name="Ds_Signature"
              value={redsysParameters.Ds_Signature || null}
            />
            <span class="flex w-full rounded-md shadow-sm sm:ml-3 sm:w-auto">
              <Button
                type="submit"
                className={classes.acceptButton}
                size="large"
                variant="outlined"
                onClick={handleReserveClass}
              >
                <ShoppingCartIcon className={classes.marginRight} />
                Redirección a REDSYS
              </Button>
            </span>
          </form>
        </DialogActions>
      </Dialog>
    );
  }

  return (
    <Dialog open={open} className={classes.root} onClose={handleCancelClose}>
      <DialogTitle id="form-dialog-title">
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <span style={{ fontWeight: "bold" }}>Reservar Clase</span>
          {studentClasses <= 0 ? (
            <span style={{ fontWeight: "bold" }}>{values.price}€</span>
          ) : null}
        </div>
      </DialogTitle>
      <Divider style={{ margin: "0 20px" }}></Divider>
      <DialogContent style={{ overflowY: "hidden", margin: "20px" }}>
        <Typography>
          Va a reservar la clase del{" "}
          <span style={{ fontWeight: "bold" }}>
            {moment(values.date).format("DD/MM/YYYY")}
          </span>{" "}
          a las{" "}
          <span style={{ fontWeight: "bold" }}>
            {values.startHour &&
              values.startHour.slice(0, values.startHour.length - 3)}
          </span>{" "}
          impartida por:{" "}
          <span style={{ fontWeight: "bold" }}>
            {teacher.length > 0 &&
              `${teacher[0].firstName} ${teacher[0].lastName1}`}
          </span>
        </Typography>
        <br />
        <Typography>
          {" "}
          {studentClasses <= 0 ? null : (
            <span style={{ fontWeight: "bold" }}>
              Se restará una clase de tu bolsa
            </span>
          )}
        </Typography>
      </DialogContent>
      <DialogActions className={classes.actions}>
        <p>{errorMessage}</p>
        <Button size="large" variant="outlined" onClick={handleCancelClose}>
          <CloseIcon className={classes.marginRight} />
          Cancelar
        </Button>
        {studentClasses <= 0 ? (
          <Button
            type="submit"
            className={classes.acceptButton}
            size="large"
            variant="outlined"
            onClick={handleReserveClass}
          >
            <ShoppingCartIcon className={classes.marginRight} />
            Comprar
          </Button>
        ) : (
          <Button
            className={classes.acceptButton}
            size="large"
            variant="outlined"
            onClick={handleSubmit}
          >
            <SaveIcon className={classes.marginRight} /> Reservar Clase
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default ModalBuyClass;
