import axios from "axios";
import {API_NAME} from "./base";

const SupervisorInstance = axios.create({
  baseURL: `${API_NAME}api/supervisors`,
  withCredentials: true,
});

SupervisorInstance.interceptors.request.use(
  (config) => {
    config.headers.Authorization = `Bearer ${localStorage.getItem("user")}`;
    return config;
  },
  (error) => Promise.reject(error)
);

const SupervisorApi = {
  getAll: ({ orderDir = "asc", page = 1, perPage = 10 } = {}) => {
    return SupervisorInstance.get(``, {
      params: { orderDir, page, perPage },
    })
      .then((response) => response.data)
      .catch((err) => console.error(err));
  },

  getOne: (teacherID) => {
    return SupervisorInstance.get(`/get-one/${teacherID}`)
      .then((response) => response.data)
      .catch((err) => console.error(err));
  },

  create: (data) => {
    return SupervisorInstance.post("", data)
      .then((response) => response.data)
      .catch((err) => console.error(err));
  },

  update: (data, ID) => {
    return SupervisorInstance.post(`/${ID}`, data)
      .then((response) => response.data)
      .catch((err) => console.error(err));
  },
};

const teachers = SupervisorApi;
export default teachers;
