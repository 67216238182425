import React from "react";
import { Route } from "react-router-dom";
import ArchingsViews from "../Center/archings/ArchingsViews";
import CenterCalendarView from "../Center/calendar/CalendarCenter";
import ExamsList from "../Center/exams/ExamsList";
import ExamView from "../Center/exams/ExamView";
import FormStudent from "../Center/students/FormStudent";
import StudentsList from "../Center/students/StudentsList";
import StudentsViews from "../Center/students/StudentsViews";
import FormTariff from "../Center/tariffs/FormTariff";
import TariffsList from "../Center/tariffs/TariffsList";
import FormTeacher from "../Center/teachers/FormTeacher";
import TeachersList from "../Center/teachers/TeachersList";
import TeachersViews from "../Center/teachers/TeachersViews";
import ListVehicles from "../Center/vehicles/VehiclesList";
import ExitPointsList from "../Center/exitPoints/ExitPointsList";
import TestResult from "../Student/tests/TestResult";
import Permissions from "../Admin/Permissions";
import CenterList from "../Admin/CenterList";
import FormCenter from "../Admin/FormCenter";
import Central from "../Admin/Central";
import FormSupervisor from "../Admin/FormSupervisor.js";

const RoutesAdmin = ({ user, reloadUser, isAdmin, selectedCenter }) => {
  return (
    <>
      <Route
        exact={true}
        path="/add-student"
        render={(props) => (
          <FormStudent
            {...props}
            user={user}
            isAdmin={isAdmin}
            centerID={selectedCenter}
            reloadUser={reloadUser}
          />
        )}
      />
      <Route
        exact={true}
        path="/edit-student/:studentID"
        render={(props) => (
          <StudentsViews
            {...props}
            user={user}
            isAdmin={isAdmin}
            centerID={selectedCenter}
            reloadUser={reloadUser}
          />
        )}
      />
      <Route
        exact={true}
        path="/edit-student/:studentID/:testID"
        render={(props) => (
          <TestResult
            {...props}
            user={user}
            isAdmin={isAdmin}
            centerID={selectedCenter}
            reloadUser={reloadUser}
          />
        )}
      />
      <Route
        exact={true}
        path="/add-teacher"
        render={(props) => (
          <FormTeacher
            {...props}
            user={user}
            isAdmin={isAdmin}
            centerID={selectedCenter}
            reloadUser={reloadUser}
          />
        )}
      />
      <Route
        exact={true}
        path="/vehiculos"
        render={(props) => (
          <ListVehicles
            {...props}
            user={user}
            isAdmin={isAdmin}
            centerID={selectedCenter}
            reloadUser={reloadUser}
          />
        )}
      />
      <Route
        exact={true}
        path="/edit-teacher/:teacherID"
        render={(props) => (
          <TeachersViews
            {...props}
            user={user}
            isAdmin={isAdmin}
            centerID={selectedCenter}
            reloadUser={reloadUser}
          />
        )}
      />
      <Route
        exact={true}
        path="/calendario"
        render={(props) => (
          <CenterCalendarView
            {...props}
            user={user}
            isAdmin={isAdmin}
            centerID={selectedCenter}
            reloadUser={reloadUser}
          />
        )}
      />
      <Route
        exact={true}
        path="/"
        render={(props) => (
          <StudentsList
            {...props}
            user={user}
            isAdmin={isAdmin}
            centerID={selectedCenter}
            reloadUser={reloadUser}
          />
        )}
      />
      <Route
        exact={true}
        path="/teachers"
        render={(props) => (
          <TeachersList
            {...props}
            user={user}
            isAdmin={isAdmin}
            centerID={selectedCenter}
            reloadUser={reloadUser}
          />
        )}
      />
      <Route
        exact={true}
        path="/examenes"
        render={(props) => (
          <ExamsList
            {...props}
            user={user}
            isAdmin={isAdmin}
            centerID={selectedCenter}
            reloadUser={reloadUser}
          />
        )}
      />
      <Route
        exact={true}
        path="/tarifas"
        render={(props) => (
          <TariffsList
            {...props}
            user={user}
            isAdmin={isAdmin}
            centerID={selectedCenter}
            reloadUser={reloadUser}
          />
        )}
      />
      <Route
        exact={true}
        path="/arqueos"
        render={(props) => (
          <ArchingsViews
            {...props}
            user={user}
            isAdmin={isAdmin}
            centerID={selectedCenter}
            reloadUser={reloadUser}
          />
        )}
      />
      <Route
        exact={true}
        path="/add-center"
        render={(props) => (
          <FormCenter
            {...props}
            user={user}
            isAdmin={isAdmin}
            centerID={selectedCenter}
            reloadUser={reloadUser}
          />
        )}
      />
      <Route
        exact={true}
        path="/edit-center/:centerID"
        render={(props) => (
          <FormCenter
            {...props}
            user={user}
            isAdmin={isAdmin}
            centerID={selectedCenter}
            reloadUser={reloadUser}
          />
        )}
      />
      <Route
        exact={true}
        path="/add-supervisor"
        render={(props) => (
          <FormSupervisor
            {...props}
            user={user}
            isAdmin={isAdmin}
            centerID={selectedCenter}
            reloadUser={reloadUser}
          />
        )}
      />
      <Route
        exact={true}
        path="/edit-supervisor/:supervisorID"
        render={(props) => (
          <FormSupervisor
            {...props}
            user={user}
            isAdmin={isAdmin}
            centerID={selectedCenter}
            reloadUser={reloadUser}
          />
        )}
      />
      <Route
        exact={true}
        path="/add-tarifa"
        render={(props) => (
          <FormTariff
            {...props}
            user={user}
            isAdmin={isAdmin}
            centerID={selectedCenter}
            reloadUser={reloadUser}
          />
        )}
      />
      <Route
        exact={true}
        path="/edit-tarifa/:tariffID"
        render={(props) => (
          <FormTariff
            {...props}
            user={user}
            isAdmin={isAdmin}
            centerID={selectedCenter}
            reloadUser={reloadUser}
          />
        )}
      />
      <Route
        exact={true}
        path="/examen/:examID"
        render={(props) => (
          <ExamView
            {...props}
            user={user}
            isAdmin={isAdmin}
            centerID={selectedCenter}
            reloadUser={reloadUser}
          />
        )}
      />
      <Route
        exact={true}
        path="/permisos"
        render={(props) => (
          <Permissions
            {...props}
            user={user}
            isAdmin={isAdmin}
            centerID={selectedCenter}
            reloadUser={reloadUser}
          />
        )}
      />
      <Route
        exact={true}
        path="/salidas"
        render={(props) => (
          <ExitPointsList
            {...props}
            user={user}
            isAdmin={isAdmin}
            centerID={selectedCenter}
            reloadUser={reloadUser}
          />
        )}
      />
      <Route
        exact={true}
        path="/centros"
        render={(props) => (
          <CenterList
            {...props}
            user={user}
            isAdmin={isAdmin}
            reloadUser={reloadUser}
          />
        )}
      />
      <Route
        exact={true}
        path="/central"
        render={(props) => (
          <Central
            {...props}
            user={user}
            isAdmin={isAdmin}
            reloadUser={reloadUser}
          />
        )}
      />
    </>
  );
};

export default RoutesAdmin;
