import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import SaveIcon from "@material-ui/icons/Save";
import React, { useEffect, useState } from "react";
import { exitPoints as exitPointsAPI } from "../../../api";
import { MenuItem } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  marginRight: {
    marginRight: theme.spacing(1),
  },
  labelRoot: {
    color: "white",
    fontWeight: "bold",
    "&$labelFocused": {
      color: "white",
    },
  },
  acceptButton: {
    backgroundColor: "rgb(18,151,91)",
    "&:hover": {
      backgroundColor: "rgb(18,151,91)",
    },
  },
  actions: {
    backgroundColor: "rgb(248,250,252)",
  },
}));

const ModalEditFormPoint = (props) => {
  const {
    getPoints,
    open,
    setOpen,
    exitPointID,
  } = props;
  const classes = useStyles();

  const [values, setValues] = useState({});
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    if (exitPointID) {
      exitPointsAPI
        .getOne(exitPointID)
        .then((response) => {
          setValues(response);
        })
        .catch((x) => {
          // setError("Error trayendo centros");
        });
    }
  }, [exitPointID, open]);

  const handleChange = (key, value) => {
    setValues({
      ...values,
      [key]: value,
    });
  };

  const handleSubmit = () => {
    exitPointsAPI
      .update(values, exitPointID)
      .then((response) => {
        getPoints();
        setOpen(false);
      })
      .catch((x) => {
        setErrorMessage("Error adding permiso");
      });
  };

  const handleCancelClose = () => {
    setValues({});
    setOpen(false);
  };

  return (
    <Dialog open={open} onClose={handleCancelClose}>
      <DialogTitle id="form-dialog-title">
        <span>MODIFICAR VEHICULO</span>
      </DialogTitle>
      <Divider style={{ margin: "0 20px" }}></Divider>
      <DialogContent style={{ overflowY: "hidden", margin: "20px 0" }}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography>
              Nombre<span style={{ color: "rgb(159,46,37)" }}>*</span>
            </Typography>
            <TextField
              fullWidth
              disabled
              size="small"
              variant="outlined"
              placeholder="DESCRIPCION"
              value={values.name}
            ></TextField>
          </Grid>
          <Grid item xs={12}>
                  <Typography>
                    Estado
                  </Typography>
                  <TextField
                    fullWidth
                    variant="outlined"
                    size="small"
                    id="type"
                    placeholder="Activo"
                    select
                    value={values.active || 0}
                    onChange={(e) => handleChange("active", e.target.value)}
                  >
                    {[
                      { id: 1, value: 1, name: "Activo" },
                      { id: 2, value: 0, name: "Inactivo" },
                    ].map((option) => (
                      <MenuItem key={option.id} value={option.value}>
                        {option.name}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
          <Grid item xs={12}>
                <a
        href={`https://www.google.com/maps?q=${values.position}`}
        target="_blank"
        rel="noopener noreferrer"
      >
        <Button>MAPA</Button>
      </a>
      </Grid>
        </Grid>
      </DialogContent>
      <DialogActions className={classes.actions}>
        <p>{errorMessage}</p>
        <Button size="large" variant="outlined" onClick={handleCancelClose}>
          <CloseIcon className={classes.marginRight} />
          Cancelar
        </Button>
        <Button
          className={classes.acceptButton}
          size="large"
          variant="outlined"
          onClick={handleSubmit}
        >
          <SaveIcon className={classes.marginRight} /> <span>Modificar</span>
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ModalEditFormPoint;
