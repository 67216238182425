import {
  AppBar,
  CardContent,
  Collapse,
  Grid,
  IconButton,
  makeStyles,
  Toolbar,
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import ClearIcon from "@material-ui/icons/Clear";
import MenuIcon from "@material-ui/icons/Menu";
import React from "react";
import { AutiusSmallIcon } from "../../assets/img/AutiusSmallIcon";
import AdminRoutes from "./AdminRoutes";
import CenterRoutes from "./CenterRoutes";
import StudentRoutes from "./StudentRoutes";
import SupervisorRoutes from "./SupervisorRoutes";
import TeacherRoutes from "./TeacherRoutes";

const useStyles = makeStyles((theme) => ({
  toolBar: {
    display: "column",
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
}));

const NavMobil = ({ user, logOut, pendingStudents, fetchPendingStudents }) => {
  const classes = useStyles;

  const [expanded, setExpanded] = React.useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <AppBar position="static">
      <Toolbar>
        <Grid container style={{ padding: "10px" }}>
          <Grid container direction="row" justify="space-between">
            <Grid item>
              <AutiusSmallIcon></AutiusSmallIcon>
            </Grid>
            <Grid item justify="space-between">
              <IconButton
                edge="start"
                className={classes.menuButton}
                color="inherit"
                aria-label="menu"
                onClick={handleExpandClick}
              >
                {expanded ? (
                  <ClearIcon style={{ fontSize: 30 }} />
                ) : (
                  <MenuIcon style={{ fontSize: 30 }} />
                )}
              </IconButton>
            </Grid>
          </Grid>
          <Grid container>
            <Collapse in={expanded} timeout="auto" unmountOnExit>
              <CardContent>
                {user && user.role === "ROLE_ADMIN" ? (
                  <AdminRoutes
                    isMobil={true}
                    user={user}
                    setExpanded={setExpanded}
                  ></AdminRoutes>
                ) : null}
                {user && user.role === "ROLE_CENTER" ? (
                  <CenterRoutes
                    isMobil={true}
                    user={user}
                    setExpanded={setExpanded}
                    pendingStudents={pendingStudents}
                    fetchPendingStudents={fetchPendingStudents}
                  ></CenterRoutes>
                ) : null}
                {user && user.role === "ROLE_STUDENT" ? (
                  <StudentRoutes isMobil={true} user={user}></StudentRoutes>
                ) : null}
                {user && user.role === "ROLE_TEACHER" ? (
                  <TeacherRoutes isMobil={true} user={user}></TeacherRoutes>
                ) : null}
                {user && user.role === "ROLE_SUPERVISOR" ? (
                  <SupervisorRoutes
                    isMobil={true}
                    user={user}
                  ></SupervisorRoutes>
                ) : null}
                <Grid
                  style={{
                    marginTop: "20px",
                  }}
                >
                  <Button
                    style={{ display: "flex", justifyContent: "flex-start" }}
                    fullWidth
                    onClick={() => logOut()}
                  >
                    <Typography>Cerrar sesión</Typography>
                  </Button>
                </Grid>
              </CardContent>
            </Collapse>
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  );
};

export default NavMobil;
