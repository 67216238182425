import {
  Button,
  Chip,
  Grid,
  MenuItem,
  TextField,
  Typography,
} from "@material-ui/core";
// import { makeStyles } from "@material-ui/core/styles";
import { Autocomplete } from "@material-ui/lab";
import React, { useEffect, useState } from "react";
import {
  exams as examsApi,
  students as studentsAPI,
  vehicles as vehiclesAPI,
} from "../../../api";

// const useStyles = makeStyles((theme) => ({
//   root: {
//     flexGrow: 1,
//     width: "100%",
//     minHeight: "100vh",
//   },
//   gridContainer: {
//     backgroundColor: "rgb(255,255,255)",
//     borderTop: "5px solid rgb(159,46,37)",
//     borderRadius: "0 0 10px 10px",
//     padding: "20px",
//     marginTop: "20px",
//   },
//   header: {
//     backgroundColor: "rgb(248,250,252)",
//     borderBottom: "2px solid rgb(227,232,239)",
//     height: 70,
//     display: "flex",
//     justifyContent: "center",
//     alignItems: "center",
//   },
//   headerText: {
//     fontWeight: "bold",
//     marginLeft: 40,
//     color: theme.palette.primary.main,
//   },
//   textArea: {
//     minHeight: 150,
//     minWidth: "99%",
//     maxWidth: "99%",
//     width: "99",
//     borderRadius: 3,
//     padding: 8,
//   },
// }));

const PracticalExamOptions = ({
  teachers = [],
  examData,
  centerID,
  fetchPracticalStudents,
}) => {
  const [values, setValues] = useState([
    { teacherID: null, vehicleID: null, students: [] },
  ]);
  const [vehicles, setVehicles] = useState([]);
  const [students, setStudents] = useState([]);

  useEffect(() => {
    let params = {
      centerID: centerID !== "1" ? centerID : null,
      perPage: 100,
    };

    vehiclesAPI.getAll(params).then((results) => {
      setVehicles(results.results);
    });
    studentsAPI.getStudentForClasses({ centerID }).then((results) => {
      const studentWithState = results.results.map((elem) => {
        elem["selected"] = false;
        return elem;
      });
      setStudents(studentWithState);
    });
  }, [centerID]);

  const handleChange = (index, key, newValue) =>
    setValues((prevState) =>
      prevState.map((session, i) => {
        if (i === index) {
          return { ...session, [key]: newValue };
        }
        return session;
      })
    );

  const handleSubmitSesions = () => {
    examsApi
      .storePracticalStudents({ sessions: values, examID: examData.id })
      .then((result) => {
        setValues([{ teacherID: null, vehicleID: null, students: [] }]);
        fetchPracticalStudents();
      })
      .catch((result) => {
        console.log("error");
      });
  };

  return (
    <Grid
      container
      spacing={2}
      style={{ padding: "10px", height: "100%" }}
      direction="row"
      // justify="space-around"
    >
      <Grid container item>
        <Grid
          item
          xs={12}
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: 20,
          }}
        >
          <Grid item>
            <Typography>
              <span style={{ fontWeight: "bold" }}>
                Asignar profesores y alumnos
              </span>
            </Typography>
          </Grid>
          <Grid item>
            <Button
              fullWidth
              onClick={() => {
                setValues([
                  ...values,
                  values.push({
                    teacherID: null,
                    vehicleID: null,
                    students: [],
                  }),
                ]);
              }}
            >
              <span>Añadir Profesor</span>
            </Button>
          </Grid>
        </Grid>
      </Grid>
      {!!values.length &&
        values.map((session, index) => {
          return (
            <Grid
              key={index}
              container
              spacing={2}
              style={{ marginBottom: 20 }}
              justify="space-around"
            >
              <Grid item xs={12} md={9}>
                <TextField
                  fullWidth
                  variant="outlined"
                  size="small"
                  id="genre"
                  placeholder="Seleccione un profesor"
                  select
                  value={session.teacherID || "none"}
                  onChange={(e) =>
                    handleChange(index, "teacherID", e.target.value)
                  }
                >
                  <MenuItem value="none" disabled>
                    Seleccione un profesor
                  </MenuItem>
                  {teachers.map((option) => (
                    <MenuItem key={option.id} value={option.id}>
                      {option.firstName} {option.lastName1}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={12} md={3}>
                <TextField
                  fullWidth
                  variant="outlined"
                  size="small"
                  id="genre"
                  placeholder="Seleccione un vehiculo"
                  select
                  value={session.vehicleID || "none"}
                  onChange={(e) =>
                    handleChange(index, "vehicleID", e.target.value)
                  }
                >
                  <MenuItem value="none" disabled>
                    Seleccione un vehículo
                  </MenuItem>
                  {vehicles.map((option) => (
                    <MenuItem key={option} value={option.id}>
                      {option.enrollment}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={12} md={12}>
                <Autocomplete
                  style={{ fontWeight: "bold", minHeight: "40px" }}
                  size="small"
                  multiple
                  id="tags-standard"
                  options={students}
                  getOptionLabel={(option) => option.studentName}
                  // defaultValue={values}
                  value={session.students || []}
                  disableClearable
                  onChange={(_, value) =>
                    handleChange(index, "students", value)
                  }
                  renderTags={(tagValue, getTagProps) =>
                    tagValue.map((option, index) => (
                      <Chip
                        style={{
                          backgroundColor: "#41b883",
                          color: "white",
                          fontWeight: "bold",
                        }}
                        label={option.studentName}
                        {...getTagProps({ index })}
                      />
                    ))
                  }
                  renderInput={(params) => {
                    return (
                      <>
                        {/* <Label style={{ fontWeight: "bold" }}>Nuevos</Label> */}
                        <TextField
                          style={{
                            fontWeight: "bold",
                            marginTop: "-3px",
                            minHeight: 20,
                          }}
                          {...params}
                          variant="outlined"
                          placeholder="Selecciona Alumnos"
                          margin="normal"
                          fullWidth
                        />
                      </>
                    );
                  }}
                />
              </Grid>
            </Grid>
          );
        })}

      <Grid
        container
        item
        xs={12}
        direction="row"
        justify="flex-end"
        alignItems="flex-end"
        style={{ marginTop: 15 }}
      >
        <Button
          fullWidth
          onClick={() => {
            handleSubmitSesions();
          }}
        >
          <span>Guardar</span>
        </Button>
      </Grid>
    </Grid>
  );
};

export default PracticalExamOptions;
