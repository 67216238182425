import React from "react";
import { Route } from "react-router-dom";
import Login from "../Auth/Login";
import PasswordRecover from "../Auth/PasswordRecover";

const RoutesPublic = ({ user, reloadUser }) => {
  return (
    <>
      <Route
        exact={true}
        path="/"
        render={(props) => (
            <Login user={user} reloadUser={reloadUser}></Login>
        )}
      />
      <Route
        exact={true}
        path="/recordar-password"
        render={(props) => (
          <PasswordRecover {...props} user={user} reloadUser={reloadUser} />
        )}
      />
     
    </>
  );
};

export default RoutesPublic;
