import { InputAdornment, MenuItem } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import SaveIcon from "@material-ui/icons/Save";
import React, { useState } from "react";
import { studentPayments as studentsPaymentsAPI } from "../../../api";
import { useAuth } from "../../../contexts/AuthProvider/AuthProvider";

const getIsQuantityDisabled = ({isAdmin, description}) =>{
  if(isAdmin){
    return false
  }
  if(description === 'Curso Teórico'){
    return false
  }
  if(description === 'Tasas'){
    return false
  }
  if(description === 'Matrícula'){
    return false
  }

return true
}

const useStyles = makeStyles((theme) => ({
  marginRight: {
    marginRight: theme.spacing(1),
  },
  labelRoot: {
    color: "white",
    fontWeight: "bold",
    "&$labelFocused": {
      color: "white",
    },
  },
  acceptButton: {
    backgroundColor: "rgb(18,151,91)",
    "&:hover": {
      backgroundColor: "rgb(18,151,91)",
    },
  },
  actions: {
    backgroundColor: "rgb(248,250,252)",
  },
}));

const ModalAddFormPay = (props) => {
  const {
    studentID,
    tariffDetails,
    open,
    setOpen,
    getPayments,
  } = props;
  const classes = useStyles();
  const { user} = useAuth();

  const isAdmin = user.role === "ROLE_ADMIN";
  const [values, setValues] = useState({ type: "Cargo" });
  const [errorMessage, setErrorMessage] = useState("");

  const handleChange = (key, value) => {
    if (key === "description") {
      setValues({
        ...values,
        [key]: value,
        quantity: tariffDetails[value],
      });
    } else if (value === "Cargo") {
      setValues({
        ...values,
        [key]: value,
        paymentType: null,
      });
    } else {
      setValues({
        ...values,
        [key]: value,
      });
    }
  };

  const handleSubmit = () => {
    const finalValues = { ...values, studentID: studentID };
    studentsPaymentsAPI
      .storePayment(finalValues)
      .then((response) => {
        setValues({ type: "Cargo" });
        getPayments();
        setOpen(false);
      })
      .catch((x) => {
        setErrorMessage("Error adding permiso");
      });
  };

  const handleCancelClose = () => {
    setValues({ type: "Cargo" });
    setOpen(false);
  };

  var tariffArray = Object.keys(tariffDetails);
  
  const isQuantityDisabled = getIsQuantityDisabled({isAdmin, description: values.description})

  
  return (
    <Dialog open={open} onClose={handleCancelClose}>
      <DialogTitle id="form-dialog-title">
        <span>CREAR ENTRADA</span>
      </DialogTitle>
      <Divider style={{ margin: "0 20px" }}></Divider>
      <DialogContent style={{ overflowY: "hidden", margin: "20px 0" }}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography>Concepto</Typography>
            <TextField
              fullWidth
              variant="outlined"
              size="small"
              id="description"
              placeholder="Seleccionar un concepto"
              select
              value={values.description || ""}
              onChange={(e) => handleChange("description", e.target.value)}
            >
              <MenuItem disabled value={""}>
                Seleccione un concepto
              </MenuItem>
              {tariffArray.map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12}>
            <Typography>
              Importe
              <span style={{ color: "rgb(159,46,37)" }}>*</span>
            </Typography>
            <TextField
              fullWidth
              variant="outlined"
              size="small"
              type="number"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">€</InputAdornment>
                ),
              }}
              placeholder="0.00"
              value={values.quantity}
              onChange={(e) => handleChange("quantity", e.target.value)}
              disabled={isQuantityDisabled}
            ></TextField>
          </Grid>
          <Grid item xs={12}>
            <Typography>Tipo</Typography>
            <TextField
              fullWidth
              variant="outlined"
              size="small"
              id="type"
              placeholder="type"
              select
              value={values.type || "Cargo"}
              onChange={(e) => handleChange("type", e.target.value)}
            >
              {["Cargo", "Pago"].map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          {values.type === "Pago" ? (
            <Grid item xs={12}>
              <Typography>Forma de pago</Typography>
              <TextField
                fullWidth
                variant="outlined"
                size="small"
                id="paymentType"
                placeholder="paymentType"
                select
                value={values.paymentType || ""}
                onChange={(e) => handleChange("paymentType", e.target.value)}
              >
                <MenuItem disabled value={""}>
                  Seleccione una forma de pago
                </MenuItem>
                {[
                  "Efectivo",
                  "Transferencia",
                  "TPV Manual",
                  "TPV Online",
                  "SUPERVISOR (Si es pack recuerda además reducir nº clases bolsa)",
                ].map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          ) : null}
        </Grid>
      </DialogContent>
      <DialogActions className={classes.actions}>
        <p>{errorMessage}</p>
        <Button size="large" variant="outlined" onClick={handleCancelClose}>
          <CloseIcon className={classes.marginRight} />
          Cancelar
        </Button>
        <Button
          className={classes.acceptButton}
          size="large"
          variant="outlined"
          onClick={handleSubmit}
        >
          <SaveIcon className={classes.marginRight} /> <span>Confirmar</span>
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ModalAddFormPay;
