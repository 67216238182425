import axios from "axios";
import { API_NAME_2 } from "../base";

const StudentTeachersInstance = axios.create({
  baseURL: `${API_NAME_2}student/teachers`,
  withCredentials: true,
});

StudentTeachersInstance.interceptors.request.use(
  (config) => {
    config.headers.Authorization = `Bearer ${localStorage.getItem("user")}`;
    return config;
  },
  (error) => Promise.reject(error)
);

const studentTeachersApi = {
  getAll: async () => {
    return StudentTeachersInstance.get(`/list`)
      .then((response) => {
        return response.data;
      })
      .catch((err) => console.error(err));
  },
};

const studentTeachers = studentTeachersApi;
export default studentTeachers;
