import { MenuItem } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import SaveIcon from "@material-ui/icons/Save";
import React, { useEffect, useState } from "react";
import { exams as examsApi } from "../../../api";

const useStyles = makeStyles((theme) => ({
  marginRight: {
    marginRight: theme.spacing(1),
  },
  labelRoot: {
    color: "white",
    fontWeight: "bold",
    "&$labelFocused": {
      color: "white",
    },
  },
  acceptButton: {
    backgroundColor: "rgb(18,151,91)",
    "&:hover": {
      backgroundColor: "rgb(18,151,91)",
    },
  },
  actions: {
    backgroundColor: "rgb(248,250,252)",
  },
}));

const ModalEditResultStudent = (props) => {
  const { examID, open, setOpen, student, fetchStudents } = props;
  const classes = useStyles();

  const [result, setResult] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    setResult(student.result);
  }, [student, open]);

  const handleChange = (value) => {
    setResult(value);
  };

  const handleSubmit = () => {
    examsApi
      .updateStudentResult({ result, studentID: student.id }, examID)
      .then((response) => {
        fetchStudents();
        setOpen(false);
      })
      .catch((x) => {
        setErrorMessage("Error adding permiso");
      });
  };
  const handleCancelClose = () => {
    setResult("");
    setOpen(false);
  };

  return (
    <Dialog open={open} onClose={handleCancelClose}>
      <DialogTitle id="form-dialog-title">
        <span>MODIFICAR RESULTADO</span>
      </DialogTitle>
      <Divider style={{ margin: "0 20px" }}></Divider>
      <DialogContent style={{ overflowY: "hidden", margin: "20px 0" }}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography>Evaluación</Typography>
            <TextField
              fullWidth
              variant="outlined"
              size="small"
              id="genre"
              placeholder="genre"
              select
              value={result || ""}
              onChange={(e) => handleChange(e.target.value)}
            >
              {["----", "Apto", "No apto", "Aplazado", "No presentado"].map(
                (option) => (
                  <MenuItem key={option.value} value={option}>
                    {option}
                  </MenuItem>
                )
              )}
            </TextField>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions className={classes.actions}>
        <p>{errorMessage}</p>
        <Button size="large" variant="outlined" onClick={handleCancelClose}>
          <CloseIcon className={classes.marginRight} />
          Cancelar
        </Button>
        <Button
          className={classes.acceptButton}
          size="large"
          variant="outlined"
          onClick={handleSubmit}
        >
          <SaveIcon className={classes.marginRight} /> <span>Modificar</span>
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ModalEditResultStudent;
