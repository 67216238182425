import axios from "axios";
import { API_NAME_2 } from "./base";

const PasswordInstance = axios.create({
  baseURL: `${API_NAME_2}`,
  withCredentials: true,
});

PasswordInstance.interceptors.request.use(
  (config) => {
    config.headers.Authorization = `Bearer ${localStorage.getItem("user")}`;
    return config;
  },
  (error) => Promise.reject(error)
);

const PasswordInstanceApi = {
  sendEmail: async (passwordRecoveryCredentials) => {
    try {
      const response = await PasswordInstance.post("authentication/send-password-email", passwordRecoveryCredentials);
      return response.data;
    } catch (err) {
      return console.error(err);
    }
  },
  
};

const password = PasswordInstanceApi;
export default password;
