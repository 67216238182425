import {
  Button,
  Fade,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React, { useEffect, useState } from "react";
import {
  classesPractical as classesPracticalAPI,
  classesTypes as classesTypesAPI,
  students as studentsAPI,
  teachers as teachersAPI,
} from "../../../api";
import { FADE_TRANSITION } from "../../../lib/constants";
import CalendarCustom from "../../core/Calendar";
import { LoadingIcon } from "../../core/LoadingIcon";
import ModalFormAddClass from "./ModalFormAddClass";
import ModalFormEditClass from "./ModalFormEditClass";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    minHeight: "100vh",
    "& .MuiTextField-root": {
      minWidth: "20ch",
    },
  },
  gridContainer: {
    backgroundColor: "rgb(255,255,255)",
    // margin: "20px",
    borderTop: "5px solid rgb(159,46,37)",
    borderRadius: "0 0 10px 10px",
    padding: 20,
  },
  header: {
    backgroundColor: "rgb(248,250,252)",
    borderBottom: "2px solid rgb(227,232,239)",
    height: 70,
  },
  headerText: {
    fontWeight: "bold",
    marginLeft: 40,
    color: theme.palette.primary.main,
  },
  headerButtons: {
    marginRight: 40,
  },
  textArea: {
    minHeight: 150,
    minWidth: "99%",
    maxWidth: "99%",
    width: "99",
    borderRadius: 3,
    padding: 8,
  },
}));

const CalendarCenter = ({ centerID }) => {
  const classes = useStyles();
  const [teachers, setTeachers] = useState([]);
  const [students, setStudents] = useState([]);
  const [selectedTeacher, setSelectedTeacher] = useState(null);
  const [classesTypes, setClassesTypes] = useState([]);
  const [timeTable, setTimeTable] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [openEdit, setOpenEdit] = React.useState(false);
  const [selectedClassID, setSelectedClassID] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    let params = {
      centerID: centerID !== "1" ? centerID : null,
      perPage: 100,
      page: 1,
    };
    teachersAPI.getAll(params).then((results) => {
      setTeachers(results.results);
    });
    studentsAPI.getStudentForClasses({ centerID }).then((results) => {
      setStudents(results.results);
    });
    classesTypesAPI.getAllTypes(params).then((results) => {
      setClassesTypes(results.results);
    });
  }, [centerID]);

  useEffect(() => {
    fetchClasses();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTeacher, centerID]);

  const fetchClasses = () => {
    setIsLoading(true);
    const teacherID = selectedTeacher;
    classesPracticalAPI.getClasses({ teacherID, centerID }).then((results) => {
      const resultsWithDates = results.results.map((elem) => {
        elem.start = new Date(elem.start);
        elem.end = new Date(elem.end);
        return elem;
      });
      setTimeTable(resultsWithDates);
      setIsLoading(false);
    });
  };

  const handleChange = (event) => {
    setSelectedTeacher(event.target.value);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClickEditOpen = (ID) => {
    if (ID) {
      setSelectedClassID(ID);
    } else {
      setSelectedClassID(null);
    }
    setOpenEdit(true);
  };

  return (
    <Fade in={true} timeout={FADE_TRANSITION}>
      <div className={classes.root}>
        <ModalFormAddClass
          centerID={centerID}
          open={open}
          classesTypes={classesTypes}
          teachers={teachers}
          setOpen={setOpen}
          fetchClasses={fetchClasses}
          students={students}
        />
        <ModalFormEditClass
          centerID={centerID}
          open={openEdit}
          classesTypes={classesTypes}
          teachers={teachers}
          setOpen={setOpenEdit}
          setSelectedClassID={setSelectedClassID}
          fetchClasses={fetchClasses}
          classID={selectedClassID}
          students={students}
        />
        <Grid
          container
          className={classes.header}
          direction="row"
          justify="space-between"
          alignItems="center"
        >
          <Grid item>
            <Typography className={classes.headerText}>CALENDARIO</Typography>
          </Grid>
          {centerID === 1 ? null : (
            <Grid item className={classes.headerButtons}>
              <Button onClick={handleClickOpen}>AÑADIR CLASE</Button>
            </Grid>
          )}
        </Grid>
        <Grid container justify="space-around" style={{ margin: "20px 0" }}>
          <Grid item xs={12} md={12} lg={2} className={classes.gridContainer}>
            <FormControl component="fieldset">
              <FormLabel component="legend" style={{ marginBottom: "20px" }}>
                Lista Profesores
              </FormLabel>
              <RadioGroup
                aria-label="teacher"
                name="teacher"
                value={selectedTeacher}
                onChange={handleChange}
              >
                {teachers &&
                  teachers.map((teacher, index) => {
                    return (
                      <FormControlLabel
                        key={index}
                        value={`${teacher.id}`}
                        control={<Radio color="primary" />}
                        label={`${teacher.firstName} ${teacher.lastName1}`}
                      />
                    );
                  })}
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={12} lg={9}>
            {isLoading ? (
              <LoadingIcon></LoadingIcon>
            ) : (
              <div className={classes.gridContainer}>
                <Grid
                  container
                  style={{ margin: "20px 0" }}
                  spacing={2}
                  // justify="space-between"
                >
                  <Grid item>
                    <span
                      style={{
                        backgroundColor: "green",
                        padding: "5px 30px",
                        marginRight: "5px",
                      }}
                    ></span>
                    Libres
                  </Grid>
                  <Grid item>
                    <span
                      style={{
                        backgroundColor: "#ad201c",
                        padding: "5px 30px",
                        marginRight: "5px",
                      }}
                    ></span>
                    Ocupadas
                  </Grid>
                </Grid>
                <CalendarCustom
                  handleClickEditOpen={handleClickEditOpen}
                  events={timeTable}
                ></CalendarCustom>
              </div>
            )}
          </Grid>
        </Grid>
      </div>
    </Fade>
  );
};

export default CalendarCenter;
