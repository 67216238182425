import axios from "axios";
import {API_NAME} from "./base";

const ArchingInstance = axios.create({
  baseURL: `${API_NAME}api/archings`,
  withCredentials: true,
  headers: {
    Authorization: `Bearer ${localStorage.getItem("user") || ""}`,
  },
});

ArchingInstance.interceptors.request.use(
  (config) => {
    config.headers.Authorization = `Bearer ${localStorage.getItem("user")}`;
    return config;
  },
  (error) => Promise.reject(error)
);

const ArchingApi = {
  getAll: async ({
    view = "listing",
    centerID = null,
    orderBy = "name",
    orderDir = "asc",
    startDate = null,
    endDate = null,
    page = 1,
    perPage = 10,
  }) => {
    return ArchingInstance.get(``, {
      params: {
        view,
        centerID,
        orderBy,
        startDate,
        endDate,
        orderDir,
        page,
        perPage,
      },
    })
      .then((response) => response.data)
      .catch((err) => console.error(err));
  },

  create: async (centerID) => {
    return ArchingInstance.post("", centerID)
      .then((response) => response.data)
      .catch((err) => console.error(err));
  },
};

const archings = ArchingApi;
export default archings;
