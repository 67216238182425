import {
  Grid,
  Hidden,
  ListItem,
  ListItemText,
  Tooltip,
  withStyles,
} from "@material-ui/core";
import AccountBalanceIcon from "@material-ui/icons/AccountBalance";
import DescriptionIcon from "@material-ui/icons/Description";
import DriveEtaIcon from "@material-ui/icons/DriveEta";
import EuroSymbolIcon from "@material-ui/icons/EuroSymbol";
import EventIcon from "@material-ui/icons/Event";
import PeopleAltIcon from "@material-ui/icons/PeopleAlt";
import PersonIcon from "@material-ui/icons/Person";
import RecentActorsIcon from "@material-ui/icons/RecentActors";
import React, { useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import StoreIcon from "@material-ui/icons/Store";
import RoomIcon from "@material-ui/icons/Room";
import TrendingUpIcon from '@material-ui/icons/TrendingUp';

const StyledListItem = withStyles({
  root: {
    // backgroundColor: "blue",
    "&.Mui-selected": {
      backgroundColor: "darkRed",
    },
  },
})(ListItem);

// Quiero probar a hacer un map de options para pintar las rutas del nav(problema con la clase del icon)
// Funciona
const OPTIONS = [
  {
    title: "Alumnos",
    index: 0,
    query: "",
    icon: <PersonIcon fill="white"></PersonIcon>,
  },
  {
    title: "Profesores",
    index: 1,
    query: "teachers",
    icon: <PeopleAltIcon fill="white" />,
  },
  {
    title: "Vehículos",
    index: 2,
    query: "vehiculos",
    icon: <DriveEtaIcon fill="white"></DriveEtaIcon>,
  },
  {
    title: "Calendario",
    index: 3,
    query: "calendario",
    icon: <EventIcon fill="white"></EventIcon>,
  },
  {
    title: "Exámenes",
    index: 4,
    query: "examenes",
    icon: <DescriptionIcon fill="white"></DescriptionIcon>,
  },
  {
    title: "Tarifas",
    index: 5,
    query: "tarifas",
    icon: <EuroSymbolIcon fill="white"></EuroSymbolIcon>,
  },
  {
    title: "Arqueos",
    index: 6,
    query: "arqueos",
    icon: <AccountBalanceIcon fill="white"></AccountBalanceIcon>,
  },
  {
    title: "P. Salida",
    index: 7,
    query: "salidas",
    icon: <RoomIcon fill="white"></RoomIcon>,
  },
  {
    title: "Permisos",
    index: 8,
    query: "permisos",
    icon: <RecentActorsIcon fill="white"></RecentActorsIcon>,
  },
  {
    title: "Centros",
    index: 9,
    query: "centros",
    icon: <StoreIcon fill="white"></StoreIcon>,
  },
  {
    title: "Central",
    index: 10,
    query: "central",
    icon: <TrendingUpIcon fill="white"></TrendingUpIcon>,
  },
];

const AdminRoutes = ({ user, isMobil = false, setExpanded = () => {} }) => {
  // PINTAR EL APARTADO DONDE ESTAS ACTUALMENTE AL ACCEDER A LA PAGINA
  let location = useLocation();

  let initialValue;
  OPTIONS.forEach((option) => {
    if (location.pathname.includes(option.query)) {
      initialValue = option.index;
    } else initialValue = 0;
  });

  const [selectedIndex, setSelectedIndex] = useState(initialValue);

  const handleListItemClick = (event, index) => {
    setSelectedIndex(index);
    setExpanded(false);
  };

  return (
    <>
      {OPTIONS.map((row) => {
        return (
          <Grid key={row.index} item xs={12}>
            <Tooltip title={row.title} placement="top">
              <StyledListItem
                button
                onClick={(event) => handleListItemClick(event, row.index)}
                component={NavLink}
                selected={selectedIndex === row.index}
                style={{
                  color: "white",
                  display: "flex",
                  justifyContent: "flex-start",
                  borderRadius: "6px",
                }}
                to={row.index === 0 ? `/` : `/${row.query}`}
              >
                {isMobil ? (
                  <ListItemText primary={row.title} />
                ) : (
                  <>
                    {row.icon}
                    <span style={{ margin: "0 5px" }}></span>
                    <Hidden mdDown>
                      <ListItemText primary={row.title} />
                    </Hidden>
                  </>
                )}
              </StyledListItem>
            </Tooltip>
          </Grid>
        );
      })}
    </>
  );
};

export default AdminRoutes;
