import moment from "moment";
import xlsx from "xlsx";

export const exportData = (
  data,
  label = "Sheet 1",
  fileName = "Movimientos"
) => {
  let wb = xlsx.utils.book_new();
  const fitToColumn = (data) => {
    const columnWidths = [];
    for (const property in data[0]) {
      columnWidths.push({
        wch: Math.max(
          property ? property.toString().length : 0,
          ...data.map((obj) =>
            obj[property] ? obj[property].toString().length : 0
          )
        ),
      });
    }
    return columnWidths;
  };
  const ws = xlsx.utils.json_to_sheet(data);
  ws["!cols"] = fitToColumn(data);
  wb.SheetNames.push(label);
  wb.Sheets[label] = ws;
  xlsx.writeFile(
    wb,
    `${fileName}-${moment(new Date()).format("DD-MM-YYYY")}.xlsx`
  );
};
