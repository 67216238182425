import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Divider from "@material-ui/core/Divider";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import SaveIcon from "@material-ui/icons/Save";
import React, { useState } from "react";

const useStyles = makeStyles((theme) => ({
  //   root: {
  //     "& .MuiTextField-root": {
  //       width: "20ch",
  //     },
  //   },
  marginRight: {
    marginRight: theme.spacing(1),
  },
  labelRoot: {
    color: "white",
    fontWeight: "bold",
    "&$labelFocused": {
      color: "white",
    },
  },
  acceptButton: {
    backgroundColor: "rgb(18,151,91)",
    "&:hover": {
      backgroundColor: "rgb(18,151,91)",
    },
  },
  actions: {
    backgroundColor: "rgb(248,250,252)",
  },
}));

const ModalPackPaymentSuccess = (props) => {
  const { open, setOpen, classesAdded, fetchStudentClasses } = props;
  const classes = useStyles();

  const [values, ] = useState({});

  const handleCancelClose = () => {
    setOpen(false);
    fetchStudentClasses();
  };

  if (values === null) {
    return <Typography>Loading</Typography>;
  }
  return (
    <Dialog open={open} className={classes.root} onClose={handleCancelClose}>
      <DialogTitle id="form-dialog-title">
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <span style={{ fontWeight: "bold" }}>CONFIRMACION DE COMPRA</span>
        </div>
      </DialogTitle>
      <Divider style={{ margin: "0 20px" }}></Divider>
      <DialogContent style={{ overflowY: "hidden", margin: "20px" }}>
        <Typography>Ha comprado {classesAdded} clases</Typography>
      </DialogContent>
      <DialogActions className={classes.actions}>
        <Button
          className={classes.acceptButton}
          size="large"
          variant="outlined"
          onClick={handleCancelClose}
        >
          <SaveIcon className={classes.marginRight} /> Aceptar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ModalPackPaymentSuccess;
