import { Button, Fade, makeStyles, Typography } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import React, { useEffect, useRef, useState } from "react";
import Moment from "react-moment";
import { students as studentsAPI, tariffs as tariffsAPI } from "../../../api";
import { DATE_FORMAT_COMPLETE, FADE_TRANSITION } from "../../../lib/constants";
import { exportData } from "../../../lib/exportData";
import CustomDatePicker from "../../core/CustomDatePicker";
import CustomTable from "../../core/CustomTable";
import ModalAddFormPay from "./ModalAddFormPay";
const NO_VALUE = "No valor";

const useStyles = makeStyles((theme) => ({
  rootGrid: {
    maxWidth: "100%",
    margin: "auto",
  },
  root: {
    flexGrow: 1,
    width: "100%",
    "& .MuiTextField-root": {
      minWidth: "20ch",
    },
  },
  totalStyle: {
    border: `2px solid ${theme.palette.primary.main}`,
    padding: "50px",
    backgroundColor: "#fff",
    fontSize: "25px",
    fonwWidth: "bold",
  },
  header: {
    backgroundColor: "rgb(248,250,252)",
    borderBottom: "2px solid rgb(227,232,239)",
    height: 70,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  headerText: {
    fontWeight: "bold",
    marginLeft: 20,
    color: theme.palette.primary.main,
  },
  summery: {
    marginBottom: 40,
    color: "rgb(159,46,37)",
  },
  headerButtons: {
    marginRight: 40,
  },
  searchContainer: {
    margin: 20,
    border: "1px solid",
    borderColor: theme.palette.primary.dark,
    borderRadius: 5,
    padding: 12,
  },
  tabPanel: {
    backgroundColor: theme.palette.primary.main,
  },
  downLoadButton: {
    marginRight: theme.spacing(1),
    backgroundColor: "transparent",
    color: "rgb(56, 161, 105)",
    border: "1px solid rgb(56, 161, 105)",
    "&:hover": {
      marginRight: theme.spacing(1),
      border: "1px solid rgb(56, 161, 105)",
      backgroundColor: "rgb(56, 161, 105)",
      opacity: 1,
      color: "#FFFFFF",
    },
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.primary.dark,
  },
  formControl: {
    width: "100%",
  },
  alignRight: {
    textAlign: "right",
  },
  alignLeft: {
    textAlign: "left",
  },
  container: {
    width: "100%",
    marginLeft: 12,
    marginRight: 12,
  },
  savedSearchLink: {
    marginLeft: "auto",
    marginRight: 0,
  },
}));

const columns = [
  {
    title: "Fecha",
    field: "date",
    render: (data) => (
      <Moment format={DATE_FORMAT_COMPLETE}>{data.date}</Moment>
    ),
    emptyValue: NO_VALUE,
    cardFormat: "DATE_COMPLETE",
  },
  {
    title: "Descripcion",
    field: "description",
    emptyValue: NO_VALUE,
    cardFormat: "STRING",
  },
  {
    title: "Tipo",
    field: "type",
    render: (data) => (
      <Typography>
        <span
          style={
            data.type === "Cargo"
              ? {
                  backgroundColor: "rgb(190, 227, 248)",
                  padding: "2px 9px",
                  borderRadius: "20px",
                }
              : {
                  backgroundColor: "rgb(198, 246, 213)",
                  padding: "2px 9px",
                  borderRadius: "20px",
                }
          }
        >
          {data.type}
        </span>
      </Typography>
    ),
    emptyValue: NO_VALUE,
    width: 20,
    cardFormat: "TYPE_PAYMENT",
  },
  {
    title: "Cantidad",
    field: "quantity",
    type: "currency",
    currencySetting: { currencyCode: "EUR" },
    emptyValue: NO_VALUE,
    cardFormat: "CURRENCY",
  },
  {
    title: "Forma de pago",
    field: "paymentType",
    emptyValue: "",
    cardFormat: "STRING",
  },
];

const sumColumns = [
  {
    title: "Descripción",
    field: "description",
    emptyValue: NO_VALUE,
    cardFormat: "STRING",
  },
  {
    title: "Cargos",
    field: "charge",
    type: "currency",
    currencySetting: { currencyCode: "EUR" },
    emptyValue: NO_VALUE,
    cardFormat: "CURRENCY",
  },
  {
    title: "Pagos",
    field: "payment",
    type: "currency",
    currencySetting: { currencyCode: "EUR" },
    emptyValue: NO_VALUE,
    cardFormat: "CURRENCY",
  },
  {
    title: "Total",
    field: "total",
    type: "currency",
    currencySetting: { currencyCode: "EUR" },
    emptyValue: NO_VALUE,
    cardFormat: "CURRENCY",
  },
];

const StudentPayments = ({ centerID, studentID }) => {
  const classes = useStyles();
  const tableRef = useRef(null);

  const [, setIsLoading] = useState(true);
  const [data, setData] = useState([]);
  const [sumPayments, setSumPayments] = useState([]);
  const [tariffDetails, setTariffDetails] = useState([]);
  const [page, setPage] = useState(1);
  const defaultPageSize = 10;
  const [totalResults, setTotalResults] = useState();
  const [totalSum, setTotalSum] = useState();
  const [sorting, ] = useState({ orderBy: "name", orderDir: "asc" });

  const [criteria, setCriteria] = useState({});

  const [openAdd, setOpenAdd] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    setIsLoading(true);

    getPayments();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [criteria, page, sorting]);

  const getPayments = () => {
    let params = {
      view: "listing",
      studentID,
      perPage: defaultPageSize,
      page: page,
      ...criteria,
    };
    studentsAPI.getPayments(params).then((result) => {
      setData(result.results);
      setPage(result.page);
      setTotalResults(result.totalCount);
    });

    studentsAPI.getSumPayments(params).then((result) => {
      setSumPayments(result.results);
      setTotalSum(result.total);
    });
  };

  useEffect(() => {
    setIsLoading(true);

    tariffsAPI.getStudentPaymentTariff(studentID).then((result) => {
      setTariffDetails(result.result);
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [criteria, page, sorting]);

  const handleCriteriaChange = (newCriteria) => {
    setPage(1);
    setCriteria({ ...criteria, ...newCriteria });
  };

  const handlePageChange = (newPage) => {
    window.scrollTo(0, 0);
    setPage(newPage);
  };

  const handleClickAddOpen = () => {
    setOpenAdd(true);
  };

  const downloadExcel = () => {
    let params = {
      view: "export",
      studentID,
      ...criteria,
    };
    studentsAPI.getPayments(params).then((result) => {
      exportData(result.data, "Movimientos", result.studentName);
    });
  };

  if (columns.length === 0) return "LOADING";

  return (
    <Fade in={true} timeout={FADE_TRANSITION}>
      <div className={classes.root}>
        <ModalAddFormPay
          open={openAdd}
          setOpen={setOpenAdd}
          tableRef={tableRef}
          studentID={studentID}
          tariffDetails={tariffDetails}
          getPayments={getPayments}
        />
        <div style={{ margin: "40px 40px 5px 40px" }}>
          <Grid
            container
            xs={12}
            spacing={2}
            direction="row"
            justify="space-between"
          >
            <Grid
              item
              xs={12}
              sm={12}
              md="auto"
              lg="auto"
              direction="row"
              justify="flex-start"
            >
              <CustomDatePicker
                startDate={criteria.startDate}
                endDate={criteria.endDate}
                handleChangeCriteria={handleCriteriaChange}
              ></CustomDatePicker>
              {/* <Button className={classes.clearButton} onClick={deleteFilters}>
              Quitar filtros
            </Button> */}
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md="auto"
              lg="auto"
              direction="row"
              justify="flex-end"
            >
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <Button
                    fullWidth
                    onClick={() => downloadExcel()}
                    className={classes.downLoadButton}
                    variant="outlined"
                  >
                    <span>Exportar a Excel</span>
                  </Button>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Button
                    fullWidth
                    className={classes.button}
                    onClick={handleClickAddOpen}
                  >
                    CREAR ENTRADA
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
        <Grid container style={{ padding: "20px 40px 0 40px" }}>
          <CustomTable
            tableRef={tableRef}
            data={data}
            columns={columns}
            handlePageChange={handlePageChange}
            page={page}
            totalResults={totalResults}
            defaultPageSize={defaultPageSize}
          ></CustomTable>
        </Grid>
        <Grid container style={{ padding: "20px 40px 30px 40px" }}>
          <Grid item xl={8} md={8} sm={12} xs={12}>
            <Typography className={classes.summery}>
              RESUMEN POR CONCEPTOS
            </Typography>
            <CustomTable
              tableRef={tableRef}
              data={sumPayments}
              columns={sumColumns}
              hasPagination={false}
            ></CustomTable>
          </Grid>
          <Grid
            item
            xl={4}
            md={4}
            sm={12}
            xs={12}
            style={{
              margin: "20px 0",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography className={classes.totalStyle}>
              Total: {totalSum && totalSum.toLocaleString()} €
            </Typography>
          </Grid>
        </Grid>
      </div>
    </Fade>
  );
};

export default StudentPayments;
