import axios from "axios";
import {API_NAME} from "./base";

const PaymentsInstance = axios.create({
  baseURL: `${API_NAME}api/payments`,
  withCredentials: true,
});

PaymentsInstance.interceptors.request.use(
  (config) => {
    config.headers.Authorization = `Bearer ${localStorage.getItem("user")}`;
    return config;
  },
  (error) => Promise.reject(error)
);

const PaymentsApi = {
  getPaymentPacksDetails: ({
    centerID = null,
    studentID = null,
    packId = null,
    price = null,
  } = {}) => {
    return PaymentsInstance.get("/signatureForPacks", {
      params: { centerID, studentID, packId, price },
    })
      .then((response) => response.data)
      .catch((err) => console.error(err));
  },

  getPaymentClassDetails: ({
    centerID = null,
    studentID = null,
    classID = null,
    price = null,
  } = {}) => {
    return PaymentsInstance.get("/signatureForClass", {
      params: { centerID, studentID, classID, price },
    })
      .then((response) => response.data)
      .catch((err) => console.error(err));
  },

  processPaymentCallback: (data) => {
    return PaymentsInstance.post("/processPacks", data)
      .then((response) => response.data)
      .catch((err) => console.error(err));
  },

  processPaymentCallbackClass: (data) => {
    return PaymentsInstance.post("/processClass", data)
      .then((response) => response.data)
      .catch((err) => console.error(err));
  },
};

const payments = PaymentsApi;
export default payments;
