import { InputAdornment, MenuItem } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import SaveIcon from "@material-ui/icons/Save";
import React, { useEffect, useState } from "react";
import { tariffs as tariffsAPI, packs as packsAPI } from "../../../api";
import { activeOptions } from "../../../lib/textOptions";

const useStyles = makeStyles((theme) => ({
  marginRight: {
    marginRight: theme.spacing(1),
  },
  labelRoot: {
    color: "white",
    fontWeight: "bold",
    "&$labelFocused": {
      color: "white",
    },
  },
  acceptButton: {
    backgroundColor: "rgb(18,151,91)",
  },
  actions: {
    backgroundColor: "rgb(248,250,252)",
  },
}));

const ModalEditFormPack = (props) => {
  const { getTariff, open, setOpen, packID } = props;
  const classes = useStyles();

  const [values, setValues] = useState({});
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    if (packID) {
      tariffsAPI
        .getOnePack(packID)
        .then((response) => {
          setValues(response.result);
        })
        .catch((x) => {
          // setError("Error trayendo centros");
        });
    }
  }, [packID, open]);

  const handleChange = (key, value) => {
    setValues({
      ...values,
      [key]: value,
    });
  };

  const handleSubmit = () => {
    packsAPI
      .updatePack(values, packID)
      .then((response) => {
        getTariff();
        setOpen(false);
      })
      .catch((x) => {
        setErrorMessage("Error adding permiso");
      });
  };

  const handleCancelClose = () => {
    setValues({});
    setOpen(false);
  };

  return (
    <Dialog open={open} onClose={handleCancelClose}>
      <DialogTitle id="form-dialog-title">
        <span>MODIFICAR PACK</span>
      </DialogTitle>
      <Divider style={{ margin: "0 20px" }}></Divider>
      <DialogContent style={{ overflowY: "hidden", margin: "20px 0" }}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography>
              Descripción<span style={{ color: "rgb(159,46,37)" }}>*</span>
            </Typography>
            <TextField
              fullWidth
              size="small"
              variant="outlined"
              placeholder="DESCRIPCION"
              value={values.description}
              onChange={(e) => handleChange("description", e.target.value)}
            ></TextField>
          </Grid>
          <Grid item xs={12}>
            <Typography>Cantidad de clases</Typography>
            <TextField
              fullWidth
              variant="outlined"
              size="small"
              type="number"
              placeholder="0"
              value={values.quantity}
              onChange={(e) => handleChange("quantity", e.target.value)}
            ></TextField>
          </Grid>
          <Grid item xs={12}>
            <Typography>Precio</Typography>
            <TextField
              fullWidth
              variant="outlined"
              size="small"
              type="number"
              placeholder="0"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">€</InputAdornment>
                ),
              }}
              value={values.price}
              onChange={(e) => handleChange("price", e.target.value)}
            ></TextField>
          </Grid>
          <Grid item xs={12}>
            <Typography>
              Activo
              <span style={{ color: "rgb(159,46,37)" }}>*</span>
            </Typography>
            <TextField
              fullWidth
              variant="outlined"
              size="small"
              id="type"
              placeholder="Punto de salida"
              select
              value={values.active || 0}
              onChange={(e) => handleChange("active", e.target.value)}
            >
              {activeOptions.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions className={classes.actions}>
        <p>{errorMessage}</p>
        <Button size="large" variant="outlined" onClick={handleCancelClose}>
          <CloseIcon className={classes.marginRight} />
          Cancelar
        </Button>
        <Button
          className={classes.acceptButton}
          size="large"
          variant="outlined"
          onClick={handleSubmit}
        >
          <SaveIcon className={classes.marginRight} /> <span>Modificar</span>
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ModalEditFormPack;
