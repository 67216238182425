import React from "react";


export const LogoutIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    color={props.fill}
    className={props.class}
    fillOpacity="0"
    width={props.width || "25px"}
    height={props.height || "25px"}
    viewBox="0 0 24 24"
    strokeWidth="2"
    stroke="currentColor"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path stroke="none" d="M0 0h24v24H0z" />
    <line x1="4" y1="12" x2="14" y2="12" />
    <line x1="4" y1="12" x2="8" y2="16" />
    <line x1="4" y1="12" x2="8" y2="8" />
    <line x1="20" y1="4" x2="20" y2="20" />
  </svg>
);
