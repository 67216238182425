import React, { useEffect, useRef, useState } from "react";
import { Button, Fade, Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import CloseRoundedIcon from "@material-ui/icons/CloseRounded";
import DoneRoundedIcon from "@material-ui/icons/DoneRounded";
import { Redirect, Link } from "react-router-dom";
import { tariffs as tariffsAPI } from "../../../api";
import { FADE_TRANSITION, NO_VALUE } from "../../../lib/constants";
import CustomTable from "../../core/CustomTable";
import { LoadingIcon } from "../../core/LoadingIcon";
import ModalAddFormExam from "../exams/ModalAddFormExam";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
    minHeight: "100vh",
  },
  header: {
    // backgroundColor: "rgb(248,250,252)",
    // borderBottom: "2px solid rgb(227,232,239)",
    height: 70,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin: "0 40px",
  },
  headerText: {
    fontWeight: "bold",
    // marginLeft: 40,
    color: theme.palette.primary.main,
  },
}));

const columns = [
  {
    title: "Nombre tarifa",
    field: "name",
    emptyValue: NO_VALUE,
    cardFormat: "STRING",
  },
  {
    title: "Matrícula",
    field: "pvpSignUp",
    type: "currency",
    currencySetting: { currencyCode: "EUR" },
    emptyValue: NO_VALUE,
    cardFormat: "CURRENCY",
  },
  {
    title: "1er Curso teórico",
    field: "pvpFirstTheoricExam",
    type: "currency",
    currencySetting: { currencyCode: "EUR" },
    emptyValue: NO_VALUE,
    cardFormat: "CURRENCY",
  },
  {
    title: "Curso teórico",
    field: "pvpTheoricExam",
    type: "currency",
    currencySetting: { currencyCode: "EUR" },
    emptyValue: NO_VALUE,
    cardFormat: "CURRENCY",
  },
  {
    title: "Examen práctico",
    field: "pvpPracticalExam",
    type: "currency",
    currencySetting: { currencyCode: "EUR" },
    emptyValue: NO_VALUE,
    cardFormat: "CURRENCY",
  },
  {
    title: "1ª Tramitación",
    field: "pvpFirstProcedure",
    type: "currency",
    currencySetting: { currencyCode: "EUR" },
    emptyValue: NO_VALUE,
    cardFormat: "CURRENCY",
  },
  {
    title: "Tramitación",
    field: "pvpProcedure",
    type: "currency",
    currencySetting: { currencyCode: "EUR" },
    emptyValue: NO_VALUE,
    cardFormat: "CURRENCY",
  },
  {
    title: "Tasa",
    field: "pvpRate",
    type: "currency",
    currencySetting: { currencyCode: "EUR" },
    emptyValue: NO_VALUE,
    cardFormat: "CURRENCY",
  },
  {
    title: "Nº Clases simples",
    field: "simpleClasses",
    emptyValue: NO_VALUE,
    cardFormat: "STRING",
  },
  {
    title: "Nº Clases completas",
    field: "completeClasses",
    emptyValue: NO_VALUE,
    cardFormat: "STRING",
  },
  {
    title: "Activo",
    field: "active",
    cardFormat: "RESULT",
    render: (data) => (
      <>
        {data.active === 1 ? (
          <DoneRoundedIcon
            style={{
              backgroundColor: "rgb(154, 230, 180)",
              color: "black",
              borderRadius: "50%",
              padding: "6px",
              width: "18px",
              height: "18px",
              fontWeight: "bold",
            }}
          ></DoneRoundedIcon>
        ) : (
          <CloseRoundedIcon
            style={{
              backgroundColor: "rgb(173, 32, 28)",
              color: "black",
              borderRadius: "50%",
              padding: "6px",
              width: "18px",
              height: "18px",
              fontWeight: "bold",
            }}
          ></CloseRoundedIcon>
        )}
      </>
    ),
    emptyValue: NO_VALUE,
  },
];

const TariffsList = ({ centerID, isAdmin }) => {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);
  const [selectedTariffID, setSelectedTariffID] = useState("");

  const [page, setPage] = useState(1);
  const defaultPageSize = 10;
  const [totalResults, setTotalResults] = useState();
  const [openAdd, setOpenAdd] = useState(false);

  const tableRef = useRef(null);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    setIsLoading(true);
    let params = {
      centerID: centerID !== "1" ? centerID : null,
      perPage: defaultPageSize,
      page: page,
    };
    tariffsAPI.getAll(params).then((result) => {
      setData(result.results);
      setPage(result.page);
      setTotalResults(result.totalCount);
      setIsLoading(false);
    });
  }, [page, centerID]);

  const handleTariffSelected = (id) => {
    setSelectedTariffID(id);
  };
  const handlePageChange = (newPage) => {
    window.scrollTo(0, 0);
    setPage(newPage);
  };

  if (selectedTariffID) {
    return <Redirect to={`/edit-tarifa/${selectedTariffID}`}></Redirect>;
  }

  if (columns.length === 0) return "LOADING";

  return (
    <Fade in={true} timeout={FADE_TRANSITION}>
      <div className={classes.root}>
        <ModalAddFormExam
          open={openAdd}
          setOpen={setOpenAdd}
          tableRef={tableRef}
        />
        <div className={classes.header}>
          <Grid container xs={12} justify="space-between" alignItems="center">
            <Grid item>
              <Typography className={classes.headerText}>
                <span>TARIFAS</span>
              </Typography>
            </Grid>
            <Grid item>
              <Link to="add-tarifa">
                <Button>CREAR TARIFA</Button>
              </Link>
            </Grid>
          </Grid>
        </div>
        <div style={{ padding: "20px 40px 0 40px" }}>
          {isLoading ? (
            <LoadingIcon></LoadingIcon>
          ) : (
            <CustomTable
              tableRef={tableRef}
              data={data}
              columns={columns}
              handlePageChange={handlePageChange}
              page={page}
              totalResults={totalResults}
              defaultPageSize={defaultPageSize}
              handleClickEditOpen={handleTariffSelected}
            ></CustomTable>
          )}
        </div>
      </div>
    </Fade>
  );
};

export default TariffsList;
