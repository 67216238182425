import { InputAdornment } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import { PDFDownloadLink } from "@react-pdf/renderer";
import React, { useState } from "react";
import StudentContract from "../../documents/StudentContract/StudentContract";

const useStyles = makeStyles((theme) => ({
  marginRight: {
    marginRight: theme.spacing(1),
  },
  labelRoot: {
    color: "white",
    fontWeight: "bold",
    "&$labelFocused": {
      color: "white",
    },
  },
  acceptButton: {
    backgroundColor: "rgb(18,151,91)",
    "&:hover": {
      backgroundColor: "rgb(18,151,91)",
    },
  },
  actions: {
    backgroundColor: "rgb(248,250,252)",
  },
}));

const ModalAddContractPrice = (props) => {
  const { open, setOpen, data } = props;
  const classes = useStyles();

  const [price, setPrice] = useState("");
  const [errorMessage] = useState("");

  const handleChange = (newValue) => {
    setPrice(newValue);
  };

  const handleCancelClose = () => {
    setOpen(false);
  };

  return (
    <Dialog open={open} onClose={handleCancelClose}>
      <DialogTitle id="form-dialog-title">
        <span>Clase Teórica</span>
      </DialogTitle>
      <Divider style={{ margin: "0 20px" }}></Divider>
      <DialogContent style={{ overflowY: "hidden", margin: "20px 0" }}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography>Precio de la clase teórica</Typography>
            <TextField
              fullWidth
              size="small"
              type="number"
              variant="outlined"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">€</InputAdornment>
                ),
              }}
              value={price}
              onChange={(e) => handleChange(e.target.value)}
            ></TextField>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions className={classes.actions}>
        <p>{errorMessage}</p>
        <PDFDownloadLink
          document={<StudentContract data={data} classPrice={price} />}
          fileName={`Contrato-${data.student.firstName}-${data.student.lastName1}`}
        >
          {({ blob, url, loading, error }) =>
            loading ? "Cargando document..." : "Descargar!"
          }
        </PDFDownloadLink>
      </DialogActions>
    </Dialog>
  );
};

export default ModalAddContractPrice;
