import axios from "axios";
import {API_NAME} from "./base";

const PendingStudentsInstance = axios.create({
  baseURL: `${API_NAME}api/pendingStudents`,
  withCredentials: true,
  headers: {
    Authorization: `Bearer ${localStorage.getItem("user") || ""}`,
  },
});

PendingStudentsInstance.interceptors.request.use(
  (config) => {
    config.headers.Authorization = `Bearer ${localStorage.getItem("user")}`;
    return config;
  },
  (error) => Promise.reject(error)
);

const PendingInstanceApi = {
  getAll: ({
    q = null,
    centerID = null,
    orderBy = "name",
    orderDir = "asc",
    page = 1,
    perPage = 10,
  } = {}) => {
    return PendingStudentsInstance.get(``, {
      params: {
        q,
        centerID,
        orderBy,
        orderDir,
        page,
        perPage,
      },
    })
      .then((response) => response.data)
      .catch((err) => err);
  },

  getOne: (studentID) => {
    return PendingStudentsInstance.get(`/${studentID}`)
      .then((response) => response.data)
      .catch((err) => console.error(err));
  },

  create: (data) => {
    return PendingStudentsInstance.post("", data)
      .then((response) => response.data)
      .catch((err) => console.error(err));
  },

  update: (data, ID) => {
    return PendingStudentsInstance.post(`/${ID}`, data)
      .then((response) => response.data)
      .catch((err) => console.error(err));
  },
};

const pendingStudents = PendingInstanceApi;
export default pendingStudents;
