import { Button, Fade, Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { students as studentsAPI, tariffs as tariffsAPI } from "../../../api";
import { DATE_FORMAT, FADE_TRANSITION } from "../../../lib/constants";
import ModalFormBuyPacks from "../calendar/ModalFormBuyPacks";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    minHeight: "100vh",
    "& .MuiTextField-root": {
      minWidth: "20ch",
    },
  },
  gridContainer: {
    backgroundColor: "rgb(255,255,255)",
    // margin: "20px",
    borderTop: "5px solid rgb(159,46,37)",
    borderRadius: "0 0 10px 10px",
    padding: 20,
  },
  header: {
    backgroundColor: "rgb(248,250,252)",
    borderBottom: "2px solid rgb(227,232,239)",
    height: 70,
  },
  headerText: {
    fontWeight: "bold",
    marginLeft: 40,
    color: theme.palette.primary.main,
  },
  headerButtons: {
    marginRight: 40,
  },
  textArea: {
    minHeight: 150,
    minWidth: "99%",
    maxWidth: "99%",
    width: "99",
    borderRadius: 3,
    padding: 8,
  },
}));

const Profile = ({ user }) => {
  const classes = useStyles();
  const [values, setValues] = useState([]);
  const [openPack, setOpenPack] = React.useState(false);
  const [packs, setPacks] = useState([]);

  useEffect(() => {
    // student Get One
    studentsAPI
      .getOwnProfile()
      .then((response) => {
        setValues([
          {
            title: "Nombre",
            value: `${response.firstName} ${response.lastName1} ${response.lastName2}`,
          },
          {
            title: "Clases prácticas",
            value: `${response.classes} Clases restantes` || (
              <Typography>Compra mas clases</Typography>
            ),
          },
          {
            title: "Fecha de nacimiento",
            value: moment(response.birthday).format(DATE_FORMAT),
          },
          {
            title: "DNI",
            value: response.dni,
          },
          {
            title: "Teléfono",
            value: response.phone,
          },
          {
            title: "Correo electrónico",
            value: response.email,
          },
          {
            title: "Dirección",
            value: `${response.wayType} ${response.wayName} ${response.block} ${response.wayNumber} ${response.floor} ${response.door}`,
          },
          {
            title: "Localidad",
            value: response.city,
          },
          {
            title: "Permiso",
            value: response.permission,
          },
          {
            title: "Teórica",
            value: response.theory
              ? moment(response.theory).format(DATE_FORMAT)
              : "",
          },
          {
            title: "Práctica",
            value: response.practice
              ? moment(response.practice).format(DATE_FORMAT)
              : "",
          },
        ]);
      })
      .catch((x) => {
        // setError("Error trayendo centros");
      });

    tariffsAPI
      .getStudentPackActives(user.id)
      .then((response) => {
        setPacks(response.result);
      })
      .catch((x) => {
        // setError("Error trayendo centros");
      });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClickOpenPacks = () => {
    setOpenPack(true);
  };

  return (
    <Fade in={true} timeout={FADE_TRANSITION}>
      <div className={classes.root}>
        <ModalFormBuyPacks
          open={openPack}
          packs={packs}
          setOpen={setOpenPack}
          studentID={user.id}
          centerID={user.centerID}
        />
        <Grid
          container
          className={classes.header}
          direction="row"
          justify="space-between"
          alignItems="center"
        >
          <Grid item>
            <Typography className={classes.headerText}>MI PERFIL</Typography>
          </Grid>
          <Grid item className={classes.headerButtons}>
            <Typography>
              <Button onClick={handleClickOpenPacks}>COMPRAR CLASES</Button>
            </Typography>
          </Grid>
        </Grid>
        <Grid container>
          <Grid
            style={{
              backgroundColor: "white",
              margin: "20px 20px",
              borderRadius: "10px",
            }}
            container
            item
            xs={12}
            md={9}
            lg={8}
          >
            <Grid container item xl={12} style={{ padding: 20 }}>
              <Grid item xs={12}>
                <Typography>Información del alumno</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography>Datos personales y del curso</Typography>
              </Grid>
            </Grid>
            {values.length > 0 &&
              values.map((value, index) => {
                return (
                  <Grid
                    container
                    item
                    xl={12}
                    key={index}
                    alignContent="center"
                    style={{
                      padding: 20,
                      height: "65px",
                      backgroundColor: index % 2 ? "#fff" : "rgb(245,249,251)",
                    }}
                  >
                    <Grid item xs={12} sm={6}>
                      <Typography>{value.title}</Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography>{value.value}</Typography>
                    </Grid>
                  </Grid>
                );
              })}
          </Grid>
        </Grid>
      </div>
    </Fade>
  );
};

export default Profile;
