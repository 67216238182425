import { Button, makeStyles, TextareaAutosize, TextField, Typography } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { supervisorQuestions as supervisorQuestionsAPI } from "../../../../api/supervisor";
import { decodeHtml } from '../../../../lib/helpers';

const useStyles = makeStyles((theme) => ({
    root: {
        "& .MuiTextField-root": {
            minWidth: "40ch",
        },
    },
    textArea: {
        minHeight: 50,
        minWidth: 500,
        maxWidth: 500,
        borderRadius: 3,
    },
}));

export default function EditQuestionInput({ inputType = "TextField", field, question, refreshCall }) {

    const classes = useStyles()

    const [state, setState] = useState("")

    useEffect(() => {
        setState(question[field])
    }, [question, field]);


    const handleSubmit = (e) => {
        supervisorQuestionsAPI
            .update({ ...question, [field]: state }, question.id)
            .then(() => refreshCall());
    }


    return (
        <div classes={classes.root} style={{ display: "flex", minWidth: "400px" }}>
            {inputType === "TextField" ?
                <TextField
                    fullWidth
                    variant="outlined"
                    size="small"
                    name={question[field]}
                    value={state}
                    onChange={(e) => {
                        setState(e.target.value)
                    }
                    }
                /> :
                <TextareaAutosize
                    id="observations"
                    name="observations"
                    className={classes.textArea}
                    rowsMax={2}
                    placeholder="Escriba el mensaje"
                    value={state}
                    onChange={(e) => {
                        setState(e.target.value)
                    }
                    }
                />}

            {(state !== question[field]) ?

                <Button style={{ backgroundColor: 'green' }} onClick={() => handleSubmit()}>Guardar</Button> : ""

            }
        </div>)

}
