import React from "react";

export const SmsNo = (props) => (
  <svg
    version="1.1"
    id="Capa_1"
    xmlns="http://www.w3.org/2000/svg"
    // xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    width={props.width || "100px"}
    height={props.height || "100px"}
    viewBox="0 0 748.582 444.096"
    enable-background="new 0 0 748.582 444.096"
    // xml:space="preserve"
  >
    <g id="BG">
      <g id="Layer_2"></g>
    </g>
    <g id="Icon">
      <g>
        <g>
          <path
            fill="#424242"
            d="M52.138,439.304c40.199,2.849,79.922-14.178,105.486-46.067c13.961,3.148,28.482,4.819,43.395,4.819
				h345.275c108.447,0,196.361-87.914,196.361-196.361c0-108.448-87.912-196.362-196.361-196.362H201.017
				C92.572,5.333,4.656,93.247,4.656,201.695c0,71.121,37.811,133.408,94.426,167.861c-1.879,7.438-4.471,14.809-7.824,22.021
				C82.117,411.228,68.476,427.353,52.138,439.304z"
          />
        </g>
        <g>
          <g>
            <path
              fill="#E63B76"
              d="M162.433,334.2c0.578,0.437,1.162,0.864,1.746,1.294C163.5,334.998,162.921,334.567,162.433,334.2z"
            />
          </g>
          <g>
            <path
              fill="#E63B76"
              d="M164.529,335.748c5.914,4.35,12.035,8.433,18.354,12.228
					C174.462,342.791,168.23,338.445,164.529,335.748z"
            />
          </g>
          <g>
            <path
              fill="#E63B76"
              d="M160.603,332.799c0.006,0.004,0.637,0.505,1.83,1.401C161.818,333.738,161.212,333.266,160.603,332.799z
					"
            />
          </g>
          <g>
            <path
              fill="#1C1C1C"
              d="M52.138,439.304c40.199,2.849,79.922-14.178,105.486-46.067c13.961,3.148,28.482,4.819,43.395,4.819
					h147.417c-72.843,0-134.249-30.799-165.554-50.08c-6.105-3.668-12.055-7.604-17.797-11.819
					C105.001,292.047,67.98,219.674,67.98,145.103c0-38.958,9.426-75.713,26.111-108.117C40.259,72.005,4.656,132.689,4.656,201.695
					c0,71.121,37.811,133.408,94.426,167.861c-1.879,7.438-4.471,14.809-7.824,22.021C82.117,411.228,68.476,427.353,52.138,439.304
					z"
            />
          </g>
          <g>
            <path
              fill="#E63B76"
              d="M164.177,335.494c0.115,0.084,0.232,0.168,0.352,0.255C164.412,335.664,164.294,335.58,164.177,335.494z
					"
            />
          </g>
        </g>
        <g>
          <path
            fill="#2B2B2B"
            d="M510.824,32.149c47.732,0.767,91.293,18.688,124.78,47.881c40.976,35.72,66.875,88.297,66.875,146.927
				c0,27.753-5.815,54.146-16.272,78.04c-0.438,0.787-0.862,1.583-1.312,2.363c0.231-0.304,0.449-0.617,0.681-0.923
				c22.115-29.108,35.266-65.402,35.266-104.781c0-95.766-77.633-173.401-173.4-173.401C534.876,28.255,522.63,29.61,510.824,32.149
				z"
          />
        </g>
      </g>
      <rect x="0" y="168" fill="#FFFFFF" width="749" height="67" />
      <rect x="8" y="184" fill="#1C1C1C" width="733" height="35" />
    </g>
  </svg>
);
