import {
  Button,
  Fade,
  Grid,
  MenuItem,
  TextField,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { KeyboardDatePicker } from "@material-ui/pickers";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { exams as examsApi, teachers as teachersAPI } from "../../../api";
import { FADE_TRANSITION, NO_VALUE } from "../../../lib/constants";
import CustomTable from "../../core/CustomTable";
import ModalDelete from "./ModalDelete";
import ModalEditResultStudent from "./ModalEditResultStudent";
import PracticalExamOptions from "./PracticalExamOptions";
import TheoryExamOptions from "./TheoryExamOptions";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
    minHeight: "100vh",
  },
  gridContainer: {
    backgroundColor: "rgb(255,255,255)",
    borderTop: "5px solid rgb(159,46,37)",
    borderRadius: "0 0 10px 10px",
    padding: "20px",
    marginTop: "20px",
  },
  header: {
    backgroundColor: "rgb(248,250,252)",
    borderBottom: "2px solid rgb(227,232,239)",
    height: 70,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  headerText: {
    fontWeight: "bold",
    marginLeft: 40,
    color: theme.palette.primary.main,
  },
  textArea: {
    minHeight: 150,
    minWidth: "99%",
    maxWidth: "99%",
    width: "99",
    borderRadius: 3,
    padding: 8,
  },
}));

const columnTheory = [
  {
    title: "#",
    field: "number",
    emptyValue: NO_VALUE,
    cardFormat: "STRING",
    width: 100,
  },
  {
    title: "Alumno",
    field: "studentName",
    render: (data) => (
      <Link
        onClick={(e) => {
          e.stopPropagation();
        }}
        to={`/edit-student/${data.id}`}
        target="_blank"
        rel="noopener noreferrer"
      >
        <Button
          // className={classes.acceptButton}
          size="large"
          variant="outlined"
        >
          <Typography>
            <strong>{data.studentName}</strong>
          </Typography>
        </Button>
      </Link>
    ),
    emptyValue: NO_VALUE,
    cardFormat: "STRING",
  },
  {
    title: "Tipo",
    field: "type",
    emptyValue: NO_VALUE,
    cardFormat: "STRING",
  },
  {
    title: "Resultado",
    field: "result",
    emptyValue: NO_VALUE,
    cardFormat: "STRING",
  },
];

const columnPractical = [
  {
    title: "Profesor",
    field: "teacherName",
    emptyValue: NO_VALUE,
    cardFormat: "STRING",
    width: 100,
  },
  {
    title: "Matricula",
    field: "enrollment",
    emptyValue: NO_VALUE,
    cardFormat: "STRING",
  },
  {
    title: "Alumno",
    field: "studentName",
    render: (data) => (
      <Link
        onClick={(e) => {
          e.stopPropagation();
        }}
        to={`/edit-student/${data.id}`}
        target="_blank"
        rel="noopener noreferrer"
      >
        <Button
          // className={classes.acceptButton}
          size="large"
          variant="outlined"
        >
          <Typography>
            <strong>{data.studentName}</strong>
          </Typography>
        </Button>
      </Link>
    ),
    emptyValue: NO_VALUE,
    cardFormat: "STRING",
  },
  {
    title: "Resultado",
    field: "result",
    emptyValue: NO_VALUE,
    cardFormat: "STRING",
  },
];

const ExamView = ({ centerID }) => {
  const classes = useStyles();
  const tableRef = useRef(null);
  const { examID } = useParams();
  const [selectedStudent, setSelectedStudent] = useState({});
  const [examData, setExamData] = useState({});
  const [students, setStudents] = useState([]);
  const [teachers, setTeachers] = useState([]);
  const [selectedTeacherID, setselectedTeacherID] = useState(null);
  const [page] = useState(1);
  const defaultPageSize = 10;
  const [totalResults] = useState();
  const [openEdit, setOpenEdit] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  useEffect(() => {
    if (examID) {
      examsApi
        .getOne(examID)
        .then((response) => {
          setExamData(response);
          if (response.type === "Teórico") {
            fetchTheoryStudents();
          } else {
            fetchPracticalStudents();
          }
        })
        .catch((x) => {
          console.log("Error trayendo centros");
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [centerID]);

  useEffect(() => {
    let params = {
      centerID: centerID !== "1" ? centerID : null,
      perPage: 100,
    };
    teachersAPI.getAll(params).then((results) => {
      setTeachers(results.results);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchTheoryStudents = () => {
    if (examID) {
      examsApi
        .getTheoryStudents(examID)
        .then((response) => {
          setStudents(response);
        })
        .catch((x) => {
          console.log("Error trayendo centros");
        });
    }
  };

  const fetchPracticalStudents = () => {
    if (examID) {
      examsApi
        .getPracticalStudents(examID)
        .then((response) => {
          setStudents(response);
        })
        .catch((x) => {
          console.log("Error trayendo centros");
        });
    }
  };

  const handleChange = (key, value) => {
    setExamData({
      ...examData,
      [key]: value,
    });
  };
  const handleUpdateExam = () => {
    examsApi
      .update(examData, examID)
      .then((response) => {})
      .catch((x) => {
        console.log("Error updating Exam");
      });
  };

  const handleClickEditOpenResultStudent = (student) => {
    if (student) {
      setSelectedStudent(student);
    } else {
      setSelectedStudent(student);
    }
    setOpenEdit(true);
  };

  const handleProfile = (studentID) => {
    const win = window.open(`/edit-student/${studentID}`, "_blank");
    win.focus();
  };

  const handleDeleteTheoryStudent = (ID) => {
    examsApi
      .delete({ ID, examID })
      .then((response) => {
        if (examData.type === "Teórico") {
          fetchTheoryStudents();
        } else {
          fetchPracticalStudents();
        }
        setOpenDelete(true);
      })
      .catch((x) => {
        console.log("Error adding permiso");
      });
  };

  if (examData == null) {
    return <div>Loading</div>;
  }
  return (
    <Fade in={true} timeout={FADE_TRANSITION}>
      <div className={classes.root}>
        <ModalEditResultStudent
          open={openEdit}
          setOpen={setOpenEdit}
          tableRef={tableRef}
          student={selectedStudent}
          examID={examID}
          fetchStudents={
            examData.type === "Teórico"
              ? fetchTheoryStudents
              : fetchPracticalStudents
          }
        />
        <ModalDelete
          open={openDelete}
          setOpen={setOpenDelete}
          tableRef={tableRef}
        />
        <div className={classes.header}>
          <Grid container xs={12} direction="row" justify="space-between">
            <Typography className={classes.headerText}>
              {examData.type === "Teórico" ? (
                <span>MODIFICAR EXAMEN TEÓRICO</span>
              ) : (
                <span>MODIFICAR EXAMEN PRÁCTICO</span>
              )}
            </Typography>
            {examData.type === "Teórico" ? (
              <a
                style={{ marginRight: 40 }}
                href={`https://develop2020.autius.com/api/getTheoricalList/${examID}`}
                target="_blank"
                rel="noopener noreferrer"
                class="h-8 px-3 py-1 ml-2 bg-granate rounded-lg hover:bg-red-700 tracking-wide focus:outline-none hover:shadow-md focus:shadow-outline focus:border-blue-300 transition-all transform duration-200"
              >
                <Button>Generar PDF</Button>
              </a>
            ) : (
              <Grid
                item
                xs={12}
                md={6}
                container
                justify="flex-end"
                style={{ marginRight: 40 }}
              >
                <TextField
                  variant="outlined"
                  size="small"
                  id="genre"
                  placeholder="Seleccione un profesor"
                  select
                  value={selectedTeacherID || "none"}
                  onChange={(e) => setselectedTeacherID(e.target.value || null)}
                >
                  <MenuItem value="none" disabled>
                    Seleccione un profesor
                  </MenuItem>
                  {teachers.map((option) => (
                    <MenuItem key={option.id} value={option.id}>
                      {option.firstName} {option.lastName1}
                    </MenuItem>
                  ))}
                </TextField>
                {selectedTeacherID ? (
                  <a
                    href={`https://develop2020.autius.com/api/getPracticalList/${examID}/${selectedTeacherID}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    class="h-8 px-3 py-1 ml-2 bg-granate rounded-lg hover:bg-red-700 tracking-wide focus:outline-none hover:shadow-md focus:shadow-outline focus:border-blue-300 transition-all transform duration-200"
                  >
                    <Button>Generar PDF</Button>
                  </a>
                ) : null}
              </Grid>
            )}
          </Grid>
        </div>

        <div style={{ margin: "40px 20px 20px 20px" }}>
          <Grid container spacing={2} direction="row" justify="space-around">
            <Grid
              item
              xs={12}
              sm={12}
              md={3}
              lg={3}
              className={classes.gridContainer}
            >
              <Grid
                container
                spacing={2}
                style={{ padding: "20px" }}
                direction="row"
                justify="flex-start"
              >
                <Grid item xs={12}>
                  <Typography>
                    Nº de registro
                    <span style={{ color: "rgb(159,46,37)" }}>*</span>
                  </Typography>
                  <TextField
                    fullWidth
                    variant="outlined"
                    size="small"
                    placeholder="Registro"
                    value={examData.registerNumber}
                    onChange={(e) =>
                      handleChange("registerNumber", e.target.value)
                    }
                  ></TextField>
                </Grid>
                <Grid item xs={12}>
                  <Typography>
                    Fecha de examen
                    <span style={{ color: "rgb(159,46,37)" }}>*</span>
                  </Typography>
                  <KeyboardDatePicker
                    fullWidth
                    autoOk
                    placeholder="Registro"
                    variant="inline"
                    inputVariant="outlined"
                    size="small"
                    format="DD/MM/yyyy"
                    value={examData.date || null}
                    InputAdornmentProps={{ position: "end" }}
                    onChange={(date) =>
                      handleChange("date", moment(date).format())
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography>
                    Tipo
                    <span style={{ color: "rgb(159,46,37)" }}>*</span>
                  </Typography>
                  <TextField
                    disabled
                    style={{ backgroundColor: "white" }}
                    variant="outlined"
                    fullWidth
                    size="small"
                    id="Type"
                    select
                    value={examData.type || ""}
                    onChange={(e) => handleChange("type", e.target.value)}
                  >
                    {["Práctico", "Teórico"].map((option) => (
                      <MenuItem key={option} value={option}>
                        {option}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={12}>
                  <Button fullWidth onClick={handleUpdateExam}>
                    <span>Modificar</span>
                  </Button>
                </Grid>
              </Grid>
            </Grid>

            <Grid
              item
              xs={12}
              sm={12}
              md={8}
              lg={8}
              className={classes.gridContainer}
            >
              <Grid
                container
                spacing={2}
                style={{ padding: "10px", height: "100%" }}
                direction="row"
                justify="space-between"
              >
                <Grid item xs={12}>
                  {examData.type === "Teórico" && (
                    <TheoryExamOptions
                      fetchTheoryStudents={fetchTheoryStudents}
                      examData={examData}
                      centerID={centerID}
                    ></TheoryExamOptions>
                  )}
                  {examData.type === "Práctico" && (
                    <PracticalExamOptions
                      fetchPracticalStudents={fetchPracticalStudents}
                      examData={examData}
                      centerID={centerID}
                      teachers={teachers}
                    ></PracticalExamOptions>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
        <div style={{ padding: "20px 40px 40px 40px" }}>
          {examData.type === "Teórico" && (
            <CustomTable
              tableRef={tableRef}
              data={students}
              columns={columnTheory}
              // handlePageChange={handlePageChange}
              page={page}
              totalResults={totalResults}
              defaultPageSize={defaultPageSize}
              hasPagination={false}
              handleClickEditOpen={handleClickEditOpenResultStudent}
              hasActions={true}
              handleDelete={handleDeleteTheoryStudent}
              handleProfile={handleProfile}
              fetchAll={true}
            ></CustomTable>
          )}

          {examData.type === "Práctico" && (
            <CustomTable
              tableRef={tableRef}
              data={students}
              columns={columnPractical}
              // handlePageChange={handlePageChange}
              page={page}
              totalResults={totalResults}
              defaultPageSize={defaultPageSize}
              hasPagination={false}
              handleClickEditOpen={handleClickEditOpenResultStudent}
              hasActions={true}
              handleDelete={handleDeleteTheoryStudent}
              fetchAll={true}
              handleProfile={handleProfile}
            ></CustomTable>
          )}
        </div>
      </div>
    </Fade>
  );
};

export default ExamView;
