import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import SaveIcon from "@material-ui/icons/Save";
import { KeyboardDatePicker } from "@material-ui/pickers";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { vehicles as vehiclesAPI } from "../../../api";
import { MenuItem } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  marginRight: {
    marginRight: theme.spacing(1),
  },
  labelRoot: {
    color: "white",
    fontWeight: "bold",
    "&$labelFocused": {
      color: "white",
    },
  },
  acceptButton: {
    backgroundColor: "rgb(18,151,91)",
  },
  actions: {
    backgroundColor: "rgb(248,250,252)",
  },
}));

const ModalEditFormVehicle = (props) => {
  const {
    getVehicles,
    open,
    setOpen,
    vehicleID,
  } = props;
  const classes = useStyles();

  const [values, setValues] = useState({});
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    if (vehicleID) {
      vehiclesAPI
        .getOne(vehicleID)
        .then((response) => {
          setValues(response);
        })
        .catch((x) => {
          // setError("Error trayendo centros");
        });
    }
  }, [vehicleID, open]);

  const handleChange = (key, value) => {
    setValues({
      ...values,
      [key]: value,
    });
  };

  const handleSubmit = () => {
    vehiclesAPI
      .update(values, vehicleID)
      .then((response) => {
        getVehicles();
        setOpen(false);
      })
      .catch((x) => {
        setErrorMessage("Error adding permiso");
      });
  };

  const handleCancelClose = () => {
    setValues({});
    setOpen(false);
  };

  return (
    <Dialog open={open} onClose={handleCancelClose}>
      <DialogTitle id="form-dialog-title">
        <span>MODIFICAR VEHICULO</span>
      </DialogTitle>
      <Divider style={{ margin: "0 20px" }}></Divider>
      <DialogContent style={{ overflowY: "hidden", margin: "20px 0" }}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography>
              Descripción<span style={{ color: "rgb(159,46,37)" }}>*</span>
            </Typography>
            <TextField
              fullWidth
              size="small"
              variant="outlined"
              placeholder="DESCRIPCION"
              value={values.description}
              onChange={(e) => handleChange("description", e.target.value)}
            ></TextField>
          </Grid>
          <Grid item xs={12}>
            <Typography>Matrícula</Typography>
            <TextField
              fullWidth
              variant="outlined"
              size="small"
              placeholder="AAA0000"
              value={values.enrollment}
              onChange={(e) =>
                handleChange("enrollment", e.target.value.toUpperCase())
              }
            ></TextField>
          </Grid>
          <Grid item xs={12}>
            <Typography>
              Fecha vencimiento ITV
              <span style={{ color: "rgb(159,46,37)" }}>*</span>
            </Typography>
            <KeyboardDatePicker
              fullWidth
              autoOk
              placeholder="ITV"
              variant="inline"
              inputVariant="outlined"
              size="small"
              format="DD/MM/yyyy"
              value={values.itvDueDate || null}
              InputAdornmentProps={{ position: "end" }}
              onChange={(date) =>
                handleChange("itvDueDate", moment(date).format())
              }
            />
          </Grid>
          <Grid item xs={12}>
            <Typography>
              Fecha vencimiento del seguro
              <span style={{ color: "rgb(159,46,37)" }}>*</span>
            </Typography>
            <KeyboardDatePicker
              fullWidth
              autoOk
              placeholder="SEGURO"
              variant="inline"
              inputVariant="outlined"
              size="small"
              format="DD/MM/yyyy"
              value={values.insuranceDueDate || null}
              InputAdornmentProps={{ position: "end" }}
              onChange={(date) =>
                handleChange("insuranceDueDate", moment(date).format())
              }
            />
          </Grid>
          <Grid item xs={12}>
            <Typography>
              Próximo mantenimiento preventivo
              <span style={{ color: "rgb(159,46,37)" }}>*</span>
            </Typography>
            <KeyboardDatePicker
              fullWidth
              autoOk
              placeholder="MANTENIMIENTO"
              variant="inline"
              inputVariant="outlined"
              size="small"
              format="DD/MM/yyyy"
              value={values.nextPreventiveMaintenance || null}
              InputAdornmentProps={{ position: "end" }}
              onChange={(date) =>
                handleChange("nextPreventiveMaintenance", moment(date).format())
              }
            />
          </Grid>
         
                <Grid item xs={12}>
                  <Typography>
                    Estado
                  </Typography>
                  <TextField
                    fullWidth
                    variant="outlined"
                    size="small"
                    id="type"
                    placeholder="Activo"
                    select
                    value={values.isActive || 0}
                    onChange={(e) => handleChange("isActive", e.target.value)}
                  >
                    {[
                      { id: 1, value: 1, name: "Activo" },
                      { id: 2, value: 0, name: "Inactivo" },
                    ].map((option) => (
                      <MenuItem key={option.id} value={option.value}>
                        {option.name}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
        </Grid>
      </DialogContent>
      <DialogActions className={classes.actions}>
        <p>{errorMessage}</p>
        <Button size="large" variant="outlined" onClick={handleCancelClose}>
          <CloseIcon className={classes.marginRight} />
          Cancelar
        </Button>
        <Button
          className={classes.acceptButton}
          size="large"
          variant="outlined"
          onClick={handleSubmit}
        >
          <SaveIcon className={classes.marginRight} /> <span>Modificar</span>
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ModalEditFormVehicle;
