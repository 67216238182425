import { Fade, Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React, { useEffect, useState } from "react";
import { Redirect, useParams } from "react-router-dom";
import { classesPractical as classesPracticalAPI } from "../../../api";
import { FADE_TRANSITION } from "../../../lib/constants";
import CalendarCustom from "../../core/Calendar";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    // minHeight: "100vh",
    "& .MuiTextField-root": {
      minWidth: "20ch",
    },
  },
  gridContainer: {
    backgroundColor: "rgb(255,255,255)",
    // margin: "20px",
    borderTop: "5px solid rgb(159,46,37)",
    borderRadius: "0 0 10px 10px",
    padding: 20,
  },
  header: {
    backgroundColor: "rgb(248,250,252)",
    borderBottom: "2px solid rgb(227,232,239)",
    height: 70,
  },
  headerText: {
    fontWeight: "bold",
    marginLeft: 40,
    color: theme.palette.primary.main,
  },
  headerButtons: {
    marginRight: 40,
  },
  textArea: {
    minHeight: 150,
    minWidth: "99%",
    maxWidth: "99%",
    width: "99",
    borderRadius: 3,
    padding: 8,
  },
}));

const CalendarTeacher = ({ user }) => {
  const classes = useStyles();
  const { centerID } = useParams();
  const [values, ] = useState({ idCenter: centerID });
  const [timeTable, setTimeTable] = useState([]);
  const [selectedClassID, setSelectedClassID] = useState("");

  useEffect(() => {
    // eslint-disable-next-line no-unused-vars
    let teacherID = null;
    if (values.teacherID) {
      teacherID = values.teacherID;
    }
    let disponibility = values.disponibility;
    classesPracticalAPI
      .getClasses({ disponibility, teacherID: user.id })
      .then((results) => {
        const resultsWithDates = results.results.map((elem) => {
          elem.start = new Date(elem.start);
          elem.end = new Date(elem.end);
          return elem;
        });
        setTimeTable(resultsWithDates);
      });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values]);

  const handleClickEditOpen = (ID) => {
    if (ID) {
      setSelectedClassID(ID);
    } else {
      setSelectedClassID(null);
    }
  };

  if (selectedClassID) {
    return <Redirect to={`clase/${selectedClassID}`}></Redirect>;
  }

  return (
    <Fade in={true} timeout={FADE_TRANSITION}>
    <div className={classes.root}>
      <Grid
        container
        className={classes.header}
        direction="row"
        justify="space-between"
        alignItems="center"
      >
        <Grid item>
          <Typography className={classes.headerText}>CALENDARIO</Typography>
        </Grid>
      </Grid>
      <Grid container style={{ marginTop: "20px" }}>
        <Grid item xs={12} md={12} lg={12}>
          <div className={classes.gridContainer}>
            <Grid
              container
              style={{ margin: "20px 0" }}
              spacing={2}
              // justify="space-between"
            >
              <Grid item>
                <span
                  style={{
                    backgroundColor: "green",
                    padding: "5px 30px",
                    marginRight: "5px",
                  }}
                ></span>
                Sin Alumno
              </Grid>
              <Grid item>
                <span
                  style={{
                    backgroundColor: "#ad201c",
                    padding: "5px 30px",
                    marginRight: "5px",
                  }}
                ></span>
                Ocupadas
              </Grid>
            </Grid>
            <CalendarCustom
              view="teacher"
              handleClickEditOpen={handleClickEditOpen}
              events={timeTable}
            ></CalendarCustom>
          </div>
        </Grid>
      </Grid>
    </div>
    </Fade>
  );
};

export default CalendarTeacher;
