import { Button, Fade, Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React, { useEffect, useState } from "react";
import { FADE_TRANSITION } from "../../../../lib/constants";
import { tests as testsAPI } from "../../../../api";
import { Link } from "react-router-dom";
import { LoadingIcon } from "../../../core/LoadingIcon";

const BUTTONS = [
  { id: 1, name: "SESIÓN 1", value: "S1" },
  { id: 2, name: "SESIÓN 2", value: "S2" },
  { id: 3, name: "SESIÓN 3", value: "S3" },
  { id: 4, name: "SESIÓN 4", value: "S4" },
  { id: 5, name: "SESIÓN 5", value: "S5" },
  { id: 6, name: "SESIÓN 6", value: "S6" },
];

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    minHeight: "100vh",
    "& .MuiTextField-root": {
      minWidth: "20ch",
    },
  },
  gridContainer: {
    backgroundColor: "rgb(255,255,255)",
    borderTop: "5px solid rgb(159,46,37)",
    borderRadius: "0 0 10px 10px",
    padding: 20,
  },
  header: {
    backgroundColor: "rgb(248,250,252)",
    borderBottom: "2px solid rgb(227,232,239)",
    height: 70,
  },
  headerText: {
    fontWeight: "bold",
    marginLeft: 40,
    color: theme.palette.primary.main,
  },
  headerButtons: {
    marginRight: 40,
  },
  textArea: {
    minHeight: 150,
    minWidth: "99%",
    maxWidth: "99%",
    width: "99",
    borderRadius: 3,
    padding: 8,
  },
  disabled: {
    border: "1px solid #ad201c",
    backgroundColor: "grey",
    opacity: 0.5,
    color: "#FFFFFF",
  },
}));

const Sessions = ({ user: student }) => {
  const classes = useStyles();

  const [data, setData] = useState(true);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(true);
    let params = {
      studentID: student.id,
    };

    testsAPI.getStudentSessionLevel(params).then((result) => {
      const activeSessions = result.results;
      if (activeSessions.length === 0) {
        activeSessions.push({
          category: `S1`,
          passed: 0,
        });
      } else if (
        activeSessions.length < 6 &&
        activeSessions[activeSessions.length - 1].passed === 4
      ) {
        activeSessions.push({
          category: `S${activeSessions.length + 1}`,
          passed: 0,
        });
      }
      setData(activeSessions);
      setIsLoading(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isLoading) return <LoadingIcon></LoadingIcon>;

  return (
    <Fade in={true} timeout={FADE_TRANSITION}>
      <div className={classes.root}>
        <Grid
          container
          className={classes.header}
          direction="row"
          // justify="space-between"
          alignItems="center"
        >
          <Grid item style={{ marginLeft: "20px" }}>
            <Link to={`/mis_tests`}>
              <Button>
                <span style={{ color: "white" }}>{"<"}</span>
              </Button>
            </Link>
          </Grid>
          <Grid item>
            <Typography className={classes.headerText}>
              TEST SESIONES
            </Typography>
          </Grid>
          <Grid item className={classes.headerButtons}></Grid>
        </Grid>
        <Grid
          style={{
            height: "100%",
            padding: "40px",
            display: "flex",
            alignContent: "flex-start",
            alignItems: "center",
          }}
          container
          spacing={3}
        >
          <Grid item>
            <Typography>
              Aprueba los 4 test de cada sesión para poder pasar a la siguiente
            </Typography>
            <Typography>
              <strong>(Recuerda que son 6 sesiones en total)</strong>
            </Typography>
          </Grid>
          <Grid item container direction="column" spacing={2}>
            {data.map((session, index) => {
              const options = BUTTONS.find(
                (el) => el.value === session.category
              );
              const isDisabled = session.passed === 4 ? true : false;

              return (
                <Grid
                  container
                  direction="row"
                  item
                  style={{ alignItems: "center" }}
                  key={index}
                >
                  <Button
                    disabled={isDisabled}
                    className={isDisabled ? classes.disabled : null}
                  >
                    <Link
                      to={`/test-sesion/${session.category}`}
                    >
                      <span style={{ color: "white" }}>{options.name}</span>
                    </Link>
                  </Button>
                  <Typography style={{ margin: "0 20px" }}>
                    {session.passed}/4 Aprobados
                  </Typography>
                </Grid>
              );
            })}
          </Grid>
        </Grid>
      </div>
    </Fade>
  );
};

export default Sessions;
