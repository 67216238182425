import {
  Grid,
  MenuItem,
  TextField,
  Typography
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React, { useEffect, useState } from "react";
import { centers as centersAPI } from "../../api";
import { NO_VALUE } from "../../lib/constants";

const useStyles = makeStyles((theme) => ({
  rootGrid: {
    maxWidth: "190px",
    margin: "auto",
    "& .MuiTextField-root": {
      minWidth: "20ch",
    },
  },
  root: {
    flexGrow: 1,
    width: "100%",
  },
  header: {
    backgroundColor: "rgb(255,255,255)",
    borderBottom: "2px solid rgb(227,232,239)",
    height: 150,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  gridHeader: {
    marginLeft: 40,
  },
  gridHeaderText: {
    fontWeight: "bold",
    color: theme.palette.primary.main,
  },
  headerButtons: {
    marginRight: 40,
  },
  searchContainer: {
    margin: 20,
    border: "1px solid",
    borderColor: theme.palette.primary.dark,
    borderRadius: 5,
    padding: 12,
  },
  tabPanel: {
    backgroundColor: theme.palette.primary.main,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.primary.dark,
  },
  formControl: {
    width: "100%",
  },
  alignRight: {
    textAlign: "right",
  },
  alignLeft: {
    textAlign: "left",
  },
  container: {
    width: "100%",
    marginLeft: 12,
    marginRight: 12,
  },
  savedSearchLink: {
    marginLeft: "auto",
    marginRight: 0,
  },
}));

const columns = [{ title: "Permisos", field: "name", emptyValue: NO_VALUE }];

const CenterSelection = ({ fetchCenter }) => {
  const classes = useStyles();
  const [centers, setCenters] = useState([]);
  const [selectedCenter, setSelectedCenter] = useState(2);

  useEffect(() => {
    centersAPI
      .getAll()
      .then((response) => {
        const results = response.results;
        setCenters(results);
      })
      .catch((x) => {
        console.log("Error trayendo permisos");
      });
  }, []);

  useEffect(() => {
    centersAPI
      .getOne(selectedCenter)
      .then((response) => {
        // const results = response.results;
        // setCenterInfo({
        //   actives: response.actives,
        //   other: response.otherActives,
        // });
      })
      .catch((x) => {
        console.log("Error trayendo permisos");
      });
  }, [selectedCenter]);

  const handleChange = (value) => {
    setSelectedCenter(value);
    fetchCenter(value);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  if (columns.length === 0) return "LOADING";
  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <Grid container item xs={12} md={3} className={classes.gridHeader}>
          <Grid item xs={12}>
            <Typography className={classes.gridHeaderText}>
              SELECCIONE CENTRO
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              variant="outlined"
              size="small"
              id="type"
              placeholder="type"
              select
              value={selectedCenter}
              onChange={(e) => handleChange(e.target.value)}
            >
              {centers &&
                centers.map((option) => (
                  <MenuItem key={option.id} value={option.id}>
                    {option.name.toUpperCase()}
                  </MenuItem>
                ))}
            </TextField>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default CenterSelection;
