import { InputAdornment, MenuItem } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import SaveIcon from "@material-ui/icons/Save";
import { Autocomplete } from "@material-ui/lab";
import { KeyboardDatePicker } from "@material-ui/pickers";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { classes as classesAPI, teachers as teachersAPI } from "../../../api";

const useStyles = makeStyles((theme) => ({
  marginRight: {
    marginRight: theme.spacing(1),
  },
  labelRoot: {
    color: "white",
    fontWeight: "bold",
    "&$labelFocused": {
      color: "white",
    },
  },
  acceptButton: {
    backgroundColor: "rgb(18,151,91)",
    "&:hover": {
      backgroundColor: "rgb(18,151,91)",
    },
  },
  actions: {
    backgroundColor: "rgb(248,250,252)",
  },
}));

const ModalFormAddClass = (props) => {
  const {
    centerID,
    teachers,
    classesTypes,
    classID,
    fetchClasses,
    open,
    setOpen,
    students,
    setOpenSuccess,
    setType,
    setSelectedClassID,
  } = props;
  const classes = useStyles();

  const [values, setValues] = useState({});

  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    if (classID) {
      teachersAPI.getClassById({ classID }).then((results) => {
        setValues(results.results);
      });
    }
  }, [classID]);

  const handleChange = (key, value) => {
    if (key === "idClassType" && value != 1) {
      setValues({
        ...values,
        [key]: value,
        studentID: null,
      });
    } else {
      setValues({
        ...values,
        [key]: value,
      });
    }
  };

  const handleSubmit = () => {
    classesAPI
      .update({ ...values, centerID }, classID)
      .then(() => {
        setValues({});
        setSelectedClassID(null);
        setOpen(false);
        fetchClasses();
        setOpenSuccess(true);
        setType("added");
      })
      .catch((error) => {
        switch (error.code) {
          case "Invalid value":
            setErrorMessage("Error: Invalid value(s), please try again");
            break;
          default:
            setErrorMessage("");
        }
      });
  };

  const handleCancel = () => {
    classesAPI
      .cancel({ ...values, centerID }, classID)
      .then(() => {
        setValues({ ...values, studentID: null });
        setOpen(false);
        fetchClasses();
        setOpenSuccess(true);
        setType("added");
      })
      .catch((error) => {
        console.log("hay error");
        switch (error.code) {
          case "Invalid value":
            setErrorMessage("Error: Invalid value(s), please try again");
            break;
          default:
            setErrorMessage("");
        }
      });
  };

  const handleCancelClose = () => {
    setOpen(false);
  };

  const actualStudent =
    students && students.find((student) => student.id === values.studentID);

  if (values === null) {
    return <Typography>Loading</Typography>;
  }
  return (
    <Dialog open={open} className={classes.root} onClose={handleCancelClose}>
      <DialogTitle id="form-dialog-title">MODIFICAR CLASE</DialogTitle>
      <Divider style={{ margin: "0 20px" }}></Divider>
      <DialogContent style={{ overflowY: "hidden", margin: "20px 0" }}>
        <Grid container spacing={3}>
          <Grid item xs={4}>
            <Typography>
              Fecha de la clase
              <span style={{ color: "rgb(159,46,37)" }}>*</span>
            </Typography>
            <KeyboardDatePicker
              fullWidth
              autoOk
              placeholder="Fecha de la clase"
              variant="inline"
              inputVariant="outlined"
              size="small"
              format="DD/MM/yyyy"
              value={values.date || null}
              InputAdornmentProps={{ position: "end" }}
              onChange={(date) => handleChange("date", moment(date).format())}
            />
          </Grid>
          <Grid item xs={4}>
            <Typography>Hora de inicio</Typography>
            <TextField
              fullWidth
              variant="outlined"
              size="small"
              type="time"
              placeholder="##:##"
              value={values.startHour}
              onChange={(e) => handleChange("startHour", e.target.value)}
            ></TextField>
          </Grid>
          <Grid item xs={4}>
            <Typography>Precio de la clase</Typography>
            <TextField
              fullWidth
              variant="outlined"
              size="small"
              placeholder="€0.00"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">€</InputAdornment>
                ),
              }}
              value={values.price}
              onChange={(e) => handleChange("price", e.target.value)}
            ></TextField>
          </Grid>
          <Grid item xs={12}>
            <Typography>Profesor</Typography>
            <TextField
              fullWidth
              variant="outlined"
              size="small"
              id="teacher"
              placeholder="PROFESOR"
              select
              value={values.idTeacher || null}
              onChange={(e) => handleChange("idTeacher", e.target.value)}
            >
              {teachers.map((option) => (
                <MenuItem key={option.id} value={option.id}>
                  {option.firstName} {option.lastName1}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12}>
            <Typography>Tipo de clase</Typography>
            <TextField
              fullWidth
              variant="outlined"
              size="small"
              id="classType"
              placeholder="TIPO"
              select
              value={values.idClassType || null}
              onChange={(e) => handleChange("idClassType", e.target.value)}
            >
              {classesTypes.map((option) => (
                <MenuItem key={option.id} value={option.id}>
                  {option.description}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          {values.idClassType === 1 || values.idClassType === 4 ? (
            <Grid item xs={12}>
              <Typography>Alumno</Typography>
              <Autocomplete
                value={actualStudent || null}
                fullWidth
                size="small"
                id="combo-box-demo"
                options={students}
                getOptionLabel={(option) => option.studentName}
                onChange={(e, option) =>
                  handleChange("studentID", option ? option.id : null)
                }
                renderInput={(params) => (
                  <TextField fullWidth {...params} variant="outlined" />
                )}
              />
            </Grid>
          ) : null}
        </Grid>
      </DialogContent>
      <DialogActions className={classes.actions}>
        <p>{errorMessage}</p>
        <Button size="large" variant="outlined" onClick={handleCancelClose}>
          <CloseIcon className={classes.marginRight} />
          Cancelar
        </Button>
        {values.studentID ? (
          <Button size="large" variant="outlined" onClick={handleCancel}>
            <CloseIcon className={classes.marginRight} />
            Anular
          </Button>
        ) : null}

        <Button
          className={classes.acceptButton}
          size="large"
          variant="outlined"
          onClick={handleSubmit}
        >
          <SaveIcon className={classes.marginRight} /> Modificar Clase
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ModalFormAddClass;
