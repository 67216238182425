import {
  Grid,
  Hidden,
  Link,
  ListItem,
  ListItemText,
  Tooltip,
  Typography,
  withStyles,
} from "@material-ui/core";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import DescriptionIcon from "@material-ui/icons/Description";
import DriveEtaIcon from "@material-ui/icons/DriveEta";
import EventIcon from "@material-ui/icons/Event";
import MenuBookIcon from "@material-ui/icons/MenuBook";
import HowToRegIcon from "@material-ui/icons/HowToReg";
import React, { useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import dgtIcon from "../../assets/img/dgt.png";

const StyledListItem = withStyles({
  root: {
    "&.Mui-selected": {
      backgroundColor: "darkRed",
    },
  },
})(ListItem);

const OPTIONS = [
  {
    title: "Calendario",
    index: 0,
    query: "",
    icon: <EventIcon fill="white"></EventIcon>,
  },
  {
    title: (
      <div
        style={{
          display: "flex",
          alignContent: "center",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography style={{ paddingRight: "10px" }}>Tests</Typography>
        <img style={{ width: "70px" }} src={dgtIcon} alt="DGT" />
      </div>
    ),
    index: 1,
    query: "mis_tests",
    icon: <DescriptionIcon fill="white" />,
  },
  {
    title: "Mis Clases",
    index: 2,
    query: "mis_clases",
    icon: <DriveEtaIcon fill="white"></DriveEtaIcon>,
  },
  {
    title: "Perfil",
    index: 3,
    query: "mi_perfil",
    icon: <AccountCircleIcon fill="white"></AccountCircleIcon>,
  },
  {
    title: "Libro Digital",
    index: 4,
    query: "libro_digital",
    icon: <MenuBookIcon fill="white"></MenuBookIcon>,
  },
  {
    title: "Provisional DGT",
    index: 5,
    query: "dgt",
    icon: <HowToRegIcon fill="white"></HowToRegIcon>,
  },
];


const StudentRoutes = ({ user, isMobil = false, setExpanded = () => {} }) => {

  // PINTAR EL APARTADO DONDE ESTAS ACTUALMENTE AL ACCEDER A LA PAGINA
  let location = useLocation();

  let initialValue;
  OPTIONS.forEach((option) => {
    if (location.pathname.includes(option.query)) {
      initialValue = option.index;
    }
  });

  const [selectedIndex, setSelectedIndex] = useState(initialValue);

  const handleListItemClick = (event, index) => {
    setSelectedIndex(index);
    setExpanded(false);
  };

  return (
    <>
      {OPTIONS.map((row, index) => {
        return (
          <Grid item xs={12} key={index}>
            <Tooltip title={row.title} placement="top">
              <StyledListItem
                button
                onClick={
                  row.download
                    ? () => {}
                    : (event) => handleListItemClick(event, index)
                }
                component={NavLink}
                selected={selectedIndex === index}
                style={{
                  color: "white",
                  display: "flex",
                  justifyContent: "flex-start",
                  borderRadius: "6px",
                }}
                to={`/${row.query}`}
              >
                {isMobil ? (
                  <ListItemText primary={row.title} />
                ) : (
                  <>
                    {row.icon}
                    <span style={{ margin: "0 5px" }}></span>
                    <Hidden mdDown>
                      <ListItemText primary={row.title} />
                    </Hidden>
                  </>
                )}
              </StyledListItem>
            </Tooltip>
          </Grid>
        );
      })}
      <Grid item xs={12}>
        <Tooltip title="Descarga" placement="top">
          <ListItem
            button
            onClick={() => {}}
            component={Link}
            style={{
              color: "white",
              display: "flex",
              justifyContent: "flex-start",
              borderRadius: "6px",
            }}
            href="/esquemas/esquemas.pdf"
            download
          >
            {isMobil ? (
              <ListItemText primary="Esquemas" />
            ) : (
              <>
                <CloudDownloadIcon fill="white"></CloudDownloadIcon>
                <span style={{ margin: "0 5px" }}></span>
                <Hidden mdDown>
                  <ListItemText primary="Esquemas" />
                </Hidden>
              </>
            )}
          </ListItem>
        </Tooltip>
      </Grid>
    </>
  );
};

export default StudentRoutes;
