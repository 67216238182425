import { Snackbar, Typography } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import React from "react";

const CustomSnackbar = ({
  openSnack = () => {},
  handleCloseSnack = () => {},
  message = "Creado",
  type = "succes",
}) => {

  return (
    <Snackbar
      open={openSnack}
      autoHideDuration={3000}
      onClose={handleCloseSnack}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
    >
      <Alert onClose={handleCloseSnack} severity={type}>
        <Typography>{message}</Typography>
      </Alert>
    </Snackbar>
  );
};

export default CustomSnackbar;
