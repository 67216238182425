import React from "react";
import { Route } from "react-router-dom";
import ArchingsViews from "../Center/archings/ArchingsViews";
import CenterCalendarView from "../Center/calendar/CalendarCenter";
import CourseStudentsList from "../Center/courses/CourseStudentsList";
import CourseViews from "../Center/courses/CourseVIews";
import FormCode from "../Center/courses/FormCode";
import FormCourse from "../Center/courses/FormCourse";
import ExamsList from "../Center/exams/ExamsList";
import ExamView from "../Center/exams/ExamView";
import ExitPointsList from "../Center/exitPoints/ExitPointsList";
import PendingStudentsList from "../Center/pendingStudents/PendingStudentsList";
import FormStudent from "../Center/students/FormStudent";
import StudentsList from "../Center/students/StudentsList";
import StudentsViews from "../Center/students/StudentsViews";
import FormTariff from "../Center/tariffs/FormTariff";
import TariffsList from "../Center/tariffs/TariffsList";
import FormTeacher from "../Center/teachers/FormTeacher";
import TeachersList from "../Center/teachers/TeachersList";
import TeachersViews from "../Center/teachers/TeachersViews";
import ListVehicles from "../Center/vehicles/VehiclesList";
import TestResult from "../Student/tests/TestResult";
import ExamRequestStatusList from "../Center/examRequestStatus/ExamRequestStatusList";
import HistorialTests from "../Center/students/HistorialTests";
// import FormPointsStudent from "../Center/students/FormPointsStudent";
// import PointCoursesViews from "../Center/pointCourses/PointCoursesVIews";
// import FormPointCourse from "../Center/pointCourses/FormPointCourse";
import TestResultStudent from "../Center/students/TestResultStudent";

const RoutesCenter = ({ user, reloadUser, fetchPendingStudents }) => {
  return (
    <>
      <Route
        exact={true}
        path="/add-student"
        render={(props) => (
          <FormStudent
            {...props}
            user={user}
            centerID={user.id}
            reloadUser={reloadUser}
            fetchPendingStudents={fetchPendingStudents}
          />
        )}
      />
      {/* <Route
        exact={true}
        path="/add-points-student"
        render={(props) => (
          <FormPointsStudent
            {...props}
            user={user}
            centerID={user.id}
            reloadUser={reloadUser}
            fetchPendingStudents={fetchPendingStudents}
          />
        )}
      /> */}
      <Route
        exact={true}
        path="/edit-student/:studentID"
        render={(props) => (
          <StudentsViews
            {...props}
            user={user}
            centerID={user.id}
            reloadUser={reloadUser}
          />
        )}
      />
      <Route
        exact={true}
        path="/create-pending-student/:pendingStudentID"
        render={(props) => (
          <FormStudent
            {...props}
            user={user}
            centerID={user.id}
            reloadUser={reloadUser}
          />
        )}
      />
      <Route
        exact={true}
        path="/add-code"
        render={(props) => (
          <FormCode
            {...props}
            user={user}
            centerID={user.id}
            reloadUser={reloadUser}
          />
        )}
      />
      <Route
        exact={true}
        path="/edit-code/:codeID"
        render={(props) => (
          <FormCode
            {...props}
            user={user}
            centerID={user.id}
            reloadUser={reloadUser}
          />
        )}
      />
      <Route
        exact={true}
        path="/add-course"
        render={(props) => (
          <FormCourse
            {...props}
            user={user}
            centerID={user.id}
            reloadUser={reloadUser}
          />
        )}
      />
      {/* <Route
        exact={true}
        path="/add-point-course"
        render={(props) => (
          <FormPointCourse
            {...props}
            user={user}
            centerID={user.id}
            reloadUser={reloadUser}
          />
        )}
      /> */}
      <Route
        exact={true}
        path="/edit-course/:courseID"
        render={(props) => (
          <FormCourse
            {...props}
            user={user}
            centerID={user.id}
            reloadUser={reloadUser}
          />
        )}
      />
      <Route
        exact={true}
        path="/edit-student/:studentID/:testID"
        render={(props) => (
          <TestResult
            {...props}
            user={user}
            centerID={user.id}
            reloadUser={reloadUser}
          />
        )}
      />
       <Route
        exact={true}
        path="/tests-historial/:studentID"
        render={(props) => (
          <HistorialTests
            {...props}
            user={user}
            reloadUser={reloadUser}
          />
        )}
      />
       <Route
        exact={true}
        path="/test/:studentID/:testID"
        render={(props) => (
          <TestResultStudent {...props} user={user} reloadUser={reloadUser} />
        )}
      />
      <Route
        exact={true}
        path="/add-teacher"
        render={(props) => (
          <FormTeacher
            {...props}
            user={user}
            centerID={user.id}
            reloadUser={reloadUser}
          />
        )}
      />
      <Route
        exact={true}
        path="/vehiculos"
        render={(props) => (
          <ListVehicles
            {...props}
            user={user}
            centerID={user.id}
            reloadUser={reloadUser}
          />
        )}
      />
      <Route
        exact={true}
        path="/edit-teacher/:teacherID"
        render={(props) => (
          <TeachersViews
            {...props}
            user={user}
            centerID={user.id}
            reloadUser={reloadUser}
          />
        )}
      />
      <Route
        exact={true}
        path="/calendario"
        render={(props) => (
          <CenterCalendarView
            {...props}
            user={user}
            centerID={user.id}
            reloadUser={reloadUser}
          />
        )}
      />
      <Route
        exact={true}
        path="/"
        render={(props) => (
          <StudentsList
            {...props}
            user={user}
            centerID={user.id}
            reloadUser={reloadUser}
          />
        )}
      />
      <Route
        exact={true}
        path="/pendientes"
        render={(props) => (
          <PendingStudentsList
            {...props}
            user={user}
            centerID={user.id}
            reloadUser={reloadUser}
          />
        )}
      />
      <Route
        exact={true}
        path="/course-students/:courseID"
        render={(props) => (
          <CourseStudentsList
            {...props}
            user={user}
            centerID={user.id}
            reloadUser={reloadUser}
          />
        )}
      />
      <Route
        exact={true}
        path="/cursos"
        render={(props) => (
          <CourseViews
            {...props}
            user={user}
            centerID={user.id}
            reloadUser={reloadUser}
          />
        )}
      />
      {/* <Route
        exact={true}
        path="/cursos-puntos"
        render={(props) => (
          <PointCoursesViews
            {...props}
            user={user}
            centerID={user.id}
            reloadUser={reloadUser}
          />
        )}
      /> */}
      <Route
        exact={true}
        path="/teachers"
        render={(props) => (
          <TeachersList
            {...props}
            user={user}
            centerID={user.id}
            reloadUser={reloadUser}
          />
        )}
      />
      <Route
        exact={true}
        path="/examenes"
        render={(props) => (
          <ExamsList
            {...props}
            user={user}
            centerID={user.id}
            reloadUser={reloadUser}
          />
        )}
      />
      <Route
        exact={true}
        path="/solicitud-examen"
        render={(props) => (
          <ExamRequestStatusList
            {...props}
            user={user}
            centerID={user.id}
            reloadUser={reloadUser}
          />
        )}
      />
      <Route
        exact={true}
        path="/tarifas"
        render={(props) => (
          <TariffsList
            {...props}
            user={user}
            centerID={user.id}
            reloadUser={reloadUser}
          />
        )}
      />
      <Route
        exact={true}
        path="/arqueos"
        render={(props) => (
          <ArchingsViews
            {...props}
            user={user}
            centerID={user.id}
            reloadUser={reloadUser}
          />
        )}
      />
      <Route
        exact={true}
        path="/add-tarifa"
        render={(props) => (
          <FormTariff
            {...props}
            user={user}
            centerID={user.id}
            reloadUser={reloadUser}
          />
        )}
      />
      <Route
        exact={true}
        path="/edit-tarifa/:tariffID"
        render={(props) => (
          <FormTariff
            {...props}
            user={user}
            centerID={user.id}
            reloadUser={reloadUser}
          />
        )}
      />
      <Route
        exact={true}
        path="/examen/:examID"
        render={(props) => (
          <ExamView
            {...props}
            user={user}
            centerID={user.id}
            reloadUser={reloadUser}
          />
        )}
      />
      <Route
        exact={true}
        path="/salidas"
        render={(props) => (
          <ExitPointsList
            {...props}
            user={user}
            centerID={user.id}
            reloadUser={reloadUser}
          />
        )}
      />
     
    </>
  );
};

export default RoutesCenter;
