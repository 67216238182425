import {
  Button,
  Fade,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
} from "@material-ui/core";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import React, { useEffect, useState } from "react";
import { Redirect, useParams } from "react-router-dom";
import { tests as testsAPI } from "../../../api";
import { FADE_TRANSITION } from "../../../lib/constants";
import { decodeHtml } from "../../../lib/helpers";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      minWidth: "20ch",
    },
  },
  gridContainer: { margin: "40px 0" },
  header: {
    backgroundColor: "rgb(248,250,252)",
    borderBottom: "2px solid rgb(227,232,239)",
    height: 70,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  headerText: {
    fontWeight: "bold",
    marginLeft: 40,
    color: theme.palette.primary.main,
  },
  textArea: {
    minHeight: 150,
    minWidth: "99%",
    maxWidth: "99%",
    width: "99",
    borderRadius: 3,
    padding: 8,
  },
}));

const BlueRadio = withStyles({
  root: {
    "&$checked": {
      color: "#7DAFDB",
    },
  },
  checked: {},
})((props) => <Radio required color="default" {...props} />);

const FormTest = ({ user: student, type = "normal" }) => {
  const classes = useStyles();
  const { nTest, category, level } = useParams();
  const [values, setValues] = useState([]);
  const [isSubmiting, setIsSubmiting] = useState(false);
  const history = useHistory()

  useEffect(() => {
    let params = {
      permission: student.permission,
      category,
      nTest,
      type,
      level
    };
   
if(student.permission === 'B'){
  if (category) {
    testsAPI
      .getSessionTest(params)
      .then((response) => {
        setValues(response.result);
      })
      .catch((x) => {
        console.log("Error trayendo centros");
      });
  } else {
    testsAPI
      .getTheoryTest(params)
      .then((response) => {
        setValues(response.result);
      })
      .catch((x) => {
        console.log("Error trayendo centros");
      });
  }
} else{
  if (category) {
    testsAPI
      .getSessionTest(params)
      .then((response) => {
        setValues(response.result);
      })
      .catch((x) => {
        console.log("Error trayendo centros");
      });
  } else {
    testsAPI
      .getRandomTest(params)
      .then((response) => {
        setValues(response.result);
      })
      .catch((x) => {
        console.log("Error trayendo centros");
      });
  }
}
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = (index, key, newValue) =>
    setValues((prevState) =>
      prevState.map((question, i) => {
        if (i === index) {
          return { ...question, [key]: newValue };
        }
        return question;
      })
    );

  const handleSubmitTest = (event) => {
    event.preventDefault();
    setIsSubmiting(true);
    testsAPI
      .create({ student: student, questions: values, category, nTest })
      .then((response) => {
        const testId = response.result
        history.push({ 
            pathname: `/test/${student.id}/${testId}`,
            nextPath: '/mis_tests'
});
      })
      .catch((x) => {
        console.log("Error adding test");
        setIsSubmiting(false);
      });
  };


  return (
    <Fade in={true} timeout={FADE_TRANSITION}>
      <div className={classes.root}>
        <div className={classes.header}>
          <Grid container xs={12}>
            <Typography className={classes.headerText}>
             {student.permission === 'B' ? <span>TEST {nTest}</span> :<span>NUEVO TEST</span>} 
            </Typography>
          </Grid>
        </div>
        <form onSubmit={handleSubmitTest}>
          <FormControl component="fieldset" error={"error"}>
            <Grid container className={classes.gridContainer}>
              {values.length > 0 &&
                values.map((rowData, index) => {
                  return (
                    <Grid
                      item
                      container
                      xs={12}
                      key={index}
                      style={{
                        margin: "3px 30px",
                        backgroundColor: "rgb(255,255,255)",
                        borderRadius: "10px",
                        padding: "25px",
                      }}
                    >
                      <Grid item xs={12} style={{ padding: "5px 0" }}>
                        <Typography
                          style={{
                            fontWeight: "bold",
                          }}
                        >
                          {index+1}. {decodeHtml(rowData.question)}
                        </Typography>
                      </Grid>
                      <Grid container spacing={2} style={{ padding: "10px 0" }}>
                        <Grid item xs={12} sm={4} lg={3}>
                          <img
                            style={{ width: "100%" }}
                            src={
                              rowData.imgURL
                                ? rowData?.imgURL
                                : `/imagenes/questions/${rowData.img}`
                            }
                            alt=""
                          />
                        </Grid>
                        <Grid
                          item
                          container
                          justify="space-around"
                          direction="column"
                          xs={12}
                          sm={8}
                          lg={9}
                        >
                          <RadioGroup
                            aria-label="test"
                            name={index}
                            value={rowData.answer}
                            onChange={(e) =>
                              handleChange(
                                index,
                                "chosenAnswer",
                                e.target.value
                              )
                            }
                          >
                            <FormControlLabel
                              style={{ padding: "5px 0" }}
                              value={"1"}
                              color="primary"
                              control={<BlueRadio />}
                              label={decodeHtml(rowData.a)}
                            />
                            <FormControlLabel
                              style={{ padding: "5px 0" }}
                              value={"2"}
                              color="primary"
                              control={<BlueRadio />}
                              label={decodeHtml(rowData.b)}
                            />
                            <FormControlLabel
                              style={{ padding: "5px 0" }}
                              value={"3"}
                              color="primary"
                              control={<BlueRadio />}
                              label={decodeHtml(rowData.c)}
                            />
                          </RadioGroup>
                        </Grid>
                      </Grid>
                    </Grid>
                  );
                })}
              <Grid
                item
                style={{
                  width: "100%",
                  margin: "20px 30px",
                  textAlign: "right",
                }}
              >
                <Button
                  disabled={isSubmiting}
                  type="submit"
                  style={{ width: 150 }}
                >
                  Finalizar
                </Button>
              </Grid>
            </Grid>
          </FormControl>
        </form>
        <div style={{ height: "20px" }}></div>
      </div>
    </Fade>
  );
};

export default FormTest;
