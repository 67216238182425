import { Button, Fade, makeStyles, Typography } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import React, { useEffect, useRef, useState } from "react";
import Moment from "react-moment";
import { students as studentsAPI, requests as requestsAPI, exams as examsAPI } from "../../../api";
import { DATE_FORMAT_COMPLETE, FADE_TRANSITION } from "../../../lib/constants";
import CustomTable from "../../core/CustomTable";
import ModalAddRequest from "./ModalAddRequest";
const NO_VALUE = "No valor";

export const EXAM_TYPE = {
  theorical: "TEORIA",
  practical: "PRÁCTICA",
};

const useStyles = makeStyles((theme) => ({
  rootGrid: {
    maxWidth: "100%",
    margin: "auto",
  },
  root: {
    flexGrow: 1,
    width: "100%",
    "& .MuiTextField-root": {
      minWidth: "20ch",
    },
  },
  totalStyle: {
    border: `2px solid ${theme.palette.primary.main}`,
    padding: "50px",
    backgroundColor: "#fff",
    fontSize: "25px",
    fonwWidth: "bold",
  },
  header: {
    backgroundColor: "rgb(248,250,252)",
    borderBottom: "2px solid rgb(227,232,239)",
    height: 70,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  headerText: {
    fontWeight: "bold",
    marginLeft: 20,
    color: theme.palette.primary.main,
  },
  summery: {
    marginBottom: 40,
    color: "rgb(159,46,37)",
  },
  headerButtons: {
    marginRight: 40,
  },
  searchContainer: {
    margin: 20,
    border: "1px solid",
    borderColor: theme.palette.primary.dark,
    borderRadius: 5,
    padding: 12,
  },
  tabPanel: {
    backgroundColor: theme.palette.primary.main,
  },
  downLoadButton: {
    marginRight: theme.spacing(1),
    backgroundColor: "transparent",
    color: "rgb(56, 161, 105)",
    border: "1px solid rgb(56, 161, 105)",
    "&:hover": {
      marginRight: theme.spacing(1),
      border: "1px solid rgb(56, 161, 105)",
      backgroundColor: "rgb(56, 161, 105)",
      opacity: 1,
      color: "#FFFFFF",
    },
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.primary.dark,
  },
  formControl: {
    width: "100%",
  },
  alignRight: {
    textAlign: "right",
  },
  alignLeft: {
    textAlign: "left",
  },
  container: {
    width: "100%",
    marginLeft: 12,
    marginRight: 12,
  },
  savedSearchLink: {
    marginLeft: "auto",
    marginRight: 0,
  },
}));

const columns = [
  {
    title: "Fecha",
    field: "created_at",
    width: 200,
    render: (data) => (
      <Moment format={DATE_FORMAT_COMPLETE}>{data.created_at}</Moment>
    ),
    emptyValue: NO_VALUE,
    cardFormat: "DATE_COMPLETE",
  },
  {
    title: "Tipo Examen",
    field: "examType",
    type: "currency",
    emptyValue: NO_VALUE,
    width: 150,
    render: (data) => {
      return <Typography style={{textAlign: 'left'}}>{EXAM_TYPE[data.examType]}</Typography>;
    },
    cardFormat: "STRING",
  },
  {
    title: "Estado",
    field: "title",
    emptyValue: NO_VALUE,
    cardFormat: "STRING",
  },
  {
    title: "Información",
    field: "additionalInfo",
    emptyValue: "",
    cardFormat: "STRING",
  },
  
];

const StudentRequests = ({ centerID, studentID }) => {
  const classes = useStyles();
  const tableRef = useRef(null);

  const [, setIsLoading] = useState(true);
  const [data, setData] = useState([]);
  const [documents, setStudentDocuments] = useState({});
  const [examTypes, setExamTypes] = useState([]);
  const [requestsTypes, setRequestsTypes] = useState([]);
  const [page, setPage] = useState(1);
  const defaultPageSize = 10;
  const [totalResults, setTotalResults] = useState();
  const [sorting] = useState({ orderBy: "name", orderDir: "asc" });

  const [criteria, setCriteria] = useState({});

  const [openAdd, setOpenAdd] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    setIsLoading(true);

    getStudentRequests();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [criteria, page, sorting]);

  const getStudentRequests = () => {
    let params = {
      view: "listing",
      studentID,
      perPage: defaultPageSize,
      page: page,
      ...criteria,
    };
    studentsAPI.getRequests(params).then((result) => {
      setData(result.results);
      setPage(result.page);
      setTotalResults(result.totalCount);
    });
  };

  useEffect(() => {
    setIsLoading(true);

    requestsAPI.getAll().then((result) => {
      setRequestsTypes(result.results);
    });

    studentsAPI.getDocuments(studentID).then((result) => {
      setStudentDocuments(result.documents);
    });

    examsAPI.getExamTypes().then((result)=>{
      const formatedExamTypes = result.results.map((e)=>({
        id: e.id,
        label: EXAM_TYPE[e.description]
        
      }))
      setExamTypes(formatedExamTypes)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [criteria, page, sorting]);

  const handlePageChange = (newPage) => {
    window.scrollTo(0, 0);
    setPage(newPage);
  };

  const handleClickAddOpen = () => {
    setOpenAdd(true);
  };

  //   const downloadExcel = () => {
  //     let params = {
  //       view: "export",
  //       studentID,
  //       ...criteria,
  //     };
  //     studentsAPI.getPayments(params).then((result) => {
  //       exportData(result.data, "Movimientos", result.studentName);
  //     });
  //   };

  if (columns.length === 0) return "LOADING";

  return (
    <Fade in={true} timeout={FADE_TRANSITION}>
      <div className={classes.root}>
        <ModalAddRequest
          open={openAdd}
          setOpen={setOpenAdd}
          tableRef={tableRef}
          studentID={studentID}
          requestsTypes={requestsTypes}
          examTypes={examTypes}
          refetch={getStudentRequests}
        />
        <div style={{ margin: "40px 40px 5px 40px" }}>
          <Grid
            container
            xs={12}
            spacing={2}
            direction="row"
            justify="space-between"
          >
            <Grid
              item
              xs={12}
              sm={12}
              md="auto"
              lg="auto"
              direction="row"
              justify="flex-end"
            >
              <Grid container direction="row" spacing={2}>
                <Grid item>
                  <Button fullWidth className={documents.dniFront ? classes.downLoadButton : null}>
                    <a style={{color: 'black'}} href={documents.dniFront} download>DNI FRONT</a>
                  </Button>
                </Grid>
                <Grid item>
                  <Button fullWidth className={documents.dniBack ? classes.downLoadButton : null}>
                    <a style={{color: 'black'}} href={documents.dniBack} download>DNI BACK</a>
                  </Button>
                </Grid>
                <Grid item>
                  <Button fullWidth className={documents.psychotechnical ? classes.downLoadButton : null}>
                    <a style={{color: 'black'}} href={documents.psychotechnical} download>PSICOTÉCTNICO</a>
                  </Button>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md="auto"
              lg="auto"
              direction="row"
              justify="flex-end"
            >
              <Grid container spacing={2}>
                {/* <Grid item xs={12} sm={4}>
                  <Button
                    fullWidth
                    onClick={() => downloadExcel()}
                    className={classes.downLoadButton}
                    variant="outlined"
                  >
                    <span>Exportar </span>
                  </Button>
                </Grid> */}
                <Grid item xs={12} sm={12}>
                  <Button
                    fullWidth
                    className={classes.button}
                    onClick={handleClickAddOpen}
                  >
                    CREAR NUEVO ESTADO
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
        <Grid container style={{ padding: "20px 40px 0 40px" }}>
          <CustomTable
            tableRef={tableRef}
            data={data}
            columns={columns}
            handlePageChange={handlePageChange}
            page={page}
            totalResults={totalResults}
            defaultPageSize={defaultPageSize}
          ></CustomTable>
        </Grid>
      </div>
    </Fade>
  );
};

export default StudentRequests;
