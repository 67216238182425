import {
  Fade,
  Grid,
  MenuItem,
  Radio,
  TextField,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Pagination } from "@material-ui/lab";
import MaterialTable from "material-table";
import React, { useEffect, useState } from "react";
import { supervisorQuestions as supervisorQuestionsAPI } from "../../../api/supervisor";
import { FADE_TRANSITION, NO_VALUE } from "../../../lib/constants";
import { decodeHtml } from "../../../lib/helpers";
import EditQuestionInput from "./components/EditQuestionInput";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      maxWidth: "100ch",
    },
  },
  gridContainer: {
    margin: 40,
    padding: 20,
  },
  header: {
    backgroundColor: "rgb(248,250,252)",
    borderBottom: "2px solid rgb(227,232,239)",
    height: 70,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  headerText: {
    fontWeight: "bold",
    marginLeft: 40,
    color: theme.palette.primary.main,
  },
  textArea: {
    minHeight: 150,
    minWidth: "99%",
    maxWidth: "99%",
    width: "99",
    borderRadius: 3,
    padding: 8,
  },
  radio: {
    "&$checked": {
      color: "red",
    },
  },
  checked: {
    "&$checked": {
      color: "green",
    },
  },
}));

const CATEGORY_OPTIONS = [
  "S1",
  "S2",
  "S3",
  "S4",
  "S5",
  "S6",
  "N1",
  "N2",
  "N3",
  "N4",
];

const DetailPanel = ({ rowData, refreshCall }) => {
  const classes = useStyles();
  return (
    <Grid clases={classes.root} container spacing={3} style={{ padding: "10px 5px" }}>
      <Grid item xs={12} sm={4} lg={3}>
        <img
          style={{ width: "100%" }}
          // src={`/imagenes/questions/${rowData.img}`}
          src={
            rowData.imgURL
              ? rowData?.imgURL
              : `/imagenes/questions/${rowData.img}`
          }
          alt=""
        />
      </Grid>
      <Grid item container xs={12} sm={8} lg={9}>
        <Grid container item xs={12} direction="row">
          <Radio
            checked={
              parseInt(rowData.answer) === 1 ||
              parseInt(rowData.correctAnswer) === 1
            }
            classes={{
              checked: parseInt(rowData.correctAnswer) === 1 && classes.checked,
            }}
            color="primary"
            value={1}
            label={<Typography>{decodeHtml(rowData.a)}</Typography>}
            name="radio-button-demo"
            inputProps={{ "aria-label": "A" }}
          ></Radio>
          <EditQuestionInput field={"a"} question={rowData} refreshCall={refreshCall} />
        </Grid>

        <Grid container item xs={12} direction="row">
          <Radio
            checked={
              parseInt(rowData.answer) === 2 ||
              parseInt(rowData.correctAnswer) === 2
            }
            classes={{
              checked:
                parseInt(rowData.correctAnswer) === 2 ? classes.checked : "red",
            }}
            color="primary"
            value={2}
            label={<Typography>{decodeHtml(rowData.b)}</Typography>}
            name="radio-button-demo"
            inputProps={{ "aria-label": "A" }}
          />
          <EditQuestionInput field={"b"} question={rowData} refreshCall={refreshCall} />
        </Grid>
        <Grid container item xs={12} direction="row">
          <Radio
            checked={
              parseInt(rowData.answer) === 3 ||
              parseInt(rowData.correctAnswer) === 3
            }
            classes={{
              checked:
                parseInt(rowData.correctAnswer) === 3 ? classes.checked : "red",
            }}
            color="primary"
            value={3}
            label={<Typography>{decodeHtml(rowData.c)}</Typography>}
            name="radio-button-demo"
            inputProps={{ "aria-label": "A" }}
          />
          <EditQuestionInput field={"c"} question={rowData} refreshCall={refreshCall} />
        </Grid>
      </Grid>
    </Grid>
  );
};

const Questions = () => {
  const columns = [
    {
      title: "#",
      field: "id",
      emptyValue: NO_VALUE,
      render: (data) => <Typography>{data.tableData.id + 1}</Typography>,
      // width: 10,
      cardFormat: "STRING",
    },
    {
      title: "DESCRIPCIÓN ",
      field: "question",
      render: (data) => <EditQuestionInput inputType={"TextArea"} field={"question"} question={data} refreshCall={fetchQuestions} />,
      emptyValue: NO_VALUE,
      cardFormat: "STRING",
    },
    {
      title: "CATEGORIA",
      field: "id",
      emptyValue: NO_VALUE,
      width: 5,
      render: (props) => {
        return (
          <TextField
            fullWidth
            variant="outlined"
            size="small"
            id="teacher"
            placeholder="disponibility"
            select
            value={props.category || ""}
            // Aqui hacer llamada al back directamente para que cambie la categoria de esa pregunta
            onChange={(e) => {
              supervisorQuestionsAPI
                .update({ ...props, category: e.target.value }, props.id)
                .then(() => fetchQuestions());
            }}
          >
            {CATEGORY_OPTIONS.map((option) => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </TextField>
        );
      },
    },
  ];

  const classes = useStyles();
  const [data, setData] = useState([]);
  const [, setError] = useState(null);
  const [page, setPage] = useState(1);
  const defaultPageSize = 25;
  const [totalResults, setTotalResults] = useState();
  const [criteria, setCriteria] = useState({
    searchText: "",
    category: "Todos",
    active: "Activas",
  });
  const [, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(true);
    fetchQuestions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [criteria, page]);

  const fetchQuestions = () => {
    let params = {
      perPage: defaultPageSize,
      page: page,
      ...criteria,
    };
    supervisorQuestionsAPI
      .getAll(params)
      .then((response) => {
        setData(response.results);
        setTotalResults(response.totalCount);
      })
      .catch((x) => {
        setError("Error trayendo test");
      });
  };

  const handlePageChange = (newPage) => {
    window.scrollTo(0, 0);
    setPage(newPage);
  };

  const handleCriteriaChange = (newCriteria) => {
    setPage(1);
    setCriteria({ ...criteria, ...newCriteria });
  };

  return (
    <Fade in={true} timeout={FADE_TRANSITION}>
      <div className={classes.root}>
        <div className={classes.header}>
          <Grid container xs={12}>
            <Typography className={classes.headerText}>
              <span>PREGUNTAS</span>
            </Typography>
          </Grid>
        </div>
        <div className={classes.gridContainer}>
          <Grid container spacing={2} direction="row" justify="flex-start">
            <Grid item xs={12} sm={4} md="auto" lg="auto">
              <TextField
                fullWidth
                style={{ backgroundColor: "white" }}
                variant="outlined"
                label="Buscar por texto"
                size="small"
                id="searchText"
                value={criteria.searchText}
                onChange={(e) =>
                  handleCriteriaChange({ searchText: e.target.value })
                }
              />
            </Grid>
            <Grid item xs={12} sm={4} md="auto" lg="auto">
              <TextField
                fullWidth
                style={{ backgroundColor: "white" }}
                variant="outlined"
                label="Categoria"
                size="small"
                id="category"
                select
                value={criteria.category}
                onChange={(e) =>
                  handleCriteriaChange({ category: e.target.value })
                }
              >
                <MenuItem value={"Todos"}>Todos</MenuItem>
                {CATEGORY_OPTIONS.map((option, index) => (
                  <MenuItem key={index} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} sm={4} md="auto" lg="auto">
              <TextField
                fullWidth
                style={{ backgroundColor: "white" }}
                variant="outlined"
                size="small"
                id="active"
                label="Estado"
                select
                value={criteria.active}
                onChange={(e) =>
                  handleCriteriaChange({ active: e.target.value })
                }
              >
                {["Todas", "Activas", "Inactivas"].map((option, index) => (
                  <MenuItem key={index} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          </Grid>
        </div>
        <div className={classes.gridContainer}>
          <Grid container spacing={3} direction="row" justify="flex-start">
            <Grid item xs={12}>
              <Grid container direction="row" justify="space-between">
                <Grid>
                  <Grid item xs={12}>
                    <MaterialTable
                      style={{
                        border: 0,
                        display: "grid",
                        zIndex: 0,
                      }}
                      columns={columns}
                      data={data}
                      detailPanel={(rowData) => (
                        <DetailPanel refreshCall={fetchQuestions} rowData={rowData}></DetailPanel>
                      )}
                      options={{
                        headerStyle: {
                          backgroundColor: "grey",
                          color: "#FFF",
                          fontWeight: "bold",
                          paddingTop: 8,
                          paddingBottom: 8,
                        },
                        rowStyle: (x) => {
                          if (x.active === 0) {
                            return {
                              backgroundColor: "rgba(254, 215, 215, 1)",
                              border: 0,
                            };
                          }

                          return { border: 0 };
                        },
                        paging: false,
                        addRowPosition: "first",
                        search: false,
                        toolbar: false,
                      }}
                    />
                  </Grid>
                  <Grid
                    container
                    style={{ justifyContent: "flex-end", padding: "20px" }}
                  >
                    <Pagination
                      shape="rounded"
                      color="primary"
                      // Hacer operacion para saber el numero de paginas a mostrar dependiendo de los resultados que tenga(igual puedo traer el dato ya hecho dle back)
                      count={Math.ceil(totalResults / defaultPageSize)}
                      page={page}
                      onChange={
                        (event, newPage) => handlePageChange(newPage)
                      }
                    />
                  </Grid>
                </Grid>
                <Grid></Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
        <div style={{ height: "20px" }}></div>
      </div>
    </Fade>
  );
};

export default Questions;
