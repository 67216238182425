import axios from "axios";
import {API_NAME} from "./base";

const ExportPdfInstance = axios.create({
  baseURL: `${API_NAME}api/export-pdf`,
  withCredentials: true,
  responseType: "blob", // important
});

ExportPdfInstance.interceptors.request.use(
  (config) => {
    config.headers.Authorization = `Bearer ${localStorage.getItem("user")}`;
    return config;
  },
  (error) => Promise.reject(error)
);

const ExportPdfApi = {
  getOne: ({ type = null, studentID = null } = {}) => {
    return ExportPdfInstance.get(``, {
      params: { type, studentID },
    })
      .then((response) => response.data)
      .catch((err) => console.error(err));
  },
};

const exportPdf = ExportPdfApi;
export default exportPdf;
