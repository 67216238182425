import axios from "axios";
import {API_NAME} from "./base";

const StudentPaymentInstance = axios.create({
  baseURL: `${API_NAME}api/studentPayments`,
  withCredentials: true,
});

StudentPaymentInstance.interceptors.request.use(
  (config) => {
    config.headers.Authorization = `Bearer ${localStorage.getItem("user")}`;
    return config;
  },
  (error) => Promise.reject(error)
);
const StudentPaymentApi = {
  storePayment: (data) => {
    return StudentPaymentInstance.post("/store-payment", data)
      .then((response) => response.data)
      .catch((err) => console.error(err));
  },
};

const tariffs = StudentPaymentApi;
export default tariffs;
