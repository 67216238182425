import { Button, makeStyles, Typography } from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import Moment from "react-moment";
import { students as studentsAPI, classes as classesAPI } from "../../../api";
import { calculateLevelPracticalClasses } from "../../../lib/calculateLevelPracticalClasses";
import { DATE_FORMAT } from "../../../lib/constants";
import CustomTable from "../../core/CustomTable";
import { Doughnut } from "react-chartjs-2";
import { LoadingIcon } from "../../core/LoadingIcon";
const NO_VALUE = "No valor";

const doughnutOptions = {
  height: "100%",
  events: ["mousemove"], // this is needed, otherwise onHover is not fired
  onHover: (event, chartElement) => {
    event.target.style.cursor = chartElement[0] ? "pointer" : "default";
  },
  legend: {
    display: false,
    position: "top",

    labels: {
      fontColor: "#FFFFFF",
      fontStyle: "bold",
    },
  },
  elements: {
    arc: {
      borderColor: "#002C43", //.minColor.toString(),
      borderWidth: 1,
    },
  },
};

const useStyles = makeStyles((theme) => ({
  rootGrid: {
    maxWidth: "100%",
    margin: "auto",
  },
  root: {
    flexGrow: 1,
    width: "100%",
    "& .MuiTextField-root": {
      minWidth: "20ch",
    },
  },
  header: {
    backgroundColor: "rgb(248,250,252)",
    borderBottom: "2px solid rgb(227,232,239)",
    height: 70,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  headerText: {
    fontWeight: "bold",
    marginLeft: 40,
    color: theme.palette.primary.main,
  },
  headerButtons: {
    marginRight: 40,
  },
  searchContainer: {
    margin: 20,
    border: "1px solid",
    borderColor: theme.palette.primary.dark,
    borderRadius: 5,
    padding: 12,
  },
  tabPanel: {
    backgroundColor: theme.palette.primary.main,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.primary.dark,
  },
  formControl: {
    width: "100%",
  },
  alignRight: {
    textAlign: "right",
  },
  alignLeft: {
    textAlign: "left",
  },
  container: {
    width: "100%",
    marginLeft: 12,
    marginRight: 12,
  },
  savedSearchLink: {
    marginLeft: "auto",
    marginRight: 0,
  },
}));

const columns = [
  {
    title: "Fecha",
    field: "date",
    render: (data) => <Moment format={DATE_FORMAT}>{data.date}</Moment>,
    emptyValue: NO_VALUE,
    cardFormat: "DATE",
  },
  {
    title: "Hora inicio",
    field: "startHour",
    emptyValue: NO_VALUE,
    cardFormat: "STRING",
  },
  {
    title: "Profesor",
    field: "firstName",
    render: (data) => (
      <Typography>
        {data.firstName && data.firstName} {data.lastName1 && data.lastName1}{" "}
        {data.lastName2 && data.lastName2}
      </Typography>
    ),
    emptyValue: NO_VALUE,
    cardFormat: "STRING",
  },
  {
    title: "Lugar de inicio",
    field: "position",
    render: (data) => (
      <a
        href={`https://www.google.com/maps?q=${data.position}`}
        target="_blank"
        rel="noopener noreferrer"
      >
        <Button>LUGAR</Button>
      </a>
    ),
    width: 150,
    emptyValue: "",
    cardFormat: "POSITION",
  },
];

const ClassesList = ({ centerID, studentID }) => {
  const classes = useStyles();
  const tableRef = useRef(null);
  //CAMBIAR A DINAMICO

  // const columns = useSearchParamsInColumns(tableColumns);
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const defaultPageSize = 10;
  const [totalResults, setTotalResults] = useState();
  const [sorting,] = useState({ orderBy: "name", orderDir: "asc" });

  const [level, setLevel] = useState([]);
  const [classedPassed, setClassesPassed] = useState(null);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    setIsLoading(true);
    let params = {
      studentID: studentID,
      perPage: defaultPageSize,
      page: page,
    };

    studentsAPI.getPracticalClasses(params).then((result) => {
      setData(result.results);
      setPage(result.page);
      setTotalResults(result.totalCount);
      setIsLoading(false);
    });

    classesAPI.getPracticalClassesLevel({ studentID }).then((result) => {
      const levelStudent = result.level.filter(({ evaluation }) => {
        return evaluation >= 5;
      }).length;
      setClassesPassed(levelStudent);
      setLevel(calculateLevelPracticalClasses(levelStudent));
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, sorting]);

  const handlePageChange = (newPage) => {
    window.scrollTo(0, 0);
    setPage(newPage);
  };

  const dataForDonut = classedPassed <= 20 ? classedPassed : 20;
  const Donutdata = {
    labels: ["% Pasado", "% Total"],
    datasets: [
      {
        data: [(dataForDonut / 20) * 100, 100],
        backgroundColor: ["rgb(52, 201, 127)", "rgb(199, 76, 72)"],
        // hoverBackgroundColor: hoverColors,
      },
    ],
  };

  if (columns.length === 0) return "LOADING";

  return (
    <>
      {isLoading ? (
        <LoadingIcon></LoadingIcon>
      ) : (
        <div className={classes.root}>
          <div style={{ padding: "20px 40px 0 40px" }}>
            <Typography>
              <b>NIVEL DE PRÁCTICAS: {level}</b>
            </Typography>
          </div>
          <div style={{ padding: "20px 40px 0 40px", width: "300px" }}>
            <Doughnut height={150} options={doughnutOptions} data={Donutdata} />
          </div>
          <div style={{ padding: "20px 40px 0 40px" }}>
            <CustomTable
              tableRef={tableRef}
              data={data}
              columns={columns}
              handlePageChange={handlePageChange}
              page={page}
              totalResults={totalResults}
              defaultPageSize={defaultPageSize}
              //   handleClickEditOpen={handleStudentSelected}
            ></CustomTable>
          </div>
        </div>
      )}
    </>
  );
};

export default ClassesList;
