import React, { useEffect, useRef, useState } from "react";
import { Button, Fade, makeStyles, Typography } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Moment from "react-moment";
import { Link, Redirect } from "react-router-dom";
import { teachers as teachersAPI } from "../../../api";
import { DATE_FORMAT, FADE_TRANSITION, NO_VALUE } from "../../../lib/constants";
import CustomTable from "../../core/CustomTable";
import { LoadingIcon } from "../../core/LoadingIcon";

const useStyles = makeStyles((theme) => ({
  rootGrid: {
    maxWidth: "100%",
    margin: "auto",
  },
  root: {
    flexGrow: 1,
    width: "100%",
    minHeight: "100vh",
  },
  header: {
    backgroundColor: "rgb(248,250,252)",
    borderBottom: "2px solid rgb(227,232,239)",
    height: 70,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  headerText: {
    fontWeight: "bold",
    marginLeft: 40,
    color: theme.palette.primary.main,
  },
  headerButtons: {
    marginRight: 40,
  },
  searchContainer: {
    margin: 20,
    border: "1px solid",
    borderColor: theme.palette.primary.dark,
    borderRadius: 5,
    padding: 12,
  },
  tabPanel: {
    backgroundColor: theme.palette.primary.main,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.primary.dark,
  },
  formControl: {
    width: "100%",
  },
  alignRight: {
    textAlign: "right",
  },
  alignLeft: {
    textAlign: "left",
  },
  container: {
    width: "100%",
    marginLeft: 12,
    marginRight: 12,
  },
  savedSearchLink: {
    marginLeft: "auto",
    marginRight: 0,
  },
}));

const columns = [
  {
    title: "Nombre",
    field: "firstName",
    emptyValue: NO_VALUE,
    cardFormat: "STRING",
  },
  {
    title: "Apellidos",
    field: "lastName1",
    emptyValue: NO_VALUE,
    cardFormat: "STRING",
  },
  {
    title: "DNI",
    field: "dni",
    emptyValue: NO_VALUE,
    cardFormat: "STRING",
  },
  {
    title: "Fecha nacimiento",
    field: "birthday",
    render: (data) => <Moment format={DATE_FORMAT}>{data.birthday}</Moment>,
    emptyValue: NO_VALUE,
    cardFormat: "DATE",
  },
  {
    title: "Télefono",
    field: "phone",
    emptyValue: NO_VALUE,
    cardFormat: "STRING",
  },
  {
    title: "Email",
    field: "email",
    emptyValue: NO_VALUE,
    cardFormat: "STRING",
  },
  {
    title: "Estado",
    field: "isActive",
    emptyValue: NO_VALUE,
    cardFormat: "STRING",
    render: (data) => (
      <p>{data.isActive ? 'Activo':'Inactivo'}</p>
    ),
  },
];

const TeachersList = ({ centerID, isAdmin }) => {
  const classes = useStyles();
  const tableRef = useRef(null);
  //CAMBIAR A DINAMICO
  // const columns = useSearchParamsInColumns(tableColumns);
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);
  const [selectedTeacherID, setSelectedTeacherID] = useState("");
  const [page, setPage] = useState(1);
  const defaultPageSize = 10;
  const [totalResults, setTotalResults] = useState();
  const [sorting, ] = useState({ orderBy: "name", orderDir: "asc" });

  const [criteria, ] = useState({});

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    setIsLoading(true);
    let params = {
      centerID: centerID !== "1" ? centerID : null,
      perPage: defaultPageSize,
      page: page,
    };
    teachersAPI.getAll(params).then((result) => {
      setData(result.results);
      setPage(result.page);
      setTotalResults(result.totalCount);
      setIsLoading(false);
    });
  }, [criteria, page, sorting, centerID]);

  const handlePageChange = (newPage) => {
    window.scrollTo(0, 0);
    setPage(newPage);
  };

  const handleTeacherSelected = (id) => {
    setSelectedTeacherID(id);
  };

  if (columns.length === 0) return "LOADING";

  if (selectedTeacherID) {
    return <Redirect to={`/edit-teacher/${selectedTeacherID}`}></Redirect>;
  }

  return (
    <Fade in={true} timeout={FADE_TRANSITION}>
      <div className={classes.root}>
        <div className={classes.header}>
          <Grid container xs={12}>
            <Typography className={classes.headerText}>PROFESORES</Typography>
          </Grid>
          {centerID === 1 ? null : (
            <Grid
              container
              spacing={3}
              xs={12}
              className={classes.headerButtons}
              direction="row"
              justify="flex-end"
            >
              <Link to={`/add-teacher`}>
                <Button>CREAR PROFESOR</Button>
              </Link>
            </Grid>
          )}
        </div>
        <div style={{ padding: "20px 40px 0 40px" }}>
          {isLoading ? (
            <LoadingIcon></LoadingIcon>
          ) : (
            <CustomTable
              tableRef={tableRef}
              data={data}
              columns={columns}
              handlePageChange={handlePageChange}
              page={page}
              totalResults={totalResults}
              defaultPageSize={defaultPageSize}
              handleClickEditOpen={handleTeacherSelected}
            ></CustomTable>
          )}
        </div>
      </div>
    </Fade>
  );
};

export default TeachersList;
