import { InputAdornment } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import SearchIcon from "@material-ui/icons/Search";
import React, { useRef, useState } from "react";

const WAIT_INTERVAL = 700;

const DelayedChangeTextField = ({
  value,
  type = "text",
  onChange,
  ...props
}) => {
  let textInput = useRef(null);
  const [timer, setTimer] = useState(null);
  const [valueFinal, setValueFinal] = useState("");

  const handleChange = (e) => {
    var newValue = e.target.value.toUpperCase();

    clearTimeout(timer);
    setValueFinal(newValue);
    setTimer(
      setTimeout(() => {
        onChange(newValue);
      }, WAIT_INTERVAL)
    );
  };

  return (
    <TextField
      fullWidth
      style={{ backgroundColor: "white" }}
      variant="outlined"
      size="small"
      placeholder="Nombre, DNI.."
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>
        ),
      }}
      value={valueFinal || ""}
      onChange={handleChange}
      type={type}
      inputRef={textInput}
      {...props}
    />
  );
};

export default DelayedChangeTextField;
