import axios from "axios";
import {API_NAME} from "./base";

const ClassTypesInstance = axios.create({
  baseURL: `${API_NAME}api/classTypes`,
  withCredentials: true,
});

ClassTypesInstance.interceptors.request.use(
  (config) => {
    config.headers.Authorization = `Bearer ${localStorage.getItem("user")}`;
    return config;
  },
  (error) => Promise.reject(error)
);

const ClassApi = {
  getAllTypes: () => {
    return ClassTypesInstance.get(``)
      .then((response) => response.data)
      .catch((err) => console.error(err));
  },

  getTeachersClasses: ({ teacherID = null, centerID = null } = {}) => {
    return ClassTypesInstance.get(`/`, {
      params: { teacherID, centerID },
    })
      .then((response) => response.data)
      .catch((err) => console.error(err));
  },
};

const classes = ClassApi;
export default classes;
