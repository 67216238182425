import { Button, Fade, Grid, TextField, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import DoneRoundedIcon from "@material-ui/icons/DoneRounded";
import React, { useEffect, useRef, useState } from "react";
import { classes as classesAPI, patterns as patternsAPI } from "../../../api";
import { FADE_TRANSITION } from "../../../lib/constants";
import CustomDatePicker from "../../core/CustomDatePicker";
import CustomSnackbar from "../../core/CustomSnackbar";
import ModalAddPattern from "./ModalAddPattern";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
    minHeight: "100vh",
  },
  gridContainer: {
    backgroundColor: "rgb(255,255,255)",
    borderTop: "5px solid rgb(159,46,37)",
    borderRadius: "0 0 10px 10px",
  },
  buttonActive: {
    margin: "10px",
    padding: "10px",
    color: "black",
    fontSize: "17px",
    backgroundColor: "rgb(240, 255, 244)",
    border: "1px solid rgb(154, 230, 180)",
    "&:hover": {
      border: "1px solid rgb(154, 230, 180)",
      backgroundColor: "rgb(240, 255, 244)",
    },
  },
  buttonInactive: {
    margin: "10px",
    padding: "10px",
    color: "black",
    fontSize: "17px",
    backgroundColor: "#fff",
    border: "transparent",
    "&:hover": {
      border: "transparent",
      backgroundColor: "rgb(240, 255, 244)",
      opacity: 1,
    },
  },
  header: {
    backgroundColor: "rgb(248,250,252)",
    borderBottom: "2px solid rgb(227,232,239)",
    height: 70,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  headerText: {
    fontWeight: "bold",
    marginLeft: 40,
    color: theme.palette.primary.main,
  },
  textArea: {
    minHeight: 150,
    minWidth: "99%",
    maxWidth: "99%",
    width: "99",
    borderRadius: 3,
    padding: 8,
  },
}));

const TeacherTimeTable = ({ teacherID, centerID }) => {
  const classes = useStyles();
  const tableRef = useRef(null);
  const [values, setValues] = useState([]);
  const [patterns, setPatterns] = useState([]);
  const [criteria, setCriteria] = useState({ idCenter: centerID });
  const [openAdd, setOpenAdd] = useState(false);
  const [openSnack, setOpenSnack] = useState(false);

  useEffect(
    () => {
      fetchPatterns();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [openAdd],
    centerID
  );

  const fetchPatterns = () => {
    patternsAPI
      .getAll({ teacherID })
      .then((response) => {
        setPatterns(response);
      })
      .catch((x) => {
        console.log("Error trayendo centros");
      });
  };

  const handleChange = (key, value) => {
    setValues({
      ...values,
      [key]: value,
    });
  };

  const handleClickAddOpen = () => {
    setOpenAdd(true);
  };

  const handleCriteriaChange = (newCriteria) => {
    setCriteria({ ...criteria, ...newCriteria });
  };

  const handleSubmitCreateClasses = () => {
    let params = {
      teacherID,
      ...criteria,
      ...values,
    };
    classesAPI
      .createRangeClasses(params)
      .then((result) => {
        setOpenSnack(true);
      })
      .catch();
  };
  const handleChangePatternState = (elem) => {
    let active = !elem.active;
    if (active === true) {
      active = "1";
    }
    if (active === false) {
      active = "0";
    }

    patternsAPI
      .update({ active }, elem.id)
      .then((response) => {
        fetchPatterns();
      })
      .catch((x) => {
        // console.logMessage("Error adding pattern");
      });
  };

  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnack(false);
  };

  return (
    <Fade in={true} timeout={FADE_TRANSITION}>
      <div className={classes.root}>
        <CustomSnackbar
          openSnack={openSnack}
          handleCloseSnack={handleCloseSnack}
          message={"Classes creadas"}
          type="success"
        ></CustomSnackbar>
        <ModalAddPattern
          patterns={patterns}
          open={openAdd}
          setOpen={setOpenAdd}
          tableRef={tableRef}
          teacherID={teacherID}
        />
        <div style={{ margin: "40px 20px 20px 20px" }}>
          <Grid container spacing={2} direction="row" justify="space-around">
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              lg={4}
              className={classes.gridContainer}
            >
              <Grid
                container
                spacing={2}
                style={{ padding: "20px" }}
                direction="row"
                justify="space-between"
              >
                <Grid item xs={12} sm={12} md={6}>
                  <Typography>
                    Hora de Inicio
                    <span style={{ color: "rgb(159,46,37)" }}>*</span>
                  </Typography>
                  <TextField
                    fullWidth
                    variant="outlined"
                    type="time"
                    size="small"
                    placeholder="Hora de inicio"
                    value={values.startHour}
                    onChange={(e) => handleChange("startHour", e.target.value)}
                  ></TextField>
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <Typography>
                    Hora de fin
                    <span style={{ color: "rgb(159,46,37)" }}>*</span>
                  </Typography>
                  <TextField
                    fullWidth
                    variant="outlined"
                    size="small"
                    type="time"
                    placeholder="Hora de fin"
                    value={values.endHour}
                    onChange={(e) => handleChange("endHour", e.target.value)}
                  ></TextField>
                </Grid>
                <Grid item xs={12}>
                  <Typography>
                    Rango de fechas
                    <span style={{ color: "rgb(159,46,37)" }}>*</span>
                  </Typography>
                  <CustomDatePicker
                    startDate={criteria.startDate}
                    endDate={criteria.endDate}
                    handleChangeCriteria={handleCriteriaChange}
                  ></CustomDatePicker>
                </Grid>
                <Grid item xs={12}>
                  <Button fullWidth onClick={handleSubmitCreateClasses}>
                    <span>GENERAR CLASES</span>
                  </Button>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              lg={5}
              // className={classes.gridContainer}
            >
              <Grid container spacing={2} direction="row">
                <Grid container spacing={2} item xs={12}>
                  {patterns.length > 0 &&
                    patterns.map((elem, index) => {
                      return (
                        <Grid item xs={3}>
                          <Button
                            fullWidth
                            key={index}
                            className={
                              elem.active
                                ? classes.buttonActive
                                : classes.buttonInactive
                            }
                            onClick={() => handleChangePatternState(elem)}
                          >
                            {elem.hour.slice(0, elem.hour.length - 3)}
                            {elem.active === 1 ? (
                              <DoneRoundedIcon
                                style={{
                                  backgroundColor: "rgb(154, 230, 180)",
                                  color: "black",
                                  borderRadius: "50%",
                                  padding: "6px",
                                  marginLeft: "5px",
                                  width: "12px",
                                  height: "12px",
                                  fontWeight: "bold",
                                }}
                              ></DoneRoundedIcon>
                            ) : null}
                          </Button>
                        </Grid>
                      );
                    })}
                </Grid>
                <Grid item xs={12}>
                  <Button
                    style={{ margin: "10px" }}
                    onClick={handleClickAddOpen}
                  >
                    <span>AÑADIR PATRÓN</span>
                  </Button>
                </Grid>
                {/* <Grid item xs={6}>
                <Button onClick={handleSubmitCenter}>
                  <span>ACTUALIZAR PATRONES</span>
                </Button>
              </Grid> */}
              </Grid>
            </Grid>
          </Grid>
        </div>
      </div>
    </Fade>
  );
};

export default TeacherTimeTable;
