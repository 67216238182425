import axios from "axios";
import {API_NAME} from "./base";

const CourseInstance = axios.create({
  baseURL: `${API_NAME}api/courses`,
  withCredentials: true,
});

CourseInstance.interceptors.request.use(
  (config) => {
    config.headers.Authorization = `Bearer ${localStorage.getItem("user")}`;
    return config;
  },
  (error) => Promise.reject(error)
);

const CourseApi = {
  getAll: ({
    centerID = null,
    orderBy = "name",
    orderDir = "asc",
    page = 1,
    perPage = 10,
    coursesForOptions = null,
  } = {}) => {
    return CourseInstance.get(`/`, {
      params: {
        centerID,
        orderBy,
        orderDir,
        page,
        perPage,
        coursesForOptions,
      },
    })
      .then((response) => response.data)
      .catch((err) => console.error(err));
  },

  getStudentsCourse: ({
    centerID = null,
    courseID = null,
    orderBy = "name",
    orderDir = "asc",
  } = {}) => {
    return CourseInstance.get(`/courseStudents`, {
      params: { centerID, courseID, orderBy, orderDir },
    })
      .then((response) => response.data)
      .catch((err) => console.error(err));
  },

  getTheoryStudents: ({
    centerID = null,
    doingTheory = null,
    hasMedical = null,
    level = null,
    startDate = null,
    endDate = null,
    orderBy = "INICIO CURSO",
    orderDir = "asc",
    courseID = null,
  } = {}) => {
    return CourseInstance.get(`/theory-students`, {
      params: {
        centerID,
        orderBy,
        orderDir,
        courseID,
        level,
        startDate,
        endDate,
        doingTheory,
        hasMedical,
      },
    })
      .then((response) => response.data)
      .catch((err) => console.error(err));
  },

  getOne: (vehicleID) => {
    return CourseInstance.get(`/${vehicleID}`)
      .then((response) => response.data)
      .catch((err) => console.error(err));
  },

  create: (data) => {
    return CourseInstance.post("", data)
      .then((response) => response.data)
      .catch((err) => console.error(err));
  },

  update: (data, ID) => {
    return CourseInstance.post(`/${ID}`, data)
      .then((response) => response.data)
      .catch((err) => console.error(err));
  },

  updateStudentObservations: (data, studentID) => {
    return CourseInstance.post(`/save-observation/${studentID}`, data)
      .then((response) => response.data)
      .catch((err) => console.error(err));
  },
  delete: (ID) => {
    return CourseInstance.delete(`/${ID}`)
      .then((response) => response.data)
      .catch((err) => console.error(err));
  },
};

const courses = CourseApi;
export default courses;
