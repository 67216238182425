import { Button, Fade, makeStyles } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import React, { useEffect, useRef, useState } from "react";
import Moment from "react-moment";
import { Redirect } from "react-router-dom";
import { archings as archingsAPI } from "../../../api";
import CustomTable from "../../../components/core/CustomTable";
// import { archings as archingsAPI } from "api";
import { DATE_FORMAT_COMPLETE, FADE_TRANSITION } from "../../../lib/constants";
import { exportData } from "../../../lib/exportData";
import CustomDatePicker from "../../core/CustomDatePicker";
import { LoadingIcon } from "../../core/LoadingIcon";

const NO_VALUE = "No valor";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
  },
  button: {
    marginRight: theme.spacing(1),
  },
  clearButton: {
    marginLeft: theme.spacing(2),
  },
  downLoadButton: {
    marginRight: theme.spacing(1),
    backgroundColor: "transparent",
    color: "rgb(56, 161, 105)",
    border: "1px solid rgb(56, 161, 105)",
    "&:hover": {
      marginRight: theme.spacing(1),
      border: "1px solid rgb(56, 161, 105)",
      backgroundColor: "rgb(56, 161, 105)",
      opacity: 1,
      color: "#FFFFFF",
    },
  },
}));

const columns = [
  {
    title: "Fecha",
    field: "date",
    render: (data) => (
      <Moment format={DATE_FORMAT_COMPLETE}>{data.date}</Moment>
    ),
    emptyValue: NO_VALUE,
    cardFormat: "DATE_COMPLETE",
  },
  {
    title: "TPV Manual",
    field: "tpv",
    type: "currency",
    currencySetting: { currencyCode: "EUR" },
    emptyValue: NO_VALUE,
    cardFormat: "CURRENCY",
  },
  {
    title: "TPV Online",
    field: "online",
    type: "currency",
    currencySetting: { currencyCode: "EUR" },
    emptyValue: NO_VALUE,
    cardFormat: "CURRENCY",
  },
  {
    title: "Transferencia",
    field: "wireTransfer",
    type: "currency",
    currencySetting: { currencyCode: "EUR" },
    emptyValue: NO_VALUE,
    cardFormat: "CURRENCY",
  },
  {
    title: "Efectivo",
    field: "cash",
    type: "currency",
    currencySetting: { currencyCode: "EUR" },
    emptyValue: NO_VALUE,
    cardFormat: "CURRENCY",
  },
  {
    title: "Retiradas",
    field: "withdrawals",
    type: "currency",
    currencySetting: { currencyCode: "EUR" },
    emptyValue: NO_VALUE,
    cardFormat: "CURRENCY",
  },
  {
    title: "Total Efectivo",
    field: "total",
    type: "currency",
    currencySetting: { currencyCode: "EUR" },
    emptyValue: NO_VALUE,
    cardFormat: "CURRENCY",
  },
];

const ArchingsList = ({ centerID }) => {
  const classes = useStyles();
  const tableRef = useRef(null);

  // const columns = useSearchParamsInColumns(tableColumns);
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);
  const [selectedStudentID, ] = useState("");
  const [page, setPage] = useState(1);
  const defaultPageSize = 25;
  const [totalResults, setTotalResults] = useState();
  const [, setErrorMessage] = useState("");

  const [criteria, setCriteria] = useState({});

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    fetchArchings();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [criteria, page, centerID]);

  const fetchArchings = () => {
    setIsLoading(true);
    let params = {
      centerID: centerID !== "1" ? centerID : null,
      perPage: 25,
      page: page,
      ...criteria,
    };
    archingsAPI.getAll(params).then((result) => {
      setData(result.results);
      setPage(result.page);
      setTotalResults(result.totalCount);
      setIsLoading(false);
    });
  };

  const handleCriteriaChange = (newCriteria) => {
    setPage(1);
    setCriteria({ ...criteria, ...newCriteria });
  };

  const handlePageChange = (newPage) => {
    window.scrollTo(0, 0);
    setPage(newPage);
  };

  const handleSubmitArching = () => {
    archingsAPI
      .create({ centerID })
      .then((response) => {
        fetchArchings();
      })
      .catch((x) => {
        setErrorMessage("Error adding permiso");
      });
  };

  const downloadExcel = () => {
    let params = {
      view: "export",
      centerID: centerID !== "1" ? centerID : null,
      perPage: 25,
      page: page,
      ...criteria,
    };
    archingsAPI.getAll(params).then((result) => {
      exportData(result.data, "Arqueos", result.centerName);
    });
  };

  if (selectedStudentID) {
    return <Redirect to={`/edit-student/${selectedStudentID}`}></Redirect>;
  }

  if (columns.length === 0) return "LOADING";

  return (
    <Fade in={true} timeout={FADE_TRANSITION}>
      <div className={classes.root}>
        <div style={{ margin: "40px 40px 5px 40px" }}>
          <Grid
            container
            xs={12}
            spacing={2}
            direction="row"
            justify="space-between"
          >
            <Grid
              item
              xs={12}
              sm={12}
              md="auto"
              lg="auto"
              direction="row"
              justify="flex-start"
            >
              <CustomDatePicker
                startDate={criteria.startDate}
                endDate={criteria.endDate}
                handleChangeCriteria={handleCriteriaChange}
              ></CustomDatePicker>
              {/* <Button className={classes.clearButton} onClick={deleteFilters}>
              Quitar filtros
            </Button> */}
            </Grid>
            {centerID === 1 ? null : (
              <Grid
                item
                xs={12}
                sm={12}
                md="auto"
                lg="auto"
                direction="row"
                justify="flex-end"
              >
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <Button
                      fullWidth
                      onClick={() => downloadExcel()}
                      className={classes.downLoadButton}
                      variant="outlined"
                    >
                      <span>Exportar a Excel</span>
                    </Button>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Button
                      fullWidth
                      className={classes.button}
                      onClick={() => {
                        handleSubmitArching();
                      }}
                    >
                      CREAR ARQUEO
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            )}
          </Grid>
        </div>

        <div style={{ padding: "20px 40px 0 40px" }}>
          {isLoading ? (
            <LoadingIcon></LoadingIcon>
          ) : (
            <CustomTable
              tableRef={tableRef}
              data={data}
              columns={columns}
              handlePageChange={handlePageChange}
              page={page}
              totalResults={totalResults}
              defaultPageSize={defaultPageSize}
              // handleClickEditOpen={handleStudentSelected}
            ></CustomTable>
          )}
        </div>
      </div>
    </Fade>
  );
};

export default ArchingsList;
