import React from "react";
import { Route } from "react-router-dom";
import CalendarStudent from "../Student/calendar/CalendarStudent";
import ClassesStudent from "../Student/classes/ClassesStudent";
import DgtView from "../Student/Dgt/DgtView";
import DigitalBook from "../Student/digitalBook/DigitalBook";
import Profile from "../Student/profile/Profile";
import FormTest from "../Student/tests/FormTest";
import Sessions from "../Student/tests/Sessions/Sessions";
import TestResult from "../Student/tests/TestResult";
import TestsList from "../Student/tests/TestsList";
import Historial from "../Student/tests/Historial";
import FormTestError from "../Student/tests/FormTestError";

const RoutesStudent = ({ user, reloadUser }) => {
  return (
    <>
      <Route
        exact={true}
        path="/"
        render={(props) => (
          <CalendarStudent {...props} user={user} reloadUser={reloadUser} />
        )}
      />
      <Route
        exact={true}
        path="/mi_perfil"
        render={(props) => (
          <Profile {...props} user={user} reloadUser={reloadUser} />
        )}
      />
      <Route
        exact={true}
        path="/mis_clases"
        render={(props) => (
          <ClassesStudent {...props} user={user} reloadUser={reloadUser} />
        )}
      />
      <Route
        exact={true}
        path="/mis_tests"
        render={(props) => (
          <TestsList {...props} user={user} reloadUser={reloadUser} />
        )}
      />
      <Route
        exact={true}
        path="/sesiones"
        render={(props) => (
          <Sessions
            {...props}
            user={user}
            reloadUser={reloadUser}
            type="reto"
          />
        )}
      />
      <Route
        exact={true}
        path="/test/:studentID/:testID"
        render={(props) => (
          <TestResult {...props} user={user} reloadUser={reloadUser} />
        )}
      />
      <Route
        exact={true}
        path="/nuevo_test/:level"
        render={(props) => (
          <FormTest
            {...props}
            user={user}
            reloadUser={reloadUser}
            type="normal"
          />
        )}
      />
      <Route
        exact={true}
        path="/test-aeol/:nTest"
        render={(props) => (
          <FormTest
            {...props}
            user={user}
            reloadUser={reloadUser}
            type="aeol"
          />
        )}
      />
      <Route
        exact={true}
        path="/tests-historial"
        render={(props) => (
          <Historial
            {...props}
            user={user}
            reloadUser={reloadUser}
          />
        )}
      />
      <Route
        exact={true}
        path="/test-sesion/:category"
        render={(props) => (
          <FormTest
            {...props}
            user={user}
            reloadUser={reloadUser}
            type="normal"
          />
        )}
      />
      <Route
        exact={true}
        path="/test-errores"
        render={(props) => (
          <FormTestError
            {...props}
            user={user}
            reloadUser={reloadUser}
            type="error"
          />
        )}
      />
      <Route
        exact={true}
        path="/test-challenge"
        render={(props) => (
          <FormTest
            {...props}
            user={user}
            reloadUser={reloadUser}
            type="reto"
          />
        )}
      />
      <Route
        exact={true}
        path="/libro_digital"
        render={(props) => (
          <DigitalBook
            {...props}
            user={user}
            reloadUser={reloadUser}
            type="reto"
          />
        )}
      />
      <Route
        exact={true}
        path="/dgt"
        render={(props) => (
          <DgtView {...props} user={user} reloadUser={reloadUser} type="reto" />
        )}
      />
    </>
  );
};

export default RoutesStudent;
