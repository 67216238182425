import React, { useEffect } from "react";
import { createContext, useContext, useState } from "react";
import { users as usersAPI } from "./../../api";

const AuthContext = createContext({ user: "", logOut: () => {} });

export const useAuth = () => {
  const context = useContext(AuthContext);
  return context;
};

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState("");
  const [checkingToken, setCheckingToken] = useState(true);

  useEffect(() => {
    const token = localStorage.getItem("user") || "";
    if (token !== "") {
      usersAPI
        .isLoged(token)
        .then((results) => {
          login(results);
          setCheckingToken(false);
        })
        .catch((err) => {
          console.log(err, "error");
          logOut();
        });
    } else {
      setCheckingToken(false);
    }
  }, []);

  const login = (userParam) => {
    localStorage.setItem("user", userParam.token);
    setUser(userParam.user);
  };

  const logOut = () => {
    localStorage.removeItem("user");
    setUser("");
  };

  const reloadUser = (updatedUser) => {
    setUser(updatedUser);
  };

  return (
    <AuthContext.Provider
      value={{ user, login, logOut, reloadUser, checkingToken }}
    >
      {children}
    </AuthContext.Provider>
  );
};
