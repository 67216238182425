import { Button, Grid, TextField, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React, { useEffect, useState } from "react";
import { Redirect, useParams } from "react-router-dom";
import { centers as centerApi } from "../../api";

const useStyles = makeStyles((theme) => ({
  gridContainer: {
    backgroundColor: "rgb(255,255,255)",
    margin: 40,
    borderTop: "5px solid rgb(159,46,37)",
    borderRadius: "0 0 10px 10px",
    padding: 20,
  },
  header: {
    backgroundColor: "rgb(248,250,252)",
    borderBottom: "2px solid rgb(227,232,239)",
    height: 70,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  headerText: {
    fontWeight: "bold",
    marginLeft: 40,
    color: theme.palette.primary.main,
  },
}));

const FormCenter = () => {
  const classes = useStyles();
  const { centerID } = useParams();
  const [values, setValues] = useState({});
  const [redirect, setRedirect] = useState(false);
  const [, setError] = useState(null);

  useEffect(() => {
    if (centerID) {
      centerApi
        .getOne(centerID)
        .then((response) => {
          setValues(response.results);
        })
        .catch((x) => {
          setError("Error trayendo centros");
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = (key, value) => {
    setValues({
      ...values,
      [key]: value,
    });
  };

  const handleSubmitCenter = () => {
    centerID
      ? centerApi
        .update(values, centerID)
        .then((response) => {
          setRedirect(true);
        })
        .catch((x) => {
          setError("Error adding permiso");
        })
      : centerApi
        .create(values)
        .then((response) => {
          setRedirect(true);
        })
        .catch((x) => {
          setError("Error adding permiso");
        });
  };

  if (redirect) {
    return <Redirect to="/centros"></Redirect>;
  }

  return (
    <div>
      <div className={classes.header}>
        <Grid container xs={12}>
          <Typography className={classes.headerText}>
            {centerID ? (
              <span>MODIFICAR CENTRO</span>
            ) : (
              <span>CREAR CENTRO</span>
            )}
          </Typography>
        </Grid>
      </div>

      <div className={classes.gridContainer}>
        <Grid container spacing={3} direction="row" justify="flex-start">
          <Grid item xs={12}>
            <Typography>
              <b>Datos de acceso</b>
            </Typography>
          </Grid>
          <Grid item xs="auto" sm="auto" md="auto" lg="auto">
            <Typography>
              Nombre<span style={{ color: "rgb(159,46,37)" }}>*</span>
            </Typography>
            <TextField
              variant="outlined"
              size="small"
              placeholder="Nombre"
              value={values.name}
              onChange={(e) => handleChange("name", e.target.value)}
            ></TextField>
          </Grid>
          <Grid item xs="auto" sm="auto" md="auto" lg="auto">
            <Typography>
              Email<span style={{ color: "rgb(159,46,37)" }}>*</span>
            </Typography>
            <TextField
              variant="outlined"
              size="small"
              placeholder="Email"
              value={values.email}
              onChange={(e) => handleChange("email", e.target.value)}
            ></TextField>
          </Grid>
          <Grid item xs="auto" sm="auto" md="auto" lg="auto">
            <Typography>Contraseña</Typography>
            <TextField
              variant="outlined"
              size="small"
              placeholder="********"
              type="password"
              value={values.password}
              onChange={(e) => handleChange("password", e.target.value)}
            ></TextField>
          </Grid>
          <Grid item xs={12}>
            <Typography>
              <b>Precio de las clases</b>
            </Typography>
          </Grid>
          <Grid item xs="auto" sm="auto" md="auto" lg="auto">
            <Typography>Precio mínimo</Typography>
            <TextField
              variant="outlined"
              size="small"
              placeholder="€ 0.0"
              value={values.minPrice}
              onChange={(e) => handleChange("minPrice", e.target.value)}
            ></TextField>
          </Grid>
          <Grid item xs="auto" sm="auto" md="auto" lg="auto">
            <Typography>Precio máximo</Typography>
            <TextField
              variant="outlined"
              size="small"
              placeholder="€ 0.0"
              value={values.maxPrice}
              onChange={(e) => handleChange("maxPrice", e.target.value)}
            ></TextField>
          </Grid>
          <Grid item xs={12}>
            <Typography>
              <b>Precio packs para cursos</b>
            </Typography>
          </Grid>
          <Grid item xs="auto" sm="auto" md="auto" lg="auto">
            <Typography>Precio pack 5 clases para Curso</Typography>
            <TextField
              variant="outlined"
              size="small"
              placeholder="€ 0.0"
              value={values.pack5price}
              onChange={(e) => handleChange("pack5price", e.target.value)}
            ></TextField>
          </Grid>
          <Grid item xs="auto" sm="auto" md="auto" lg="auto">
            <Typography>Precio pack 10 clases para Curso</Typography>
            <TextField
              variant="outlined"
              size="small"
              placeholder="€ 0.0"
              value={values.pack10price}
              onChange={(e) => handleChange("pack10price", e.target.value)}
            ></TextField>
          </Grid>
          <Grid item xs="auto" sm="auto" md="auto" lg="auto">
            <Typography>Precio pack 20 clases para Curso</Typography>
            <TextField
              variant="outlined"
              size="small"
              placeholder="€ 0.0"
              value={values.pack20price}
              onChange={(e) => handleChange("pack20price", e.target.value)}
            ></TextField>
          </Grid>
          <Grid item xs="auto" sm="auto" md="auto" lg="auto">
            <Typography>Precio pack 30 clases para Curso</Typography>
            <TextField
              variant="outlined"
              size="small"
              placeholder="€ 0.0"
              value={values.pack30price}
              onChange={(e) => handleChange("pack30price", e.target.value)}
            ></TextField>
          </Grid>
          <Grid item xs={12}>
            <Typography>
              <b>Datos del centro</b>
            </Typography>
          </Grid>
          <Grid item xs="auto" sm="auto" md="auto" lg="auto">
            <Typography>Denominación escuela</Typography>
            <TextField
              variant="outlined"
              size="small"
              placeholder="Denominación"
              value={values.denomination}
              onChange={(e) => handleChange("denomination", e.target.value)}
            ></TextField>
          </Grid>
          <Grid item xs="auto" sm="auto" md="auto" lg="auto">
            <Typography>CIF Empresa</Typography>
            <TextField
              variant="outlined"
              size="small"
              placeholder="CIF Empresa"
              value={values.cif}
              onChange={(e) => handleChange("cif", e.target.value)}
            ></TextField>
          </Grid>
          <Grid item xs={12} style={{ margin: "-15px" }}></Grid>
          <Grid item xs="auto" sm="auto" md="auto" lg="auto">
            <Typography>Población</Typography>
            <TextField
              variant="outlined"
              size="small"
              placeholder="Población"
              value={values.city}
              onChange={(e) => handleChange("city", e.target.value)}
            ></TextField>
          </Grid>
          <Grid item xs="auto" sm="auto" md="auto" lg="auto">
            <Typography>Dirección</Typography>
            <TextField
              variant="outlined"
              size="small"
              placeholder="Dirección"
              value={values.adress}
              onChange={(e) => handleChange("adress", e.target.value)}
            ></TextField>
          </Grid>
          <Grid item xs="auto" sm="auto" md="auto" lg="auto">
            <Typography>Código postal</Typography>
            <TextField
              variant="outlined"
              size="small"
              placeholder="Código postal"
              value={values.postalCode}
              onChange={(e) => handleChange("postalCode", e.target.value)}
            ></TextField>
          </Grid>
          <Grid item xs={12} style={{ margin: "-15px" }}></Grid>
          <Grid item xs="auto" sm="auto" md="auto" lg="auto">
            <Typography>Director</Typography>
            <TextField
              variant="outlined"
              size="small"
              placeholder="Director"
              value={values.master}
              onChange={(e) => handleChange("master", e.target.value)}
            ></TextField>
          </Grid>
          <Grid item xs="auto" sm="auto" md="auto" lg="auto">
            <Typography>DNI Director</Typography>
            <TextField
              variant="outlined"
              size="small"
              placeholder="DNI Director"
              value={values.masterDNI}
              onChange={(e) => handleChange("masterDNI", e.target.value)}
            ></TextField>
          </Grid>
          <Grid item xs={12} style={{ margin: "-15px" }}></Grid>
          <Grid item xs="auto" sm="auto" md="auto" lg="auto">
            <Typography>Nº Provincial</Typography>
            <TextField
              variant="outlined"
              size="small"
              placeholder="Nº Provincial"
              value={values.provinceNumber}
              onChange={(e) => handleChange("provinceNumber", e.target.value)}
            ></TextField>
          </Grid>
          <Grid item xs="auto" sm="auto" md="auto" lg="auto">
            <Typography>Nº Sección</Typography>
            <TextField
              variant="outlined"
              size="small"
              placeholder="0"
              value={values.sectionNumber}
              onChange={(e) => handleChange("sectionNumber", e.target.value)}
            ></TextField>
          </Grid>
          <Grid item xs="auto" sm="auto" md="auto" lg="auto">
            <Typography>Nº Dígito de control</Typography>
            <TextField
              variant="outlined"
              size="small"
              placeholder="0"
              value={values.controlDigit}
              onChange={(e) => handleChange("controlDigit", e.target.value)}
            ></TextField>
          </Grid>

          <Grid item xs={12}>
            <Button fullWidth onClick={handleSubmitCenter}>
              {centerID ? <span>MODIFICAR</span> : <span>CREAR</span>}
            </Button>
          </Grid>
        </Grid>
      </div>
      <div style={{ height: "20px" }}></div>
    </div>
  );
};

export default FormCenter;
