import {
  Button,
  Grid,
  Hidden,
  makeStyles,
  Typography,
} from "@material-ui/core";
import React from "react";
import autiusBlanco from "../../assets/img/autius-blanco.svg";
import { AutiusSmallIcon } from "../../assets/img/AutiusSmallIcon";
import { LogoutIcon } from "../../assets/img/LogoutIcon";
import useWindowSize from "../../hooks/windowSize";
import CenterRoutes from "../Navigation/CenterRoutes";
import AdminRoutes from "./AdminRoutes";
import StudentRoutes from "./StudentRoutes";
import SupervisorRoutes from "./SupervisorRoutes";
import TeacherRoutes from "./TeacherRoutes";

const useStyles = makeStyles((theme) => ({
  container: {
    // maxWidth: "14rem",
    [theme.breakpoints.up("xl")]: {
      maxWidth: "14rem",
      flexShrink: 0,
    },
    [theme.breakpoints.down("md")]: {
      maxWidth: "5rem",
      flexShrink: 0,
    },
  },
}));

const Nav = ({ user, logOut, pendingStudents, fetchPendingStudents }) => {
  const classes = useStyles;

  const size = useWindowSize();
  return (
    <Grid
      container
      className={classes.container}
      style={{
        position: "fixed",
        height: "100vh",
        maxWidth: size.width > 1279 ? "14rem" : "5rem",
        backgroundColor: "#ad201c",
        overflowX: "auto",
      }}
    >
      <Grid item>
        <Hidden lgUp>
          <Grid
            item
            container
            direction="row"
            justify="center"
            alignItems="center"
          >
            <div
              style={{
                padding: "20px 0 20px 0",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <AutiusSmallIcon fill="white"></AutiusSmallIcon>
            </div>
          </Grid>
        </Hidden>
        <Hidden mdDown>
          <Grid container direction="row" justify="center" alignItems="center">
            <div
              style={{
                padding: "10px 0 20px 0",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img
                style={{ width: "170px" }}
                src={autiusBlanco}
                alt="autius blanco"
              />{" "}
            </div>
          </Grid>
        </Hidden>
        {user && user.role === "ROLE_ADMIN" ? (
          <AdminRoutes user={user}></AdminRoutes>
        ) : null}
        {user && user.role === "ROLE_CENTER" ? (
          <CenterRoutes
            user={user}
            pendingStudents={pendingStudents}
            fetchPendingStudents={fetchPendingStudents}
          ></CenterRoutes>
        ) : null}
        {user && user.role === "ROLE_STUDENT" ? (
          <StudentRoutes user={user}></StudentRoutes>
        ) : null}
        {user && user.role === "ROLE_TEACHER" ? (
          <TeacherRoutes user={user}></TeacherRoutes>
        ) : null}
        {user && user.role === "ROLE_SUPERVISOR" ? (
          <SupervisorRoutes user={user}></SupervisorRoutes>
        ) : null}
      </Grid>

      <Grid
        item
        container
        style={{ display: "column", justifyContent: "space-between" }}
        // style={{ position: "absolute", bottom: "20px" }}
      >
        <Grid item xs={12}></Grid>
        <Grid item xs={12}>
          <Button style={{ height: 40 }} onClick={() => logOut()}>
            <LogoutIcon
              // style={{ paddingRight: "15px" }}
              fill="white"
            ></LogoutIcon>
            <span style={{ margin: "0 5px" }}></span>
            <Hidden mdDown>
              <Typography>Cerrar sesión</Typography>
            </Hidden>
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Nav;
