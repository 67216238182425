import axios from "axios";
import {API_NAME} from "./base";

const teacherInstance = axios.create({
  baseURL: `${API_NAME}api/teachers`,
  withCredentials: true,
});

teacherInstance.interceptors.request.use(
  (config) => {
    config.headers.Authorization = `Bearer ${localStorage.getItem("user")}`;
    return config;
  },
  (error) => Promise.reject(error)
);

const teacherInstanceApi = {
  getAll: ({
    centerID = null,
    orderBy = "name",
    orderDir = "asc",
    page = 1,
    perPage = 10,
  } = {}) => {
    return teacherInstance
      .get(``, {
        params: { centerID, orderBy, orderDir, page, perPage },
      })
      .then((response) => response.data)
      .catch((err) => err);
  },
  getClassById: ({ classID = null } = {}) => {
    return teacherInstance
      .get(`/classID`, {
        params: { classID },
      })
      .then((response) => response.data)
      .catch((err) => console.error(err));
  },
  getExportClasses: ({
    view = "export",
    teacherID = null,
    startDate = null,
    endDate = null,
  } = {}) => {
    return teacherInstance
      .get(`/get-export-classes`, {
        params: {
          view,
          teacherID,
          startDate,
          endDate,
        },
      })
      .then((response) => response.data)
      .catch((err) => console.error(err));
  },
  getOne: (teacherID) => {
    return teacherInstance
      .get(`/get-one/${teacherID}`)
      .then((response) => response.data)
      .catch((err) => console.error(err));
  },
  create: (data) => {
    return teacherInstance
      .post("", data)
      .then((response) => response.data)
      .catch((err) => console.error(err));
  },
  update: (data, ID) => {
    return teacherInstance
      .post(`/${ID}`, data)
      .then((response) => response.data)
      .catch((err) => console.error(err));
  },
};

const teachers = teacherInstanceApi;
export default teachers;
