import { Button, Fade, makeStyles, Typography } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import CloseIcon from "@material-ui/icons/Close";
import DoneIcon from "@material-ui/icons/Done";
import React, { useEffect, useRef, useState } from "react";
import { Link, Redirect } from "react-router-dom";
import { codes as codesAPI } from "../../../api";
import { FADE_TRANSITION } from "../../../lib/constants";
import CustomTable from "../../core/CustomTable";
import { LoadingIcon } from "../../core/LoadingIcon";
const NO_VALUE = "No valor";

const useStyles = makeStyles((theme) => ({
  rootGrid: {
    maxWidth: "100%",
    margin: "auto",
  },
  root: {
    flexGrow: 1,
    width: "100%",
    "& .MuiTextField-root": {
      minWidth: "20ch",
    },
  },
  header: {
    backgroundColor: "rgb(248,250,252)",
    borderBottom: "2px solid rgb(227,232,239)",
    height: 70,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  headerText: {
    fontWeight: "bold",
    marginLeft: 40,
    color: theme.palette.primary.main,
  },
  headerButtons: {
    marginRight: 40,
  },
  searchContainer: {
    margin: 20,
    border: "1px solid",
    borderColor: theme.palette.primary.dark,
    borderRadius: 5,
    padding: 12,
  },
  tabPanel: {
    backgroundColor: theme.palette.primary.main,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.primary.dark,
  },
  marginLeft: {
    marginLeft: "10px",
  },
  formControl: {
    width: "100%",
  },
  alignRight: {
    textAlign: "right",
  },
  alignLeft: {
    textAlign: "left",
  },
  container: {
    width: "100%",
    marginLeft: 12,
    marginRight: 12,
  },
  savedSearchLink: {
    marginLeft: "auto",
    marginRight: 0,
  },
}));

const columns = [
  {
    title: "CODIGO",
    field: "code",
    emptyValue: NO_VALUE,
    cardFormat: "STRING",
    cellStyle: { textAlign: "center" },
    headerStyle: { textAlign: "center" },
  },
  {
    title: "NOMBRE",
    field: "name",
    emptyValue: NO_VALUE,
    cardFormat: "STRING",
    cellStyle: { textAlign: "center" },
    headerStyle: { textAlign: "center" },
  },
  {
    title: "DESCRIPCION",
    field: "description",
    emptyValue: NO_VALUE,
    render: (data) => <Typography>{data.description}</Typography>,
    cardFormat: "STRING",
    cellStyle: { textAlign: "center" },
    headerStyle: { textAlign: "center" },
  },
  {
    title: "DESCUENTO",
    field: "discount",
    emptyValue: NO_VALUE,
    cardFormat: "STRING",
    render: (data) => (
      <Typography>
        {data.type === "percentage"
          ? `${data.discount}%`
          : `-${data.discount}€`}
      </Typography>
    ),
    cellStyle: { textAlign: "center" },
    headerStyle: { textAlign: "center" },
  },
  {
    title: "VECES USADO (Desde 06/10/2021)",
    field: "sum",
    emptyValue: NO_VALUE,
    cardFormat: "STRING",
    cellStyle: { textAlign: "center" },
    headerStyle: { textAlign: "center" },
  },
  {
    title: "ACTIVO",
    field: "isActive",
    emptyValue: NO_VALUE,
    cardFormat: "STRING",
    render: (data) => (
      <>
        {data.isActive === 0 ? (
          <Grid>
            <CloseIcon
              style={{
                padding: 3,
                fontSize: "20px",
                paddding: 5,
                color: "#9b2c2c",
                backgroundColor: "rgba(254, 215, 215)",
                borderRadius: "50%",
              }}
            ></CloseIcon>
          </Grid>
        ) : (
          <Grid>
            <DoneIcon
              style={{
                padding: 3,
                fontSize: "20px",
                color: "rgb(39, 103, 73)",
                backgroundColor: "rgb(154, 230, 180)",
                borderRadius: "50%",
              }}
            ></DoneIcon>
          </Grid>
        )}
      </>
    ),
    cellStyle: { textAlign: "center" },
    headerStyle: { textAlign: "center" },
  },
];

const CodesList = ({ centerID, isAdmin }) => {
  const classes = useStyles();
  const tableRef = useRef(null);

  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);
  const [selectedCodeID, setSelectedCodeID] = useState("");
  const [page, setPage] = useState(1);
  const defaultPageSize = 10;
  const [totalResults, setTotalResults] = useState();
  const [sorting, ] = useState({ orderBy: "name", orderDir: "asc" });

  const [criteria, ] = useState({ type: "Todos" });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    setIsLoading(true);
    let params = {
      centerID: centerID !== "1" ? centerID : null,
      perPage: defaultPageSize,
      page: page,
      ...criteria,
    };

    codesAPI.getAll(params).then((result) => {
      setData(result.results);
      setPage(result.page);
      setTotalResults(result.totalCount);
      setIsLoading(false);
    });
  }, [criteria, page, sorting, centerID]);

  const handlePageChange = (newPage) => {
    window.scrollTo(0, 0);
    setPage(newPage);
  };
  const handleStudentSelected = (id) => {
    setSelectedCodeID(id);
  };

  if (selectedCodeID) {
    return <Redirect to={`/edit-code/${selectedCodeID}`}></Redirect>;
  }

  if (columns.length === 0) return "LOADING";

  return (
    <Fade in={true} timeout={FADE_TRANSITION}>
      <div className={classes.root}>
        <div style={{ margin: "40px 40px 5px 40px" }}>
          <Grid container spacing={2} direction="row" justify="flex-start">
            {/* <Grid item xs={12} sm={6} md="auto" lg="auto">
              <TextField
                fullWidth
                style={{ backgroundColor: "white" }}
                variant="outlined"
                size="small"
                placeholder="Buscar alumnos..."
                value={criteria.q}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
                onChange={(e) => {
                  handleCriteriaChange({
                    q: e.target.value.toUpperCase() || "",
                  });
                }}
              ></TextField>
            </Grid>
            <Grid item xs={12} sm={6} md="auto" lg="auto">
              <TextField
                fullWidth
                style={{ backgroundColor: "white" }}
                variant="outlined"
                size="small"
                id="Type"
                select
                value={criteria.type}
                onChange={(e) => handleCriteriaChange({ type: e.target.value })}
              >
                {studentTypeOptions.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} sm={12} md="auto" lg="auto">
              <CustomDatePicker
                startDate={criteria.startDate}
                endDate={criteria.endDate}
                handleChangeCriteria={handleCriteriaChange}
              ></CustomDatePicker>
            </Grid> */}
            {centerID === 1 ? null : (
              <Grid
                item
                className={classes.headerButtons}
                direction="row"
                justify="flex-end"
              >
                <Link to={`/add-code`}>
                  <Button>CREAR CÓDIGO</Button>
                </Link>
              </Grid>
            )}
            {/* <Grid item xs={12} sm="auto" md="auto" lg="auto">
            <Button fullWidth onClick={deleteFilters}>
              Quitar filtros
            </Button>
          </Grid> */}
          </Grid>
        </div>
        <div style={{ padding: "20px 40px 0 40px" }}>
          {isLoading ? (
            <LoadingIcon></LoadingIcon>
          ) : (
            <CustomTable
              tableRef={tableRef}
              data={data}
              columns={columns}
              handlePageChange={handlePageChange}
              page={page}
              totalResults={totalResults}
              defaultPageSize={defaultPageSize}
              handleClickEditOpen={handleStudentSelected}
            ></CustomTable>
          )}
        </div>
      </div>
    </Fade>
  );
};

export default CodesList;
