import { Fade } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import { FADE_TRANSITION } from "../../../lib/constants";
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    minHeight: "100vh",
    "& .MuiTextField-root": {
      minWidth: "20ch",
    },
  },
  gridContainer: {
    backgroundColor: "rgb(255,255,255)",
    // margin: "20px",
    borderTop: "5px solid rgb(159,46,37)",
    borderRadius: "0 0 10px 10px",
    padding: 20,
  },
  header: {
    backgroundColor: "rgb(248,250,252)",
    borderBottom: "2px solid rgb(227,232,239)",
    height: 70,
  },
  headerText: {
    fontWeight: "bold",
    marginLeft: 40,
    color: theme.palette.primary.main,
  },
  headerButtons: {
    marginRight: 40,
  },
  textArea: {
    minHeight: 150,
    minWidth: "99%",
    maxWidth: "99%",
    width: "99",
    borderRadius: 3,
    padding: 8,
  },
}));

const DigitalBook = () => {
  const classes = useStyles();

  return (
    <Fade in={true} timeout={FADE_TRANSITION}>
      <div className={classes.root}>
        <iframe
          style={{ position: "relative", height: "100vh" }}
          width="100%"
          height="100vh"
          //   scrolling="no"
          frameBorder="0"
          title="libro-digital"
          id="libro-digital"
          src="./libro_digital/index.html"
        ></iframe>
      </div>
    </Fade>
  );
};

export default DigitalBook;
