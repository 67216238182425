import {
  Badge,
  Grid,
  Hidden,
  ListItem,
  ListItemText,
  Tooltip,
  withStyles,
} from "@material-ui/core";
import AccountBalanceIcon from "@material-ui/icons/AccountBalance";
import DescriptionIcon from "@material-ui/icons/Description";
import DriveEtaIcon from "@material-ui/icons/DriveEta";
import EuroSymbolIcon from "@material-ui/icons/EuroSymbol";
import EventIcon from "@material-ui/icons/Event";
import PeopleAltIcon from "@material-ui/icons/PeopleAlt";
import PersonIcon from "@material-ui/icons/Person";
import RoomIcon from "@material-ui/icons/Room";
import NotificationsActiveIcon from "@material-ui/icons/NotificationsActive";
import AssignmentIndIcon from "@material-ui/icons/AssignmentInd";
import React, { useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import "./Animation.css";

const StyledListItem = withStyles({
  root: {
    "&.Mui-selected": {
      backgroundColor: "darkRed",
    },
  },
})(ListItem);

// Quiero probar a hacer un map de options para pintar las rutas del nav(problema con la clase del icon)
// Funciona

const CenterRoutes = ({
  user,
  isMobil = false,
  setExpanded = () => {},
  pendingStudents = 0,
  fetchPendingStudents,
}) => {
  const OPTIONS = [
    {
      title: "Alumnos",
      index: 0,
      query: "",
      icon: <PersonIcon fill="white"></PersonIcon>,
    },
    // TODO, esconder is es solo app
    {
      title: "Pendientes",
      index: 1,
      query: "pendientes",
      icon: (
        <Badge
          badgeContent={pendingStudents}
          className={pendingStudents > 0 ? "shake" : null}
        >
          <NotificationsActiveIcon
            className={pendingStudents > 0 ? "element-to-shake" : null}
            fill="white"
          ></NotificationsActiveIcon>
        </Badge>
      ),
    },
    // TODO, esconder si es solo web
    ...user.hasApp === 1 ? [{
      title: "Solicitud Examen",
      index: 2,
      query: "solicitud-examen",
      icon: <AssignmentIndIcon fill="white"></AssignmentIndIcon>,
    }] : [],
    {
      title: "Gestor Cursos",
      index: 3,
      query: "cursos",
      icon: <AssignmentIndIcon fill="white"></AssignmentIndIcon>,
    },
    {
      title: "Profesores",
      index: 4,
      query: "teachers",
      icon: <PeopleAltIcon fill="white" />,
    },
    {
      title: "Vehículos",
      index: 5,
      query: "vehiculos",
      icon: <DriveEtaIcon fill="white"></DriveEtaIcon>,
    },
    {
      title: "Calendario",
      index: 6,
      query: "calendario",
      icon: <EventIcon fill="white"></EventIcon>,
    },
    {
      title: "Exámenes",
      index: 7,
      query: "examenes",
      icon: <DescriptionIcon fill="white"></DescriptionIcon>,
    },
    {
      title: "Tarifas",
      index: 8,
      query: "tarifas",
      icon: <EuroSymbolIcon fill="white"></EuroSymbolIcon>,
    },
    {
      title: "Arqueos",
      index: 9,
      query: "arqueos",
      icon: <AccountBalanceIcon fill="white"></AccountBalanceIcon>,
    },
    {
      title: "P. Salida",
      index: 10,
      query: "salidas",
      icon: <RoomIcon fill="white"></RoomIcon>,
    },
  ];

  // PINTAR EL APARTADO DONDE ESTAS ACTUALMENTE AL ACCEDER A LA PAGINA
  let location = useLocation();

  let initialValue;
  OPTIONS.forEach((option) => {
    if (location.pathname.includes(option.query)) {
      initialValue = option.index;
    } else initialValue = 0;
  });

  const [selectedIndex, setSelectedIndex] = useState(initialValue);

  const handleListItemClick = (event, index) => {
    setSelectedIndex(index);
    fetchPendingStudents(user.id);
    setExpanded(false);
  };

  return (
    <>
      {OPTIONS.map((row) => {
        return (
          <Grid key={row.index} item xs={12}>
            <Tooltip title={row.title} placement="top">
              <StyledListItem
                button
                onClick={(event) => handleListItemClick(event, row.index)}
                component={NavLink}
                selected={selectedIndex === row.index}
                style={{
                  color: "white",
                  display: "flex",
                  justifyContent: "flex-start",
                  borderRadius: "6px",
                }}
                to={row.index === 0 ? `/` : `/${row.query}`}
              >
                {isMobil ? (
                  <ListItemText primary={row.title} />
                ) : (
                  <>
                    {row.icon}
                    <span style={{ margin: "0 5px" }}></span>
                    <Hidden mdDown>
                      <ListItemText primary={row.title} />
                    </Hidden>
                  </>
                )}
              </StyledListItem>
            </Tooltip>
          </Grid>
        );
      })}
    </>
  );
};

export default CenterRoutes;
