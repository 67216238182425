import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import SaveIcon from "@material-ui/icons/Save";
import CustomDatePicker from "../../core/CustomDatePicker";

const useStyles = makeStyles((theme) => ({
  marginRight: {
    marginRight: theme.spacing(1),
  },
  labelRoot: {
    color: "white",
    fontWeight: "bold",
    "&$labelFocused": {
      color: "white",
    },
  },
  acceptButton: {
    backgroundColor: "rgb(18,151,91)",
  },
  actions: {
    backgroundColor: "rgb(248,250,252)",
  },
}));

const ModalExportActivity = (props) => {
  const { open, setOpen, downloadExcel } = props;
  const classes = useStyles();

  const [values, setValues] = useState({});
  const [errorMessage, ] = useState("");

  const handleChange = (...newCriteria) => {
    setValues({
      ...values,
      ...newCriteria,
    });
  };
  const handleSubmit = () => {
    downloadExcel(values[0]);
    setOpen(false);
  };

  const handleCancelClose = () => {
    setValues({});
    setOpen(false);
  };

  return (
    <Dialog open={open} onClose={handleCancelClose}>
      <DialogTitle id="form-dialog-title">
        <span>SELECCIONA RANGO DE FECHAS</span>
      </DialogTitle>
      <Divider style={{ margin: "0 20px" }}></Divider>
      <DialogContent style={{ overflowY: "hidden", margin: "20px 0" }}>
        <Grid
          item
          xs={12}
          sm={12}
          md="auto"
          lg="auto"
          style={{ minHeight: "380px" }}
        >
          <CustomDatePicker
            startDate={values.startDate}
            endDate={values.endDate}
            handleChangeCriteria={handleChange}
          ></CustomDatePicker>
        </Grid>
      </DialogContent>
      <DialogActions className={classes.actions}>
        <p>{errorMessage}</p>
        <Button size="large" variant="outlined" onClick={handleCancelClose}>
          <CloseIcon className={classes.marginRight} />
          Cancelar
        </Button>
        <Button
          className={classes.acceptButton}
          size="large"
          variant="outlined"
          onClick={handleSubmit}
        >
          <SaveIcon className={classes.marginRight} /> <span>Descargar</span>
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ModalExportActivity;
