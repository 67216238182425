import { Button, Grid, TextField, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React, { useEffect, useRef, useState } from "react";
import { permissions as permissionApi } from "../../api";
import { NO_VALUE } from "../../lib/constants";
import CustomTable from "../core/CustomTable";

const useStyles = makeStyles((theme) => ({
  rootGrid: {
    maxWidth: "190px",
    margin: "auto",
  },
  root: {
    flexGrow: 1,
    width: "100%",
    minHeight: "100vh",
  },
  header: {
    backgroundColor: "rgb(248,250,252)",
    borderBottom: "2px solid rgb(227,232,239)",
    height: 70,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  headerText: {
    fontWeight: "bold",
    marginLeft: 40,
    color: theme.palette.primary.main,
  },
  headerButtons: {
    marginRight: 40,
  },
  searchContainer: {
    margin: 20,
    border: "1px solid",
    borderColor: theme.palette.primary.dark,
    borderRadius: 5,
    padding: 12,
  },
  tabPanel: {
    backgroundColor: theme.palette.primary.main,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.primary.dark,
  },
  formControl: {
    width: "100%",
  },
  alignRight: {
    textAlign: "right",
  },
  alignLeft: {
    textAlign: "left",
  },
  container: {
    width: "100%",
    marginLeft: 12,
    marginRight: 12,
  },
  savedSearchLink: {
    marginLeft: "auto",
    marginRight: 0,
  },
}));

const columns = [{ title: "Permisos", field: "name", emptyValue: NO_VALUE }];

const Permissions = () => {
  const classes = useStyles();
  const [permission, setPermission] = useState("");
  const [permissions, setPermissions] = useState([]);
  const tableRef = useRef(null);

  useEffect(() => {
    getPermissions();
  }, []);

  const getPermissions = () => {
    permissionApi
      .getAll()
      .then((response) => {
        setPermissions(response.results);
      })
      .catch((x) => {
        console.log("Error trayendo permisos");
      });
  };

  const handleChangePermission = (e) => {
    setPermission(e.target.value);
  };

  const handleSubmitPermission = () => {
    permissionApi
      .create(permission)
      .then((response) => {
        setPermission("");
        getPermissions();
      })
      .catch((x) => {
        console.log("Error adding permiso");
      });
  };
  const handleDelete = (ID) => {
    permissionApi
      .delete(ID)
      .then((response) => {
        setPermission("");
        getPermissions();
      })
      .catch((x) => {
        console.log("Error adding permiso");
      });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  if (columns.length === 0) return "LOADING";

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <Grid container xs={12}>
          <Typography className={classes.headerText}>
            <span>PERMISOS</span>
          </Typography>
        </Grid>
        <Grid
          container
          spacing={3}
          xs={12}
          className={classes.headerButtons}
          direction="row"
          justify="flex-end"
        >
          <TextField
            style={{ backgroundColor: "white" }}
            variant="outlined"
            size="small"
            value={permission}
            onChange={(e) => handleChangePermission(e)}
          ></TextField>
          <Button onClick={handleSubmitPermission}>Añadir</Button>
        </Grid>
      </div>

      <div
        style={{
          maxWidth: "600px",
          padding: "20px 40px 0 40px",
          margin: "0 auto",
          position: "relative",
        }}
      >
        <CustomTable
          tableRef={tableRef}
          data={permissions}
          columns={columns}
          hasPagination={false}
          hasActions={true}
          handleDelete={handleDelete}
        ></CustomTable>
      </div>
    </div>
  );
};

export default Permissions;
