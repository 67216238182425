import {
  Button,
  Card,
  CardActions,
  CardContent,
  Grid,
  makeStyles,
  TextField,
  Typography,
} from "@material-ui/core";
import React, { useState } from "react";
import { password as passwordApi } from "../../api";
import autiusRojo from "../../assets/img/autius-rojo.svg";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
    backgroundColor: "rgba(159,46,37)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  card: {
    padding: "10px 30px 30px 30px",
    borderRadius: "15px",
    maxWidth: "400px",
  },
}));

const PasswordRecover = () => {
  const classes = useStyles();
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");

  const handleForSubmit = (event) => {
    event.preventDefault();
    
    passwordApi
      .sendEmail({email})
      .then((response) => {
        setError("Si tu usuario está en nuestra base de datos, te mandaremos un email con las instrucciones para recuperar tu cuenta");
      })
      .catch((err) => {
        setError("Usuario no encontrado");
      });
  };

  return (
    <div className={classes.root}>
      <Card className={classes.card}>
        <form onSubmit={(e) => handleForSubmit(e)}>
          <CardContent>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <div
                  style={{
                    paddingTop: "20px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <img
                    style={{ width: "300px" }}
                    src={autiusRojo}
                    alt="React Logo"
                  />{" "}
                </div>
              </Grid>
              <Grid item xs={12} >
                <p style={{textAlign: 'center'}}>Te mandaremos un email con los pasos que tienes que seguir para recuperar tu contraseña </p> 
              </Grid>
              <Grid item xs={12}>
                <Typography>Email</Typography>
                <TextField
                  required
                  fullWidth
                  variant="outlined"
                  size="small"
                  // type="email"
                  placeholder="email"
                  value={email}
                  onChange={(newValue) => setEmail(newValue.target.value)}
                ></TextField>
              </Grid>
            </Grid>
          </CardContent>
          <CardActions>
            <Grid container>
              <Grid item xs={12}>
                <Button fullWidth type="submit">
                  MANDAR
                </Button>
              </Grid>
              <Grid item xs={12}>
                <p>{error}</p>
              </Grid>
              <Grid item xs={12}>
                <p>¿Ya eres estudiante? <Link style={{color: 'rgba(159,46,37)', fontWeight: 'bold'}} to={'/'}>Login</Link></p> 
              </Grid>
            </Grid>
          </CardActions>
        </form>
      </Card>
    </div>
  );
};

export default PasswordRecover;
