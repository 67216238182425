import { Button, Fade, Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import {  FADE_TRANSITION } from "../../../lib/constants";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    minHeight: "100vh",
    "& .MuiTextField-root": {
      minWidth: "20ch",
    },
  },
  gridContainer: {
    backgroundColor: "rgb(255,255,255)",
    // margin: "20px",
    borderTop: "5px solid rgb(159,46,37)",
    borderRadius: "0 0 10px 10px",
    padding: 20,
  },
  header: {
    backgroundColor: "rgb(248,250,252)",
    borderBottom: "2px solid rgb(227,232,239)",
    height: 70,
  },
  headerText: {
    fontWeight: "bold",
    marginLeft: 40,
    color: theme.palette.primary.main,
  },
  headerButtons: {
    marginRight: 40,
  },
  textArea: {
    minHeight: 150,
    minWidth: "99%",
    maxWidth: "99%",
    width: "99",
    borderRadius: 3,
    padding: 8,
  },
}));

const DgtView = ({ user }) => {
  const classes = useStyles();

  return (
    <Fade in={true} timeout={FADE_TRANSITION}>
      <div className={classes.root}>
        <Grid
          container
          className={classes.header}
          direction="row"
          justify="space-between"
          alignItems="center"
        >
          <Grid item>
            <Typography className={classes.headerText}>
              PROVISIONAL DGT
            </Typography>
          </Grid>
          <Grid item className={classes.headerButtons}></Grid>
        </Grid>
        <Grid container>
          <Grid
            style={{
              backgroundColor: "white",
              margin: "20px 20px",
              borderRadius: "10px",
            }}
            container
            item
            xs={12}
            md={9}
            lg={8}
          >
            <Grid container item xl={12} style={{ padding: 20 }} spacing={2}>
              <Grid item xs={12}>
                <Typography>
                  Desde la siguiente página oficial de la DGT podrás:
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography>
                  * Consultar la nota de tu examen teórico y el nº de errores
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography>
                  * Consultar la nota de tu examen práctico y las faltas
                  cometidas
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography>
                  * Descargar la autorización provisional para conducir (el
                  carnet definitivo te llegará en dos o tres semanas tras el
                  aprobado)
                </Typography>
              </Grid>
              <Grid style={{ margin: 10 }}>
                <a
                  href="https://sedeclave.dgt.gob.es/WEB_NOTP_CONSULTA/consultaNota.faces"
                  target="a_blank"
                >
                  <Button>ACCEDER</Button>
                </a>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </Fade>
  );
};

export default DgtView;
