import axios from "axios";
import {API_NAME} from "./base";

const ClassInstance = axios.create({
  baseURL: `${API_NAME}api/classes`,
  withCredentials: true,
});

ClassInstance.interceptors.request.use(
  (config) => {
    config.headers.Authorization = `Bearer ${localStorage.getItem("user")}`;
    return config;
  },
  (error) => Promise.reject(error)
);

const ClassApi = {
  createOne: (data) => {
    return ClassInstance.post("/create-one", data)
      .then((response) => response.data)
      .catch((err) => console.error(err));
  },

  getOnePracticalClass: (classID) => {
    return ClassInstance.get(`/${classID}`)
      .then((response) => response.data)
      .catch((err) => console.error(err));
  },

  getPracticalClassesLevel: ({ studentID = null } = {}) => {
    return ClassInstance.get(`/get-practical-level`, {
      params: { studentID },
    })
      .then((response) => response.data)
      .catch((err) => console.error(err));
  },

  updatePracticalClass: (data, ID) => {
    return ClassInstance.post(`/edit-practical-class/${ID}`, data)
      .then((response) => response.data)
      .catch((err) => console.error(err));
  },

  reserveClass: (data, ID) => {
    return ClassInstance.post(`/reserve-class/${ID}`)
      .then((response) => response.data)
      .catch((err) => console.error(err));
  },

  createRangeClasses: (data) => {
    return ClassInstance.post("/create-multiple", data)
      .then((response) => response.data)
      .catch((err) => console.error(err));
  },

  update: (data, ID) => {
    return ClassInstance.post(`/edit/${ID}`, data)
      .then((response) => response.data)
      .catch((err) => console.error(err));
  },

  updateByStudentBag: (data, ID) => {
    return ClassInstance.post(`/buy-class-bag/${ID}`, data)
      .then((response) => response.data)
      .catch((err) => console.error(err));
  },

  cancel: (data, ID) => {
    return ClassInstance.post(`/class-cancel/${ID}`, data)
      .then((response) => response.data)
      .catch((err) => console.error(err));
  },
};

const classes = ClassApi;
export default classes;
