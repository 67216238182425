import {
  AppBar,
  Grid,
  makeStyles,
  Tab,
  Tabs,
  Tooltip,
  Typography,
} from "@material-ui/core";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "./calendar.css";

const useStyles = makeStyles((theme) => ({
  tab: {
    minWidth: 100, // a number of your choice
    width: 100,
    fontSize: "25px", // a number of your choice
  },
  controls: {
    height: 50,
    alignItems: "center",
    border: "1px solid #DDD",
  },
  hover: {
    cursor: "pointer",
  },
}));

function openTabPanel(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  };
}

const tabs = [
  { value: "month", label: "Mes", index: 0 },
  { value: "week", label: "Semana", index: 1 },
  { value: "day", label: "Día", index: 2 },
];

require("moment/locale/es.js");
const localizer = momentLocalizer(moment);

function CustomEvent({ event }, view) {
  let tooltipTitle;
  if (view === "teacher" && event.studentName) {
    tooltipTitle = (
      <>
        <Typography>
          ALUMNO: {`${event.studentName} ${event.studentLastName}`}
        </Typography>
        <Typography>HORA: {moment(event.start).format("HH:mm")}</Typography>
      </>
    );
  } else {
    tooltipTitle = (
      <>
        <Typography>
          PROFESOR: {`${event.teacherName} ${event.teacherLastName}`}
        </Typography>
        <Typography>HORA: {moment(event.start).format("HH:mm")}</Typography>
      </>
    );
  }

  return (
    <Tooltip title={tooltipTitle} aria-label="add">
      <Typography>
        <Typography style={{ fontSize: "15px" }}>{event.title}</Typography>
      </Typography>
    </Tooltip>
  );
}

// Componente para el header
function CustomToolbar(props, events) {
  const [tabValue, setTabValue] = useState(1);
  const classes = useStyles();

  useEffect(() => {
    props.onView("week");
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [events]);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <div>
      <Grid
        container
        // fullWidth
        justify="center"
        style={{ backgroundColor: "#ad201c" }}
      >
        <Grid item>
          <AppBar position="static">
            <Tabs
              value={tabValue}
              indicatorColor="secondary"
              onChange={handleTabChange}
            >
              {tabs.map((tab) => {
                return (
                  <Tab
                    className={classes.tab}
                    key={tab.value}
                    label={tab.label}
                    onClick={() => props.onView(tab.value)}
                    {...openTabPanel(0)}
                  />
                );
              })}
            </Tabs>
          </AppBar>
        </Grid>
      </Grid>
      <Grid container justify="space-between" className={classes.controls}>
        <Grid item className={classes.hover} style={{ marginLeft: 20 }}>
          <ArrowBackIosIcon onClick={() => props.onNavigate("PREV")} />
        </Grid>
        <Grid item>
          <span className="label-date">{props.label}</span>
        </Grid>
        <Grid item className={classes.hover} style={{ marginRight: 20 }}>
          <ArrowForwardIosIcon onClick={() => props.onNavigate("NEXT")} />
        </Grid>
      </Grid>
    </div>
  );
}

// Componente principal
const MyCalendar = ({
  studentID = null,
  changeDate,
  events = [],
  view = null,
  handleClickEditOpen = () => {},
  height,
  width,
}) => {
  const sayDay = (date) => {
    const newDate = moment(date).format("DD-MM-YYYY");
    changeDate(newDate);
  };

  return (
    <Calendar
      min={new Date(2020, 10, 0, 6, 15, 0)}
      max={new Date(2017, 10, 0, 22, 45, 0)}
      timeslots={1}
      step={45}
      onNavigate={changeDate ? (date) => sayDay(date) : false}
      selectable={true}
      localizer={localizer}
      defaultDate={new Date()}
      defaultView="week"
      events={events}
      tooltipAccessor={null}
      startAccessor="start"
      endAccessor="end"
      titleAccessor="title"
      views={{
        month: true,
        week: true,
        day: true,
      }}
      formats={{
        eventTimeRangeFormat: (hours) => {},
        weekdayFormat: (date, culture, localizer) =>
          localizer.format(date, "dddd", culture),

        // weekdayFormat: 'ddd',
        // dayFormat: 'ddd'
        // dateFormat: 'DDDD',
        // monthHeaderFormat: 'MMMM YYYY'
        // dayFormat: 'LL',
        // dayRangeHeaderFormat: ""
      }}
      eventPropGetter={(event) => {
        if (studentID) {
          return {
            style: {
              backgroundColor:
                studentID == event.idStudent
                  ? "rgb(207, 179, 55)" // Mi clase
                  : event.class === "reservada"
                  ? "#ad201c" // Ocupada
                  : "green", // Libre
              color:
                studentID == event.idStudent
                  ? "black"
                  : event.class === "reservada"
                  ? "white"
                  : "white",
              border: "1px solid grey",
              borderTop: "2px solid black",
              borderRadius: 0,
              minHeight: "20px",
            },
          };
        } else if (studentID == null) {
          return {
            style: {
              backgroundColor:
                event.class === "reservada" ? "#ad201c" : "green",
              color: event.class === "reservada" ? "white" : "white",
              border: "1px solid grey",
              borderTop: "2px solid black",
              borderRadius: 0,
              minHeight: "20px",
            },
          };
        }
      }}
      onSelectEvent={(event) => {
        if (view === "student") {
          if (
            // Class already started
            moment().diff(event.start, "minutes") > 0
          ) {
            return;
          } else if (
            // No se puede reservar una clase con menos de 2 horas de antelacion
            moment().diff(event.start, "minutes") < 0 &&
            moment().diff(event.start, "minutes") > -120
          ) {
            return;
          }

          if (
            event.idStudent == studentID &&
            moment().diff(event.start, "minutes") > -1440
          ) {
            return;
          }

          if (event.idStudent == studentID) {
            handleClickEditOpen(event.id, "editar");
          } else if (event.idStudent) {
            return;
          } else {
            handleClickEditOpen(event.id, "reservar");
          }
        } else if (view === "teacher") {
          // Vista teacher (Con y sin alumno)
          if (event.idStudent == null) {
            return;
          } else {
            handleClickEditOpen(event.id);
          }
        } else {
          // Vista centro
          handleClickEditOpen(event.id);
        }
      }}
      messages={{
        next: ">",
        previous: "<",
        today: "Hoy",
        month: "Mes",
        week: "Semana",
        day: "Día",
      }}
      components={{
        event: (prop) => CustomEvent(prop, view),
        toolbar: (prop) => CustomToolbar(prop, events),
      }}
      style={{ height: "750px", width: "100%" }}
    />
  );
};

export default MyCalendar;
