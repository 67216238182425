import { Button, makeStyles, Typography } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { teachers as teachersAPI } from "../../../api";
import { exportData } from "../../../lib/exportData";
import FormTeacher from "./FormTeacher";
import ModalExportActivity from "./ModalExportActivity";
import TeacherTimeTable from "./TeacherTimeTable";

const useStyles = makeStyles((theme) => ({
  rootGrid: {
    maxWidth: "100%",
    margin: "auto",
  },
  root: {
    flexGrow: 1,
    width: "100%",
    minHeight: "100vh",
    "& .MuiTextField-root": {
      minWidth: "20ch",
    },
  },
  button: {
    marginRight: theme.spacing(1),
    backgroundColor: "rgb(160, 174, 192)",
  },
  downLoadButton: {
    marginRight: theme.spacing(1),
    backgroundColor: "transparent",
    color: "rgb(56, 161, 105)",
    border: "1px solid rgb(56, 161, 105)",
    "&:hover": {
      marginRight: theme.spacing(1),
      border: "1px solid rgb(56, 161, 105)",
      backgroundColor: "rgb(56, 161, 105)",
      opacity: 1,
      color: "#FFFFFF",
    },
  },
  activeButton: {
    marginRight: theme.spacing(1),
    border: "1px solid #ad201c",
    backgroundColor: "#ad201c",
    opacity: 1,
    color: "#FFFFFF",
    "&:hover": {
      marginRight: theme.spacing(1),
      border: "1px solid #ad201c",
      backgroundColor: "#ad201c",
      opacity: 1,
      color: "#FFFFFF",
    },
  },
  header: {
    backgroundColor: "rgb(248,250,252)",
    borderBottom: "2px solid rgb(227,232,239)",
    height: 70,
    alignItems: "center",
  },
  headerText: {
    fontWeight: "bold",
    marginLeft: 40,
    color: theme.palette.primary.main,
  },
  headerButtons: {
    marginRight: 40,
  },
  searchContainer: {
    margin: 20,
    border: "1px solid",
    borderColor: theme.palette.primary.dark,
    borderRadius: 5,
    padding: 12,
  },
  tabPanel: {
    backgroundColor: theme.palette.primary.main,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.primary.dark,
  },
  formControl: {
    width: "100%",
  },
  buttonsGroup: {
    textAlign: "right",
    margin: "0 40px",
  },
  alignLeft: {
    textAlign: "left",
  },
  container: {
    width: "100%",
    marginLeft: 12,
    marginRight: 12,
  },
  savedSearchLink: {
    marginLeft: "auto",
    marginRight: 0,
  },
}));

const TeachersViews = ({ centerID }) => {
  const classes = useStyles();
  const { teacherID } = useParams();
  const [view, setView] = useState("Ficha");
  const [openAdd, setOpenAdd] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const changeView = (newView) => {
    setView(newView);
  };

  const handleClickAddOpen = () => {
    setOpenAdd(true);
  };

  const downloadExcel = (dates = {}) => {
    const startDate = dates.startDate ? dates.startDate : null;
    const endDate = dates ? dates.endDate : null;

    let params = {
      view: "export",
      teacherID,
      startDate,
      endDate,
      // ...criteria,
    };
    teachersAPI.getExportClasses(params).then((result) => {
      exportData(result.data, "Clases", result.teacherName);
    });
  };

  return (
    <div className={classes.root}>
      <ModalExportActivity
        open={openAdd}
        setOpen={setOpenAdd}
        centerID={centerID}
        downloadExcel={(dates) => downloadExcel(dates)}
      />
      <Grid
        container
        direction="row"
        justify="space-between"
        className={classes.header}
      >
        <Grid item>
          <Typography className={classes.headerText}>
            <span>{view.toUpperCase()}</span>
          </Typography>
        </Grid>
        <Grid item className={classes.buttonsGroup}>
          <Button
            onClick={handleClickAddOpen}
            className={classes.downLoadButton}
            variant="outlined"
          >
            <span>Descargar Actividad</span>
          </Button>
          <Button
            onClick={() => changeView("Ficha")}
            variant="outlined"
            className={view === "Ficha" ? classes.activeButton : classes.button}
          >
            <span>FICHA</span>
          </Button>
          <Button
            onClick={() => changeView("Horario")}
            variant="outlined"
            className={
              view === "Horario" ? classes.activeButton : classes.button
            }
          >
            <span>HORARIO</span>
          </Button>
        </Grid>
      </Grid>

      {view === "Ficha" && (
        <FormTeacher centerID={centerID} teacherID={teacherID} />
      )}
      {view === "Horario" && (
        <TeacherTimeTable centerID={centerID} teacherID={teacherID} />
      )}
    </div>
  );
};

export default TeachersViews;
