import moment from "moment";
import React, { useState } from "react";
import { DateRangePicker } from "react-dates";

const CustomDatePicker = ({
  widthCustom = null,
  handleChangeCriteria,
  startDate,
  endDate,
}) => {
  const [focusedInput, setFocusedInput] = useState(null);
  const [dates, setDates] = useState({});

  return (
    <DateRangePicker
      minimumNights={0}
      startDate={dates.startDate} // momentPropTypes.momentObj or null,
      startDateId="1" // PropTypes.string.isRequired,
      startDatePlaceholderText="Desde"
      endDate={dates.endDate} // momentPropTypes.momentObj or null,
      endDateId="2" // PropTypes.string.isRequired,
      endDatePlaceholderText="Hasta"
      onDatesChange={({ startDate, endDate }) => {
        setDates({ startDate: startDate, endDate: endDate });
        handleChangeCriteria({
          startDate: startDate ? moment(startDate).format() : null,
          endDate: endDate ? moment(endDate).format() : null,
        });
      }} // PropTypes.func.isRequired,
      focusedInput={focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
      onFocusChange={(focusedInput) => setFocusedInput(focusedInput)} // PropTypes.func.isRequired,
      numberOfMonths={1}
      showClearDates
      // showDefaultInputIcon
      hideKeyboardShortcutsPanel={true}
      small={true}
      isOutsideRange={() => false}
      block
    />
  );
};

export default CustomDatePicker;
