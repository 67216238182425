import React from "react";
import { Grid, Tooltip, Typography } from "@material-ui/core";
import { Doughnut, Pie } from "react-chartjs-2";

const doughnutOptions = {
  height: "100%",
  events: ["mousemove"], // this is needed, otherwise onHover is not fired
  onHover: (event, chartElement) => {
    event.target.style.cursor = chartElement[0] ? "pointer" : "default";
  },
  legend: {
    display: false,
    position: "top",

    labels: {
      fontColor: "#FFFFFF",
      fontStyle: "bold",
    },
  },
  elements: {
    arc: {
      borderColor: "#002C43", //.minColor.toString(),
      borderWidth: 1,
    },
  },
};

const TestStadistics = ({ totalResults, passedCount, level }) => {

  const Donutdata = {
    labels: ["Hechos", "Total"],
    datasets: [
      {
        data: [totalResults, 150],
        backgroundColor: ["rgb(52, 201, 127)", "rgb(199, 76, 72)"],
        // hoverBackgroundColor: hoverColors,
      },
    ],
  };
  const levelData = {
    labels: ["Nivel", "Total"],
    datasets: [
      {
        data: [level, 4],
        backgroundColor: ["rgb(52, 201, 127)", "rgb(199, 76, 72)"],
        // hoverBackgroundColor: hoverColors,
      },
    ],
  };

  return (
    <Grid
      container
      spacing={2}
      // justify="space-between"
    >
      <Grid
        item
        container
        xs={12}
        md={4}
        style={{
          display: "flex",
          alignItems: "center",
          alignContent: "flex-start",
        }}
        spacing={2}
      >
        <Grid
          item
          xs={12}
          md={12}
          justify="center"
          // style={{ maxHeight: "400px" }}
        >
          <Typography
            style={{
              display: "flex",
              justifyContent: "center",
              color: "rgb(199, 76, 72)",
              fontWeight: "bold",
            }}
          >
            EVALUACIÓN {((passedCount / totalResults) * 100).toFixed(2)}%
            (Aprobados)
          </Typography>
          <Grid
            item={12}
            style={{
              alignItems: "center",
              alignContent: "center",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                maxHeight: "500px",
              }}
            >
              <Tooltip
                title={`${passedCount} aprobados de ${totalResults} hechos`}
                aria-label="add"
              >
                <div
                  style={{
                    backgroundColor: "rgb(199, 76, 72)",
                    height: 40,
                    width: 200,
                    cursor: "pointer",
                    border: "2px solid black",
                  }}
                >
                  <div
                    style={{
                      backgroundColor: "rgb(52, 201, 127)",
                      cursor: "pointer",
                      height: 40,
                      width: `${(passedCount / totalResults) * 100}%`,
                      borderRight: "2px solid black",
                    }}
                  ></div>
                </div>
              </Tooltip>
            </div>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        item
        container
        xs={12}
        md={4}
        style={{
          display: "flex",
          alignItems: "center",
          alignContent: "center",
        }}
        spacing={2}
      >
        <Grid
          item
          xs={12}
          md={12}
          justify="center"
          // style={{ maxHeight: "400px" }}
        >
          <Typography
            style={{
              display: "flex",
              justifyContent: "center",
              color: "rgb(199, 76, 72)",
              fontWeight: "bold",
            }}
          >
            NIVEL {level}
          </Typography>
          <Grid
            item={12}
            style={{
              alignItems: "center",
              alignContent: "center",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                maxHeight: "500px",
              }}
            >
              <Pie height={100} options={doughnutOptions} data={levelData} />
            </div>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        item
        container
        xs={12}
        md={4}
        style={{
          display: "flex",
          alignItems: "center",
          alignContent: "center",
        }}
        spacing={2}
      >
        <Grid
          item
          xs={12}
          md={12}
          justify="center"
          // style={{ maxHeight: "400px" }}
        >
          <Typography
            style={{
              display: "flex",
              justifyContent: "center",
              color: "rgb(199, 76, 72)",
              fontWeight: "bold",
            }}
          >
            PROGRESO {((totalResults / 150) * 100).toFixed(2)} %
          </Typography>
          <Grid
            item={12}
            style={{
              alignItems: "center",
              alignContent: "center",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                maxHeight: "500px",
              }}
            >
              <Doughnut
                height={100}
                options={doughnutOptions}
                data={Donutdata}
              />
            </div>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default TestStadistics;
