import { MenuItem, TextareaAutosize } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import SaveIcon from "@material-ui/icons/Save";
import React, { useState } from "react";
import { requests as requestsAPI } from "../../../api";
import { useAuth } from "../../../contexts/AuthProvider/AuthProvider";


const useStyles = makeStyles((theme) => ({
  marginRight: {
    marginRight: theme.spacing(1),
  },
  labelRoot: {
    color: "white",
    fontWeight: "bold",
    "&$labelFocused": {
      color: "white",
    },
  },
  acceptButton: {
    backgroundColor: "rgb(18,151,91)",
    "&:hover": {
      backgroundColor: "rgb(18,151,91)",
    },
  },
  actions: {
    backgroundColor: "rgb(248,250,252)",
  },
}));

const ModalAddRequest = (props) => {
  const {
    studentID,
    requestsTypes,
    examTypes,
    open,
    setOpen,
    refetch,
  } = props;
  const classes = useStyles();
  const { user} = useAuth();

  const isAdmin = user.role === "ROLE_ADMIN";
  const [values, setValues] = useState({  });
  const [errorMessage, setErrorMessage] = useState("");

  const handleChange = (key, value) => {
      setValues({
        ...values,
        [key]: value,
      });
  };

  const handleSubmit = () => {
    const finalValues = { ...values, studentID: studentID };
    requestsAPI
      .storeRequest(finalValues)
      .then((response) => {
        setValues({ });
        refetch()
        setOpen(false);
      })
      .catch((x) => {
        setErrorMessage("Error adding permiso");
      });
  };

  const handleCancelClose = () => {
    setValues({  });
    setOpen(false);
  };

  
  return (
    <Dialog open={open} onClose={handleCancelClose}>
      <DialogTitle id="form-dialog-title">
        <span>CREAR ENTRADA</span>
      </DialogTitle>
      <Divider style={{ margin: "0 20px" }}></Divider>
      <DialogContent style={{ overflowY: "hidden", margin: "20px 0" }}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography>Tipo Examen</Typography>
            <TextField
              fullWidth
              variant="outlined"
              size="small"
              id="examTypeID"
              placeholder="Seleccionar una solicitud"
              select
              value={values.examTypeID || ""}
              onChange={(e) => handleChange("examTypeID", e.target.value)}
            >
              <MenuItem disabled value={""}>
                Seleccione un concepto
              </MenuItem>
              {examTypes.map((option) => (
                <MenuItem key={option.id} value={option.id}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12}>
            <Typography>Tipo Solicitud</Typography>
            <TextField
              fullWidth
              variant="outlined"
              size="small"
              id="examApplicationStatusTypeID"
              placeholder="Seleccionar una solicitud"
              select
              value={values.examApplicationStatusTypeID || ""}
              onChange={(e) => handleChange("examApplicationStatusTypeID", e.target.value)}
            >
              <MenuItem disabled value={""}>
                Seleccione un concepto
              </MenuItem>
              {requestsTypes.map((option) => (
                <MenuItem key={option.id} value={option.id}>
                  {option.title}
                </MenuItem>
              ))}
            </TextField>
          </Grid>

          <Grid item xs={12}>
            <Typography>Observaciones para el estudiante</Typography>
            <TextareaAutosize
              style={{ width: "99%" }}
              id="additionalInfo"
              name="additionalInfo"
              className={classes.textArea}
              rowsMin={20}
              rowsMax={20}
              placeholder="Escriba el mensaje"
              value={values.additionalInfo}
              onChange={(e) => handleChange("additionalInfo", e.target.value)}
            />
          </Grid>
     
        </Grid>
      </DialogContent>
      <DialogActions className={classes.actions}>
        <p>{errorMessage}</p>
        <Button size="large" variant="outlined" onClick={handleCancelClose}>
          <CloseIcon className={classes.marginRight} />
          Cancelar
        </Button>
        <Button
          className={classes.acceptButton}
          size="large"
          variant="outlined"
          onClick={handleSubmit}
        >
          <SaveIcon className={classes.marginRight} /> <span>Confirmar</span>
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ModalAddRequest;
