import React from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";

const styles = StyleSheet.create({
  row: {
    flexDirection: "row",
    textAlign: "left",
    border: "1px solid black",
    height: 24,
  },
  description: {
    fontSize: 8,
    marginRight: 10,
  },
  xyz: {
    fontSize: 8
  },
});

const TableCell = ({ placeHolder, value, flex }) => {

  return  <View style={[styles.row, {flex: flex}]}>
    <Text style={styles.description} >{placeHolder}</Text>
    <Text style={styles.xyz}>{value}</Text>
  </View>;
};

export default TableCell;