import { Button, makeStyles, TextareaAutosize, TextField, Typography } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { centers as centersAPI } from "../../../../api";
import { decodeHtml } from '../../../../lib/helpers';

const useStyles = makeStyles((theme) => ({
    root: {
        "& .MuiTextField-root": {
            minWidth: "40ch",
        },
    },
    textArea: {
        minHeight: 50,
        minWidth: 500,
        maxWidth: 500,
        borderRadius: 3,
    },
}));

export default function EditBannerInput({ field, center, refreshCall }) {

    const classes = useStyles()

    const [state, setState] = useState("")

    useEffect(() => {
        setState(center[field])
    }, [center, field]);


    const handleSubmit = (e) => {
        centersAPI
            .updateBanner({ [field]: state }, center.id)
            .then(() => refreshCall());
    }


    return (
        <div classes={classes.root} style={{ display: "flex", minWidth: "400px" }}>
                <TextField
                    fullWidth
                    variant="outlined"
                    size="small"
                    name={center[field]}
                    value={state}
                    onChange={(e) => {
                        setState(e.target.value)
                    }
                    }
                /> 
                <Button style={{ backgroundColor: 'green' }} onClick={() => handleSubmit()}>Guardar</Button>
        </div>)

}
