import axios from "axios";
import {API_NAME} from "./base";

const QuestionInstance = axios.create({
  baseURL: `${API_NAME}api/questions`,
  withCredentials: true,
});

QuestionInstance.interceptors.request.use(
  (config) => {
    config.headers.Authorization = `Bearer ${localStorage.getItem("user")}`;
    return config;
  },
  (error) => Promise.reject(error)
);

const QuestionApi = {
  getAll: ({
    searchText = "",
    page = 1,
    perPage = 10,
    category = "Todos",
    active = "Todas",
  } = {}) => {
    return QuestionInstance.get(`/`, {
      params: {
        searchText,
        active,
        category,
        page,
        perPage,
      },
    })
      .then((response) => response.data)
      .catch((err) => console.error(err));
  },

  create: (data) => {
    return QuestionInstance.post("/store-test", data)
      .then((response) => response.data)
      .catch((err) => console.error(err));
  },

  update: (data, ID) => {
    return QuestionInstance.post(`/${ID}`, data)
      .then((response) => response.data)
      .catch((err) => console.error(err));
  },
};

const tests = QuestionApi;
export default tests;
