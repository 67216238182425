import { Fade, makeStyles, Typography } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import React, { useEffect, useRef, useState } from "react";
import Moment from "react-moment";
import { Redirect } from "react-router-dom";
import { requests as requestsApi } from "../../../api";
import { DATE_FORMAT_COMPLETE, FADE_TRANSITION } from "../../../lib/constants";
import CustomTable from "../../core/CustomTable";
import { LoadingIcon } from "../../core/LoadingIcon";
import { EXAM_TYPE } from "../students/StudentRequests";
const NO_VALUE = "No valor";

const useStyles = makeStyles((theme) => ({
  rootGrid: {
    maxWidth: "100%",
    margin: "auto",
  },
  root: {
    flexGrow: 1,
    width: "100%",
    minHeight: "100vh",
    "& .MuiTextField-root": {
      minWidth: "20ch",
    },
  },
  header: {
    backgroundColor: "rgb(248,250,252)",
    borderBottom: "2px solid rgb(227,232,239)",
    height: 70,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  headerText: {
    fontWeight: "bold",
    marginLeft: 40,
    color: theme.palette.primary.main,
  },
  headerButtons: {
    marginRight: 40,
  },
  searchContainer: {
    margin: 20,
    border: "1px solid",
    borderColor: theme.palette.primary.dark,
    borderRadius: 5,
    padding: 12,
  },
  tabPanel: {
    backgroundColor: theme.palette.primary.main,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.primary.dark,
  },
  marginLeft: {
    marginLeft: "10px",
  },
  formControl: {
    width: "100%",
  },
  alignRight: {
    textAlign: "right",
  },
  alignLeft: {
    textAlign: "left",
  },
  container: {
    width: "100%",
    marginLeft: 12,
    marginRight: 12,
  },
  savedSearchLink: {
    marginLeft: "auto",
    marginRight: 0,
  },
}));

const columns = [
  {
    title: "Fecha",
    field: "created_at",
    width: 200,
    render: (data) => (
      <Moment format={DATE_FORMAT_COMPLETE}>{data.created_at}</Moment>
    ),
    emptyValue: NO_VALUE,
    cardFormat: "DATE_COMPLETE",
  },
  {
    title: "Alumno",
    field: "firstName",
    width: 150,
    emptyValue: NO_VALUE,
    render: (data) => {
     
      return <Typography>{data.firstName} {data.lastName1}</Typography>;
    },
    cardFormat: "STRING",
  },
  {
    title: "Tipo Examen",
    field: "examType",
    type: "currency",
    emptyValue: NO_VALUE,
    width: 150,
    render: (data) => {
      
      return <Typography style={{textAlign: 'left'}}>{EXAM_TYPE[data.examType]}</Typography>;
    },
    cardFormat: "STRING",
  },
  {
    title: "Descripcion",
    field: "title",
    emptyValue: NO_VALUE,
    cardFormat: "STRING",
  },
];

const ExamRequestStatusList = ({ centerID, isAdmin }) => {
  const classes = useStyles();
  const tableRef = useRef(null);

  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);
  const [, setCourses] = useState([]);
  const [selectedStudentID, setSelectedStudentID] = useState("");
  const [page, setPage] = useState(1);
  const defaultPageSize = 10;
  const [totalResults, setTotalResults] = useState();
  const [sorting, ] = useState({ orderBy: "name", orderDir: "asc" });

  const [criteria, ] = useState({ course: "Todos" });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    setIsLoading(true);
    let params = {
      centerID: centerID !== "1" ? centerID : null,
      perPage: defaultPageSize,
      page: page,
      ...criteria,
    };

    requestsApi.getLastStudentsRequests(params).then((result) => {
      setData(result.results);
      setPage(result.page);
      setTotalResults(result.totalCount);
      setIsLoading(false);
    });

    setCourses([]);
  }, [criteria, page, sorting, centerID]);

  const handlePageChange = (newPage) => {
    window.scrollTo(0, 0);
    setPage(newPage);
  };
  const handleStudentSelected = (id) => {
    setSelectedStudentID(id);
  };

  if (selectedStudentID) {
    return (
      <Redirect to={`/edit-student/${selectedStudentID}?view=Solicitudes`}></Redirect>
    );
  }

  if (columns.length === 0) return "LOADING";

  return (
    <Fade in={true} timeout={FADE_TRANSITION}>
      <div className={classes.root}>
        <div className={classes.header}>
          <Grid container>
            <Typography className={classes.headerText}>SOLICITUD EXAMEN</Typography>
          </Grid>
        </div>

        <div style={{ margin: "40px 40px 5px 40px" }}>
          <Grid container spacing={2} direction="row" justify="flex-start">
            <Grid item xs={12} sm={6} md="auto" lg="auto">
            </Grid>
          </Grid>
        </div>
        <div style={{ padding: "20px 40px 0 40px" }}>
          {isLoading ? (
            <LoadingIcon></LoadingIcon>
          ) : (
            <CustomTable
              tableRef={tableRef}
              data={data}
              columns={columns}
              handlePageChange={handlePageChange}
              page={page}
              totalResults={totalResults}
              defaultPageSize={defaultPageSize}
              handleClickEditOpen={handleStudentSelected}
            ></CustomTable>
          )}
        </div>
      </div>
    </Fade>
  );
};

export default ExamRequestStatusList;
