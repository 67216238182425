import axios from "axios";
import {API_NAME} from "./base";

const testInstance = axios.create({
  baseURL: `${API_NAME}api/center`,
  withCredentials: true,
});

testInstance.interceptors.request.use(
  (config) => {
    config.headers.Authorization = `Bearer ${localStorage.getItem("user")}`;
    return config;
  },
  (error) => Promise.reject(error)
);

const CenterApi = {
  getOneResult: ({testID, studentID}) => {
    return testInstance
      .get(`/students/${testID}`, {
        params: { studentID },
      })
      .then((response) => response.data)
      .catch((err) => console.error(err));
  },
  getStudentTests: ({
    studentID = null,
    orderBy = "name",
    orderDir = "asc",
    page = 1,
    perPage = 10,
  } = {}) => {
    return testInstance
      .get(`/students/student-tests`, {
        params: { studentID, orderBy, orderDir, page, perPage },
      })
      .then((response) => response.data)
      .catch((err) => console.error(err));
  },

  getTestsByNumber: ({
    studentID = null,
  } = {}) => {
    return testInstance
      .get(`/students/student-tests-by-number`, {
        params: { studentID  },
      })
      .then((response) => response.data)
      .catch((err) => console.error(err));
  },

  getStudentErrorTestsNumber: ({
    studentID = null,
  } = {}) => {
    return testInstance
      .get(`/students/student-error-test-number`, {
        params: { studentID  },
      })
      .then((response) => response.data.result)
      .catch((err) => console.error(err));
  },

  getTestsBySession: ({
    studentID = null,
  } = {}) => {
    return testInstance
      .get(`/students/student-tests-by-session`, {
        params: { studentID  },
      })
      .then((response) => response.data)
      .catch((err) => console.error(err));
  },

};

const center = CenterApi;
export default center;
