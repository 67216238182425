import { Button, Fade, makeStyles, Typography } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import React, { useEffect, useState } from "react";
import { FADE_TRANSITION } from "../../../lib/constants";
import ArchingsClosures from "./ArchingsClosures";
import ArchingsList from "./ArchingsList";
import ArchingsMoves from "./ArchingsMoves";
import ArchingsStudents from "./ArchingsStudents";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
    minHeight: "100vh",
    "& .MuiTextField-root": {
      minWidth: "20ch",
    },
  },
  button: {
    marginRight: theme.spacing(1),
    backgroundColor: "rgb(160, 174, 192)",
  },
  activeButton: {
    marginRight: theme.spacing(1),
    border: "1px solid #ad201c",
    backgroundColor: "#ad201c",
    opacity: 1,
    color: "#FFFFFF",
    "&:hover": {
      marginRight: theme.spacing(1),
      border: "1px solid #ad201c",
      backgroundColor: "#ad201c",
      opacity: 1,
      color: "#FFFFFF",
    },
  },
  header: {
    backgroundColor: "rgb(248,250,252)",
    borderBottom: "2px solid rgb(227,232,239)",
    height: 70,
    alignItems: "center",
  },
  headerText: {
    fontWeight: "bold",
    marginLeft: 40,
    color: theme.palette.primary.main,
  },
  buttonsGroup: {
    textAlign: "right",
    margin: "0 40px",
  },
}));

const ArchingsViews = ({ centerID }) => {
  const classes = useStyles();
  const [view, setView] = useState("Arqueos");

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const changeView = (newView) => {
    setView(newView);
  };

  return (
    <Fade in={true} timeout={FADE_TRANSITION}>
      <div className={classes.root}>
        <Grid
          container
          direction="row"
          justify="space-between"
          className={classes.header}
        >
          <Grid item>
            <Typography className={classes.headerText}>
              <span>{view.toUpperCase()}</span>
            </Typography>
          </Grid>
          <Grid item className={classes.buttonsGroup}>
            <Button
              onClick={() => changeView("Arqueos")}
              variant="outlined"
              className={
                view === "Arqueos" ? classes.activeButton : classes.button
              }
            >
              <span>ARQUEOS</span>
            </Button>
            <Button
              onClick={() => changeView("Salidas")}
              variant="outlined"
              className={
                view === "Salidas" ? classes.activeButton : classes.button
              }
            >
              <span>SALIDAS</span>
            </Button>
            <Button
              onClick={() => changeView("Movimientos")}
              variant="outlined"
              className={
                view === "Movimientos" ? classes.activeButton : classes.button
              }
            >
              <span>MOVIMIENTOS</span>
            </Button>
            <Button
              onClick={() => changeView("Saldos")}
              variant="outlined"
              className={
                view === "Saldos" ? classes.activeButton : classes.button
              }
            >
              <span>SALDOS ALUMNOS</span>
            </Button>
          </Grid>
        </Grid>

        {view === "Arqueos" && <ArchingsList centerID={centerID} />}
        {view === "Salidas" && <ArchingsClosures centerID={centerID} />}
        {view === "Movimientos" && <ArchingsMoves centerID={centerID} />}
        {view === "Saldos" && <ArchingsStudents centerID={centerID} />}
      </div>
    </Fade>
  );
};

export default ArchingsViews;
