import React, { useEffect, useState } from "react";
import {
  Button,
  Chip, Grid,
  TextField
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import {
  exams as examsApi,
  students as studentsAPI, teachers as teachersAPI
} from "../../../api";


const TheoryExamOptions = ({ examData, centerID, fetchTheoryStudents }) => {
  const [values, setValues] = useState({ idCenter: centerID });
  const [, setTeachers] = useState([]);
  const [students, setStudents] = useState([]);

  useEffect(() => {
    let params = {
      centerID: centerID !== "1" ? centerID : null,
      perPage: 100,
      page: 1,
    };
    teachersAPI.getAll(params).then((results) => {
      setTeachers(results.results);
    });
    studentsAPI.getStudentForClasses({ centerID }).then((results) => {
      const studentWithState = results.results.map((elem) => {
        elem["selected"] = false;
        return elem;
      });
      setStudents(studentWithState);
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChangeStudents = (newValues) => {
    setValues({ ...values, ...newValues });
  };

  const handleSubmitStudents = () => {
    examsApi
      .storeTheoryStudents({ students: values, examID: examData.id })
      .then((result) => {
        setValues({ idCenter: centerID });
        fetchTheoryStudents();
      })
      .catch((result) => {
        console.log("NOOOOOO");
      });
  };

  const studentsFiltered =
    !!students.length && students.filter((elem) => elem.selected !== true);
  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <span style={{ fontWeight: "bold" }}>Alumnos nuevos</span>
      </Grid>
      <Grid item xs={12}>
        <Autocomplete
          style={{ fontWeight: "bold", minHeight: "40px" }}
          size="small"
          multiple
          id="tags-standard"
          options={studentsFiltered}
          getOptionLabel={(option) => option.studentName}
          // defaultValue={values}
          value={values.newStudents || []}
          disableClearable
          onChange={(e, value) =>
            handleChangeStudents({
              newStudents: value || null,
            })
          }
          renderTags={(tagValue, getTagProps) =>
            tagValue.map((option, index) => (
              <Chip
                style={{
                  backgroundColor: "#41b883",
                  color: "white",
                  fontWeight: "bold",
                }}
                label={option.studentName}
                {...getTagProps({ index })}
              />
            ))
          }
          renderInput={(params) => {
            return (
              <>
                {/* <Label style={{ fontWeight: "bold" }}>Nuevos</Label> */}
                <TextField
                  style={{
                    fontWeight: "bold",
                    marginTop: "-3px",
                    minHeight: 20,
                  }}
                  {...params}
                  variant="outlined"
                  placeholder="Selecciona Alumnos"
                  margin="normal"
                  fullWidth
                />
              </>
            );
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <span style={{ fontWeight: "bold" }}>Alumnos repetidores</span>
      </Grid>
      <Grid item xs={12}>
        <Autocomplete
          style={{ fontWeight: "bold", minHeight: "40px" }}
          size="small"
          multiple
          id="tags-standard"
          options={studentsFiltered}
          getOptionLabel={(option) => option.studentName}
          // defaultValue={values}
          value={values.repeatStudents || []}
          disableClearable
          onChange={(e, value) =>
            handleChangeStudents({
              repeatStudents: value || null,
            })
          }
          renderTags={(tagValue, getTagProps) =>
            tagValue.map((option, index) => (
              <Chip
                style={{
                  backgroundColor: "#41b883",
                  color: "white",
                  fontWeight: "bold",
                }}
                label={option.studentName}
                {...getTagProps({ index })}
              />
            ))
          }
          renderInput={(params) => {
            return (
              <>
                {/* <Label style={{ fontWeight: "bold" }}>Nuevos</Label> */}
                <TextField
                  style={{
                    fontWeight: "bold",
                    marginTop: "-3px",
                    minHeight: 20,
                  }}
                  {...params}
                  variant="outlined"
                  placeholder="Selecciona Alumnos"
                  margin="normal"
                  fullWidth
                />
              </>
            );
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <span style={{ fontWeight: "bold" }}>Alumnos pendientes</span>
      </Grid>
      <Grid item xs={12}>
        <Autocomplete
          style={{ fontWeight: "bold", minHeight: "40px" }}
          size="small"
          multiple
          id="tags-standard"
          options={studentsFiltered}
          getOptionLabel={
            (option) => option.studentName
            // <Typography style={{ backgroundColor: "green" }}>
            //   {option.studentName}
            // </Typography>
          }
          // defaultValue={values}
          value={values.pendingStudents || []}
          disableClearable
          onChange={(e, value) =>
            handleChangeStudents({
              pendingStudents: value || null,
            })
          }
          renderTags={(tagValue, getTagProps) =>
            tagValue.map((option, index) => (
              <Chip
                style={{
                  backgroundColor: "#41b883",
                  color: "white",
                  fontWeight: "bold",
                }}
                label={option.studentName}
                {...getTagProps({ index })}
              />
            ))
          }
          renderInput={(params) => {
            return (
              <>
                {/* <Label style={{ fontWeight: "bold" }}>Nuevos</Label> */}
                <TextField
                  style={{
                    fontWeight: "bold",
                    marginTop: "-3px",
                    minHeight: 20,
                  }}
                  {...params}
                  variant="outlined"
                  placeholder="Selecciona Alumnos"
                  margin="normal"
                  fullWidth
                />
              </>
            );
          }}
        />
      </Grid>
      <Grid
        container
        item
        xs={12}
        direction="row"
        justify="flex-end"
        alignItems="flex-end"
        style={{ marginTop: 15 }}
      >
        <Button
          fullWidth
          onClick={() => {
            handleSubmitStudents();
          }}
        >
          <span>Guardar</span>
        </Button>
      </Grid>
    </Grid>
  );
};

export default TheoryExamOptions;
