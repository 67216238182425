import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import SaveIcon from "@material-ui/icons/Save";
import React, { useState } from "react";
import { patterns as patternsAPI } from "../../../api";

const useStyles = makeStyles((theme) => ({
  marginRight: {
    marginRight: theme.spacing(1),
  },
  labelRoot: {
    color: "white",
    fontWeight: "bold",
    "&$labelFocused": {
      color: "white",
    },
  },
  acceptButton: {
    backgroundColor: "rgb(18,151,91)",
  },
  actions: {
    backgroundColor: "rgb(248,250,252)",
  },
}));

const ModalAddPattern = (props) => {
  const { teacherID, patterns, open, setOpen } = props;
  const classes = useStyles();

  const [values, setValues] = useState({ teacherID: teacherID, active: 1 });
  const [errorMessage, setErrorMessage] = useState("");

  const handleChange = (key, value) => {
    setValues({
      ...values,
      [key]: value,
    });
  };

  const handleSubmit = () => {
    const hasPattern = patterns.some(
      (item) => item.hour === `${values.hour}:00`
    );

    if (hasPattern == true) {
      setErrorMessage("Estás intentando introducir un patrón repetido");
    } else {
      patternsAPI
        .create(values)
        .then((response) => {
          setValues({ teacherID: teacherID, active: 1 });
          setErrorMessage("");
          setOpen(false);
        })
        .catch((x) => {
          setErrorMessage("Error adding permiso");
        });
    }
  };

  const handleCancelClose = () => {
    setValues({ teacherID: teacherID, active: 1 });
    setErrorMessage("");
    setOpen(false);
  };

  return (
    <Dialog open={open} onClose={handleCancelClose}>
      <DialogTitle id="form-dialog-title">
        <span style={{ fontWeight: "bold" }}>CREAR PATRÓN</span>
      </DialogTitle>
      <Divider style={{ margin: "0 20px" }}></Divider>
      <DialogContent style={{ overflowY: "hidden", margin: "20px 0" }}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography>
              Patrón<span style={{ color: "rgb(159,46,37)" }}>*</span>
            </Typography>
            <TextField
              fullWidth
              size="small"
              type="time"
              variant="outlined"
              placeholder="Patrón"
              value={values.hour}
              onChange={(e) => handleChange("hour", e.target.value)}
            ></TextField>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions className={classes.actions}>
        <p>{errorMessage}</p>
        <Button size="large" variant="outlined" onClick={handleCancelClose}>
          <CloseIcon className={classes.marginRight} />
          Cancelar
        </Button>
        <Button
          className={classes.acceptButton}
          size="large"
          variant="outlined"
          onClick={handleSubmit}
        >
          <SaveIcon className={classes.marginRight} /> <span>Añadir</span>
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ModalAddPattern;
