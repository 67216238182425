import { Button, Fade, Grid, Radio, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import MaterialTable from "material-table";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { tests as testsAPI } from "../../../api";
import { FADE_TRANSITION, NO_VALUE } from "../../../lib/constants";
import { decodeHtml } from "../../../lib/helpers";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const TestResultState = ({ questions }) => {
  const passedQuestions = questions.filter(
    (q) => q.result === "Acierto"
  ).length;
  const totalQuestions = questions.length;
  const percentagePassed = (passedQuestions / totalQuestions) * 100;

  return (
    <Typography
      style={
        percentagePassed >= 90
          ? {
              backgroundColor: "rgb(198, 246, 213)",
              padding: "6px 9px",
              borderRadius: "15px",
              fontWeight: "bold",
              alignSelf: "center",
            }
          : {
              backgroundColor: "rgb(248, 216, 216)",
              padding: "6px 9px",
              borderRadius: "20px",
              fontWeight: "bold",
              alignSelf: "center",
            }
      }
    >
      {percentagePassed >= 90 ? "APTO" : "NO APTO"}
    </Typography>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      minWidth: "20ch",
    },
  },
  gridContainer: {
    margin: 40,
    padding: 20,
  },
  header: {
    backgroundColor: "rgb(248,250,252)",
    borderBottom: "2px solid rgb(227,232,239)",
    height: 70,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  headerText: {
    fontWeight: "bold",
    marginLeft: 40,
    color: theme.palette.primary.main,
  },
  textArea: {
    minHeight: 150,
    minWidth: "99%",
    maxWidth: "99%",
    width: "99",
    borderRadius: 3,
    padding: 8,
  },
  radio: {
    "&$checked": {
      color: "red",
    },
  },
  checked: {
    "&$checked": {
      color: "green",
    },
  },
}));

const columns = [
  {
    title: "#",
    field: "idTest",
    emptyValue: NO_VALUE,
    render: (data) => <Typography>{data.tableData.id + 1}</Typography>,
    width: 10,
    cardFormat: "STRING",
  },
  {
    title: "DESCRIPCIÓN ",
    field: "question",
    render: (data) => <Typography>{decodeHtml(data.question)}</Typography>,
    emptyValue: NO_VALUE,
    cardFormat: "STRING",
  },
  {
    title: "RESULTADO",
    field: "result",
    render: (data) => (
      <Typography>
        <span
          style={
            data.result === "Acierto"
              ? {
                  backgroundColor: "rgb(198, 246, 213)",
                  padding: "2px 9px",
                  borderRadius: "20px",
                }
              : {
                  backgroundColor: "rgb(248, 216, 216)",
                  padding: "2px 9px",
                  borderRadius: "20px",
                }
          }
        >
          {data.result}
        </span>
      </Typography>
    ),
    emptyValue: NO_VALUE,
  },
];

const DetailPanel = ({ rowData }) => {
  const classes = useStyles();
  return (
    <Grid container spacing={3} style={{ padding: "10px 5px" }}>
      <Grid item xs={12} sm={4} lg={3}>
        <img
          style={{ width: "100%" }}
          src={
            rowData.imgURL
              ? rowData?.imgURL
              : `/imagenes/questions/${rowData.img}`
          }
          alt=""
        />
      </Grid>
      <Grid item container xs={12} sm={8} lg={9}>
        <Grid item xs={12}>
          <Radio
            checked={
              parseInt(rowData.answer) === 1 ||
              parseInt(rowData.correctAnswer) === 1
            }
            classes={{
              checked: parseInt(rowData.correctAnswer) === 1 && classes.checked,
            }}
            color="primary"
            value={1}
            label={<Typography>{decodeHtml(rowData.a)}</Typography>}
            name="radio-button-demo"
            inputProps={{ "aria-label": "A" }}
          ></Radio>
          {decodeHtml(rowData.a)}
        </Grid>

        <Grid item xs={12}>
          <Radio
            checked={
              parseInt(rowData.answer) === 2 ||
              parseInt(rowData.correctAnswer) === 2
            }
            classes={{
              checked:
                parseInt(rowData.correctAnswer) === 2 ? classes.checked : "red",
            }}
            color="primary"
            value={2}
            label={<Typography>{decodeHtml(rowData.b)}</Typography>}
            name="radio-button-demo"
            inputProps={{ "aria-label": "A" }}
          />
          {decodeHtml(rowData.b)}
        </Grid>
        <Grid xs={12}>
          <Radio
            checked={
              parseInt(rowData.answer) === 3 ||
              parseInt(rowData.correctAnswer) === 3
            }
            classes={{
              checked:
                parseInt(rowData.correctAnswer) === 3 ? classes.checked : "red",
            }}
            color="primary"
            value={3}
            label={<Typography>{decodeHtml(rowData.c)}</Typography>}
            name="radio-button-demo"
            inputProps={{ "aria-label": "A" }}
          />
          {decodeHtml(rowData.c)}
        </Grid>
      </Grid>
    </Grid>
  );
};

const TestResult = (props) => {
  const classes = useStyles();
  const { testID, studentID } = useParams();
  const [data, setData] = useState([]);
  const [, setError] = useState(null);
  const history = useHistory();


  useEffect(() => {
    testsAPI
      .getOneResult({testID, studentID})
      .then((response) => {
        const responseWithResult = response.result.map((row) => {
          row.result === 1 ? (row.result = "Acierto") : (row.result = "Fallo");
          return row;
        });
        setData(responseWithResult);
      })
      .catch((x) => {
        setError("Error trayendo test");
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const resultString = `${
    data.filter((q) => q.result === "Acierto").length
  } de ${data.length}`;

  if (data.length === 0) {
    return <></>;
  }

  return (
    <Fade in={true} timeout={FADE_TRANSITION}>
      <div className={classes.root}>
        <div className={classes.header}>
          <Grid container xs={12}>
            <Typography className={classes.headerText}>
              <span>RESULTADOS DEL TEST: {resultString} </span>
            </Typography>
          </Grid>
        </div>

        <div className={classes.gridContainer}>
          <Grid container spacing={3} direction="row" justify="flex-start">
            <Grid item xs={12}>
              <Grid container direction="row" justify="space-between">
                <Grid>
                  <Grid
                    item
                    xs={12}
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      marginBottom: "10px",
                    }}
                  >
                    <div></div>
                    <TestResultState questions={data} />
                    <Button
                      onClick={() => {
                        history.push(props.location.nextPath);
                      }}
                    >
                      Volver
                    </Button>
                  </Grid>
                  <Grid item xs={12}>
                    <MaterialTable
                      style={{
                        border: 0,
                        display: "grid",
                        zIndex: 0,
                      }}
                      columns={columns}
                      data={data}
                      detailPanel={(rowData) => (
                        <DetailPanel rowData={rowData}></DetailPanel>
                      )}
                      options={{
                        headerStyle: {
                          backgroundColor: "grey",
                          color: "#FFF",
                          fontWeight: "bold",
                          paddingTop: 8,
                          paddingBottom: 8,
                        },
                        paging: false,
                        addRowPosition: "first",
                        search: false,
                        toolbar: false,
                      }}
                      onRowClick={(event, rowData, togglePanel) =>
                        togglePanel()
                      }
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      marginTop: "10px",
                    }}
                  >
                    <Button
                      onClick={() => {
                        history.push(props.location.nextPath);
                      }}
                    >
                      Volver
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
        <div style={{ height: "20px" }}></div>
      </div>
    </Fade>
  );
};

export default TestResult;
