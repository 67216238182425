import React from "react";
import { Route } from "react-router-dom";
import CalendarTeacher from "../Teacher/calendar/CalendarTeacher";
import FormTeacherClass from "../Teacher/calendar/FormTeacherClass";
import TeacherProfile from "../Teacher/profile/TeacherProfile";

const RoutesTeacher = ({ user, reloadUser }) => {
  return (
    <>
      <Route
        exact={true}
        path="/"
        render={(props) => (
          <CalendarTeacher {...props} user={user} reloadUser={reloadUser} />
        )}
      />
      <Route
        exact={true}
        path="/mi_perfil"
        render={(props) => (
          <TeacherProfile {...props} user={user} reloadUser={reloadUser} />
        )}
      />
      <Route
        exact={true}
        path="/clase/:classID"
        render={(props) => (
          <FormTeacherClass {...props} user={user} reloadUser={reloadUser} />
        )}
      />
    </>
  );
};

export default RoutesTeacher;
