import {
  Button,
  Fade,
  Grid,
  MenuItem,
  TextField,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { Redirect, useParams } from "react-router-dom";
import { courses as coursesApi, teachers as teachersAPI, centers as centersAPI } from "../../../api";
import { FADE_TRANSITION } from "../../../lib/constants";
import { CustomField } from "../../core/CustomField";
import DaysCalendar from "../../core/DaysCalendar";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
    "& .MuiTextField-root": {
      width: "20ch",
    },
  },
  gridContainer: {
    backgroundColor: "rgb(255,255,255)",
    margin: 40,
    borderTop: "5px solid rgb(159,46,37)",
    borderRadius: "0 0 10px 10px",
    padding: 20,
  },
  header: {
    backgroundColor: "rgb(248,250,252)",
    borderBottom: "2px solid rgb(227,232,239)",
    height: 70,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  headerText: {
    fontWeight: "bold",
    marginLeft: 40,
    color: theme.palette.primary.main,
  },
  textArea: {
    minHeight: 150,
    minWidth: "99%",
    maxWidth: "99%",
    width: "99",
    borderRadius: 3,
    padding: 8,
  },
}));

const validate = (values, courseID) => {
  const errors = {};
  if (!values.name) {
    errors.name = "Requerido";
  }

  if (!values.startDate) {
    errors.startDate = "Requerido";
  }
  if (!values.endDate) {
    errors.endDate = "Requerido";
  }

  if (!values.startTime) {
    errors.startTime = "Requerido";
  }
  if (!values.endTime) {
    errors.endTime = "Requerido";
  }
  if (!values.price) {
    errors.price = "Requerido";
  }
  if (!values.slots) {
    errors.slots = "Requerido";
  }
  if (!values.idTeacher) {
    errors.idTeacher = "Requerido";
  }
  if (!values.type) {
    errors.type = "Requerido";
  }

  return errors;
};


const FormCourse = ({ centerID, isAdmin }) => {
  const classes = useStyles();

  const { courseID } = useParams();
  const [values, setValues] = useState({ idCenter: centerID });
  const [teachers, setTeachers] = useState([]);
  const [error, setError] = useState(null);
  const [redirect, setRedirect] = useState(false);
  const [days, setDays] = useState([]);
  const [packs, setPacks] = useState({});

  // const { isLoading, error, data, isFetching } = useQuery("courseData", () =>
  //   axios.get(
  //     "https://api.github.com/repos/tannerlinsley/react-query"
  //   ).then((res) => res.data)
  // );

  useEffect(() => {

    centersAPI.getPacksForCourses({ centerID }).then((response) => {
      setPacks(response.results);
    })
      .catch((x) => {
        setError("Error trayendo centros");
      });


    if (courseID) {
      coursesApi
        .getOne(courseID)
        .then((response) => {
          setValues(response);
          setDays(response.days);
        })
        .catch((x) => {
          setError("Error trayendo centros");
        });
    }
    let params = {
      centerID: centerID !== "1" ? centerID : null,
      perPage: 100,
    };
    teachersAPI.getAll(params).then((results) => {
      setTeachers(results.results);
    });
  }, [courseID, centerID]);

  const formik = useFormik({
    initialValues: {
      idCenter: centerID,
      name: (values && values.name) || "",
      startDate: (values && values.startDate) || "",
      endDate: (values && values.endDate) || "",
      startTime: (values && values.startTime) || "",
      endTime: (values && values.endTime) || "",
      price: (values && values.price) || "",
      price5pack: (values && values.price5pack) || null,
      price10pack: (values && values.price10pack) || null,
      price20pack: (values && values.price20pack) || null,
      price30pack: (values && values.price30pack) || null,
      slots: (values && values.slots) || "",
      idTeacher: (values && values.idTeacher) || "",
      type: (values && values.type) || "",
    },
    enableReinitialize: true,
    isInitialValid: false,
    validate: (values) => validate(values, courseID),
    onSubmit: (values) => {
      handleSubmitCourse(values);
    },
  });

  const handleSubmitCourse = (values) => {
    // HABRA QUE COMPROBAR EL NUMERO DE DIAS DEL CURSO

    values.days = days;
    // if (days.length < 6 || days.length > 6) {
    //   setError("Necesitas seleccionar 6 dias");
    //   return;
    // }

    setError("");
    //AQUI USAMOS EL formik.isValid que es booleano para que deje o no hacer la acción

    courseID
      ? coursesApi
        .update(values, courseID)
        .then((response) => {
          setRedirect(true);
        })
        .catch((x) => {
          setError("Error adding permiso");
        })
      : coursesApi
        .create(values)
        .then((response) => {
          setRedirect(true);
        })
        .catch((x) => {
          setError("Error adding permiso");
        });
  };

  if (redirect) {
    return <Redirect to={"/cursos"}></Redirect>;
  }

  return (
    <Fade in={true} timeout={FADE_TRANSITION}>
      <div className={classes.root}>
        {courseID ? null : (
          <div className={classes.header}>
            <Grid container xs={12}>
              <Typography className={classes.headerText}>
                <span>NUEVO CURSO</span>
              </Typography>
            </Grid>
          </div>
        )}

        <div className={classes.gridContainer}>
          <Grid container spacing={3} direction="row" justify="flex-start">
            <Grid item xs={12}>
              <Grid container direction="row" justify="space-between">
                <Grid>
                  {courseID ? (
                    <Typography>CURSO - {values.name}</Typography>
                  ) : (
                    <Typography>CURSO</Typography>
                  )}
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={8} container direction="row" spacing={3}>
              <Grid item xs="auto" sm="auto" md="auto" lg="auto">
                <CustomField
                  title="Fecha de Inicio"
                  required
                  type="date"
                  formik={formik}
                  fieldKey="startDate"
                  placeHolder="Fecha de inicio"
                  validate
                />
              </Grid>
              <Grid item xs="auto" sm="auto" md="auto" lg="auto">
                <CustomField
                  title="Fecha de fin"
                  required
                  type="date"
                  formik={formik}
                  fieldKey="endDate"
                  placeHolder="Fecha de fin"
                  validate
                />
              </Grid>
              <Grid item xs="auto" sm="auto" md="auto" lg="auto">
                <CustomField
                  title="Hora Inicio"
                  required
                  type="time"
                  formik={formik}
                  fieldKey="startTime"
                  placeHolder="HORA INICIO"
                  validate
                />
              </Grid>
              <Grid item xs="auto" sm="auto" md="auto" lg="auto">
                <CustomField
                  title="Hora fin"
                  required
                  type="time"
                  formik={formik}
                  fieldKey="endTime"
                  placeHolder="HORA FIN"
                  validate
                />
              </Grid>
              <Grid item xs="auto" sm="auto" md="auto" lg="auto">
                <CustomField
                  title="Nombre"
                  required
                  type="text"
                  formik={formik}
                  fieldKey="name"
                  placeHolder="NOMBRE"
                  validate
                />
              </Grid>

              <Grid item xs="auto" sm="auto" md="auto" lg="auto">
                <CustomField
                  title="Plazas disponibles "
                  required
                  type="number"
                  formik={formik}
                  fieldKey="slots"
                  placeHolder="PLAZAS DISPONIBLES"
                  validate
                />
              </Grid>
              <Grid item xs="auto" sm="auto" md="auto" lg="auto">
                <CustomField
                  title="Profesor"
                  required
                  type="select"
                  formik={formik}
                  fieldKey="idTeacher"
                  placeHolder="PROFESOR"
                  validate
                >
                  {teachers.map((option) => (
                    <MenuItem key={option.id} value={option.id}>
                      {option.firstName} {option.lastName1}
                    </MenuItem>
                  ))}
                </CustomField>
              </Grid>
              <Grid item xs="auto" sm="auto" md="auto" lg="auto">
                <CustomField
                  title="Modalidad"
                  required
                  type="select"
                  formik={formik}
                  fieldKey="type"
                  placeHolder="MODALIDAD"
                  validate
                >
                  {["ONLINE", "PRESENCIAL"].map((option) => (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </CustomField>
              </Grid>
              <Grid item xs={12}>
                <Grid container direction="row" justify="space-between">
                  <Grid>
                    <Typography>PRECIO CURSOS</Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs="4">
                <CustomField
                  title="Precio Curso Básico"
                  required
                  type="number"
                  formik={formik}
                  fieldKey="price"
                  placeHolder="PRECIO"
                  validate
                />
              </Grid>
              <Grid item xs="4">

              </Grid>
              <Grid item xs="4">
                <CustomField
                  title="Total Curso Básico"
                  type="number"
                  formik={formik}
                  fieldKey="price"
                  placeHolder="PRECIO"
                  validate
                  disabled
                />
              </Grid>
              <Grid item xs="4">
                <CustomField
                  title="Precio Curso Pack 5"
                  type="number"
                  formik={formik}
                  fieldKey="price5pack"
                  placeHolder="PRECIO"
                  validate
                />
              </Grid>
              <Grid item xs="4">
                <Typography>
                  Precio Pack 5 clases
                </Typography>
                <TextField
                  variant="outlined"
                  size="small"
                  value={packs.pack5price}
                  disabled
                ></TextField>
              </Grid>
              <Grid item xs="4">
                <Typography>
                  Curso + Pack 5 clases
                </Typography>
                <TextField
                  variant="outlined"
                  size="small"
                  value={formik.values.price5pack + packs.pack5price}
                  disabled
                ></TextField>
              </Grid>
              <Grid item xs="4">
                <CustomField
                  title="Precio Curso Pack 10"
                  type="number"
                  formik={formik}
                  fieldKey="price10pack"
                  placeHolder="PRECIO"
                  validate
                />
              </Grid>
              <Grid item xs="4">
                <Typography>
                  Precio Pack 10 clases
                </Typography>
                <TextField
                  variant="outlined"
                  size="small"
                  value={packs.pack10price}
                  disabled
                ></TextField>
              </Grid>
              <Grid item xs="4">
                <Typography>
                  Curso + Pack 10 clases
                </Typography>
                <TextField
                  variant="outlined"
                  size="small"
                  value={formik.values.price10pack + packs.pack10price}
                  disabled
                ></TextField>
              </Grid>
              <Grid item xs="4">
                <CustomField
                  title="Precio Curso Pack 20"
                  type="number"
                  formik={formik}
                  fieldKey="price20pack"
                  placeHolder="PRECIO"
                  validate
                />
              </Grid>
              <Grid item xs="4">
                <Typography>
                  Precio Pack 20 clases
                </Typography>
                <TextField
                  variant="outlined"
                  size="small"
                  value={packs.pack20price}
                  disabled
                ></TextField>
              </Grid>
              <Grid item xs="4">
                <Typography>
                  Curso + Pack 20 clases
                </Typography>
                <TextField
                  variant="outlined"
                  size="small"
                  value={formik.values.price20pack + packs.pack20price}
                  disabled
                ></TextField>
              </Grid>
              <Grid item xs="4">
                <CustomField
                  title="Precio Curso Pack 30"
                  type="number"
                  formik={formik}
                  fieldKey="price30pack"
                  placeHolder="PRECIO"
                  validate
                />
              </Grid>
              <Grid item xs="4">
                <Typography>
                  Precio Pack 30 clases
                </Typography>
                <TextField
                  variant="outlined"
                  size="small"
                  value={packs.pack30price}
                  disabled
                ></TextField>
              </Grid>
              <Grid item xs="4">
                <Typography>
                  Curso + Pack 30 clases
                </Typography>
                <TextField
                  variant="outlined"
                  size="small"
                  value={formik.values.price30pack + packs.pack30price}
                  disabled
                ></TextField>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={4} container>
              <Typography>Selecciona días en el calendario</Typography>
              <Grid item xs="12" sm="auto" md="auto" lg="auto">
                <DaysCalendar
                  days={days}
                  startDate={new Date()}
                  onChange={setDays}
                ></DaysCalendar>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Button
                disabled={!formik.isValid}
                fullWidth
                onClick={formik.handleSubmit}
              >
                {courseID ? <span>MODIFICAR</span> : <span>CREAR</span>}
              </Button>
              {error}
            </Grid>
          </Grid>
        </div>
        <div style={{ height: "20px" }}></div>
      </div>
    </Fade>
  );
};

export default FormCourse;
