import { Fade, Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import { FADE_TRANSITION } from "../../../lib/constants";
import ClassesList from "./ClassesList";

const useStyles = makeStyles((theme) => ({
  rootGrid: {
    maxWidth: "100%",
    margin: "auto",
  },
  root: {
    flexGrow: 1,
    width: "100%",
    minHeight: "100vh",
    "& .MuiTextField-root": {
      minWidth: "20ch",
    },
  },
  header: {
    backgroundColor: "rgb(248,250,252)",
    borderBottom: "2px solid rgb(227,232,239)",
    height: 70,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  headerText: {
    fontWeight: "bold",
    marginLeft: 40,
    color: theme.palette.primary.main,
  },
  headerButtons: {
    marginRight: 40,
  },
  searchContainer: {
    margin: 20,
    border: "1px solid",
    borderColor: theme.palette.primary.dark,
    borderRadius: 5,
    padding: 12,
  },
  tabPanel: {
    backgroundColor: theme.palette.primary.main,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.primary.dark,
  },
  marginLeft: {
    marginLeft: "10px",
  },
  formControl: {
    width: "100%",
  },
  alignRight: {
    textAlign: "right",
  },
  alignLeft: {
    textAlign: "left",
  },
  container: {
    width: "100%",
    marginLeft: 12,
    marginRight: 12,
  },
  savedSearchLink: {
    marginLeft: "auto",
    marginRight: 0,
  },
}));


const ClassesStudent = ({ user }) => {
  const classes = useStyles();

  // useEffect(() => {
  // }, []);

  return (
    <Fade in={true} timeout={FADE_TRANSITION}>
      <div className={classes.root}>
        <div className={classes.header}>
          <Grid container>
            <Typography className={classes.headerText}>MIS CLASES</Typography>
          </Grid>
        </div>
        <ClassesList studentID={user.id}></ClassesList>
      </div>
    </Fade>
  );
};

export default ClassesStudent;
