import React from "react";
import {
  Document,
  Page,
  Text,
  View,
  Image,
  StyleSheet,
} from "@react-pdf/renderer";
import moment from "moment";

const TrafficAuth = ({
  data = { tarif: {}, student: {}, center: {}, studentSignature: "" },
}) => {
  // Create styles
  const styles = StyleSheet.create({
    body: {
      fontSize: 9,
      marginTop: 30,
      marginBottom: 30,
    },
    title: {
      fontSize: 20,
      marginBottom: 20,
      textAlign: "center",
    },
    page: {
      flexDirection: "column",
      padding: 10,
      fontSize: 12,
    },
    section: {
      marginBottom: 10,
    },
    logo: {
      width: 70,
      height: 70,
      marginRight: 10,
    },
    signature: {
      width: 150,
      height: 150,
      margin: 60,
    },
    signatureDirector: {
      width: 90,
      height: 90,
      margin: 60,
    },
  });

  const { student, tariff, center, course, studentSignature } = data;

  return (
    <Document>
      <Page style={styles.body}>
        <View style={{ flexDirection: "column", margin: "20 35 5 35" }}>
          <Text style={{ fontSize: "10px" }}>
            Yo {student.firstName} {student.lastName1} {student.lastName2}, con
            DNI/NIE {student.dni}
          </Text>
        </View>

        <View style={{ flexDirection: "column", margin: "20 35 5 35" }}>
          <Text style={{ fontSize: "10px" }}>
            Autorizo a la escuela particular de conductores {center.denomination} con numero
            provincial {center.provincialNumber} con la que tengo suscrito un contrato de enseñanza,
            para que gestione en mi nombre, ante la Jefatura Provincial de
            Tráfico, el despacho de cuantos documentos sean de mi interés y
            estén directamente relacionados con la obtención del permiso o
            licencia de conducción y ello al amparo de lo que dispone el
            artículo 1.3 del Real Decreto 1295/2003, de 17 de octubre, por el
            que se aprueba el Reglamento Regulador de las Escuelas Particulares
            de Conductores.
          </Text>
        </View>

        <View style={{ flexDirection: "column", margin: "5 35" }}>
          <Text style={{ fontSize: "10px" }}>Asimismo manifiesta:</Text>

          <View style={{ flexDirection: "column", margin: "5 0" }}>
            <Text>
              - Que la escuela me ha dado fiel y puntual información del coste
              del este servicio, así como del coste de la tasa de la Jefatura
              Provincial de Tráfico vigente en el momento de la firma ({tariff.pvpRate})
              que se paga por cada solicitud, válida para dos convocatorias y
              que incluye la expedición de permisos/licencia y que ambos costes
              se encuentran detallados específicamente en el contrato de
              enseñanza que ha suscrito y del cual tiene copia.
            </Text>
          </View>
          <View style={{ flexDirection: "column", margin: "5 0" }}>
            <Text>
              - No estar privado por resolución judicial del derecho a conducir
              vehículos de motor y ciclomotores; no hallarse sometido a
              suspensión o intervención administrativa del permiso o licencia de
              conducción; no ser titular de un permiso de conducción de igual
              clase expedido en otro Estado parte del Acuerdo sobre el Espacio
              Ecónomico Europeo.
            </Text>
          </View>

          <View style={{ flexDirection: "column", margin: "5 0" }}>
            <Text>
              - Haber sido informado de que los datos de carácter personal
              recogidos en el formulario de solicitud de pruebas de aptitud
              serán tratados exclusivamente para la finalidad de prestar el
              servicio solicitado en la DGT, aplicando la normativa vigente de
              protección de datos y legitimados por el Real Decreto Legislativo
              6/2015, de 30 de octubre, por el que se aprueba el texto refundido
              de la Ley sobre Tráfico, Circulación de Vehículos a Motor y
              Seguridad Vial (el responsable del tratamiento es la Dirección
              General de Tráfico. Podrá ejercer sus derechos direigiendo
              formualrio a: DGT, calle Josefa Valcárcel 44 28071 Madrid; o bien:
              protecciondedatos@dgt.es. No están previstas transferencias
              internacionales y la conservación de los datos será poer el tiempo
              necesario para cumplir con la finalidad del tratamiento.
              https://sede.dgt.gob.es/es/contenidos/proteccion-de-datos.shtml).
            </Text>
          </View>

          <View style={{ flexDirection: "column", margin: "5 0" }}>
            <Text>
              Y para que así conste, firma el presente documento en prueba de su
              conformidad y para dejar constancia de lo manifestado a los
              efectos legales pertinentes,
            </Text>
          </View>

          <View style={{ flexDirection: "column", margin: "5 0" }}>
            <Text>en {center.city.toUpperCase()} a {moment(new Date()).format('DD/MM/yyyy HH:mm:ss')}</Text>
          </View>

          <View
            style={{
              flexDirection: "row",
              marginTop: 20,
            }}
          >
            <Text style={{ textAlign: "center" }}>Firma del alumno,</Text>
            {studentSignature && (
              <View>
                <Image src={studentSignature} style={styles.signature} />
              </View>
            )}
          </View>
        </View>
      </Page>
    </Document>
  );
};

export default TrafficAuth;
