export const activeOptions = [
  {
    value: 1,
    label: "Si",
  },
  {
    value: "0",
    label: "No",
  },
];

export const genderOptions = [
  {
    value: "Hombre",
    label: "Hombre",
  },
  {
    value: "Mujer",
    label: "Mujer",
  },
];

export const codeOptions = [
  {
    value: "percentage",
    label: "Porcentaje",
  },
  {
    value: "normal",
    label: "Diferencia",
  },
  {
    value: "double",
    label: "2X1",
  },
];

export const firstContactOptions = [
  {
    value: "Redes Sociales",
    label: "Redes Sociales",
  },
  {
    value: "Formulario web/email",
    label: "Formulario web/email",
  },
  {
    value: "Teléfono",
    label: "Teléfono",
  },
  {
    value: "Visita",
    label: "Visita",
  },
  {
    value: "Botón whatsapp",
    label: "Botón whatsapp",
  },
  {
    value: "Reserva online",
    label: "Reserva online",
  },
];
export const studentTypeOptions = [
  {
    value: "Todos",
    label: "Todos",
  },
  {
    value: "Alumnos Activos",
    label: "Alumnos Activos",
  },
  {
    value: "Alumnos Inactivos",
    label: "Alumnos Inactivos",
  },
  {
    value: "Otros Activos",
    label: "Otros Activos",
  },
  {
    value: "Otros Inactivos",
    label: "Otros Inactivos",
  },
];
