import React from "react";
import DayPicker, { DateUtils } from "react-day-picker";
import "react-day-picker/lib/style.css";
import "./DaysCalendar.css";

const MONTHS = [
  "Enero",
  "Febrero",
  "Marzo",
  "Abril",
  "Mayo",
  "Junio",
  "Julio",
  "Agosto",
  "Septiembre",
  "Octubre",
  "Noviembre",
  "Diciembre",
];
const WEEKDAYS_LONG = [
  "Domingo",
  "Lunes",
  "Martes",
  "Miercoles",
  "Jueves",
  "Viernes",
  "Sabado",
];
const WEEKDAYS_SHORT = ["D", "L", "M", "X", "J", "V", "S"];

export default class Example extends React.Component {
  constructor(props) {
    super(props);
    this.handleDayClick = this.handleDayClick.bind(this);
    this.state = {
      selectedDays: [],
    };
  }

  handleDayClick(day, { selected }) {
    const { selectedDays } = this.state;
    if (selected) {
      const selectedIndex = selectedDays.findIndex((selectedDay) =>
        DateUtils.isSameDay(selectedDay, day)
      );
      selectedDays.splice(selectedIndex, 1);
    } else {
      selectedDays.push(day);
    }
    this.setState({ selectedDays });
    this.props.onChange(selectedDays);
  }

  render() {
    const dates =
      this.props.days &&
      this.props.days.map((day) => {
        const newDay = new Date(day);
        return new Date(
          newDay.getFullYear(),
          newDay.getMonth(),
          newDay.getDate()
        );
      });
    return (
      <div style={{ border: "2px solid black", width: "290px" }}>
        <DayPicker
          locale="es"
          months={MONTHS}
          weekdaysLong={WEEKDAYS_LONG}
          weekdaysShort={WEEKDAYS_SHORT}
          firstDayOfWeek={1}
          initialMonth={new Date(this.props.startDate)}
          selectedDays={dates}
          onDayClick={this.handleDayClick}
        />
      </div>
    );
  }
}
