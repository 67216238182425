import axios from "axios";
import {API_NAME, API_NAME_2} from "./base";


const testInstance = axios.create({
  baseURL: `${API_NAME}api/tests`,
  withCredentials: true,
});

testInstance.interceptors.request.use(
  (config) => {
    config.headers.Authorization = `Bearer ${localStorage.getItem("user")}`;
    return config;
  },
  (error) => Promise.reject(error)
);

const TestApi = {
  getOneResult: ({testID, studentID}) => {
    return testInstance
      .get(`/${testID}`, {
        params: { studentID },
      })
      .then((response) => response.data)
      .catch((err) => console.error(err));
  },
  getRandomTest: ({ type, permission, level }) => {
    return testInstance
      .get(`/get-random-test`, {
        params: { type, permission, level },
      })
      .then((response) => response.data)
      .catch((err) => console.error(err));
  },

  getTheoryTest: ({ type, permission, nTest }) => {
    return testInstance
      .get(`/get-new-test`, {
        params: { type, permission, nTest },
      })
      .then((response) => response.data)
      .catch((err) => console.error(err));
  },

  getErrorTest: () => {
    return testInstance.get(`${API_NAME_2}student/theory-tests/getErrorTest`)
      .then((response) => response.data.data.questions)
      .catch((err) => console.error(err));
  },

  getErrorTestsNumber: () => {
    return testInstance.get(`${API_NAME_2}student/theory-tests/error-tests-number`)
      .then((response) => response.data.data.errorQuestionsCount)
      .catch((err) => console.error(err));
  },

  getSessionTest: ({ category, type, nTest, permission }) => {
    return testInstance
      .get(`/get-session-test`, {
        params: { category, type, nTest, permission },
      })
      .then((response) => response.data)
      .catch((err) => console.error(err));
  },

  getStudentSessionLevel: ({ studentID }) => {
    return testInstance
      .get(`/student-session`, {
        params: { studentID },
      })
      .then((response) => response.data)
      .catch((err) => console.error(err));
  },

  getStudentTests: ({
    studentID = null,
    orderBy = "name",
    orderDir = "asc",
    page = 1,
    perPage = 10,
  } = {}) => {
    return testInstance
      .get(`/student-tests`, {
        params: { studentID, orderBy, orderDir, page, perPage },
      })
      .then((response) => response.data)
      .catch((err) => console.error(err));
  },

  getTestsByNumber: ({
    studentID = null,
  } = {}) => {
    return testInstance
      .get(`/student-tests-by-number`, {
        params: { studentID  },
      })
      .then((response) => response.data)
      .catch((err) => console.error(err));
  },

  getTestsBySession: ({
    studentID = null,
  } = {}) => {
    return testInstance
      .get(`/student-tests-by-session`, {
        params: { studentID  },
      })
      .then((response) => response.data)
      .catch((err) => console.error(err));
  },

  create: (data) => {
    return testInstance
      .post("/store-test", data)
      .then((response) => response.data)
      .catch((err) => console.error(err));
  },

  createErrorTest: (data) => {
    return testInstance
      .post(`${API_NAME_2}student/theory-tests/store-test`, data)
      .then((response) => response.data)
      .catch((err) => console.error(err));
  },
};

const tests = TestApi;
export default tests;
