import { Button, Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React, { useEffect, useRef, useState } from "react";
import { NavLink, Redirect } from "react-router-dom";
import { supervisors as supervisorsAPI } from "../../api";
import { NO_VALUE } from "../../lib/constants";
import CustomTable from "../core/CustomTable";

const useStyles = makeStyles((theme) => ({
  rootGrid: {
    margin: "auto",
  },
  root: {
    flexGrow: 1,
    width: "100%",
    minHeight: "100vh",
  },
  header: {
    backgroundColor: "rgb(248,250,252)",
    borderBottom: "2px solid rgb(227,232,239)",
    height: 70,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  headerText: {
    fontWeight: "bold",
    marginLeft: 40,
    color: theme.palette.primary.main,
  },
  headerButtons: {
    marginRight: 40,
  },
  searchContainer: {
    margin: 20,
    border: "1px solid",
    borderColor: theme.palette.primary.dark,
    borderRadius: 5,
    padding: 12,
  },
  tabPanel: {
    backgroundColor: theme.palette.primary.main,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.primary.dark,
  },
  formControl: {
    width: "100%",
  },
  alignRight: {
    textAlign: "right",
  },
  alignLeft: {
    textAlign: "left",
  },
  container: {
    width: "100%",
    marginLeft: 12,
    marginRight: 12,
  },
  savedSearchLink: {
    marginLeft: "auto",
    marginRight: 0,
  },
}));

const columns = [
  { title: "Nombre", field: "firstName", emptyValue: NO_VALUE },
  { title: "Apellido", field: "lastName1", emptyValue: NO_VALUE },
  { title: "Email", field: "email", emptyValue: NO_VALUE },
];

const Central = ({ isAdmin = false }) => {
  const classes = useStyles();
  const [supervisors, setSupervisors] = useState([]);
  const [selectedSupervisorID, ] = useState(null);
  const tableRef = useRef(null);

  useEffect(() => {
    getSupervisorsi();
  }, []);

  const getSupervisorsi = () => {
    supervisorsAPI
      .getAll()
      .then((response) => {
        setSupervisors(response.results);
      })
      .catch((x) => {
        console.log("Error trayendo permisos");
      });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);


  if (selectedSupervisorID) {
    return (
      <Redirect to={`/edit-supervisor/${selectedSupervisorID}`}></Redirect>
    );
  }

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <Grid container xs={12}>
          <Typography className={classes.headerText}>
            <span>CENTRAL</span>
          </Typography>
        </Grid>
        {isAdmin ? (
          <Grid
            container
            spacing={3}
            xs={12}
            className={classes.headerButtons}
            direction="row"
            justify="flex-end"
          >
            <NavLink to="/add-supervisor">
              <Button onClick={() => {}}>CREAR SUPERVISOR</Button>
            </NavLink>
          </Grid>
        ) : null}
      </div>
      {isAdmin ? (
        <div
          style={{
            padding: "20px 40px 0 40px",
            margin: "0 auto",
            position: "relative",
          }}
        >
          <CustomTable
            tableRef={tableRef}
            data={supervisors}
            columns={columns}
            hasPagination={false}
            // handleClickEditOpen={handleCenterSelected}
          ></CustomTable>
        </div>
      ) : null}
    </div>
  );
};

export default Central;
