import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import SaveIcon from "@material-ui/icons/Save";
import { KeyboardDatePicker } from "@material-ui/pickers";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  marginRight: {
    marginRight: theme.spacing(1),
  },
  labelRoot: {
    color: "white",
    fontWeight: "bold",
    "&$labelFocused": {
      color: "white",
    },
  },
  acceptButton: {
    backgroundColor: "rgb(18,151,91)",
  },
  actions: {
    backgroundColor: "rgb(248,250,252)",
  },
}));

const ModalRecibo = (props) => {
  const { studentID, open, setOpen } = props;
  const classes = useStyles();

  const [values, setValues] = useState({});

  const handleChange = (key, value) => {
    setValues({
      ...values,
      [key]: value,
    });
  };

  const handleSubmit = () => {
    setOpen(false);
  };

  const handleCancelClose = () => {
    setOpen(false);
  };

  return (
    <Dialog open={open} onClose={handleCancelClose}>
      <DialogTitle id="form-dialog-title">
        <span>Rango de fechas</span>
      </DialogTitle>
      <Divider style={{ margin: "0 20px" }}></Divider>
      <DialogContent style={{ overflowY: "hidden", margin: "20px 0" }}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography>
              Desde
              <span style={{ color: "rgb(159,46,37)" }}>*</span>
            </Typography>
            <KeyboardDatePicker
              fullWidth
              autoOk
              placeholder="DESDE"
              variant="inline"
              inputVariant="outlined"
              size="small"
              format="DD/MM/yyyy"
              value={values.start || null}
              InputAdornmentProps={{ position: "end" }}
              onChange={(date) => handleChange("start", moment(date).format())}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography>
              Hasta
              <span style={{ color: "rgb(159,46,37)" }}>*</span>
            </Typography>
            <KeyboardDatePicker
              fullWidth
              autoOk
              placeholder="HASTA"
              variant="inline"
              inputVariant="outlined"
              size="small"
              format="DD/MM/yyyy"
              value={values.end || null}
              InputAdornmentProps={{ position: "end" }}
              onChange={(date) => handleChange("end", moment(date).format())}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions className={classes.actions}>
        <a
          style={{ color: "black" }}
          href={`https://develop2020.autius.com/api/generateStudentReceipt/${studentID}?start=${values.start}&end=${values.end}`}
        >
          <Button
            className={classes.acceptButton}
            size="large"
            variant="outlined"
            onClick={handleSubmit}
          >
            <SaveIcon className={classes.marginRight} /> <span>Aceptar</span>
          </Button>
        </a>
      </DialogActions>
    </Dialog>
  );
};

export default ModalRecibo;
