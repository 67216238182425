import React, { useEffect, useState } from "react";
import {
  Button,
  Fade,
  Grid,
  InputAdornment,
  MenuItem,
  TextField,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Redirect, useParams } from "react-router-dom";
import { tariffs as tariffApi } from "../../../api";
import { FADE_TRANSITION, NO_VALUE } from "../../../lib/constants";
import { activeOptions } from "../../../lib/textOptions";
import CustomTable from "../../core/CustomTable";
import ModalAddFormPack from "./ModalAddPack";
import ModalEditFormPack from "./ModalEditFormPack";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
    minHeight: "100vh",
    "& .MuiTextField-root": {
      minWidth: "20ch",
    },
  },
  gridContainer: {
    backgroundColor: "rgb(255,255,255)",
    margin: 40,
    borderTop: "5px solid rgb(159,46,37)",
    borderRadius: "0 0 10px 10px",
    padding: 20,
  },
  header: {
    backgroundColor: "rgb(248,250,252)",
    borderBottom: "2px solid rgb(227,232,239)",
    height: 70,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  headerText: {
    fontWeight: "bold",
    marginLeft: 40,
    color: theme.palette.primary.main,
  },
  textArea: {
    minHeight: 150,
    minWidth: "99%",
    maxWidth: "99%",
    width: "99",
    borderRadius: 3,
    padding: 8,
  },
}));

const columns = [
  {
    title: "Nombre",
    field: "description",
    emptyValue: NO_VALUE,
    cardFormat: "STRING",
  },
  {
    title: "Número de clases",
    field: "quantity",
    emptyValue: NO_VALUE,
    cardFormat: "STRING",
  },
  {
    title: "Importe",
    field: "price",
    render: (data) => <Typography>{data.price} €</Typography>,
    emptyValue: NO_VALUE,
    cardFormat: "CURRENCY",
  },
];

const FormTariff = ({ centerID, isAdmin }) => {
  const classes = useStyles();
  const { tariffID } = useParams();
  const [values, setValues] = useState({ idCenter: centerID });
  const [packs, setPacks] = useState([]);
  const [openEdit, setOpenEdit] = useState(false);
  const [openAdd, setOpenAdd] = useState(false);
  const [selectedPackID, setSelectedPackID] = useState(null);
  const [redirect, setRedirect] = useState(false);

  useEffect(() => {
    getTariff();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getTariff = () => {
    if (tariffID) {
      tariffApi
        .getOne(tariffID)
        .then((response) => {
          setValues(response);
          setPacks(response.packs);
        })
        .catch((x) => {
          console.log("Error trayendo centros");
        });
    }
  };

  const handleChange = (key, value) => {
    setValues({
      ...values,
      [key]: value,
    });
  };

  const handleSubmitTariff = () => {
    let params = {
      ...values,
    };

    tariffID
      ? tariffApi
          .update(params, tariffID)
          .then((response) => {
            setRedirect(true);
          })
          .catch((x) => {
            console.log("Error adding tarifa");
          })
      : tariffApi
          .create(params)
          .then((response) => {
            setRedirect(true);
          })
          .catch((x) => {
            console.log("Error adding permiso");
          });
  };

  const handleTariffSelected = (packId) => {
    setSelectedPackID(packId);
    setOpenEdit(true);
  };

  if (redirect) {
    return <Redirect to="/tarifas"></Redirect>;
  }

  return (
    <Fade in={true} timeout={FADE_TRANSITION}>
      <div className={classes.root}>
        <ModalAddFormPack
          open={openAdd}
          setOpen={setOpenAdd}
          centerID={centerID}
          getTariff={getTariff}
          tariffID={tariffID}
        />
        <ModalEditFormPack
          open={openEdit}
          setOpen={setOpenEdit}
          packID={selectedPackID}
          getTariff={getTariff}
        ></ModalEditFormPack>
        <div className={classes.header}>
          <Grid container xs={12}>
            <Typography className={classes.headerText}>
              {tariffID ? <span>TARIFA</span> : <span>NUEVA TARIFA</span>}
            </Typography>
          </Grid>
        </div>

        <div className={classes.gridContainer}>
          <Grid container spacing={3} direction="row" justify="flex-start">
            <Grid item xs={12}>
              <Typography>
                <b>Datos de la tarifa</b>
              </Typography>
            </Grid>
            <Grid item xs="auto" sm="auto" md="auto" lg="auto">
              <Typography>
                Nombre de la tarifa
                <span style={{ color: "rgb(159,46,37)" }}>*</span>
              </Typography>
              <TextField
                disabled={isAdmin ? false : true}
                variant="outlined"
                size="small"
                placeholder="Nombre tarifa"
                value={values.name}
                onChange={(e) => handleChange("name", e.target.value)}
              ></TextField>
            </Grid>
            {tariffID ? (
              <Grid item xs="auto" sm="auto" md="auto" lg="auto">
                <Typography>Activa</Typography>
                <TextField
                  variant="outlined"
                  size="small"
                  id="type"
                  placeholder="Punto de salida"
                  select
                  value={values.active || 0}
                  onChange={(e) => handleChange("active", e.target.value)}
                >
                  {activeOptions.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
            ) : null}

            <Grid item xs={12}>
              <Typography>
                <b>Precios de la tarifa</b>
              </Typography>
            </Grid>
            <Grid item xs="auto" sm="auto" md="auto" lg="auto">
              <Typography>Matrícula</Typography>
              <TextField
                disabled={isAdmin ? false : true}
                variant="outlined"
                size="small"
                type="number"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">€</InputAdornment>
                  ),
                }}
                placeholder="0.00"
                value={values.pvpSignUp}
                onChange={(e) => handleChange("pvpSignUp", e.target.value)}
              ></TextField>
            </Grid>
            <Grid item xs="auto" sm="auto" md="auto" lg="auto">
              <Typography>Precio del curso</Typography>
              <TextField
                disabled={isAdmin ? false : true}
                variant="outlined"
                size="small"
                type="number"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">€</InputAdornment>
                  ),
                }}
                placeholder="0.00"
                value={values.pvpCourse}
                onChange={(e) => handleChange("pvpCourse", e.target.value)}
              ></TextField>
            </Grid>
            <Grid item xs="auto" sm="auto" md="auto" lg="auto">
              <Typography>Primera tramitación</Typography>
              <TextField
                disabled={isAdmin ? false : true}
                variant="outlined"
                size="small"
                placeholder="0.00"
                type="number"
                value={values.pvpFirstProcedure}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">€</InputAdornment>
                  ),
                }}
                onChange={(e) =>
                  handleChange("pvpFirstProcedure", e.target.value)
                }
              ></TextField>
            </Grid>
            <Grid item xxs="auto" sm="auto" md="auto" lg="auto">
              <Typography>Tramitación expediente</Typography>
              <TextField
                disabled={isAdmin ? false : true}
                variant="outlined"
                size="small"
                placeholder="0.00"
                type="number"
                value={values.pvpProcedure}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">€</InputAdornment>
                  ),
                }}
                onChange={(e) => handleChange("pvpProcedure", e.target.value)}
              ></TextField>
            </Grid>
            <Grid item xs="auto" sm="auto" md="auto" lg="auto">
              <Typography>Tasa</Typography>
              <TextField
                disabled={isAdmin ? false : true}
                variant="outlined"
                size="small"
                placeholder="0.00"
                type="number"
                value={values.pvpRate}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">€</InputAdornment>
                  ),
                }}
                onChange={(e) => handleChange("pvpRate", e.target.value)}
              ></TextField>
            </Grid>
            <Grid item xs="auto" sm="auto" md="auto" lg="auto">
              <Typography>Nº Clases completas</Typography>
              <TextField
                disabled={isAdmin ? false : true}
                variant="outlined"
                size="small"
                placeholder="0"
                type="number"
                value={values.completeClasses}
                onChange={(e) =>
                  handleChange("completeClasses", e.target.value)
                }
              ></TextField>
            </Grid>
            <Grid item xs="auto" sm="auto" md="auto" lg="auto">
              <Typography>Nº Clases simples</Typography>
              <TextField
                disabled={isAdmin ? false : true}
                variant="outlined"
                size="small"
                placeholder="0"
                type="number"
                value={values.simpleClasses}
                onChange={(e) => handleChange("simpleClasses", e.target.value)}
              ></TextField>
            </Grid>
            <Grid item xs="auto" sm="auto" md="auto" lg="auto">
              <Typography>Precio de clase</Typography>
              <TextField
                disabled={isAdmin ? false : true}
                variant="outlined"
                size="small"
                placeholder="0.00"
                type="number"
                value={values.pvpPracticalClass}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">€</InputAdornment>
                  ),
                }}
                onChange={(e) =>
                  handleChange("pvpPracticalClass", e.target.value)
                }
              ></TextField>
            </Grid>

            <Grid item xs={12}>
              <Typography>
                <b>Exámenes</b>
              </Typography>
            </Grid>
            <Grid item xs="auto" sm="auto" md="auto" lg="auto">
              <Typography>Primer examen teórico</Typography>
              <TextField
                disabled={isAdmin ? false : true}
                variant="outlined"
                size="small"
                placeholder="0"
                type="number"
                value={values.pvpFirstTheoricExam}
                onChange={(e) =>
                  handleChange("pvpFirstTheoricExam", e.target.value)
                }
              ></TextField>
            </Grid>
            <Grid item xs="auto" sm="auto" md="auto" lg="auto">
              <Typography>Examen teórico</Typography>
              <TextField
                disabled={isAdmin ? false : true}
                variant="outlined"
                size="small"
                placeholder="0"
                type="number"
                value={values.pvpTheoricExam}
                onChange={(e) => handleChange("pvpTheoricExam", e.target.value)}
              ></TextField>
            </Grid>
            <Grid item xs="auto" sm="auto" md="auto" lg="auto">
              <Typography>Examen práctico</Typography>
              <TextField
                disabled={isAdmin ? false : true}
                variant="outlined"
                size="small"
                placeholder="0"
                type="number"
                value={values.pvpPracticalExam}
                onChange={(e) =>
                  handleChange("pvpPracticalExam", e.target.value)
                }
              ></TextField>
            </Grid>

            {isAdmin ? (
              <Grid item xs={12}>
                <Button fullWidth onClick={handleSubmitTariff}>
                  {tariffID ? <span>MODIFICAR</span> : <span>CREAR</span>}
                </Button>
              </Grid>
            ) : null}
          </Grid>
          <Grid item xs={12} style={{ margin: "40px 0" }}></Grid>
          {tariffID ? (
            <Grid item xs="auto" sm="auto" md="auto" lg="auto">
              <Grid container item xs={12} justify="space-between">
                <Typography
                  style={{ marginBottom: "10px", fontWeight: "bold" }}
                >
                  Packs
                </Typography>
                {isAdmin ? (
                  <Button onClick={() => setOpenAdd(true)}>CREAR PACK</Button>
                ) : null}
              </Grid>
              <div style={{ marginBottom: 20 }}></div>
              <CustomTable
                // tableRef={tableRef}
                data={packs}
                columns={columns}
                hasPagination={false}
                handleClickEditOpen={isAdmin ? handleTariffSelected : () => {}}
              ></CustomTable>
            </Grid>
          ) : null}
        </div>
      </div>
    </Fade>
  );
};

export default FormTariff;
