import { Fade, Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { teachers as teachersAPI } from "../../../api";
import { DATE_FORMAT, FADE_TRANSITION } from "../../../lib/constants";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    minHeight: "100vh",
    "& .MuiTextField-root": {
      minWidth: "20ch",
    },
  },
  gridContainer: {
    backgroundColor: "rgb(255,255,255)",
    // margin: "20px",
    borderTop: "5px solid rgb(159,46,37)",
    borderRadius: "0 0 10px 10px",
    padding: 20,
  },
  header: {
    backgroundColor: "rgb(248,250,252)",
    borderBottom: "2px solid rgb(227,232,239)",
    height: 70,
  },
  headerText: {
    fontWeight: "bold",
    marginLeft: 40,
    color: theme.palette.primary.main,
  },
  headerButtons: {
    marginRight: 40,
  },
  textArea: {
    minHeight: 150,
    minWidth: "99%",
    maxWidth: "99%",
    width: "99",
    borderRadius: 3,
    padding: 8,
  },
}));

const TeacherProfile = ({ user }) => {
  const classes = useStyles();
  const [values, setValues] = useState([]);

  useEffect(() => {
    teachersAPI
      .getOne(user.id)
      .then((response) => {
        setValues([
          {
            title: "Nombre",
            value: `${response.result.firstName} ${response.result.lastName1} ${response.result.lastName2}`,
          },
          {
            title: "Fecha de nacimiento",
            value: moment(response.result.birthday).format(DATE_FORMAT),
          },
          {
            title: "DNI",
            value: response.result.dni,
          },
          {
            title: "Teléfono",
            value: response.result.phone,
          },
          {
            title: "Correo electrónico",
            value: response.result.email,
          },
        ]);
      })
      .catch((x) => {
        // setError("Error trayendo centros");
      });
  }, [user.id]);

  return (
    <Fade in={true} timeout={FADE_TRANSITION}>
      <div className={classes.root}>
        <Grid
          container
          className={classes.header}
          direction="row"
          justify="space-between"
          alignItems="center"
        >
          <Grid item>
            <Typography className={classes.headerText}>MI PERFIL</Typography>
          </Grid>
        </Grid>
        <Grid container>
          <Grid
            style={{
              backgroundColor: "white",
              margin: "20px 20px",
              borderRadius: "10px",
            }}
            container
            item
            xs={12}
            md={6}
            lg={5}
          >
            <Grid container item xl={12} style={{ padding: 20 }}>
              <Grid item xs={12}>
                <Typography>Información del Profesor</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography>Datos personales</Typography>
              </Grid>
            </Grid>
            {values.length > 0 &&
              values.map((value, index) => {
                return (
                  <Grid
                    container
                    item
                    xl={12}
                    key={index}
                    alignContent="center"
                    style={{
                      padding: 20,
                      height: "65px",
                      backgroundColor: index % 2 ? "#fff" : "rgb(245,249,251)",
                    }}
                  >
                    <Grid item xs={12} sm={6}>
                      <Typography>{value.title}</Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography>{value.value}</Typography>
                    </Grid>
                  </Grid>
                );
              })}
          </Grid>
        </Grid>
      </div>
    </Fade>
  );
};

export default TeacherProfile;
