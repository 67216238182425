import { MenuItem } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import React, { useState } from "react";
import { payments as paymentsAPI } from "../../../api";

const useStyles = makeStyles((theme) => ({
  //   root: {
  //     "& .MuiTextField-root": {
  //       width: "20ch",
  //     },
  //   },
  marginRight: {
    marginRight: theme.spacing(1),
  },
  labelRoot: {
    color: "white",
    fontWeight: "bold",
    "&$labelFocused": {
      color: "white",
    },
  },
  acceptButton: {
    backgroundColor: "rgb(18,151,91)",
    "&:hover": {
      backgroundColor: "rgb(18,151,91)",
    },
  },
  actions: {
    backgroundColor: "rgb(248,250,252)",
  },
}));

const ModalFormBuyPacks = (props) => {
  const { packs = [], open, setOpen, centerID, studentID } = props;
  const classes = useStyles();

  const [values, ] = useState({});
  const [redsysParameters, setRedsysParameters] = useState({});

  const handleChange = (selectedPack) => {
    let params = {
      centerID,
      studentID,
      packId: selectedPack.id,
      price: selectedPack.price,
    };
    paymentsAPI.getPaymentPacksDetails(params).then((result) => {
      setRedsysParameters(result.result);
    });
  };


  const handleCancelClose = () => {
    setOpen(false);
  };

  return (
    <Dialog
      open={open}
      fullWidth={true}
      maxWidth={"xs"}
      className={classes.root}
      onClose={handleCancelClose}
    >
      <DialogTitle id="form-dialog-title">COMPRAR PACK</DialogTitle>
      <Divider style={{ margin: "0 20px" }}></Divider>
      <DialogContent style={{ overflowY: "hidden", margin: "20px 0" }}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography>
              Seleccione un pack de clases para sumar a su bolsa personal. Se
              redireccionará a una pasarela de pago. Seleccione un pack y pulse
              en "Ir a comprar" para continuar.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography>Packs disponibles</Typography>
            <TextField
              fullWidth
              variant="outlined"
              size="small"
              id="teacher"
              placeholder="pack"
              select
              value={values.pack}
              onChange={(e) => handleChange(e.target.value)}
            >
              {packs.map((option) => (
                <MenuItem key={option.id} value={option}>
                  {option.description} ({option.price}€)
                </MenuItem>
              ))}
            </TextField>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions className={classes.actions}>
        <Button size="large" variant="outlined" onClick={handleCancelClose}>
          <CloseIcon className={classes.marginRight} />
          Cancelar
        </Button>
        {/* Ejemplo de redirect OK http://localhost:3000/?state=OK&studentId=49&centerId=2&packId=1&Ds_SignatureVersion=HMAC_SHA256_V1&Ds_MerchantParameters=eyJEc19EYXRlIjoiMDklMkYwMiUyRjIwMjEiLCJEc19Ib3VyIjoiMjAlM0E0NCIsIkRzX1NlY3VyZVBheW1lbnQiOiIxIiwiRHNfQW1vdW50IjoiMSIsIkRzX0N1cnJlbmN5IjoiOTc4IiwiRHNfT3JkZXIiOiIxNjEyODk5ODA2IiwiRHNfTWVyY2hhbnRDb2RlIjoiMzM3MTMxOTY1IiwiRHNfVGVybWluYWwiOiIwMDEiLCJEc19SZXNwb25zZSI6IjAwMDAiLCJEc19UcmFuc2FjdGlvblR5cGUiOiIwIiwiRHNfTWVyY2hhbnREYXRhIjoiIiwiRHNfQXV0aG9yaXNhdGlvbkNvZGUiOiIzNTg2MzEiLCJEc19Db25zdW1lckxhbmd1YWdlIjoiMSIsIkRzX0NhcmRfQ291bnRyeSI6IjgyNiIsIkRzX0NhcmRfQnJhbmQiOiIxIiwiRHNfUHJvY2Vzc2VkUGF5TWV0aG9kIjoiMSJ9&Ds_Signature=efRso_YWqYzY4xOC3kfMWM1qCDzh3ubihRPTWtYevw4= */}
        <form
          name="from"
          //PRUEBAS
          // action="https://sis-t.redsys.es:25443/sis/realizarPago"
          // REAL
          action="https://sis.redsys.es/sis/realizarPago"
          method="POST"
        >
          <input
            type="hidden"
            name="Ds_SignatureVersion"
            value="HMAC_SHA256_V1"
          />
          <input
            type="hidden"
            name="Ds_MerchantParameters"
            value={redsysParameters.Ds_MerchantParameters || null}
          />
          <input
            type="hidden"
            name="Ds_Signature"
            value={redsysParameters.Ds_Signature || null}
          />
          <span class="flex w-full rounded-md shadow-sm sm:ml-3 sm:w-auto">
            {/* <button
              type="submit"
              // disabled={true}
              // "currentPack.description == null"
            >
              Redireccionar
            </button> */}
            <Button
              type="submit"
              className={classes.acceptButton}
              size="large"
              variant="outlined"
              // onClick={handleSubmit}
            >
              <ShoppingCartIcon className={classes.marginRight} />
              Ir a comprar REDSYS
            </Button>
          </span>
        </form>
      </DialogActions>
    </Dialog>
  );
};

export default ModalFormBuyPacks;
