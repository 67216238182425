import axios from "axios";
import {API_NAME} from "./base";

const ClassPracticalInstance = axios.create({
  baseURL: `${API_NAME}api/classes`,
  withCredentials: true,
});

ClassPracticalInstance.interceptors.request.use(
  (config) => {
    config.headers.Authorization = `Bearer ${localStorage.getItem("user")}`;
    return config;
  },
  (error) => Promise.reject(error)
);

const ClassPracticalApi = {
  getClasses: ({
    disponibility = null,
    teacherID = null,
    exitPointID = null,
    centerID = null,
  } = {}) => {
    return ClassPracticalInstance.get(``, {
      params: { disponibility, teacherID, exitPointID, centerID },
    })
      .then((response) => response.data)
      .catch((err) => console.error(err));
  },

  getClassesForStudent: ({
    disponibility = null,
    teacherID = null,
    exitPointID = null,
    centerID = null,
  } = {}) => {
    return ClassPracticalInstance.get(`/classes-from-students`, {
      params: { disponibility, teacherID, exitPointID, centerID },
    })
      .then((response) => response.data)
      .catch((err) => console.error(err));
  },
};

const classesPractical = ClassPracticalApi;
export default classesPractical;
