export const LEVEL_BY_PERMISSE = {
  B: [0, 20, 40, 65, 92],
  A1: [0, 6, 12, 25, 54],
  A2: [0, 6, 12, 25, 54],
  AM: [0, 6, 12, 25, 54],
};

export const calculateLevelByPermit = (passedTests, licence = "B") => {
  let levelIndex = LEVEL_BY_PERMISSE[licence].findIndex((el) => {
    return el > passedTests;
  });

  // In case the user passed to the level 5.
  if (levelIndex == -1) return 5;
  if (levelIndex == 0) return 1;
  else return levelIndex;
};

export const testToNextLevel = (licence = "B", level, passed = 0) => {
  if (level == 5) return LEVEL_BY_PERMISSE[licence][level - 1] - passed;

  return LEVEL_BY_PERMISSE[licence][level] - passed;
};



