import axios from "axios";
import {API_NAME} from "./base";

const AuthInstance = axios.create({
  baseURL: `${API_NAME}api/users`,
  withCredentials: true,
});

AuthInstance.interceptors.request.use(
  (config) => {
    config.headers.Authorization = `Bearer ${localStorage.getItem("user")}`;
    return config;
  },
  (error) => Promise.reject(error)
);

const AuthInstanceApi = {
  login: async (user) => {
    try {
      const response = await AuthInstance.post("/login", user);
      return response.data;
    } catch (err) {
      return console.error(err);
    }
  },
  isLoged: async (token) => {
    try {
      const response = await AuthInstance.get(`/isLoged/${token}`);
      return response.data;
    } catch (err) {
      return console.error(err);
    }
  },
};

const users = AuthInstanceApi;
export default users;
