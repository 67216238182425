import { Button, Fade, makeStyles, Typography } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import React, { useEffect, useRef, useState } from "react";
import Moment from "react-moment";
import { Link, Redirect } from "react-router-dom";
import { courses as coursesAPI } from "../../../api";
import { DATE_FORMAT, FADE_TRANSITION } from "../../../lib/constants";
import CustomTable from "../../core/CustomTable";
import { LoadingIcon } from "../../core/LoadingIcon";

const NO_VALUE = "No valor";

const useStyles = makeStyles((theme) => ({
  rootGrid: {
    maxWidth: "100%",
    margin: "auto",
  },
  root: {
    flexGrow: 1,
    width: "100%",
    "& .MuiTextField-root": {
      minWidth: "20ch",
    },
  },
  header: {
    backgroundColor: "rgb(248,250,252)",
    borderBottom: "2px solid rgb(227,232,239)",
    height: 70,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  headerText: {
    fontWeight: "bold",
    marginLeft: 40,
    color: theme.palette.primary.main,
  },
  searchContainer: {
    margin: 20,
    border: "1px solid",
    borderColor: theme.palette.primary.dark,
    borderRadius: 5,
    padding: 12,
  },
  tabPanel: {
    backgroundColor: theme.palette.primary.main,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.primary.dark,
  },
  marginLeft: {
    marginLeft: "10px",
  },
  formControl: {
    width: "100%",
  },
  alignRight: {
    textAlign: "right",
  },
  alignLeft: {
    textAlign: "left",
  },
  container: {
    width: "100%",
    marginLeft: 12,
    marginRight: 12,
  },
  savedSearchLink: {
    marginLeft: "auto",
    marginRight: 0,
  },
}));

const columns = [
  {
    title: "DESCRIPCION",
    field: "name",
    emptyValue: NO_VALUE,
    cardFormat: "STRING",
    cellStyle: { textAlign: "center" },
    headerStyle: { textAlign: "center" },
  },
  {
    title: "FECHA DE INICIO",
    field: "startDate",
    emptyValue: NO_VALUE,
    render: (data) => (
      <Grid container direction="row" alignItems="center" justify="center">
        <Moment format={DATE_FORMAT}>{data.startDate}</Moment>
        {/* <EventIcon style={{ paddingLeft: 10 }}></EventIcon> */}
      </Grid>
    ),
    cardFormat: "DATE",
    cellStyle: { textAlign: "center" },
    headerStyle: { textAlign: "center" },
  },
  {
    title: "HORARIO",
    field: "startTime",
    emptyValue: NO_VALUE,
    render: (data) => (
      <Typography>
        {data.startTime.slice(0, data.startTime.length - 3)}H |{" "}
        {data.endTime.slice(0, data.endTime.length - 3)}H
      </Typography>
    ),
    cardFormat: "STRING",
    cellStyle: { textAlign: "center" },
    headerStyle: { textAlign: "center" },
  },
  {
    title: "PLAZAS",
    field: "slots",
    emptyValue: NO_VALUE,
    render: (data) => (
      <Typography>
        {data.assignedStudents}/{data.slots}
      </Typography>
    ),
    cardFormat: "STRING",
    cellStyle: { textAlign: "center" },
    headerStyle: { textAlign: "center" },
  },
  {
    title: "PROFESOR",
    field: "teacherName",
    emptyValue: NO_VALUE,
    render: (data) => (
      <Typography>
        {data.teacherName} {data.lastName}
      </Typography>
    ),
    cardFormat: "STRING",
    cellStyle: { textAlign: "center" },
    headerStyle: { textAlign: "center" },
  },
  {
    title: "MODALIDAD",
    field: "type",
    emptyValue: NO_VALUE,
    cardFormat: "STRING",
    cellStyle: { textAlign: "center" },
    headerStyle: { textAlign: "center" },
  },
];

const CoursesList = ({ centerID, isAdmin }) => {
  const classes = useStyles();
  const tableRef = useRef(null);

  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);
  const [selectedCourseID, setSelectedCourseID] = useState("");
  const [selectedCourseIDForStudents, setselectedCourseIDForStudents] =
    useState("");
  const [page, setPage] = useState(1);
  const defaultPageSize = 10;
  const [totalResults, setTotalResults] = useState();
  const [sorting, ] = useState({ orderBy: "name", orderDir: "asc" });

  const [criteria, ] = useState({ type: "Todos" });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    setIsLoading(true);
    let params = {
      centerID: centerID !== "1" ? centerID : null,
      perPage: defaultPageSize,
      page: page,
      ...criteria,
    };

    coursesAPI.getAll(params).then((result) => {
      setData(result.results);
      setPage(result.page);
      setTotalResults(result.totalCount);
      setIsLoading(false);
    });
  }, [criteria, page, sorting, centerID]);

  const handlePageChange = (newPage) => {
    window.scrollTo(0, 0);
    setPage(newPage);
  };
  const handleCourseSelected = (id) => {
    setselectedCourseIDForStudents(id);
  };

  const handleShowStudentList = (id) => {
    setSelectedCourseID(id);
  };

  if (selectedCourseID) {
    return <Redirect to={`/edit-course/${selectedCourseID}`}></Redirect>;
  }

  if (selectedCourseIDForStudents) {
    return (
      <Redirect
        to={`/course-students/${selectedCourseIDForStudents}`}
      ></Redirect>
    );
  }

  if (columns.length === 0) return "LOADING";

  return (
    <Fade in={true} timeout={FADE_TRANSITION}>
      <div className={classes.root}>
        <div style={{ margin: "40px 40px 5px 40px" }}>
          <Grid container spacing={2} direction="row" justify="flex-start">
            <Grid item xs={12} sm={12} md="auto" lg="auto">
              {centerID === 1 ? null : (
                <Grid
                  container
                  className={classes.headerButtons}
                  direction="row"
                  justify="flex-end"
                >
                  <Link to={`/add-course`}>
                    <Button>CREAR CURSO</Button>
                  </Link>
                </Grid>
              )}
            </Grid>
          </Grid>
        </div>
        <div style={{ padding: "20px 40px 0 40px" }}>
          {isLoading ? (
            <LoadingIcon></LoadingIcon>
          ) : (
            <CustomTable
              tableRef={tableRef}
              data={data}
              columns={columns}
              handlePageChange={handlePageChange}
              page={page}
              hasActions={true}
              isEditable={true}
              totalResults={totalResults}
              defaultPageSize={defaultPageSize}
              handleClickEditOpen={handleCourseSelected}
              handleDelete={handleShowStudentList}
            ></CustomTable>
          )}
        </div>
      </div>
    </Fade>
  );
};

export default CoursesList;
