import { Button, Fade, makeStyles, Typography } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import React, { useEffect, useRef, useState } from "react";
import Moment from "react-moment";
import { centers as centersAPI } from "../../../api";
import CustomTable from "../../../components/core/CustomTable";
import { DATE_FORMAT_COMPLETE, FADE_TRANSITION } from "../../../lib/constants";
import { exportData } from "../../../lib/exportData";
import CustomDatePicker from "../../core/CustomDatePicker";
import { LoadingIcon } from "../../core/LoadingIcon";

const NO_VALUE = "No valor";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
  },
  button: {
    marginRight: theme.spacing(1),
  },
  clearButton: {
    marginLeft: theme.spacing(2),
  },
  downLoadButton: {
    marginRight: theme.spacing(1),
    backgroundColor: "transparent",
    color: "rgb(56, 161, 105)",
    border: "1px solid rgb(56, 161, 105)",
    "&:hover": {
      marginRight: theme.spacing(1),
      border: "1px solid rgb(56, 161, 105)",
      backgroundColor: "rgb(56, 161, 105)",
      opacity: 1,
      color: "#FFFFFF",
    },
  },
}));

const columns = [
  {
    title: "Fecha",
    field: "date",
    render: (data) => (
      <Moment format={DATE_FORMAT_COMPLETE}>{data.date}</Moment>
    ),
    emptyValue: NO_VALUE,
    width: 200,
    cardFormat: "DATE_COMPLETE",
  },
  {
    title: "Alumno",
    field: "firstName",
    render: (data) => (
      <Typography>
        {data.firstName && data.firstName} {data.lastName1 && data.lastName1}{" "}
        {data.lastName2 && data.lastName2}
      </Typography>
    ),
    emptyValue: NO_VALUE,
    cardFormat: "STRING",
  },
  {
    title: "Descripción",
    field: "description",
    emptyValue: NO_VALUE,
    cardFormat: "STRING",
  },
  {
    title: "Tipo",
    field: "type",
    render: (data) => (
      <Typography>
        <span
          style={
            data.type === "Cargo"
              ? {
                  backgroundColor: "rgb(190, 227, 248)",
                  padding: "2px 9px",
                  borderRadius: "20px",
                }
              : {
                  backgroundColor: "rgb(198, 246, 213)",
                  padding: "2px 9px",
                  borderRadius: "20px",
                }
          }
        >
          {data.type}
        </span>
      </Typography>
    ),
    emptyValue: NO_VALUE,
    cardFormat: "TYPE_PAYMENT",
  },
  {
    title: "Cantidad",
    field: "quantity",
    type: "currency",
    currencySetting: { currencyCode: "EUR" },
    emptyValue: NO_VALUE,
    cardFormat: "CURRENCY",
  },
  {
    title: "Forma de pago",
    field: "paymentType",
    emptyValue: "",
    cardFormat: "STRING",
  },
];

const ArchingsMoves = ({ centerID }) => {
  const classes = useStyles();
  const tableRef = useRef(null);

  // const columns = useSearchParamsInColumns(tableColumns);
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const defaultPageSize = 10;
  const [totalResults, setTotalResults] = useState();

  const [criteria, setCriteria] = useState({});

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    setIsLoading(true);
    let params = {
      centerID: centerID !== "1" ? centerID : null,
      perPage: defaultPageSize,
      page: page,
      ...criteria,
    };
    centersAPI.getPayments(params).then((result) => {
      setData(result.results);
      setPage(result.page);
      setTotalResults(result.totalCount);
      setIsLoading(false);
    });
  }, [criteria, page, centerID]);

  const handleCriteriaChange = (newCriteria) => {
    setPage(1);
    setCriteria({ ...criteria, ...newCriteria });
  };

  const handlePageChange = (newPage) => {
    window.scrollTo(0, 0);
    setPage(newPage);
  };

  const downloadExcel = () => {
    let params = {
      view: "export",
      centerID: centerID !== "1" ? centerID : null,
      ...criteria,
    };
    centersAPI.getPayments(params).then((result) => {
      exportData(result.data, "Movimientos");
    });
  };

  if (columns.length === 0) return "LOADING";

  return (
    <Fade in={true} timeout={FADE_TRANSITION}>
      <div className={classes.root}>
        <div style={{ margin: "40px 40px 5px 40px" }}>
          <Grid
            container
            xs={12}
            spacing={2}
            direction="row"
            justify="space-between"
          >
            <Grid
              item
              xs={12}
              sm="auto"
              md="auto"
              lg="auto"
              direction="row"
              justify="flex-start"
            >
              <CustomDatePicker
                startDate={criteria.startDate}
                endDate={criteria.endDate}
                handleChangeCriteria={handleCriteriaChange}
              ></CustomDatePicker>
              {/* <Button className={classes.clearButton} onClick={deleteFilters}>
              Quitar filtros
            </Button> */}
            </Grid>
            <Grid
              item
              xs={12}
              sm="auto"
              md="auto"
              lg="auto"
              direction="row"
              justify="flex-end"
            >
              <Button
                fullWidth
                onClick={() => downloadExcel()}
                className={classes.downLoadButton}
                variant="outlined"
              >
                <span>Exportar movimientos a Excel</span>
              </Button>
            </Grid>
          </Grid>
        </div>

        <div style={{ padding: "20px 40px 0 40px" }}>
          {isLoading ? (
            <LoadingIcon></LoadingIcon>
          ) : (
            <CustomTable
              tableRef={tableRef}
              data={data}
              columns={columns}
              handlePageChange={handlePageChange}
              page={page}
              totalResults={totalResults}
              defaultPageSize={defaultPageSize}
              // handleClickEditOpen={handleStudentSelected}
            ></CustomTable>
          )}
        </div>
      </div>
    </Fade>
  );
};

export default ArchingsMoves;
