import { createMuiTheme } from "@material-ui/core";

export const theme = createMuiTheme({
  typography: {
    fontFamily: "Nunito, Lato, sans serif",
    caption: {
      color: "#FFFFFF",
      opacity: 0.4,
    },
    button: {
      textTransform: "none",
      fontWeight: "bold",
    },
  },
  overrides: {
    MuiTooltip: {
      tooltip: {
        fontSize: "1em",
      },
    },
    MuiPaper: {
      rounded: {
        // backgroundColor: "#002C43",
        border: "1px solid rgba(0,23,44,0.4)",
        boxShadow: "0px 0px 2px 0px rgba(0,0,0,0.24)",
        borderRadius: "3px",
        "&:hover": {
          border: "1px solid #007fbd",
          boxShadow: "0px 0px 6px 0px rgba(0,178,233,1)",
        },
      },
      elevation4: {
        boxShadow: 0,
      },
      elevation2: {
        border: "1px solid rgba(0,23,44,0.4)",
        "&:hover": {
          border: "1px solid rgba(0,23,44,0.4)",
          boxShadow: "none",
        },
      },
    },
    MuiButton: {
      root: {
        backgroundColor: "#ad201c",
        border: "1px solid #ad201c",
        borderRadius: "8px",
        fontSize: "14px",
        color: "rgba(253,245,245)",
        "&:hover": {
          backgroundColor: "rgba(159,46,37, 0.90)",
          border: "1px solid rgba(159,46,37)",
        },
        "&:active": {
          border: "1px solid rgba(159,46,37)",
          backgroundColor: "rgba(159,46,37)",
          opacity: 1,
          color: "#FFFFFF",
        },
      },
      textPrimary: {
        color: "",
      },
      outlinedSizeSmall: {
        marginRight: 8,
      },
    },
    
  },

  palette: {
    // backgroundColor: "#00182C",
    primary: {
      main: "#ad201c",
      // light: "#505050",
      // dark: "#002C43",
    },
    secondary: {
      main: "#FFFFFF",
    },
    // type: "dark",
  },
});
