import { Button, Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React, { useEffect, useRef, useState } from "react";
import { NavLink, Redirect } from "react-router-dom";
import { centers as centersAPI } from "../../api";
import { NO_VALUE } from "../../lib/constants";
import CustomTable from "../core/CustomTable";

const useStyles = makeStyles((theme) => ({
  rootGrid: {
    margin: "auto",
  },
  root: {
    flexGrow: 1,
    width: "100%",
    minHeight: "100vh",
  },
  header: {
    backgroundColor: "rgb(248,250,252)",
    borderBottom: "2px solid rgb(227,232,239)",
    height: 70,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  headerText: {
    fontWeight: "bold",
    marginLeft: 40,
    color: theme.palette.primary.main,
  },
  headerButtons: {
    marginRight: 40,
  },
  searchContainer: {
    margin: 20,
    border: "1px solid",
    borderColor: theme.palette.primary.dark,
    borderRadius: 5,
    padding: 12,
  },
  tabPanel: {
    backgroundColor: theme.palette.primary.main,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.primary.dark,
  },
  formControl: {
    width: "100%",
  },
  alignRight: {
    textAlign: "right",
  },
  alignLeft: {
    textAlign: "left",
  },
  container: {
    width: "100%",
    marginLeft: 12,
    marginRight: 12,
  },
  savedSearchLink: {
    marginLeft: "auto",
    marginRight: 0,
  },
}));

const columns = [
  { title: "Centro", field: "name", emptyValue: NO_VALUE },
  { title: "Denominacion", field: "denomination", emptyValue: NO_VALUE },
  { title: "CIF", field: "cif", emptyValue: NO_VALUE },
  { title: "Ciudad", field: "city", emptyValue: NO_VALUE },
  { title: "Min. Precio", field: "minPrice", emptyValue: NO_VALUE },
  { title: "Max. Precio", field: "maxPrice", emptyValue: NO_VALUE },
];

const CenterList = () => {
  const classes = useStyles();
  const [centers, setCenters] = useState([]);
  const [selectedCenterID, setSelectedCenterID] = useState(null);
  const [, setError] = useState(null);
  const tableRef = useRef(null);

  useEffect(() => {
    getCenters();
  }, []);

  const getCenters = () => {
    centersAPI
      .getAll()
      .then((response) => {
        setCenters(response.results);
      })
      .catch((x) => {
        setError("Error trayendo permisos");
      });
  };

  

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleCenterSelected = (id) => {
    setSelectedCenterID(id);
  };

  if (selectedCenterID) {
    return <Redirect to={`/edit-center/${selectedCenterID}`}></Redirect>;
  }

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <Grid container xs={12}>
          <Typography className={classes.headerText}>
            <span>CENTROS</span>
          </Typography>
        </Grid>
        <Grid
          container
          spacing={3}
          xs={12}
          className={classes.headerButtons}
          direction="row"
          justify="flex-end"
        >
          <NavLink to="add-center">
            <Button onClick={()=>{}}>CREAR CENTRO</Button>
          </NavLink>
        </Grid>
      </div>

      <div
        style={{
          padding: "20px 40px 0 40px",
          margin: "0 auto",
          position: "relative",
        }}
      >
        <CustomTable
          tableRef={tableRef}
          data={centers}
          columns={columns}
          hasPagination={false}
          handleClickEditOpen={handleCenterSelected}
        ></CustomTable>
      </div>
    </div>
  );
};

export default CenterList;
