import axios from "axios";
import {API_NAME} from "./base";

const HowInstance = axios.create({
  baseURL: `${API_NAME}api/hows`,
  withCredentials: true,
});

HowInstance.interceptors.request.use(
  (config) => {
    config.headers.Authorization = `Bearer ${localStorage.getItem("user")}`;
    return config;
  },
  (error) => Promise.reject(error)
);

const HowApi = {
  getAll: ({ centerID = null } = {}) => {
    return HowInstance.get(``, {
      params: { centerID },
    })
      .then((response) => response.data)
      .catch((err) => console.error(err));
  },

  getOne: (studentID) => {
    return HowInstance.get(`/${studentID}`)
      .then((response) => response.data)
      .catch((err) => console.error(err));
  },

  create: (data) => {
    return HowInstance.post("", data)
      .then((response) => response.data)
      .catch((err) => console.error(err));
  },

  update: (data, ID) => {
    return HowInstance.post(`/${ID}`, data)
      .then((response) => response.data)
      .catch((err) => console.error(err));
  },
};

const hows = HowApi;
export default hows;
