import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import SaveIcon from "@material-ui/icons/Save";
import React from "react";

const useStyles = makeStyles((theme) => ({
  marginRight: {
    marginRight: theme.spacing(1),
  },
  labelRoot: {
    color: "white",
    fontWeight: "bold",
    "&$labelFocused": {
      color: "white",
    },
  },
  acceptButton: {
    backgroundColor: "rgb(18,151,91)",
    "&:hover": {
      backgroundColor: "rgb(18,151,91)",
    },
  },
  actions: {
    backgroundColor: "rgb(248,250,252)",
  },
}));

const ModalDelete = (props) => {
  const { open, setOpen } = props;
  const classes = useStyles();

  const handleCancelClose = () => {
    setOpen(false);
  };

  return (
    <Dialog open={open} onClose={handleCancelClose}>
      <DialogTitle id="form-dialog-title">
        <span>ELMINAR ESTUDIANTE ¡ATENCIÓN!</span>
      </DialogTitle>
      <Divider style={{ margin: "0 20px" }}></Divider>
      <DialogContent style={{ overflowY: "hidden", margin: "20px 0" }}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography>
              Recuerda arreglar cargos en su ficha económica.
            </Typography>
            <Typography>
              Si es el 1er examen práctico borrar clases promocionales de la
              bolsa.
            </Typography>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions className={classes.actions}>
        <Button
          className={classes.acceptButton}
          size="large"
          variant="outlined"
          onClick={handleCancelClose}
        >
          <SaveIcon className={classes.marginRight} /> <span>Aceptar</span>
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ModalDelete;
