import axios from "axios";
import { API_NAME_2 } from "../base";

const StudentExitPointInstance = axios.create({
  baseURL: `${API_NAME_2}student/exit-points`,
  withCredentials: true,
});

StudentExitPointInstance.interceptors.request.use(
  (config) => {
    config.headers.Authorization = `Bearer ${localStorage.getItem("user")}`;
    return config;
  },
  (error) => Promise.reject(error)
);

const studentExitPointsApi = {
  getAll: async () => {
    return StudentExitPointInstance.get(`/list`)
      .then((response) => {
        return response.data;
      })
      .catch((err) => console.error(err));
  },
};

const studentExitPoints = studentExitPointsApi;
export default studentExitPoints;
