import { Fade, Grid, TextField, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { centers as centersAPI } from "../../../api";
import { DATE_FORMAT, FADE_TRANSITION } from "../../../lib/constants";
import EditBannerInput from "./components/EditBannerInput";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    minHeight: "100vh",
    "& .MuiTextField-root": {
      minWidth: "20ch",
    },
  },
  gridContainer: {
    backgroundColor: "rgb(255,255,255)",
    // margin: "20px",
    borderTop: "5px solid rgb(159,46,37)",
    borderRadius: "0 0 10px 10px",
    padding: 20,
  },
  header: {
    backgroundColor: "rgb(248,250,252)",
    borderBottom: "2px solid rgb(227,232,239)",
    height: 70,
  },
  headerText: {
    fontWeight: "bold",
    marginLeft: 40,
    color: theme.palette.primary.main,
  },
  headerButtons: {
    marginRight: 40,
  },
  textArea: {
    minHeight: 150,
    minWidth: "99%",
    maxWidth: "99%",
    width: "99",
    borderRadius: 3,
    padding: 8,
  },
}));

const CoursesConfig = ({ user }) => {
  const classes = useStyles();
  const [values, setValues] = useState([]);

  const fetchCenters = () => {
    centersAPI
    .getAll()
    .then((response) => {
      const data = response.results.filter((o) => o.id !== 1);
      setValues(data);
    })
    .catch((x) => {
      // setError("Error trayendo centros");
    });
  };

  useEffect(() => {
   fetchCenters()
  }, []);

  return (
    <Fade in={true} timeout={FADE_TRANSITION}>
      <div className={classes.root}>
        <Grid
          container
          className={classes.header}
          direction="row"
          justify="space-between"
          alignItems="center"
        >
          <Grid item>
            <Typography className={classes.headerText}>BANNERS</Typography>
          </Grid>
        </Grid>
        <Grid container>
          <Grid
            style={{
              backgroundColor: "white",
              margin: "20px 20px",
              borderRadius: "10px",
            }}
            container
            item
            xs={12}
            md={6}
            lg={5}
          >
            {values.length > 0 &&
              values.map((center, index) => {
                return (
                  <Grid key={index} item xs={12}>
                    <h2>{center.name}</h2>
                    <label>Código</label>
                    <EditBannerInput
                      field={"activeCode"}
                      center={center}
                      refreshCall={fetchCenters}
                    />
                    <label>Banner Largo</label>
                    <EditBannerInput
                      field={"bannerBig"}
                      center={center}
                      refreshCall={fetchCenters}
                    />
                    <label>Banner Corto</label>
                    <EditBannerInput
                      field={"bannerSmall"}
                      center={center}
                      refreshCall={fetchCenters}
                    />
                  </Grid>
                );
              })}
          </Grid>
        </Grid>
      </div>
    </Fade>
  );
};

export default CoursesConfig;
