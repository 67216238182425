import axios from "axios";
import {API_NAME} from "./base";

const PacksInstance = axios.create({
  baseURL: `${API_NAME}api/packs`,
  withCredentials: true,
});

PacksInstance.interceptors.request.use(
  (config) => {
    config.headers.Authorization = `Bearer ${localStorage.getItem("user")}`;
    return config;
  },
  (error) => Promise.reject(error)
);

const PackApi = {
  createPack: (data) => {
    return PacksInstance.post("", data)
      .then((response) => response.data)
      .catch((err) => console.error(err));
  },

  updatePack: (data, ID) => {
    return PacksInstance.post(`/${ID}`, data)
      .then((response) => response.data)
      .catch((err) => console.error(err));
  },
};

const packs = PackApi;
export default packs;
