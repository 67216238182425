import axios from "axios";
import {API_NAME} from "./base";

const RequestInstance = axios.create({
  baseURL: `${API_NAME}api/requests`,
  withCredentials: true,
});

RequestInstance.interceptors.request.use(
  (config) => {
    config.headers.Authorization = `Bearer ${localStorage.getItem("user")}`;
    return config;
  },
  (error) => Promise.reject(error)
);

const RequestApi = {
  getAll: async ({
    q = null,
    type = null,
    orderBy = "name",
    orderDir = "asc",
    view = "listing",
  } = {}) => {
    return RequestInstance.get(``, {
      params: {
        q,
        type,
        orderBy,
        orderDir,
        view,
      },
    })
      .then((response) => response.data)
      .catch((err) => console.error(err));
  },

  getLastStudentsRequests: async ({
    centerID = null,
    q = null,
    type = null,
    orderBy = "name",
    orderDir = "asc",
    view = "listing",
  } = {}) => {
    return RequestInstance.get(`last-students-requests`, {
      params: {
        centerID,
        q,
        type,
        orderBy,
        orderDir,
        view,
      },
    })
      .then((response) => response.data)
      .catch((err) => console.error(err));
  },

  storeRequest: async (data) => {
    return RequestInstance.post("/create-request", data)
      .then((response) => response.data)
      .catch((err) => console.error(err));
  }
}


const requests = RequestApi;
export default requests;
