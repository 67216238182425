import { Fade, makeStyles } from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import Moment from "react-moment";
import { Redirect } from "react-router-dom";
import { students as studentsAPI } from "../../../api";
import { DATE_FORMAT, FADE_TRANSITION } from "../../../lib/constants";
import CustomTable from "../../core/CustomTable";
const NO_VALUE = "No valor";

const useStyles = makeStyles((theme) => ({
  rootGrid: {
    maxWidth: "100%",
    margin: "auto",
  },
  root: {
    flexGrow: 1,
    width: "100%",
    "& .MuiTextField-root": {
      minWidth: "20ch",
    },
  },
  header: {
    backgroundColor: "rgb(248,250,252)",
    borderBottom: "2px solid rgb(227,232,239)",
    height: 70,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  headerText: {
    fontWeight: "bold",
    marginLeft: 40,
    color: theme.palette.primary.main,
  },
  headerButtons: {
    marginRight: 40,
  },
  searchContainer: {
    margin: 20,
    border: "1px solid",
    borderColor: theme.palette.primary.dark,
    borderRadius: 5,
    padding: 12,
  },
  tabPanel: {
    backgroundColor: theme.palette.primary.main,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.primary.dark,
  },
  formControl: {
    width: "100%",
  },
  alignRight: {
    textAlign: "right",
  },
  alignLeft: {
    textAlign: "left",
  },
  container: {
    width: "100%",
    marginLeft: 12,
    marginRight: 12,
  },
  savedSearchLink: {
    marginLeft: "auto",
    marginRight: 0,
  },
}));

const columns = [
  {
    title: "ID",
    field: "id",
    emptyValue: NO_VALUE,
    width: 5,
    cardFormat: "STRING",
  },
  {
    title: "Fecha de examen",
    field: "date",
    render: (data) => <Moment format={DATE_FORMAT}>{data.date}</Moment>,
    emptyValue: NO_VALUE,
    cardFormat: "DATE",
  },
  {
    title: "Tipo de examen",
    field: "type",
    emptyValue: NO_VALUE,
    cardFormat: "STRING",
  },
  {
    title: "Resultado",
    field: "result",
    emptyValue: "----",
    cardFormat: "STRING",
  },
];

const StudentExams = ({ centerID, studentID }) => {
  const classes = useStyles();
  const tableRef = useRef(null);
  //CAMBIAR A DINAMICO

  // const columns = useSearchParamsInColumns(tableColumns);
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const defaultPageSize = 10;
  const [totalResults, setTotalResults] = useState();
  const [selectedExamID, setSelectedExamID] = useState("");

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    let params = {
      studentID: studentID,
      perPage: defaultPageSize,
      page: page,
    };

    studentsAPI.getStudentExams(params).then((result) => {
      setData(result.results);
      setPage(result.page);
      setTotalResults(result.totalCount);
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  const handlePageChange = (newPage) => {
    window.scrollTo(0, 0);
    setPage(newPage);
  };

  const handleExamSelected = (id) => {
    setSelectedExamID(id);
  };

  if (selectedExamID) {
    return <Redirect to={`/examen/${selectedExamID}`}></Redirect>;
  }

  if (columns.length === 0) return "LOADING";

  return (
    <Fade in={true} timeout={FADE_TRANSITION}>
      <div className={classes.root}>
        <div style={{ padding: "20px 40px 0 40px" }}>
          <CustomTable
            tableRef={tableRef}
            data={data}
            columns={columns}
            handlePageChange={handlePageChange}
            page={page}
            totalResults={totalResults}
            defaultPageSize={defaultPageSize}
            handleClickEditOpen={handleExamSelected}
          ></CustomTable>
        </div>
      </div>
    </Fade>
  );
};

export default StudentExams;
