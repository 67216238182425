import { Fade, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import DescriptionIcon from "@material-ui/icons/Description";
import MaterialTable from "material-table";
import React, { useEffect, useState } from "react";
import { FADE_TRANSITION, NO_VALUE, ROJO_AUTIUS } from "../../../lib/constants";
import ModalAddContractPrice from "./ModalAddContractPrice";
import { students as studentsAPI } from "../../../api";

import ModalRecibo from "./ModalRecibo";
import TrafficAuth from "../../documents/TrafficAuth/TrafficAuth";
import { PDFDownloadLink } from "@react-pdf/renderer";

const useStyles = makeStyles((theme) => ({
  rootGrid: {
    // maxWidth: "190px",
    margin: "auto",
  },
  root: {
    flexGrow: 1,
    width: "100%",
    minHeight: "100vh",
  },
  header: {
    backgroundColor: "rgb(248,250,252)",
    borderBottom: "2px solid rgb(227,232,239)",
    height: 70,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  headerText: {
    fontWeight: "bold",
    marginLeft: 40,
    color: theme.palette.primary.main,
  },
  headerButtons: {
    marginRight: 40,
  },
  searchContainer: {
    margin: 20,
    border: "1px solid",
    borderColor: theme.palette.primary.dark,
    borderRadius: 5,
    padding: 12,
  },
  tabPanel: {
    backgroundColor: theme.palette.primary.main,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.primary.dark,
  },
  formControl: {
    width: "100%",
  },
  alignRight: {
    textAlign: "right",
  },
  alignLeft: {
    textAlign: "left",
  },
  container: {
    width: "100%",
    marginLeft: 12,
    marginRight: 12,
  },
  savedSearchLink: {
    marginLeft: "auto",
    marginRight: 0,
  },
}));

const columns = [
  { title: "Tipo", field: "title", emptyValue: NO_VALUE },
  { title: "Descarga", field: "link", emptyValue: NO_VALUE },
];

const StudentDownloads = ({ studentID }) => {
  const classes = useStyles();
  const [openPrice, setOpenPrice] = useState(null);
  const [openRecibo, setOpenRecibo] = useState(null);
  const [dataForPdf, setDataForPdf] = useState(null);


  

  useEffect(() => {
    studentsAPI.getContrato(studentID).then((result) => {
      setDataForPdf(result);
    });
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  if (columns.length === 0) return "LOADING";

  if (dataForPdf === null) return <></>;

  const DOCUMENT_TYPES = [
    {
      index: 0,
      title: "Contrato de enseñanza",
      type: "contrato",
      link: (
        <DescriptionIcon
          style={{ cursor: "pointer" }}
          onClick={() => setOpenPrice(true)}
        ></DescriptionIcon>
      ),
    },
    {
      index: 1,
      title: "Autorizacion Trafico",
      type: "autorizacion",
      link: ( <PDFDownloadLink
        document={<TrafficAuth data={dataForPdf} />}
        fileName={`Autorizacion-${dataForPdf.student.firstName}-${dataForPdf.student.lastName1}`}
      >
        {({ blob, url, loading, error }) =>
          loading ? "Cargando document..." : <DescriptionIcon></DescriptionIcon>
        }
      </PDFDownloadLink>
      ),
    },
    {
      index: 2,
      title: "Solicitud de aptitud",
      type: "solicitud",
      link: (
        <a
          style={{ cursor: "pointer" }}
          href={`https://develop2020.autius.com/api/generateAptitudeRequest/${studentID}`}
        >
          <DescriptionIcon></DescriptionIcon>
        </a>
      ),
    },
    {
      index: 3,
      title: "Traslado de expediente",
      type: "traslado",
      link: (
        <a
          style={{ color: "black" }}
          href={`https://develop2020.autius.com/api/generateFileTransfer/${studentID}`}
        >
          <DescriptionIcon></DescriptionIcon>
        </a>
      ),
    },
    {
      index: 4,
      title: "Ficha Teorica",
      type: "teorica",
      link: (
        <a
          style={{ color: "black" }}
          href={`https://develop2020.autius.com/api/generateTheoricalFile/${studentID}`}
        >
          <DescriptionIcon></DescriptionIcon>
        </a>
      ),
    },
    {
      index: 5,
      title: "Ficha Practica",
      type: "practica",
      link: (
        <a
          style={{ color: "black" }}
          href={`https://develop2020.autius.com/api/generatePracticalFile/${studentID}`}
        >
          <DescriptionIcon></DescriptionIcon>
        </a>
      ),
    },
    {
      index: 6,
      title: "Generar recibo",
      type: "recibo",
      link: (
        <DescriptionIcon
          style={{ cursor: "pointer" }}
          onClick={() => setOpenRecibo(true)}
        ></DescriptionIcon>
      ),
    },
  ];

  return (
    <Fade in={true} timeout={FADE_TRANSITION}>
      <div className={classes.root}>
        <ModalAddContractPrice
          data={dataForPdf}
          open={openPrice}
          setOpen={setOpenPrice}
          studentID={studentID}
        />
        <ModalRecibo
          open={openRecibo}
          setOpen={setOpenRecibo}
          studentID={studentID}
        ></ModalRecibo>
        <div style={{ padding: "40px 40px 0 40px" }}>
          <Grid container className={classes.rootGrid}>
            <Grid item xs={12}>
              <MaterialTable
                style={{
                  display: "grid",
                }}
                columns={columns}
                data={DOCUMENT_TYPES}
                options={{
                  headerStyle: {
                    backgroundColor: ROJO_AUTIUS,
                    color: "#FFF",
                    fontWeight: "bold",
                    padding: 8,
                  },
                  search: false,
                  toolbar: false,
                  paging: false,
                  pageSize: 25,
                  pageSizeOptions: [],
                  addRowPosition: "first",
                  actionsColumnIndex: -1,
                }}
                // actions={[
                //   {
                //     icon: () => <DescriptionIcon></DescriptionIcon>,
                //     tooltip: "Descargar Documento",
                //     onClick: (event, rowData) => {

                //       handleDownload(rowData.type);
                //     },
                //   },
                // ]}
                localization={{
                  header: {
                    actions: "Descarga",
                  },
                }}
              />
            </Grid>
          </Grid>
        </div>
      </div>
    </Fade>
  );
};

export default StudentDownloads;
